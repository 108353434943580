<div class="color-cardLabel">
  <label>{{ inputTitle }}</label>
</div>

<div class="color-card">
  <div class="color-squares">
    <button mat-icon-button (click)="toggleLike()">
      <mat-icon class="mat-icon">
        {{ isLiked ? 'favorite' : 'favorite_border' }}
      </mat-icon>
    </button>
  </div>
</div>
