/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductVariantTypeEnum = 'design' | 'html' | 'development' | 'implementation';

export const ProductVariantTypeEnum = {
    Design: 'design' as ProductVariantTypeEnum,
    Html: 'html' as ProductVariantTypeEnum,
    Development: 'development' as ProductVariantTypeEnum,
    Implementation: 'implementation' as ProductVariantTypeEnum
};

