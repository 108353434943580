<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="example-tree drive1"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    class="folerText"
    [ngClass]="{ selected: isNodeSelected(node) }"
  >
    {{ node.name }}
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div
      matTreeNodeToggle
      (click)="onSelect(node)"
      class="mat-tree-node d-flex gap"
      [ngClass]="{ selected: isNodeSelected(node) }"
    >
      <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="fs-10">
          {{ treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right' }}
        </mat-icon>
      </button>
      <mat-icon class="mat-icon-rtl-mirror" class="folderIcon">
        folder
      </mat-icon>
      <div class="folerText">
        {{ node.name }}
      </div>
    </div>
    <div
      [class.example-tree-invisible]="!treeControl.isExpanded(node)"
      role="group"
    >
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
