import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';

/**
 *PriceComponent
 */
@Component({
  selector: 'lib-price',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './price.component.html',
  styleUrls: [
    './price.component.scss',
  ],
})
export class PriceComponent {
  @Input() inputTitle = '';

  @Input() currencyCode: string[] = [
  ];

  @Input() inputPrice:  string | null = null;

  @Input() inputIcon = '';

  selectedHeading: string | null = '';

  /**
   *onSelectHeading
   * @param headingLevel -headingLevel
   */
  onSelectHeading(headingLevel: string): void {
    this.selectedHeading = headingLevel;
  }
}
