<div class="empty-page-box">
  <div class="empty-page-box-inner">
    <div class="empty-page-img">
      <img src="{{ heroIcon }}" alt="Genera" />
    </div>
    <div class="empty-page-content">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
  </div>
</div>
