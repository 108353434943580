import {
  ChangeDetectionStrategy, Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  FooterComponent,
} from '@genera/ui-components';
import {
  RouterModule,
} from '@angular/router';

/**
 *Footer Bar Component
 */
@Component({
  selector: 'app-footer-bar',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    RouterModule,
  ],
  templateUrl: './footer-bar.component.html',
  styleUrls: [
    './footer-bar.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterBarComponent {
  serviceName = 'Dashboard';

  year = 2023;

  version = '2.0.0';
}
