import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 * No Data UI Component
 */
@Component({
  selector: 'lib-no-data',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './no-data.component.html',
  styleUrls: [
    './no-data.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {
  @Input() noDataImage! : string;

  @Input() noDataTitle! : string;

  @Input() noDataDescription! : string;
}
