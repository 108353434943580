/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductVariantTypeEnum } from './product-variant-type-enum';


export interface UpdateProductVariantDto { 
    /**
     * Product Variant name
     */
    name?: string;
    /**
     * Description about the Product Variants
     */
    description?: string;
    /**
     * Icon of the Product Variant
     */
    icon?: string;
    /**
     * Specifies if the Product Variants is enabled/disabled
     */
    enabled?: boolean;
    /**
     * Product Id the Product Variants belongs to
     */
    productId?: string;
    /**
     * Specifies if the Product Variants Steps is edit able or not
     */
    isEditStepsAble?: boolean;
    productVariantType?: ProductVariantTypeEnum;
}
export namespace UpdateProductVariantDto {
}


