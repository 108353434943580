import  {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  RouterModule,
} from '@angular/router';
export interface ItemsData {
  label: string;
  url: string;
}

/**
 *Breadcrumb Component
 */
@Component({
  selector: 'lib-breadcrumb',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrls: [
    './breadcrumb.component.scss',
  ],
})
export class BreadcrumbComponent {
  @Input() items: ItemsData[] = [
  ];

  @Output() itemClick = new EventEmitter<ItemsData>();

  selectedBreadcrumb: ItemsData | null = null;


  rstItemSelected = true;


  firstItemSelected = true;

  defaultBreadcrumbs: object = [
    {
      label: 'Home', url: '/',
    },
  ];

  /**
   *
   */
  constructor() {
    if (this.items.length === 0) {
      this.items = [
        {
          label: 'Home', url: '/',
        },
      ];
    }
  }

  /**
   * Handles the click event for a breadcrumb item.
   * @param item - The breadcrumb item.
   * @param item.label - The label of the breadcrumb item.
   * @param item.url - The URL associated with the breadcrumb item.
   */
  onItemClick(item: ItemsData) {
    this.selectedBreadcrumb = item;
    this.itemClick.emit(item);
  }

  /**
   *
   * @param index -index
   */
  selectBreadcrumb(index: number): void {
    // Set firstItemSelected based on the index selected
    this.firstItemSelected = index === 0;
  }

}
