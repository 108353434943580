import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatTableModule,
} from '@angular/material/table';
import {
  PeopleToggleButtonComponent,
} from '../people-toggle-button/people-toggle-button.component';
import {
  MatChipsModule,
} from '@angular/material/chips';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
/**
 *
 */
@Component({
  selector: 'lib-people-contact-component',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    PeopleToggleButtonComponent,
    MatChipsModule,
  ],
  templateUrl: './people-component.component.html',
  styleUrls: [
    './people-component.component.scss',
  ],
})
export class PeopleComponentComponent {

  @Input() value!: string;

}
