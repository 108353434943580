import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';

/**
 *
 */
@Component({
  selector: 'lib-card-content',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
  ],
  templateUrl: './card-content.component.html',
  styleUrls: [
    './card-content.component.scss',
  ],
})
export class CardContentComponent {
  @Input() cardName = '';

  @Input() iconName = '';

  @Input() link = '';
}
