<div class="dropdown-people">
  <div class="dropdown-selector" [matMenuTriggerFor]="people">
    <div class="dropdown-text">{{ menuTitle }}</div>
    <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
  </div>

  <mat-menu #people="matMenu" class="dropdown-people-list">
    <div class="dropdown-search">
      <mat-icon class="icon-search">search</mat-icon>
      <input
        type="text"
        [placeholder]="placeholder"
        class="search-input"
        (click)="preventClosure($event)"
        [(ngModel)]="searchTerm"
      />
    </div>
    <ul class="dropdown-list">
      <li class="dropdown-list-item" *ngFor="let item of filteredItems">
        <div class="dropdown-list-content">
          <div class="user-img">
            <img [src]="item.img" alt="{{ item.name }}" />
          </div>
          <div class="user-info">
            <div class="user-name">{{ item.name }}</div>
            <div class="user-email">{{ item.email }}</div>
          </div>
          <div class="dropdown-icon">
            <mat-icon class="fs-20-grey">play_arrow</mat-icon>
          </div>
        </div>
      </li>
    </ul>
    <mat-divider></mat-divider>
    <ul class="dropdown-list">
      <li class="dropdown-list-item" *ngFor="let item of folder">
        <div class="dropdown-list-content">
          <div class="user-img">
            <span class="icon-bg">
              <mat-icon [ngStyle]="{ color: item.color }">{{
                item.icon
              }}</mat-icon>
            </span>
          </div>
          <div class="user-info">
            <div class="user-name">{{ item.folderName }}</div>
          </div>
        </div>
      </li>
    </ul>
  </mat-menu>
</div>
