<div class="chip">
  <mat-card>
    <h6>{{ mappingTxt }}</h6>
    <span
      class="chipcontent"
      [style.background]="mappingColor"
      [style.color]="mappingContentTxtColor"
      >{{ mappingContentTxt }}</span
    >
    <div class="select">
      <p>See Feild</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="5"
        viewBox="0 0 7 5"
        fill="none"
      >
        <path
          d="M6.95605 0.0891113L3.95605 4.08911L0.956055 0.0891113H6.95605Z"
          fill="#27A9FD"
        />
      </svg>
    </div>
  </mat-card>
</div>
