<div class="dropdown-modify">
  <div class="dropdown-selector" [matMenuTriggerFor]="modify">
    <div class="dropdown-text">{{ buttonName }}</div>
    <mat-icon class="dropdown-icon">{{ dropDownIcon }}</mat-icon>
  </div>
  <div>
    <mat-menu #modify="matMenu" class="modifed-menu">
      <div class="modifed-menu-content" *ngFor="let item of modifiedItems">
        <div class="content">
          {{ item }}
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="button-class">
        <div class="button-start">
          <a class="link-black" mat-flat-button href="" target="_blank">{{
            clearButton
          }}</a>
        </div>

        <div class="button-center">
          <a mat-flat-button href="" target="_blank">{{ cancelButton }}</a>
          <a class="link-black" mat-flat-button href="" target="_blank">{{
            submitButton
          }}</a>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
