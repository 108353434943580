import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, Inject, Input, Optional,
} from '@angular/core';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
} from '@angular/material/dialog';

interface DialogData {
  title: string;
  description: string;
  width: string;
}

/**
 * A dialog component using SCAM methodology
 */
@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [
    './dialog.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DialogComponent {


  @Input() title : string | undefined;

  @Input() description : string | undefined;

  @Input() width : string | undefined;

  /**
   *
   * @param data d
   */
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }


}
