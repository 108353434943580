/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import {
  MatSort, MatSortModule,
} from '@angular/material/sort';
import {
  MenuComponent,
} from '../menu/menu.component';
import {
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  ModifiedDropDownComponent,
} from '../modified-drop-down/modified-drop-down.component';
import {
  DrivePeopleComponent,
} from '../drive-people/drive-people.component';
import {
  DriveTypeComponent,
} from '../drive-type/drive-type.component';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  Dialog, DialogModule,
} from '@angular/cdk/dialog';
import {
  CardTypeComponent,
} from '../card-type/card-type.component';
import {
  CardContainerListComponent, CardOption,
} from '../card-container-list/card-container-list.component';
import {
  MatPaginator, MatPaginatorModule,
} from '@angular/material/paginator';
import {
  PeopleToggleButtonComponent,
} from '../people-toggle-button/people-toggle-button.component';
import {
  CardDetails, RecentCardsComponent,
} from '../recent-cards/recent-cards.component';
import {
  MenuIconComponent, MenuList,
} from '../menu-icon/menu-icon.component';

export interface TableViewData {
  name: string;
  id: string;
}

/**
 *Table view
 */
@Component({
  selector: 'lib-table-view',
  standalone: true,
  templateUrl: './table-view.component.html',
  styleUrls: [
    './table-view.component.scss',
    './table-view.component2.scss',
  ],
  imports: [
    MatTableModule,
    CommonModule,
    MatSortModule,
    MenuComponent,
    MenuIconComponent,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    DialogModule,
    ModifiedDropDownComponent,
    DrivePeopleComponent,
    DriveTypeComponent,
    CardTypeComponent,
    CardContainerListComponent,
    MatPaginatorModule,
    PeopleToggleButtonComponent,
    RecentCardsComponent,
  ],
})
export class TableViewComponent  implements AfterViewInit, OnChanges, OnInit {

  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() cardOptions!: CardOption[] | undefined;

  @Input() cardDetailsData!: CardDetails[] | undefined;

  @Input() displayedColumns: string[] | undefined;

  @Input() dataSource: any[] | undefined;

  @Input() menuItems: string[] | undefined;

  @Input() folderHeading = '';

  @Input() iconName!: string | undefined;

  @Input() recentCards =  true;

  @Input() dynamicCards =  false;

  @Input() menuList!: MenuList[] | undefined;

  @Input() menuItemActive = true;

  @Input() typeItems!: any[];

  @Input() typeItems2!: any[];

  @Input() menuTitleType2!: string;

  @Input() toggleType = true;

  @Input() togglePeople = true;

  @Input() toggleType2 = false;

  @Input() toggleModified = true;

  @Input() fileHeading = '';

  @Input() cards_heading = '';

  @Input() sortBy!: string;

  @Input() suggestedCards!: any[];

  @Input() animal!: string;

  @Input() name!: string;

  @Input() menuTitle!: string;

  @Output() redirectUrl = new EventEmitter<string>();

  @Output() openDialogRequest = new EventEmitter<any>();

  @Output() openShareDialog = new EventEmitter<any>();

  @Output() sharePopUpData = new EventEmitter<any>();

  @Output() openFileDetailsSidePenal = new EventEmitter<any>();

  @Output() selectedTogglePeople = new EventEmitter<any>();

  @Output() viewDetailsClicked = new EventEmitter<any>();

  @Output() itemToggle2Clicked = new EventEmitter<any>();

  showPagination = true;

  gridView!: boolean;

  datasource: MatTableDataSource<any> =  new MatTableDataSource();

  tableView!: boolean;

  showIcons = true;

  isPopupVisible: boolean[] = [
  ];

  filteredFolders: any = [
  ];

  filteredFiles: any = [
  ];

  menuItemsData: any[] = [
    {
      label: 'Report1', link: '/dashboard',
    },
    {
      label: 'Create Request', link: '/dashboard',
    },
    {
      label: 'Add Team Member ', link: '/dashboard',
    },
    {
      label: 'Project Settings', link: '/dashboard',
    },
  ];

  modifiedItems = [
    'Today',
    'Last 7 days',
    'Last 30 days',
    'This year (2023)',
    'Last year (2022)',
    'Custom date range',
  ];

  buttonName = 'Modified';

  sliceCard = true;

  dropDownIcon = 'arrow_drop_down';

  clearButton = 'Clear all';

  cancelButton = 'Cancel';

  submitButton = 'Apply';

  docItems = [
    {
      id: '1', name: 'Lauren Wilson', email: 'ravi@gmail.com', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw8Td_7hn7QV14zRxACEcbGzwfs_7Kf1e93qFCetx1Bq1N3_qrhlpb2UcOsiJ1dPqiD8Y&usqp=CAU',
    },
    {
      id: '1', name: 'Lauren Wilson', email: 'ravi@gmail.com', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfkP7WXBxXgzAI0kGis4cSC4Ns4jRQIghWAahDh5SDKh29tFJ9C70KlrXjwwecREEEyM0&usqp=CAU',
    },
    {
      id: '1', name: 'Lauren Wilson', email: 'ravi@gmail.com', img: '../../../assets/images/Ellipse 2912.svg',
    },
  ];

  folder = [
    {
      id: '1', folderName: 'DESTINY', icon: 'apartment', color: 'color',
    },
    {
      id: '1', folderName: 'External users', icon: 'public', color: 'color',
    },
  ];

  docFiles = [
    {
      id: '1', docName: 'Google Doc', icon: 'description', color: 'cornflowerblue',
    },
    {
      id: '1', docName: 'Google Sheet', icon: 'description', color: 'green',
    },
    {
      id: '1', docName: 'Pdf', icon: 'description', color: 'red',
    },
  ];

  selectedMenuItem = 'Name';

  buttonNameData = [
    'Name',
    'item 1',
    'item 2',
  ];

  filterTypeVal = [
    {
      value: 'proyecto_grande', viewValue: 'Proyecto Grande',
    },
    {
      value: 'single_asset', viewValue: 'Single Asset',
    },
    {
      value: 'comunicaciones', viewValue: 'Comunicaciones',
    },
  ];

  placeHolderFilterType = 'ANALISIS';

  placeHolderFilterType2 = 'CHANNEL';

  /**
   *constructor
   * @param dialog -di
   */
  constructor(public dialog: Dialog) {
  }

  /**
   *onResize
   * @param event -event
   */
  @HostListener('window:resize', [
    '$event',
  ])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: any): void {
    this.updateIconVisibility();
  }

  /**
   *switchView
   * @param view -view
   */
  switchView(view: string) {
    if (view === 'table') {
      localStorage.setItem('view', 'table');
      this.tableView = true;
      this.gridView = false;
    } else if (view === 'grid') {
      localStorage.setItem('view', 'grid');
      this.tableView = false;
      this.gridView = true;
    }
  }

  /**
   *ngOnInit
   */
  ngOnInit(): void {
    const view  = localStorage.getItem('view');
    if (view === 'grid') {
      this.gridView = true;
      this.tableView = false;
    } else {
      this.gridView = false;
      this.tableView = true;
    }
    this.filterFolders();
    this.filterFiles();
  }

  /**
   * Added datasource
   */
  ngAfterViewInit() {
    this.sort.disableClear = true;
    this.datasource = new MatTableDataSource(this.dataSource);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }

  /**
   *ngOnChanges
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource'] && changes['dataSource'].currentValue) {
      this.datasource = new MatTableDataSource(this.dataSource);
    }
  }

  /**
   * Method to emit redirect url
   * @param link Link
   */
  onRedirctUrl(link:string) {
    this.redirectUrl.emit(link);
  }

  /**
   * filterFolders
   *@returns -returns
   */
  filterFolders() {
    if (typeof this.datasource === 'object' && this.datasource !== null) {
      this.filteredFolders = this.datasource.filteredData.filter((item: any) => item.type === 'folders');
      return this.filteredFolders;
    } else {
      return [
      ];
    }
  }

  /**
   * filterFiles
   *@returns -returns
   */
  filterFiles() {
    if (typeof this.datasource === 'object' && this.datasource !== null) {
      this.filteredFiles = this.datasource.filteredData.filter((item: any) => {
        return item.type === 'image' || item.type === 'video' || item.type === 'file';
      });
      return this.filteredFiles;
    } else {
      return [
      ];
    }
  }

  /**
   *Function to handle menu item selection
   * @param item -item
   */
  onMenuItemSelect(item: string) {
    this.selectedMenuItem = item;
  }

  /**
   *Function to handle menu item selection
   * @param item -item
   */
  handleItemClicked(item: string) {
    this.itemToggle2Clicked.emit(item);
  }

  /**
   * togglePopup
   * @param index Index of the card
   */
  togglePopup(index: number) {
    this.isPopupVisible[index] = !this.isPopupVisible[index];
  }

  /**
   *
   * @param index -in
   */
  closePopup(index: number): void {
    this.isPopupVisible[index] = false;
  }

  /**
   * @param item -item
   */
  handleMenuClick(item: any) {
    if (item.type === 'image' || item.type === 'video' || item.type === 'file') {
      this.openDialogRequest.emit(item);
    } else if (item.label === 'Share') {
      this.openShareDialog.emit(item);
    }
  }

  /**
   *
   * @param item -ite
   * @param index -index
   */
  handledData(item:any, index:any) {
    if (index.label === 'Details') {
      this.handelFileDetails(item);
    }
  }

  /**
   *
   * @param value -value
   */
  SharePopUpData(value: any) {
    this.sharePopUpData.emit(value);
  }

  /**
   *
   * @param data -data
   */
  handelFileDetails(data: any) {
    this.openFileDetailsSidePenal.emit(data);
  }

  /**
   *
   * @param data -data
   */
  handleSelectedTogglePeople(data: any) {
    this.selectedTogglePeople.emit(data);
  }

  /**
   * handleViewDetailsClicked
   * @param rowInfo rowInfo
   */
  handleViewDetailsClicked(rowInfo: any) : void {
    this.viewDetailsClicked.emit(
      rowInfo,
    );
  }

  /**
   *updateIconVisibility
   */
  private updateIconVisibility(): void {
    this.showIcons = window.innerWidth >= 0.9 * window.screen.width;
  }
}
