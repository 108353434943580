import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 * Welcome text Component for login , account selection
 */
@Component({
  selector: 'lib-welcome',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './welcome.component.html',
  styleUrls: [
    './welcome.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent {
  @Input() brandLogo! : string;

  @Input() brandText! : string;
}
