import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  FormsModule,
} from '@angular/forms';

/**
 *Available colors component
 */
@Component({
  selector: 'lib-available-colors',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './available-colors.component.html',
  styleUrls: [
    './available-colors.component.scss',
  ],
})
export class AvailableColorsComponent {
  @Input() colors: string[] = [
  ];

  @Output() colorSelected = new EventEmitter<string>();

  selectedColor = '';

  /**
   * Add color
   * @param color - Color to add
   */
  addColor(color: string): void {
    if (color) {
      this.colors.push(color);
      this.selectedColor = '';
      this.colorSelected.emit(color);
    }
  }

  /**
   * Open color picker
   */
  openColorPicker(): void {
    const colorInput = document.createElement('input');
    colorInput.type = 'color';
    colorInput.addEventListener('input', (event) => {
      this.selectedColor = (event.target as HTMLInputElement).value;
    });
    colorInput.addEventListener('change', () => {
      this.addColor(this.selectedColor);
    });
    document.body.appendChild(colorInput);
    const colorCardRect = document.querySelector('.color-card')?.getBoundingClientRect();
    if (colorCardRect) {
      colorInput.style.position = 'absolute';
      colorInput.style.display = 'none';
      colorInput.style.top = `${colorCardRect.bottom}px`;
      colorInput.style.left = `${colorCardRect.left}px`;
    }
    colorInput.click();
    colorInput.addEventListener('blur', () => {
      document.body.removeChild(colorInput);
    });
  }
}
