<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li
      *ngFor="let breadcrumb of items; let i = index; let last = last"
      [ngClass]="{
        active: last,
        'first-selected': firstItemSelected && i === 0
      }"
      (click)="selectBreadcrumb(i)"
    >
      <a
        *ngIf="!last"
        [routerLink]="breadcrumb.url"
        [ngClass]="{ 'first-selected-link': firstItemSelected && i === 0 }"
        >{{ breadcrumb.label }}</a
      >
      <span *ngIf="last">{{ breadcrumb.label }}</span>
    </li>
  </ol>
</nav>
