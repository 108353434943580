import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import type Player from 'video.js/dist/types/player';

/**
 *VideoPlayerComponent
 */
@Component({
  selector: 'lib-video-player',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './video-player.component.html',
  styleUrls: [
    './video-player.component.scss',
  ],
})
export class VideoPlayerComponent  implements AfterViewInit, OnDestroy  {

  @Input() videoLink!: string;

  @ViewChild('videoPlayer') private videoPlayer!: ElementRef;

  private player!: Player;

  /**
   *
   * @param cdr -cdr
   */
  constructor(private cdr: ChangeDetectorRef) {}

  /**
   *
   */
  async ngAfterViewInit() {
    const videojs = await import('video.js');
    // Options for Video.js
    const options = {
      controls: true,
      autoplay: false,
      sources: [
        {
          src: this.videoLink,
          type: 'video/mp4',
        },
      ],
    };

    // Create Video.js player instance
    if (this.videoPlayer) {
      this.player = videojs.default(this.videoPlayer.nativeElement, options);
      this.cdr.detectChanges();
    }
  }

  /**
   *
   */
  ngOnDestroy() {
    // Destroy the Video.js player when the component is destroyed
    if (this.player) {
      this.player.dispose();
    }
  }
}
