import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  WelcomeComponent,
} from '../welcome/welcome.component';

/**
 * Loader UI Component
 */
@Component({
  selector: 'lib-loader',
  standalone: true,
  imports: [
    CommonModule,
    WelcomeComponent,
  ],
  templateUrl: './loader.component.html',
  styleUrls: [
    './loader.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() brandLogo! : string;

  @Input() brandText! : string;

  @Input() loaderText! : string;

  @Input() type = 'intermidateLoader';

}
