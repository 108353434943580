<div class="card">
  <div class="card-body">
    <div class="media-image">
      <img src="{{ image }}" alt="Media" />
    </div>
  </div>
  <div class="card-footer">
    <div class="media-content">
      <span class="action-bar"
        ><img src="../../assets/images/icon-toolbar.svg" alt="icon-toolbar"
      /></span>
      <div class="media-icon">
        <img src="{{ icon }}" alt="txt-copia" />
      </div>
      <div class="media-info">
        <h4 class="media-title">{{ title }}</h4>
        <p class="media-duration">{{ duration }}</p>
        <p class="meida-creator">Creado por: {{ creator }}</p>
      </div>
    </div>
  </div>
</div>
