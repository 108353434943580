import {
  ChangeDetectionStrategy, Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  PageNotFoundComponent as PageNotFoundUIComponent,
} from '@genera/ui-components';

/**
 *Page Not Found Component will be call whenever application did not have the provided route or link
 */
@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [
    CommonModule,
    PageNotFoundUIComponent,
  ],
  templateUrl: './page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  notFoundImage = 'https://plan.wsuite.com/assets/images/403-error-img.png';

  errorNumber = 404;

  errorMessage = 'Page Not Found';

  errorDescription = 'The page you were trying to reach is not found.' +
  'You can go back to home';

  moveToPageName = 'GO TO DASHBOARD';

  moveToPageLink = '/dashboard';

  requestAccessLink = 'https://admin.wsuite.com';
}
