<div class="login-container">
  <div class="login-body">
    <div class="left-block">
      <lib-login-welcome
        [introTitle]="introTitle"
        [introDescription]="introDescription"
        [quoteFirst]="quoteFirst"
        [quoteTwo]="quoteTwo"
        [quoteThree]="quoteThree"
      >
      </lib-login-welcome>
    </div>
    <div class="login-main">
      <div class="main-content">
        <div class="app-logo">
          <lib-welcome [brandLogo]="brandLogo"></lib-welcome>
        </div>

        <div class="account-section-box company-dialog">
          <div class="back-cta">
            <a id="email-txt" class="link-back" [routerLink]="[linkURI]">
              {{ back }}
            </a>
          </div>
          <div class="center-content">
            <div class="company-name">
              Welcome <span>{{ companyName }}</span>
            </div>
            <div class="auto-container">
              <p>{{ infoText }}</p>
            </div>

            <div class="custom-links">
              <a
                [ngClass]="loginOption.linkBackground"
                *ngFor="let loginOption of loginOptions"
                (click)="clicked.emit(loginOption)"
              >
                <div class="custom-icon">
                  <img
                    src="{{ loginOption.imageUrl }}"
                    alt="{{ loginOption.name }}"
                  />
                </div>
                {{ loginOption.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="terms-text">
          <p>
            By Singing in you accept out
            <a href="javascript:void(0)" target="_blank">Terms</a> &
            <a href="javascript:void(0)" target="_blank">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
