import {
  ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import * as d3 from 'd3';
export interface ChartValue {
  name: string;
  value: number;
};

/**
 *  chart-pie component
 */
@Component({
  selector: 'lib-chart-pie',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './chart-pie.component.html',
  styleUrls: [
    './chart-pie.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPieComponent implements OnInit, OnChanges {

  @Input() data!: ChartValue[];

  margin = 50;

  width = 750;

  height = Math.min(this.width, 500);

  radius = Math.min(this.width, this.height) / 2 - this.margin;

  colors!: d3.ScaleOrdinal<string, unknown, never>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  svg!: any;

  /**
   * init method
   */
  ngOnInit(): void {
    this.createColors();
    this.drawChart();
  }

  /**
   *
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.data = changes['data'].currentValue;
    }
  }

  /**
   *  method to create the colors for the chart
   */
  private createColors(): void {
    if (this.data) {
      this.colors = d3.scaleOrdinal()
        .domain(this.data.map(d => d.name))
        //.range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), this.data.length).reverse());
        .range([
          '#FF5C5C',
          '#41A3F3',
          '#5ABE2B',
          '#F5B829',
        ]);
    }
  }

  /**
   *  method that draws the chart
   */
  private drawChart(): void {
    this.svg = d3.select('figure#pie')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewBox', [
        -this.width / 2,
        -this.height / 2,
        this.width,
        this.height,
      ])
      .attr('style', 'max-width: 100%; height: auto; font: 36px sans-serif; fill: #fff');

    const pie = d3.pie<ChartValue>()
      .sort(null)
      .value((d: ChartValue) => d.value);

    const arc = d3.arc<ChartValue>()
      .innerRadius(0)
      .outerRadius(this.radius);

    const labelRadius = this.radius * 0.8;

    const arcLabel = d3.arc()
      .innerRadius(labelRadius)
      .outerRadius(labelRadius);

    if (this.data) {
      const arcs = pie(this.data);

      this.svg.append('g')
        .attr('stroke', 'white')
        .selectAll()
        .data(arcs)
        .join('path')
        .attr('fill', (i: string) => (this.colors(i)))
        .attr('d', arc)
        .append('title')
        .text((d: d3.PieArcDatum<ChartValue>) => `${d.data.name}: ${d.data.value.toLocaleString('en-US')}`);

      this.svg.append('g')
        .attr('text-anchor', 'middle')
        .selectAll()
        .data(arcs)
        .join('text')
        .attr('transform', (d: d3.DefaultArcObject) => `translate(${arcLabel.centroid(d)})`)
        .call((text: d3.Selection<SVGGElement, d3.PieArcDatum<ChartValue>, HTMLElement, unknown>) =>
          text.append('tspan')
            .attr('y', '-0.4em')
            .attr('font-weight', 'bold')
            .text((d: d3.PieArcDatum<ChartValue>) => d.data.name))
        .call((text: d3.Selection<SVGGElement, d3.PieArcDatum<ChartValue>, HTMLElement, unknown>) =>
          text.filter((d: d3.PieArcDatum<ChartValue>) => (d.endAngle - d.startAngle) > 0.25).append('tspan')
            .attr('x', 0)
            .attr('y', '0.7em')
            .attr('fill-opacity', 0.7)
            .text((d: d3.PieArcDatum<ChartValue>) => d.data.value.toLocaleString('en-US')));
    }
  }
}
