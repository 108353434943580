<div class="dropdown-type">
  <div class="dropdown-selector" [matMenuTriggerFor]="type">
    <div class="dropdown-text">{{ menuTitle }}</div>
    <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
  </div>
  <mat-menu #type="matMenu" class="dropdown-type-list">
    <button
      mat-menu-item
      *ngFor="let item of docItems"
      (click)="onItemClicked(item)"
    >
      <ng-container *ngIf="item.img">
        <img src="{{ item.img }}" alt="{{ item.docName }}" />
        <span>{{ item.docName }}</span>
      </ng-container>
      <ng-container *ngIf="item.icon && item.color">
        <mat-icon [ngStyle]="{ color: item.color }">{{ item.icon }}</mat-icon>
        <span>{{ item.docName }}</span>
      </ng-container>
    </button>
  </mat-menu>
</div>
