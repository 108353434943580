/* eslint-disable folders/match-regex */
import {
  Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonToggleModule,
} from '@angular/material/button-toggle';

/**
 *
 */
@Component({
  selector: 'lib-list-toggle',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
  ],
  templateUrl: './list-toggle.component.html',
  styleUrls: [
    './list-toggle.component.scss',
  ],
})
export class ListToggleComponent {
  selectedValue = '';

  tableView = false;

  gridView = false;

  /**
   *
   // eslint-disable-next-line jsdoc/require-param-description
   * @param value -value
   */
  updateSelectedValue(value: string) {
    this.selectedValue = value;
  }

  /**
   *switchView
   * @param view -view
   */
  switchView(view: string) {
    if (view === 'table') {
      this.tableView = true;
      this.gridView = false;
    } else if (view === 'grid') {
      this.tableView = false;
      this.gridView = true;
    }
  }
}
