/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateProjectStepDefinitionDto { 
    /**
     * Project Step name
     */
    name?: string;
    /**
     * Description about the Project Step
     */
    description?: string;
    /**
     * Name of the component
     */
    component?: string;
    /**
     * Icon of the Project Step
     */
    icon?: string;
    /**
     * Specifies if the Project Step is enabled/disabled
     */
    enabled?: boolean;
    /**
     * Project stages Id the Project steps belongs to
     */
    projectStageDefinitionId?: string;
    /**
     * Order of the Project Step
     */
    order?: number;
    metaData: object;
}

