/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateProductDto { 
    /**
     * Product name
     */
    name: string;
    /**
     * Description about the Product stages
     */
    description: string;
    /**
     * Icon of the stage
     */
    icon: string;
    /**
     * Specifies if the Product is enabled/disabled
     */
    enabled: boolean;
    /**
     * Product Categories Id the Product belongs to
     */
    productCategoryId: string;
}

