import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatDividerModule,
} from '@angular/material/divider';

/**
 *modified -drop-down component
 */
@Component({
  selector: 'lib-modified-drop-down',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
  ],
  templateUrl: './modified-drop-down.component.html',
  styleUrls: [
    './modified-drop-down.component.scss',
  ],
})
export class ModifiedDropDownComponent {

  @Input() buttonName = '';

  @Input() dropDownIcon = '';

  @Input() clearButton = '';

  @Input() cancelButton = '';

  @Input() submitButton = '';

  @Input() modifiedItems:string[] = [
  ];
}
