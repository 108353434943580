import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatInputModule,
} from '@angular/material/input';

/**
 * A search-box component using SCAM methodology
 */
@Component({
  selector: 'lib-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: [
    './searchbox.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent {

  @Input() placeholder = 'Search';

}
