import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';

/**
 *side-bar-card component
 */
@Component({
  selector: 'lib-side-bar-card',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
  ],
  templateUrl: './side-bar-card.component.html',
  styleUrls: [
    './side-bar-card.component.scss',
  ],
})
export class SideBarCardComponent {
  @Input() icon = '';

  @Input() iconName = '';
}
