<div class="chip-color">
  <mat-form-field class="example-chip-list">
    <mat-chip-grid #chipGrid aria-label="Enter tags">
      <input
        [placeholder]="placeHolder"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </mat-form-field>
  <mat-chip-row
    *ngFor="let item of tags"
    (removed)="remove(item)"
    [editable]="true"
    (edited)="edit(item, $event)"
    [aria-description]="'press enter to edit ' + item.name"
    class="chip-tag chip-area"
  >
    <span class="chip-text">{{ item.name }}</span>
    <button matChipRemove [attr.aria-label]="'remove ' + item.name">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-row>
</div>
