import {
  ChangeDetectionStrategy, Component, EventEmitter, Input,
  OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarModule,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

/**
 * Snack Notification component is used to show toast message
 */
@Component({
  selector: 'lib-snack-notification',
  standalone: true,
  imports: [
    CommonModule,
    MatSnackBarModule,
  ],
  templateUrl: './snack-notification.component.html',
  styleUrls: [
    './snack-notification.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackNotificationComponent implements OnInit, OnChanges {

  @Input() message! : string;

  @Input() actionLabel! : string;

  @Input() horizontalPosition!: MatSnackBarHorizontalPosition;

  @Input() verticalPosition!: MatSnackBarVerticalPosition;

  @Input() durationInSeconds!: number;

  @Input() panelType!: string;

  @Input() openNotification = true;

  @Output() openNotificationChange = new EventEmitter<boolean>();

  /**
   *
   * @param _snackBar _snackBar
   */
  constructor(private _snackBar: MatSnackBar) {}

  /**
   * OnInit Menthod
   */
  ngOnInit(): void {
    if (this.openNotification) {
      this.openSnack();
    }

  }

  /**
   * Open Snakebar Notification
   */
  openSnack():void {
    const config = this._createConfig();
    this._snackBar.open(this.message, this.actionLabel ? this.actionLabel : undefined, config);
  }

  /**
   *
   * @param changes changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openNotification'] && changes['openNotification'].currentValue) {
      this.openNotification = changes['openNotification'].currentValue;
      if (this.openNotification) {
        this.openSnack();
        this.openNotificationChange.emit(false);
      }
    }
  }


  /**
   * @returns MatSnackBarConfig
   */
  private _createConfig(): MatSnackBarConfig<MatSnackBarConfig> {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.durationInSeconds ? this.durationInSeconds * 1000 : 0;
    config.panelClass = this.panelType ? [
      this.panelType,
    ] : undefined;
    return config;
  }
}
