import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatTooltipModule,
} from '@angular/material/tooltip';
/**
 * This Component is used to give rating
 */
@Component({
  selector: 'lib-rating',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  templateUrl: './rating.component.html',
  styleUrls: [
    './rating.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements OnInit {

  @Input() rating!: number;

  @Input() starCount!: number;

  @Input() ratingColor!: string;

  @Output() ratingUpdated = new EventEmitter();

  ratingArr: number[] = [
  ];

  /**
   * To push the index in rating array
   */
  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }


  /**
   *
   * @param rating number
   * @returns false
   */
  onClick(rating: number) {
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    return false;
  }

  /**
   *
   * @param index number
   * @returns string
   */
  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}
