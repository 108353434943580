<div class="color-cardLabel">
  <label>{{ inputTitle }}</label>
</div>

<div class="color-card d-flex justify-content-between">
  <div class="color-squares">
    <div class="color-square">{{ inputQuantity }}</div>
  </div>
  <div class="add-color">
    <button (click)="incrementQuantity()">
      <i class="material-icons">add</i>
    </button>
  </div>
</div>
