<div class="grid">
  <lib-card-type
    *ngFor="let cardOption of cardOptions | slice : 0 : sliceCard"
    [type]="cardOption.type"
    [title]="cardOption.title"
    [cardId]="cardOption.cardId"
    [description]="cardOption.description"
    [icon]="cardOption.icon"
    [status]="cardOption.status"
    [menuItems2]="cardOption.menuItems"
  >
  </lib-card-type>
</div>
