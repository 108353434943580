/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthorizationCodeGrantDto } from './authorization-code-grant-dto';
import { ClientCredentialsGrantDto } from './client-credentials-grant-dto';
import { PasswordGrantDto } from './password-grant-dto';
import { RefreshTokenGrantDto } from './refresh-token-grant-dto';


/**
 * @type OAuth2ControllerTokenRequest
 * @export
 */
export type OAuth2ControllerTokenRequest = AuthorizationCodeGrantDto | ClientCredentialsGrantDto | PasswordGrantDto | RefreshTokenGrantDto;

