<div>
  <div class="card">
    <div class="profile">
      <div class="icon-container">
        <mat-icon>{{ iconName }}</mat-icon>
      </div>
      <p class="card-name">{{ cardName }}</p>
    </div>
  </div>
</div>
