<div class="card-dashboard">
  <div class="card-dashboard-header">
    <div class="header-title">
      <h4>Contact Created</h4>
    </div>
    <div class="header-actions">
      <button class="image-button"></button>
    </div>
  </div>
  <div class="card-dashboard-body">
    <lib-chart-line
      [dataPoints]="dataPoints"
      [widthCard]="widthCard"
      [heightCard]="heightCard"
    ></lib-chart-line>
  </div>
</div>
