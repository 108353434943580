<mat-drawer-container class="app-drawer-container">
  <mat-drawer
    mode="side"
    #sidenav
    [opened]="open"
    [position]="position"
    class="app-drawer"
    [style.width.px]="drawerSize"
  >
    <div class="sidebar-collapse-btn">
      <a (click)="sidenav.toggle()" href="javascript:void(0)">
        <img
          *ngIf="navIcon"
          src="{{ navIcon }}"
          [ngClass]="{ 'm-left-auto': navIconPosition === 'right' }"
          alt="menu-icon"
        />
        <div
          *ngIf="!navIcon"
          [ngClass]="{ 'm-left-auto': navIconPosition === 'right' }"
        >
          <img src="/assets/images/align-center.svg" />
        </div>
      </a>
    </div>
    <mat-accordion>
      <mat-expansion-panel
        class="expansion-panel"
        *ngFor="let list of listItems; let i = index"
        [disabled]="!list.multilistItem"
      >
        <mat-expansion-panel-header class="panel-header-body">
          <div class="panel-header">
            <a [attr.href]="!list.multilistItem ? list.link : null">
              <i><img src="{{ list.icon }}" alt="list icon" /></i>
              <span>{{ list.label }}</span>
              <span *ngIf="list.link" class="icon-check"
                ><img
                  src="https://plan.wsuite.com/assets/images/icons/check.svg"
                  alt="check icon"
              /></span>
            </a>
          </div>
        </mat-expansion-panel-header>
        <div class="panel-body" *ngFor="let ch of list.multilistItem">
          <a href="{{ ch.link }}" class="active-menu">
            <i><img src="{{ ch.icon }}" alt="icon" /></i>
            <span class="notranslate">{{ ch.label }}</span>
            <p class="number">{{ ch.number }}</p>
          </a>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-drawer>
  <!-- <mat-drawer-content>Main content</mat-drawer-content> -->
</mat-drawer-container>
