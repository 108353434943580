/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';
/**
 *
 */
@Component({
  selector: 'lib-menubar-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './menubar-dashboard.component.html',
  styleUrls: [
    './menubar-dashboard.component.scss',
  ],
})
export class MenubarDashboardComponent {

  @Input() title = 'My Segments';

  @Input() docItemsMenu!: any[];

  @Input() folder!: any[];

  @Input() upload!: any[];

  @Input() extractFile!: any[];

  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  /**
   *
   * @param item -item
   */
  onItemClicked(item: any): void {
    this.itemClicked.emit(item);
  }
}
