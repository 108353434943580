<ng-container *ngIf="buttons">
  <ng-container *ngFor="let obj of dataPoints">
    <button class="btn-point" (click)="loadData(obj, true)">
      <span
        class="circle"
        [style.background-color]="obj.selected ? obj.color : '#e9e9e9'"
      ></span>
      {{ obj.name }}
    </button>
  </ng-container>
</ng-container>
<svg id="{{ id }}" [attr.width]="widthCard" [attr.height]="heightCard"></svg>
