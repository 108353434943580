<div class="profile-menu">
  <span [matMenuTriggerFor]="menu" class="user-avatar">
    <img src="{{ userProfileImage }}" alt="{{ userName }}" />
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      fontIcon="arrow_drop_down"
    ></mat-icon>
  </span>
  <mat-menu #menu="matMenu" class="profile-menu-dropdown">
    <div class="user-detail">
      <div>
        <h4>{{ userName }}</h4>
        <p style="text-transform: capitalize">{{ userGroupName }}</p>
      </div>
    </div>

    <ul class="user-menu">
      <li *ngFor="let menu of menuItems">
        <a *ngIf="menu.link" [routerLink]="[menu.link]">{{ menu.label }}</a>
        <a
          *ngIf="!menu.link && menu.id"
          (click)="menuItemClicked.emit(menu.id)"
          >{{ menu.label }}</a
        >
      </li>
    </ul>
  </mat-menu>
</div>
