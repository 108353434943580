<div class="d-flex toggleBtn">
  <mat-button-toggle-group
    class="mat-button-toggle-group-appearance-standard-new"
    #group="matButtonToggleGroup"
    [value]="'table'"
  >
    <mat-button-toggle
      class="toggle-btn mat-button-toggle-appearance-standard-new"
      value="table"
      (click)="switchView('table')"
      aria-label="Text align left"
    >
      <mat-icon class="mat-icon-new" *ngIf="group.value === 'table'"
        >done</mat-icon
      >
      <mat-icon class="mat-icon-new">menu</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      class="toggle-btn"
      value="grid"
      (click)="switchView('grid')"
      aria-label="Text align center"
    >
      <mat-icon class="mat-icon-new" *ngIf="group.value === 'grid'"
        >done</mat-icon
      >
      <mat-icon class="mat-icon-new">grid_view</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <button
    mat-icon-button
    aria-label="Example icon-button with a menu"
    class="info-button mat-mdc-icon-button-new mat-mdc-button-base-new"
  >
    <mat-icon class="mat-icon-new" class="material-icons-outlined info-icon"
      >info</mat-icon
    >
  </button>
</div>
