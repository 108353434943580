import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  ChartLineComponent, ChartPoints,
} from '../chart-line/chart-line.component';

/**
 *
 */
@Component({
  selector: 'lib-card-line-blue',
  standalone: true,
  imports: [
    CommonModule,
    ChartLineComponent,
  ],
  templateUrl: './card-line-blue.component.html',
  styleUrls: [
    './card-line-blue.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLineBlueComponent {
  @Input() dataPoints!: ChartPoints[];

  @Input() widthCard = 305;

  @Input() heightCard = 102;
}
