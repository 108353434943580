<div #GeneraGrapeJSPlaceholders>
  <button
    class="gjs-field"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Placeholder insert"
    [disabled]="!formConfig.placeholders.length"
    (onMenuClose)="onPlaceholderMenuClosed()"
  >
    <mat-icon>data_object</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="gjs-field">
    <button
      mat-menu-item
      class="gjs-field"
      *ngFor="let placeholder of formConfig.placeholders"
      (click)="onPlaceholderClick(placeholder)"
    >
      <mat-icon *ngIf="placeholder.icon">{{ placeholder.icon }}</mat-icon>
      <span>{{ placeholder.name }}</span>
    </button>
  </mat-menu>
</div>

<div #GeneraGrapesJS></div>
