<div class="campaign-box">
  <div class="logo">
    <img src="{{ logoUrl }}" alt="Logo" />
  </div>
  <div class="campaign-detail">
    <h3 class="campaign-name">{{ campaignName }}</h3>
    <p class="campaign-id">
      Destiny <span> ID : {{ campaignId | slice : -5 }}</span>
    </p>
    <div class="d-flex">
      <p class="campaign-date">
        Release date - <span>{{ campaignDate }}</span>
      </p>
      <a routerLink="{{ downloadUrl }}" class="download-icon"
        ><img src="{{ downloadIcon }}"
      /></a>
    </div>
  </div>
</div>
