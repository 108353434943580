/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateOrderDto } from '../model/create-order-dto';
// @ts-ignore
import { CreateOrderItemDto } from '../model/create-order-item-dto';
// @ts-ignore
import { CreateOrderItemStepDto } from '../model/create-order-item-step-dto';
// @ts-ignore
import { CreateProjectDto } from '../model/create-project-dto';
// @ts-ignore
import { CreateProjectStageDto } from '../model/create-project-stage-dto';
// @ts-ignore
import { CreateProjectStepDto } from '../model/create-project-step-dto';
// @ts-ignore
import { FilterConditionBegins } from '../model/filter-condition-begins';
// @ts-ignore
import { FilterConditionBetween } from '../model/filter-condition-between';
// @ts-ignore
import { FilterConditionContains } from '../model/filter-condition-contains';
// @ts-ignore
import { FilterConditionEqualObject } from '../model/filter-condition-equal-object';
// @ts-ignore
import { FilterConditionExists } from '../model/filter-condition-exists';
// @ts-ignore
import { FilterConditionGreaterThan } from '../model/filter-condition-greater-than';
// @ts-ignore
import { FilterConditionGreaterThanEqual } from '../model/filter-condition-greater-than-equal';
// @ts-ignore
import { FilterConditionLesserThan } from '../model/filter-condition-lesser-than';
// @ts-ignore
import { FilterConditionLesserThanEqual } from '../model/filter-condition-lesser-than-equal';
// @ts-ignore
import { FilterConditionNoEqual } from '../model/filter-condition-no-equal';
// @ts-ignore
import { FilterConditionNotContains } from '../model/filter-condition-not-contains';
// @ts-ignore
import { PaginatedResponseOfOrder } from '../model/paginated-response-of-order';
// @ts-ignore
import { PaginatedResponseOfOrderItem } from '../model/paginated-response-of-order-item';
// @ts-ignore
import { PaginatedResponseOfOrderItemStep } from '../model/paginated-response-of-order-item-step';
// @ts-ignore
import { PaginatedResponseOfProject } from '../model/paginated-response-of-project';
// @ts-ignore
import { PaginatedResponseOfProjectStage } from '../model/paginated-response-of-project-stage';
// @ts-ignore
import { PaginatedResponseOfProjectStep } from '../model/paginated-response-of-project-step';
// @ts-ignore
import { SingleResponseOfOrder } from '../model/single-response-of-order';
// @ts-ignore
import { SingleResponseOfOrderItem } from '../model/single-response-of-order-item';
// @ts-ignore
import { SingleResponseOfOrderItemStep } from '../model/single-response-of-order-item-step';
// @ts-ignore
import { SingleResponseOfProject } from '../model/single-response-of-project';
// @ts-ignore
import { SingleResponseOfProjectStage } from '../model/single-response-of-project-stage';
// @ts-ignore
import { SingleResponseOfProjectStep } from '../model/single-response-of-project-step';
// @ts-ignore
import { UpdateOrderDto } from '../model/update-order-dto';
// @ts-ignore
import { UpdateOrderItemDto } from '../model/update-order-item-dto';
// @ts-ignore
import { UpdateOrderItemStepDto } from '../model/update-order-item-step-dto';
// @ts-ignore
import { UpdateProjectDto } from '../model/update-project-dto';
// @ts-ignore
import { UpdateProjectStageDto } from '../model/update-project-stage-dto';
// @ts-ignore
import { UpdateProjectStepDto } from '../model/update-project-step-dto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param createProjectDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerCreate(clientId: string, brandId: string, createProjectDto: CreateProjectDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProject>;
    public projectsControllerCreate(clientId: string, brandId: string, createProjectDto: CreateProjectDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProject>>;
    public projectsControllerCreate(clientId: string, brandId: string, createProjectDto: CreateProjectDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProject>>;
    public projectsControllerCreate(clientId: string, brandId: string, createProjectDto: CreateProjectDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerCreate.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerCreate.');
        }
        if (createProjectDto === null || createProjectDto === undefined) {
            throw new Error('Required parameter createProjectDto was null or undefined when calling projectsControllerCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects`;
        return this.httpClient.request<SingleResponseOfProject>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createProjectDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param createOrderDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerCreateOrder(clientId: string, brandId: string, projectId: string, createOrderDto: CreateOrderDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrder>;
    public projectsControllerCreateOrder(clientId: string, brandId: string, projectId: string, createOrderDto: CreateOrderDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrder>>;
    public projectsControllerCreateOrder(clientId: string, brandId: string, projectId: string, createOrderDto: CreateOrderDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrder>>;
    public projectsControllerCreateOrder(clientId: string, brandId: string, projectId: string, createOrderDto: CreateOrderDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerCreateOrder.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerCreateOrder.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerCreateOrder.');
        }
        if (createOrderDto === null || createOrderDto === undefined) {
            throw new Error('Required parameter createOrderDto was null or undefined when calling projectsControllerCreateOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders`;
        return this.httpClient.request<SingleResponseOfOrder>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createOrderDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param createOrderItemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerCreateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, createOrderItemDto: CreateOrderItemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItem>;
    public projectsControllerCreateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, createOrderItemDto: CreateOrderItemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItem>>;
    public projectsControllerCreateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, createOrderItemDto: CreateOrderItemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItem>>;
    public projectsControllerCreateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, createOrderItemDto: CreateOrderItemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerCreateOrderItem.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerCreateOrderItem.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerCreateOrderItem.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerCreateOrderItem.');
        }
        if (createOrderItemDto === null || createOrderItemDto === undefined) {
            throw new Error('Required parameter createOrderItemDto was null or undefined when calling projectsControllerCreateOrderItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items`;
        return this.httpClient.request<SingleResponseOfOrderItem>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createOrderItemDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param itemId 
     * @param createOrderItemStepDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerCreateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, createOrderItemStepDto: CreateOrderItemStepDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItemStep>;
    public projectsControllerCreateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, createOrderItemStepDto: CreateOrderItemStepDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItemStep>>;
    public projectsControllerCreateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, createOrderItemStepDto: CreateOrderItemStepDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItemStep>>;
    public projectsControllerCreateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, createOrderItemStepDto: CreateOrderItemStepDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerCreateOrderItemStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerCreateOrderItemStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerCreateOrderItemStep.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerCreateOrderItemStep.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling projectsControllerCreateOrderItemStep.');
        }
        if (createOrderItemStepDto === null || createOrderItemStepDto === undefined) {
            throw new Error('Required parameter createOrderItemStepDto was null or undefined when calling projectsControllerCreateOrderItemStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "itemId", value: itemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps`;
        return this.httpClient.request<SingleResponseOfOrderItemStep>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createOrderItemStepDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param createProjectStageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerCreateStage(clientId: string, brandId: string, projectId: string, createProjectStageDto: CreateProjectStageDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStage>;
    public projectsControllerCreateStage(clientId: string, brandId: string, projectId: string, createProjectStageDto: CreateProjectStageDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStage>>;
    public projectsControllerCreateStage(clientId: string, brandId: string, projectId: string, createProjectStageDto: CreateProjectStageDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStage>>;
    public projectsControllerCreateStage(clientId: string, brandId: string, projectId: string, createProjectStageDto: CreateProjectStageDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerCreateStage.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerCreateStage.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerCreateStage.');
        }
        if (createProjectStageDto === null || createProjectStageDto === undefined) {
            throw new Error('Required parameter createProjectStageDto was null or undefined when calling projectsControllerCreateStage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages`;
        return this.httpClient.request<SingleResponseOfProjectStage>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createProjectStageDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param stageId 
     * @param createProjectStepDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerCreateStep(clientId: string, brandId: string, projectId: string, stageId: string, createProjectStepDto: CreateProjectStepDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStep>;
    public projectsControllerCreateStep(clientId: string, brandId: string, projectId: string, stageId: string, createProjectStepDto: CreateProjectStepDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStep>>;
    public projectsControllerCreateStep(clientId: string, brandId: string, projectId: string, stageId: string, createProjectStepDto: CreateProjectStepDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStep>>;
    public projectsControllerCreateStep(clientId: string, brandId: string, projectId: string, stageId: string, createProjectStepDto: CreateProjectStepDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerCreateStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerCreateStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerCreateStep.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling projectsControllerCreateStep.');
        }
        if (createProjectStepDto === null || createProjectStepDto === undefined) {
            throw new Error('Required parameter createProjectStepDto was null or undefined when calling projectsControllerCreateStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "stageId", value: stageId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps`;
        return this.httpClient.request<SingleResponseOfProjectStep>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createProjectStepDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param fields The projection fields for records found
     * @param nextToken The cursor token returned by previous query to get next page
     * @param limit Number of entities to return
     * @param filter Filter Query object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindAll(clientId: string, brandId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PaginatedResponseOfProject>;
    public projectsControllerFindAll(clientId: string, brandId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PaginatedResponseOfProject>>;
    public projectsControllerFindAll(clientId: string, brandId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PaginatedResponseOfProject>>;
    public projectsControllerFindAll(clientId: string, brandId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindAll.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindAll.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fields) {
            fields.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fields');
            })
        }
        if (nextToken !== undefined && nextToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nextToken, 'nextToken');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects`;
        return this.httpClient.request<PaginatedResponseOfProject>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param itemId 
     * @param fields The projection fields for records found
     * @param nextToken The cursor token returned by previous query to get next page
     * @param limit Number of entities to return
     * @param filter Filter Query object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindAllOrderItemSteps(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PaginatedResponseOfOrderItemStep>;
    public projectsControllerFindAllOrderItemSteps(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PaginatedResponseOfOrderItemStep>>;
    public projectsControllerFindAllOrderItemSteps(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PaginatedResponseOfOrderItemStep>>;
    public projectsControllerFindAllOrderItemSteps(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindAllOrderItemSteps.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindAllOrderItemSteps.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindAllOrderItemSteps.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerFindAllOrderItemSteps.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling projectsControllerFindAllOrderItemSteps.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fields) {
            fields.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fields');
            })
        }
        if (nextToken !== undefined && nextToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nextToken, 'nextToken');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "itemId", value: itemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps`;
        return this.httpClient.request<PaginatedResponseOfOrderItemStep>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param fields The projection fields for records found
     * @param nextToken The cursor token returned by previous query to get next page
     * @param limit Number of entities to return
     * @param filter Filter Query object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindAllOrderItems(clientId: string, brandId: string, projectId: string, orderId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PaginatedResponseOfOrderItem>;
    public projectsControllerFindAllOrderItems(clientId: string, brandId: string, projectId: string, orderId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PaginatedResponseOfOrderItem>>;
    public projectsControllerFindAllOrderItems(clientId: string, brandId: string, projectId: string, orderId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PaginatedResponseOfOrderItem>>;
    public projectsControllerFindAllOrderItems(clientId: string, brandId: string, projectId: string, orderId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindAllOrderItems.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindAllOrderItems.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindAllOrderItems.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerFindAllOrderItems.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fields) {
            fields.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fields');
            })
        }
        if (nextToken !== undefined && nextToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nextToken, 'nextToken');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items`;
        return this.httpClient.request<PaginatedResponseOfOrderItem>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param fields The projection fields for records found
     * @param nextToken The cursor token returned by previous query to get next page
     * @param limit Number of entities to return
     * @param filter Filter Query object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindAllOrders(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PaginatedResponseOfOrder>;
    public projectsControllerFindAllOrders(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PaginatedResponseOfOrder>>;
    public projectsControllerFindAllOrders(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PaginatedResponseOfOrder>>;
    public projectsControllerFindAllOrders(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindAllOrders.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindAllOrders.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindAllOrders.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fields) {
            fields.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fields');
            })
        }
        if (nextToken !== undefined && nextToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nextToken, 'nextToken');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders`;
        return this.httpClient.request<PaginatedResponseOfOrder>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param fields The projection fields for records found
     * @param nextToken The cursor token returned by previous query to get next page
     * @param limit Number of entities to return
     * @param filter Filter Query object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindAllStages(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PaginatedResponseOfProjectStage>;
    public projectsControllerFindAllStages(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PaginatedResponseOfProjectStage>>;
    public projectsControllerFindAllStages(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PaginatedResponseOfProjectStage>>;
    public projectsControllerFindAllStages(clientId: string, brandId: string, projectId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindAllStages.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindAllStages.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindAllStages.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fields) {
            fields.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fields');
            })
        }
        if (nextToken !== undefined && nextToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nextToken, 'nextToken');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages`;
        return this.httpClient.request<PaginatedResponseOfProjectStage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param stageId 
     * @param fields The projection fields for records found
     * @param nextToken The cursor token returned by previous query to get next page
     * @param limit Number of entities to return
     * @param filter Filter Query object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindAllSteps(clientId: string, brandId: string, projectId: string, stageId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PaginatedResponseOfProjectStep>;
    public projectsControllerFindAllSteps(clientId: string, brandId: string, projectId: string, stageId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PaginatedResponseOfProjectStep>>;
    public projectsControllerFindAllSteps(clientId: string, brandId: string, projectId: string, stageId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PaginatedResponseOfProjectStep>>;
    public projectsControllerFindAllSteps(clientId: string, brandId: string, projectId: string, stageId: string, fields?: Array<string>, nextToken?: string, limit?: number, filter?: { [key: string]: FilterConditionGreaterThanEqual | FilterConditionGreaterThan | FilterConditionLesserThanEqual | FilterConditionLesserThan | FilterConditionBegins | FilterConditionExists | FilterConditionContains | FilterConditionNotContains | FilterConditionBetween | FilterConditionNoEqual | FilterConditionEqualObject | string | number | boolean; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindAllSteps.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindAllSteps.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindAllSteps.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling projectsControllerFindAllSteps.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fields) {
            fields.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'fields');
            })
        }
        if (nextToken !== undefined && nextToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nextToken, 'nextToken');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "stageId", value: stageId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps`;
        return this.httpClient.request<PaginatedResponseOfProjectStep>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindOne(clientId: string, brandId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProject>;
    public projectsControllerFindOne(clientId: string, brandId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProject>>;
    public projectsControllerFindOne(clientId: string, brandId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProject>>;
    public projectsControllerFindOne(clientId: string, brandId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindOne.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindOne.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerFindOne.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProject>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindOneOrder(clientId: string, brandId: string, projectId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrder>;
    public projectsControllerFindOneOrder(clientId: string, brandId: string, projectId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrder>>;
    public projectsControllerFindOneOrder(clientId: string, brandId: string, projectId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrder>>;
    public projectsControllerFindOneOrder(clientId: string, brandId: string, projectId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindOneOrder.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindOneOrder.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindOneOrder.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerFindOneOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrder>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindOneOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItem>;
    public projectsControllerFindOneOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItem>>;
    public projectsControllerFindOneOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItem>>;
    public projectsControllerFindOneOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindOneOrderItem.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindOneOrderItem.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindOneOrderItem.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerFindOneOrderItem.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerFindOneOrderItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrderItem>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param itemId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindOneOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItemStep>;
    public projectsControllerFindOneOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItemStep>>;
    public projectsControllerFindOneOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItemStep>>;
    public projectsControllerFindOneOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindOneOrderItemStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindOneOrderItemStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindOneOrderItemStep.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerFindOneOrderItemStep.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling projectsControllerFindOneOrderItemStep.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerFindOneOrderItemStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "itemId", value: itemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrderItemStep>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindOneStage(clientId: string, brandId: string, projectId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStage>;
    public projectsControllerFindOneStage(clientId: string, brandId: string, projectId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStage>>;
    public projectsControllerFindOneStage(clientId: string, brandId: string, projectId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStage>>;
    public projectsControllerFindOneStage(clientId: string, brandId: string, projectId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindOneStage.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindOneStage.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindOneStage.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerFindOneStage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProjectStage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param stageId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerFindOneStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStep>;
    public projectsControllerFindOneStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStep>>;
    public projectsControllerFindOneStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStep>>;
    public projectsControllerFindOneStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerFindOneStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerFindOneStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerFindOneStep.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling projectsControllerFindOneStep.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerFindOneStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "stageId", value: stageId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProjectStep>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerRemove(clientId: string, brandId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProject>;
    public projectsControllerRemove(clientId: string, brandId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProject>>;
    public projectsControllerRemove(clientId: string, brandId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProject>>;
    public projectsControllerRemove(clientId: string, brandId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerRemove.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerRemove.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerRemove.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProject>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerRemoveOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItem>;
    public projectsControllerRemoveOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItem>>;
    public projectsControllerRemoveOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItem>>;
    public projectsControllerRemoveOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerRemoveOrderItem.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerRemoveOrderItem.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerRemoveOrderItem.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerRemoveOrderItem.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerRemoveOrderItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrderItem>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param itemId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerRemoveOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItemStep>;
    public projectsControllerRemoveOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItemStep>>;
    public projectsControllerRemoveOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItemStep>>;
    public projectsControllerRemoveOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerRemoveOrderItemStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerRemoveOrderItemStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerRemoveOrderItemStep.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerRemoveOrderItemStep.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling projectsControllerRemoveOrderItemStep.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerRemoveOrderItemStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "itemId", value: itemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrderItemStep>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerRemoveStage(clientId: string, brandId: string, projectId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStage>;
    public projectsControllerRemoveStage(clientId: string, brandId: string, projectId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStage>>;
    public projectsControllerRemoveStage(clientId: string, brandId: string, projectId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStage>>;
    public projectsControllerRemoveStage(clientId: string, brandId: string, projectId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerRemoveStage.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerRemoveStage.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerRemoveStage.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerRemoveStage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProjectStage>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param stageId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerRemoveStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStep>;
    public projectsControllerRemoveStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStep>>;
    public projectsControllerRemoveStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStep>>;
    public projectsControllerRemoveStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerRemoveStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerRemoveStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerRemoveStep.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling projectsControllerRemoveStep.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerRemoveStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "stageId", value: stageId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProjectStep>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param id 
     * @param updateProjectDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerUpdate(clientId: string, brandId: string, id: string, updateProjectDto: UpdateProjectDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProject>;
    public projectsControllerUpdate(clientId: string, brandId: string, id: string, updateProjectDto: UpdateProjectDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProject>>;
    public projectsControllerUpdate(clientId: string, brandId: string, id: string, updateProjectDto: UpdateProjectDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProject>>;
    public projectsControllerUpdate(clientId: string, brandId: string, id: string, updateProjectDto: UpdateProjectDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerUpdate.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerUpdate.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerUpdate.');
        }
        if (updateProjectDto === null || updateProjectDto === undefined) {
            throw new Error('Required parameter updateProjectDto was null or undefined when calling projectsControllerUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProject>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateProjectDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param id 
     * @param updateOrderDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerUpdateOrder(clientId: string, brandId: string, projectId: string, id: string, updateOrderDto: UpdateOrderDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrder>;
    public projectsControllerUpdateOrder(clientId: string, brandId: string, projectId: string, id: string, updateOrderDto: UpdateOrderDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrder>>;
    public projectsControllerUpdateOrder(clientId: string, brandId: string, projectId: string, id: string, updateOrderDto: UpdateOrderDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrder>>;
    public projectsControllerUpdateOrder(clientId: string, brandId: string, projectId: string, id: string, updateOrderDto: UpdateOrderDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerUpdateOrder.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerUpdateOrder.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerUpdateOrder.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerUpdateOrder.');
        }
        if (updateOrderDto === null || updateOrderDto === undefined) {
            throw new Error('Required parameter updateOrderDto was null or undefined when calling projectsControllerUpdateOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrder>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateOrderDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param id 
     * @param updateOrderItemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerUpdateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, updateOrderItemDto: UpdateOrderItemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItem>;
    public projectsControllerUpdateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, updateOrderItemDto: UpdateOrderItemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItem>>;
    public projectsControllerUpdateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, updateOrderItemDto: UpdateOrderItemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItem>>;
    public projectsControllerUpdateOrderItem(clientId: string, brandId: string, projectId: string, orderId: string, id: string, updateOrderItemDto: UpdateOrderItemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerUpdateOrderItem.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerUpdateOrderItem.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerUpdateOrderItem.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerUpdateOrderItem.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerUpdateOrderItem.');
        }
        if (updateOrderItemDto === null || updateOrderItemDto === undefined) {
            throw new Error('Required parameter updateOrderItemDto was null or undefined when calling projectsControllerUpdateOrderItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrderItem>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateOrderItemDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param orderId 
     * @param itemId 
     * @param id 
     * @param updateOrderItemStepDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerUpdateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, updateOrderItemStepDto: UpdateOrderItemStepDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfOrderItemStep>;
    public projectsControllerUpdateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, updateOrderItemStepDto: UpdateOrderItemStepDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfOrderItemStep>>;
    public projectsControllerUpdateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, updateOrderItemStepDto: UpdateOrderItemStepDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfOrderItemStep>>;
    public projectsControllerUpdateOrderItemStep(clientId: string, brandId: string, projectId: string, orderId: string, itemId: string, id: string, updateOrderItemStepDto: UpdateOrderItemStepDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }
        if (updateOrderItemStepDto === null || updateOrderItemStepDto === undefined) {
            throw new Error('Required parameter updateOrderItemStepDto was null or undefined when calling projectsControllerUpdateOrderItemStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/${this.configuration.encodeParam({name: "itemId", value: itemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfOrderItemStep>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateOrderItemStepDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param id 
     * @param updateProjectStageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerUpdateStage(clientId: string, brandId: string, projectId: string, id: string, updateProjectStageDto: UpdateProjectStageDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStage>;
    public projectsControllerUpdateStage(clientId: string, brandId: string, projectId: string, id: string, updateProjectStageDto: UpdateProjectStageDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStage>>;
    public projectsControllerUpdateStage(clientId: string, brandId: string, projectId: string, id: string, updateProjectStageDto: UpdateProjectStageDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStage>>;
    public projectsControllerUpdateStage(clientId: string, brandId: string, projectId: string, id: string, updateProjectStageDto: UpdateProjectStageDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerUpdateStage.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerUpdateStage.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerUpdateStage.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerUpdateStage.');
        }
        if (updateProjectStageDto === null || updateProjectStageDto === undefined) {
            throw new Error('Required parameter updateProjectStageDto was null or undefined when calling projectsControllerUpdateStage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProjectStage>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateProjectStageDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param clientId The client the resource belongs to
     * @param brandId The brand the resource belongs to
     * @param projectId 
     * @param stageId 
     * @param id 
     * @param updateProjectStepDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsControllerUpdateStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, updateProjectStepDto: UpdateProjectStepDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SingleResponseOfProjectStep>;
    public projectsControllerUpdateStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, updateProjectStepDto: UpdateProjectStepDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SingleResponseOfProjectStep>>;
    public projectsControllerUpdateStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, updateProjectStepDto: UpdateProjectStepDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SingleResponseOfProjectStep>>;
    public projectsControllerUpdateStep(clientId: string, brandId: string, projectId: string, stageId: string, id: string, updateProjectStepDto: UpdateProjectStepDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling projectsControllerUpdateStep.');
        }
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling projectsControllerUpdateStep.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectsControllerUpdateStep.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling projectsControllerUpdateStep.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsControllerUpdateStep.');
        }
        if (updateProjectStepDto === null || updateProjectStepDto === undefined) {
            throw new Error('Required parameter updateProjectStepDto was null or undefined when calling projectsControllerUpdateStep.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (cookie) required
        localVarCredential = this.configuration.lookupCredential('cookie');
        if (localVarCredential) {
        }

        // authentication (bearer) required
        localVarCredential = this.configuration.lookupCredential('bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/plan/clients/${this.configuration.encodeParam({name: "clientId", value: clientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/brands/${this.configuration.encodeParam({name: "brandId", value: brandId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/projects/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/stages/${this.configuration.encodeParam({name: "stageId", value: stageId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/steps/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SingleResponseOfProjectStep>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateProjectStepDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
