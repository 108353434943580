<div class="segment-container">
  <div class="segment-inner">
    <div class="segment-head">
      <h3>New Segment</h3>
      <p>Create the New Segments</p>
      <a>More help</a>
    </div>

    <div class="segment-form-container">
      <div class="segment-form-head">
        <div class="segment-form-title">
          <h3>Details</h3>
          <p>Please Fill the segments details</p>
        </div>
        <div class="segment-form-actions">
          <div class="slide-toogle">
            <mat-label ngClass="labelBeforeSlide">Public Segment</mat-label>
            <mat-slide-toggle
              class="example-margin"
              [checked]="checked"
              [disabled]="disabled"
            >
            </mat-slide-toggle>
          </div>
          <div class="slide-toogle">
            <mat-label ngClass="labelBeforeSlide">Published</mat-label>
            <mat-slide-toggle
              class="example-margin"
              [checked]="checked"
              [disabled]="disabled"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="segment-form">
        <form [formGroup]="forms" (ngSubmit)="saveForm()" #configFormElement>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <label class="form-label" for="name">Name:</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Name"
                    formControlName="name"
                    required
                  />
                  <mat-error *ngIf="forms.get('name')?.hasError('required')">
                    Name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="form-label" for="alias">Alias:</label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="alias"
                    placeholder="Choose One..."
                    required
                  >
                    <mat-option *ngFor="let alias of aliases" [value]="alias">{{
                      alias
                    }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="forms.get('alias')?.hasError('required')">
                    Alias selection is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group desc">
                <label class="form-label" for="description"
                  >Description (Optional) :
                </label>
                <mat-form-field appearance="outline">
                  <textarea
                    matInput
                    placeholder="Enter Description"
                    formControlName="description"
                    id="description"
                  >
                  </textarea>
                  <mat-error
                    *ngIf="forms.get('description')?.hasError('required')"
                  >
                    Description is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form-row">
            <!-- <lib-button
              [btnLabel]="buttonLabel"
              [btnSize]="btnSize"
              [btnColor]="buttonColor"
              [btnType]="btnType"
              [buttonShow]="true"
              (eventData)="saveForm()"
              [(spinner)]="spinner"
              (spinnerChange)="spinnerChange.emit($event)"
            >
            </lib-button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="segment-cards">
  <div class="card active">
    <div class="card-body">
      <div class="icon-holder">
        <img src="../../assets/images/icon-code.svg" alt="icon-check" />
      </div>
      <div class="card-content">
        <h4 class="card-title">Load from central database</h4>
        <p class="card-description">
          Select your Audience from the general Data Base by filtering the
          records.
        </p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="icon-holder">
        <img src="../../assets/images/icon-code.svg" alt="icon-check" />
      </div>
      <div class="card-content">
        <h4 class="card-title">Load database</h4>
        <p class="card-description">Upload your contact list in .xls format</p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="icon-holder">
        <img src="../../assets/images/icon-code.svg" alt="icon-check" />
      </div>
      <div class="card-content">
        <h4 class="card-title">Request database</h4>
        <p class="card-description">
          Ask for a Data Base by sending a request.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="qb-container">
  <div class="qb-inner">
    <query-builder
      [formControl]="formsDetails"
      [(ngModel)]="formConfig.query"
      [config]="formConfig.config"
    >
      <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
        <button
          type="button"
          mat-icon-button
          color="grey"
          (click)="removeRule(rule)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-container>
      <ng-container *querySwitchGroup="let ruleset">
        <div class="radio-add-group">
          <mat-radio-group *ngIf="ruleset" [(ngModel)]="ruleset.condition">
            <mat-radio-button value="and">And</mat-radio-button>
            <mat-radio-button value="or">Or</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="addiconMain">
          <div
            class="addiconGroup"
            *queryButtonGroup="
              let ruleset;
              let addRule = addRule;
              let addRuleSet = addRuleSet;
              let removeRuleSet = removeRuleSet
            "
          >
            <button mat-button [matMenuTriggerFor]="menuRule">
              <mat-icon>add</mat-icon>
            </button>
            <mat-menu #menuRule="matMenu">
              <button mat-menu-item (click)="addRule()">+ Rule</button>
              <button mat-menu-item (click)="addRuleSet()">+ Ruleset</button>
              <button mat-menu-item (click)="removeRuleSet()">- Ruleset</button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
      <ng-container
        *queryField="let rule; let fields = fields; let onChange = onChange"
      >
        <mat-form-field>
          <mat-select
            [(ngModel)]="rule.field"
            (ngModelChange)="onChange($event, rule)"
          >
            <mat-option *ngFor="let field of fields" [value]="field.value">{{
              field.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *queryOperator="let rule; let operators = operators">
        <mat-form-field>
          <mat-select [(ngModel)]="rule.operator">
            <mat-option *ngFor="let value of operators" [value]="value">{{
              value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <!-- Override input component for 'boolean' type -->
      <ng-container *queryInput="let rule; type: 'boolean'">
        <mat-checkbox [(ngModel)]="rule.value"></mat-checkbox>
      </ng-container>
      <!-- Override input component for 'category' type -->
      <ng-container
        *queryInput="
          let rule;
          let field = field;
          let options = options;
          type: 'category'
        "
      >
        <mat-form-field>
          <mat-select [(ngModel)]="rule.value" [placeholder]="field.name">
            <mat-option *ngFor="let opt of options" [value]="opt.value">
              {{ opt.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      ...
    </query-builder>
  </div>
</div>
