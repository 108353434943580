import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatChipsModule,
} from '@angular/material/chips';
import {
  MatCardModule,
} from '@angular/material/card';

/**
 *
 */
@Component({
  selector: 'lib-map-field',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatCardModule,
  ],
  templateUrl: './map-field.component.html',
  styleUrls: [
    './map-field.component.scss',
  ],
})
export class MapFieldComponent {
  @Input() mappingTxt!: string;

  @Input() mappingColor!: string;

  @Input() mappingContentTxt!: string;

  @Input() mappingContentTxtColor!: string;
}
