<div class="login-intro">
  <div class="intro-content">
    <div class="intro-text">
      <h2>{{ introTitle }}</h2>
      <p>{{ introDescription }}</p>
    </div>
    <div class="intro-quotes">
      <span>{{ quoteFirst }}</span>
      <span>{{ quoteTwo }}</span>
      <span>{{ quoteThree }}</span>
    </div>
  </div>
</div>
