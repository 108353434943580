/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProjectStageStateEnum = 'pending' | 'progress' | 'done' | 'cancelled';

export const ProjectStageStateEnum = {
    Pending: 'pending' as ProjectStageStateEnum,
    Progress: 'progress' as ProjectStageStateEnum,
    Done: 'done' as ProjectStageStateEnum,
    Cancelled: 'cancelled' as ProjectStageStateEnum
};

