<div class="dropdown">
  <form>
    {{ filterName }}
    <mat-form-field
      class="select-form"
      appearance="outline"
      [ngClass]="styleGeneral"
    >
      <mat-select
        [ngClass]="styleSelect"
        [(ngModel)]="selectedValue"
        (selectionChange)="onSelectionChange($event)"
        name="filterName"
        placeholder="{{ placeHolder }}"
      >
        <mat-option
          [ngClass]="styleSelect"
          *ngFor="let item of filterItems"
          [value]="item.value"
        >
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
