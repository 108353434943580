import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  NgClass,
  NgIf,
} from '@angular/common';
import {
  MatRippleModule,
} from '@angular/material/core';
import {
  MatIconModule,
} from '@angular/material/icon';

/**
 * A Button component
 */
@Component({
  selector: 'lib-button',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatRippleModule,
    MatIconModule,
  ],
  templateUrl: './button.component.html',
  styleUrls: [
    './button.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

  @Input() btnIcon!: boolean;

  @Input() iconUrl!: string;

  @Input() disabled!: boolean;

  @Input() btnLabel!: string;

  @Input() btnColor!: string;

  @Input() btnSize!: string;

  @Input() btnStyle!: string;

  @Input() btnDynamic!: boolean;

  @Input() color!: string;

  @Input() buttonShow!: boolean;

  @Input() fontIcon!: string;

  @Output() eventData = new EventEmitter<Event>();

  @Input() btnType!: string;

  @Input() spinner = false;

  @Input() customIcon!: string;

  @Output() spinnerChange = new EventEmitter<boolean>();

  /**
   * Click Event Function
   *  @param event PointerEvent parameter
   */
  _click(event: MouseEvent) {
    if (this.btnType === 'submit') {
      this.spinner = true;
      this.spinnerChange.emit(this.spinner);
    }
    this.eventData.emit(event);
  }

}
