<div class="container-step">
  <div class="container-title">
    <div class="title-1">
      <label class="lbl-title">Recent Activity</label>
    </div>
    <div class="title-2">
      <button class="image-button"></button>
    </div>
  </div>
  <div class="step-progress">
    <ng-container *ngFor="let step of steps; let last = last">
      <div class="step">
        <div class="circle" [class]="step.state"></div>
        <div *ngIf="!last" class="line"></div>
        <div class="container-txt">
          <span class="txt-title">{{ step.title }}</span>
          <br />
          <span class="txt-description">{{ step.description }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
