import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, ViewChild, AfterViewInit,
  Input, ChangeDetectorRef, SimpleChanges, OnChanges, Output, EventEmitter,
} from '@angular/core';
import {
  MatTableModule, MatTableDataSource,
} from '@angular/material/table';
import {
  MatSortModule, MatSort,
} from '@angular/material/sort';
import {
  MatPaginator, MatPaginatorModule,
} from '@angular/material/paginator';
import {
  ProgressbarComponent,
} from '../progress-bar/progressbar.component';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  MenuComponent,
} from '../menu/menu.component';
import {
  PaginationComponent,
} from '../pagination/pagination.component';
import {
  RatingComponent,
} from '../rating/rating.component';
import {
  PeopleToggleButtonComponent,
} from '../people-toggle-button/people-toggle-button.component';
import {
  PeopleComponentComponent,
} from '../people-component/people-component.component';

export interface tableData {
  name: string;
  id: string;
}

export interface MenuRedirectionParams {
  id: string;
  type: string;
}
/**
 * Table component
 */
@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: [
    './table.component.scss',
  ],
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    MatSortModule,
    MatPaginatorModule,
    ProgressbarComponent,
    ButtonComponent,
    MenuComponent,
    PaginationComponent,
    RatingComponent,
    PeopleToggleButtonComponent,
    PeopleComponentComponent,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() displayedColumns: string[] | undefined;

  @Input() dataSource: tableData[] | undefined;

  @Input() pageIndex: number | undefined;

  @Input() pageSize: number | undefined;

  @Input() length: number | undefined;

  @Input() paginationShow: boolean | undefined;

  @Input() percentage!: number;

  @Input() rating!: number;

  @Input() menuItems: string[] | undefined;

  @Input() buttonShow: boolean[] | undefined;

  @Input() btnColor!: string;

  @Output() redirectUrl = new EventEmitter<string>();

  @Output() menuRedirectionParams = new EventEmitter<MenuRedirectionParams>();

  @Input() showPagination = true;

  datasource: MatTableDataSource<tableData> =  new MatTableDataSource();

  btnLabel = 'Services';

  btnService = 'blue';

  btnIcon = true;

  fontIconAdd = 'apps';

  btnSize = 'sm';

  starCount = 5;

  ratingColor = 'primary';

  value = 'View contacts 2';

  /**
   * Component constructor
   * @param changeRef Service to Trigger detection
   */
  constructor(
    private changeRef: ChangeDetectorRef,
  ) {}

  /**
   * Added datasource
   */
  ngAfterViewInit() {

    this.sort.disableClear = true;
    this.datasource = new MatTableDataSource(this.dataSource);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  /**
   *
   * @param rating number
   */
  onRatingChanged(rating: number) {
    this.rating = rating;
  }

  /**
   *
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource'] && changes['dataSource'].currentValue) {
      this.datasource = new MatTableDataSource(this.dataSource);
    }
  }

  /**
   * Method to emit redirect url
   * @param link Link
   */
  onRedirctUrl(link:string) {
    this.redirectUrl.emit(link);

  }

  /**
   *
   * @param id id
   * @param type type
   */
  onClickId(id: string, type: string) {
    this.menuRedirectionParams.emit({
      id : id,
      type: type,
    });
  }


}
