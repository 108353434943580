<div class="table">
  <table mat-table [dataSource]="datasource" matSort>
    <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col }}</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="col === 'id'">
          <a
            href="javascript:void(0);"
            class="txt-line"
            (click)="onClickId(row['id'], 'setRedirection')"
            >{{ row[col] | slice : -5 }}</a
          >
        </ng-container>
        <ng-container *ngIf="col !== 'id' && col !== 'name' && col !== 'order'">
          {{ row[col] }}
        </ng-container>
        <ng-container *ngIf="col === 'name'">
          <a
            href="javascript:void(0);"
            class="txt-line"
            (click)="onClickId(row['id'], 'setRedirection')"
            >{{ row[col] }}</a
          >
        </ng-container>
        <ng-container *ngIf="col === 'order'">
          <a
            href="javascript:void(0);"
            class="txt-line"
            (click)="onClickId(row['id'], 'openOrderPanel')"
            >{{ row[col] }}</a
          >
        </ng-container>
        <ng-container *ngIf="col === 'status'">
          <div *ngIf="row.statusItems.buttonFlag === true; else elseBlock">
            <lib-button
              [btnLabel]="row.statusItems.value"
              [btnColor]="row.statusItems.btnColor"
              [fontIcon]="fontIconAdd"
              [btnSize]="btnSize"
              [buttonShow]="row.buttonShow"
              (click)="onRedirctUrl(row.statusItems.redirectLink)"
            >
            </lib-button>
          </div>
          <ng-template #elseBlock>
            <span
              class="status"
              (click)="onRedirctUrl(row.statusItems.redirectLink)"
            >
              <i
                *ngIf="row.statusItems.bulletIcon"
                [ngClass]="row.statusItems.bulletColor"
              ></i>
              {{ row.statusItems.value | titlecase }}
              <img
                *ngIf="row.statusItems.iconLink"
                src="{{ row.statusItems.iconLink }}"
                alt="{{ row.statusItems.value }}"
              />
            </span>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="col === 'progress'">
          <lib-progressbar [percentage]="row.percentage"></lib-progressbar>
        </ng-container>
        <ng-container *ngIf="col === 'service'">
          <lib-button
            [btnLabel]="btnLabel"
            [btnColor]="btnService"
            [btnIcon]="btnIcon"
            [fontIcon]="fontIconAdd"
            [btnSize]="btnSize"
            [buttonShow]="row.buttonShow"
          >
          </lib-button>
        </ng-container>
        <ng-container *ngIf="col === 'ratings'">
          <lib-rating
            [rating]="row.rating"
            [starCount]="starCount"
            [ratingColor]="ratingColor"
            (ratingUpdated)="onRatingChanged($event)"
          >
            start</lib-rating
          >
        </ng-container>
        <ng-container *ngIf="col === 'publised' || col === 'On/Off'">
          <lib-people-toggle-button> </lib-people-toggle-button>
        </ng-container>
        <ng-container *ngIf="col === 'Contact' || col === 'Status'">
          <lib-people-contact-component
            *ngIf="row.contact ? row.contact : row.status; let data"
            [value]="data"
          >
          </lib-people-contact-component>
        </ng-container>
        <ng-container *ngIf="col === 'Execution Order'">
          {{ row.EXECUTION_ORDER }}
        </ng-container>
        <ng-container *ngIf="col === 'Last Active'">
          {{ row.Last_Active }}
        </ng-container>
        <ng-container *ngIf="col === 'action' || col === 'Action'">
          <lib-menu *ngIf="row.menuItems" [menuItems]="row.menuItems">
          </lib-menu>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- <lib-pagination [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="datasource.filteredData.length"
  [paginationShow]="paginationShow" (pageChange)="onPageChange($event)" [dataSource]="dataSource"></lib-pagination> -->

  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons
    aria-label="Select page of users"
    *ngIf="showPagination"
  ></mat-paginator>
</div>
