<label class="toggle">
  <input
    class="toggle-checkbox"
    type="checkbox"
    (change)="onCheckboxChange($event)"
  />
  <div class="toggle-switch text onChecked">
    <div [ngClass]="isChecked === 'ON' ? 'onChecked' : 'onUnChecked'">
      {{ isChecked }}
    </div>
  </div>
</label>
