import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  MatIconModule,
} from '@angular/material/icon';

export interface ServiceDetail {
  imageUrl: string;
  serviceName: string;
  serviceId: string;
}

export interface BrandDetail {
  imageUrl: string;
  brandName: string;
  brandId: string;
  services?: ServiceDetail[];
}

export interface ClientDetail {
  imageUrl: string;
  clientName: string;
  clientId: string;
  brands?: BrandDetail[];
}

export interface SelectedClientAndBrandOptions {
  client: ClientDetail;
  brand: BrandDetail;
  service: ServiceDetail;
}

/**
 * A service-menu component
 */
@Component({
  selector: 'lib-servicemenu',
  templateUrl: './servicemenu.component.html',
  styleUrls: [
    './servicemenu.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceMenuComponent {

  @Input() selectedBrand!: BrandDetail | undefined;

  @Input() clientDetails!: ClientDetail[];

  @Input() defaultImage!: 'https://production-static.wsuite.com/destiny-favicon_1622617806415.png';

  @Output() selection = new EventEmitter<SelectedClientAndBrandOptions>();

  defaultList = 6;

  displayMenu = false;

  selectedStep = 1;

  public brandDetails: BrandDetail[] = [
  ];

  public serviceDetails: ServiceDetail[] = [
  ];

  private selectedOptions: SelectedClientAndBrandOptions = {
    client: {
      clientName: '',
      clientId: '',
      imageUrl: '',
    } ,
    brand: {
      brandId: '',
      brandName: '',
      imageUrl: '',
    },
    service: {
      serviceName: '',
      serviceId: '',
      imageUrl: '',
    },
  };

  private clickedInside = false;

  /**
   * Event on click inside this component
   */
  @HostListener('click')
  clicked() {
    this.clickedInside = true;
  }

  /**
   * Global document click
   */
  @HostListener('document:click')
  handleDocumentClick() {
    // Check if the clicked element is outside the service menu
    if (!this.clickedInside) {
      this.closeMenu();
    }
    this.clickedInside = false;
  }

  /**
   * Function To Toggle menu
   */
  toggleMenu() {
    this.displayMenu = !this.displayMenu;
    this.selectedStep = 1;
  }


  /**
   * Function To set back step to the  menu
   */
  setBackStep() {
    if (this.selectedStep > 1) {
      this.selectedStep--;
    }
  };

  /**
   * This function is used to move the steps forward and backward
   * @param client The selected client
   */
  selectClient(client: ClientDetail) {
    this.brandDetails = client.brands || [
    ];
    this.selectedOptions.client = client;
    this.moveStep();
  }

  /**
   * This function is used to move the steps forward and backward
   * @param brand The selected brand
   */
  selectBrand(brand: BrandDetail) {
    this.serviceDetails = brand.services || [
    ];
    this.selectedOptions.brand = brand;
    this.moveStep();
  }

  /**
   * This function is used to move the steps forward and backward
   * @param service The selected brand
   */
  selectService(service: ServiceDetail) {
    this.selectedOptions.service = service;
    this.selection.emit(this.selectedOptions);
    this.displayMenu = false;
    this.selectedStep = 2;
  }

  /**
   * This function is used to move the steps forward and backward
   */
  moveStep() {
    if (this.selectedStep == 1 || this.selectedStep == 2) {
      this.selectedStep++;
    }
  }


  // Function to close the menu
  /**
   *
   */
  closeMenu() {
    this.displayMenu = false;
  }
}
