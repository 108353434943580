import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import {
  CommonModule,
} from '@angular/common';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  WelcomeComponent,
} from '../welcome/welcome.component';
import {
  LoginWelcomeComponent,
} from '../login-welcome/login-welcome.component';

/**
 * A account selection component
 */
@Component({
  selector: 'lib-account-selection',
  standalone: true,
  templateUrl: './account-selection.component.html',
  styleUrls: [
    './account-selection.component.scss',
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    WelcomeComponent,
    LoginWelcomeComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AccountSelectionComponent implements OnInit, OnChanges {
  @Input() title1! : string;

  @Input() title2! : string;

  @Input() companyNamePlaceHolder! : string;

  @Input() description! : string;

  @Input() submitText! : string;

  @Input() altName! : string;

  @Input() panelClass! : string;

  @Input() width! : string;

  @Output() companyName = new EventEmitter<string>();

  @Input() spinner = false;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Input() parentError!: string;

  @Input() brandText! : string;

  @Input() introTitle!: string;

  @Input() introDescription! : string;

  @Input() quoteFirst!: string;

  @Input() quoteTwo! : string;

  @Input() quoteThree!: string;

  @Input() brandLogo!: string;


  companyForm!: FormGroup;

  buttonLabel = 'CONTINUE';

  buttonColor = 'blue';

  btnSize = 'md';

  btnType = 'submit';


  /**
   *
   * @param _formBuilder FormBuilder
   */
  constructor(private _formBuilder: FormBuilder) {}

  /**
   * formGroup
   */
  ngOnInit() {
    this.companyForm = this._formBuilder.group({
      company: [
        '',
        Validators.required,
      ],
    });
  }

  /**
   * Form Submit Function
   */
  onSubmit() {
    this.companyForm.disable({
      emitEvent: false,
    });
    this.companyName.emit(this.companyForm.value.company);
  }

  /**
   * Detect Changer for setting an error from parent component
   * @param changes The Angular SimpleChanges object
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parentError'] && !changes['parentError']?.firstChange && !this.spinner) {
      this.companyForm.enable({
        emitEvent: false,
      });
      if (!changes['parentError'].currentValue) {
        this.companyForm.controls['company'].updateValueAndValidity();
      } else {
        this.companyForm.controls['company'].setErrors({
          parentError: changes['parentError'].currentValue,
        });
        this.companyForm.controls['company'].markAsTouched();
      }
    }

    if (changes['spinner'] && !changes['spinner'].firstChange && !changes['spinner'].currentValue) {
      this.companyForm.enable({
        emitEvent: false,
      });
      if (this.parentError) {
        this.companyForm.controls['company'].setErrors({
          parentError: this.parentError,
        });
        this.companyForm.controls['company'].markAsTouched();
      }
    }
  }

}

