import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-card-quick-access',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './card-quick-access.component.html',
  styleUrls: [
    './card-quick-access.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardQuickAccessComponent {
  @Input() icon : string | undefined;

  @Input() status : string | undefined;

  @Input() title : string | undefined;

  @Input() dateTime : string | undefined;

  @Input() description : string | undefined;

  /**
   * @returns Object
   */
  getStatusClass() {
    return {
      'in-development': this.status === 'In Development',
      'new': this.status === 'New',
      'finished': this.status === 'Finished',
    };
  }
}
