import {
  ChangeDetectionStrategy, Component, Input, OnInit,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import * as d3 from 'd3';
export interface ChartValue {
  name: string;
  value: number;
  color: string;
}

/**
 *  chart-donut component
 */
@Component({
  selector: 'lib-chart-donut',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './chart-donut.component.html',
  styleUrls: [
    './chart-donut.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDonutComponent implements OnInit {
  @Input() data!: ChartValue[];

  @Input() totalValue!: number;

  @Input() width!: number;

  @Input() textSeccion!: boolean;

  @Input() textEmpty!: string;

  @Input() colorEmpty!: string;

  @Input() opacityEmpty = 1;

  @Input() colorBackground!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  svg!: any;

  colors!: d3.ScaleOrdinal<string, unknown, never>;

  /**
   * init method
   */
  ngOnInit(): void {
    this.drawChart();
  }

  /**
   *  method that draws the chart
   */
  private drawChart(): void {
    const height = Math.min(this.width, 500);
    const radius = Math.min(this.width, height) / 2;

    if (this.data) {
      let combinedData;
      let arc: d3.Arc<unknown, d3.DefaultArcObject>;

      if (this.totalValue && this.totalValue > 0) {
        let remainingValue = this.totalValue;

        for (const obj of this.data) {
          remainingValue = remainingValue - obj.value;
        }

        const remainingData = [
          {
            name: this.textEmpty,
            value: remainingValue,
            color: 'rgba(255, 255, 255, 0)',
          },
        ];

        combinedData = this.data.concat(remainingData);
      } else {
        combinedData = this.data;
      }

      this.svg = d3
        .select('figure#donut')
        .append('svg')
        .attr('width', this.width)
        .attr('height', height)
        .attr('viewBox', [
          -this.width / 2,
          -height / 2,
          this.width,
          height,
        ])
        .attr('style', 'max-width: 100%; height: auto;');

      if (this.totalValue && this.totalValue > 0) {
        arc = d3
          .arc()
          .innerRadius(radius * 0.67)
          .outerRadius(radius - 1)
          .cornerRadius(10);
      } else {
        arc = d3
          .arc()
          .innerRadius(radius * 0.67)
          .outerRadius(radius - 1);
      }

      const grayArc = d3
        .arc()
        .innerRadius(radius * 0.71)
        .outerRadius(radius - 4);

      const pie = d3
        .pie<ChartValue>()
        .padAngle(1 / radius)
        .sort(null)
        .value((d) => d.value);

      this.colors = d3
        .scaleOrdinal()
        .domain(combinedData.map((d: ChartValue) => d.name))
        .range(combinedData.map((d: ChartValue) => d.color));

      this.svg
        .selectAll('.background-arc')
        .data(
          pie([
            {
              name: '',
              value: 100,
              color: this.colorEmpty,
            },
          ]),
        )
        .enter()
        .append('path')
        .attr('class', 'background-arc')
        .attr('d', grayArc)
        .attr('fill', this.colorEmpty)
        .attr('opacity', this.opacityEmpty);

      this.svg
        .append('g')
        .selectAll()
        .data(pie(combinedData))
        .join('path')
        .attr('fill', (i: string) => (this.colors(i)))
        .attr('d', arc)
        .append('title')
        .text((d: d3.PieArcDatum<ChartValue>) => `${d.data.name}: ${d.data.value.toLocaleString()}`);

      if (this.textSeccion) {
        this.svg
          .append('g')
          .attr('font-family', 'sans-serif')
          .attr('font-size', 12)
          .attr('text-anchor', 'middle')
          .selectAll()
          .data(pie(combinedData))
          .join('text')
          .attr('transform', (d: d3.DefaultArcObject) => `translate(${arc.centroid(d)})`)
          .call((text: d3.Selection<SVGGElement, d3.PieArcDatum<ChartValue>, HTMLElement, unknown>) =>
            text
              .append('tspan')
              .attr('y', '-0.4em')
              .attr('font-weight', 'bold')
              .text((d: d3.PieArcDatum<ChartValue>) => d.data.name),
          )
          .call((text: d3.Selection<SVGGElement, d3.PieArcDatum<ChartValue>, HTMLElement, unknown>) =>
            text
              .filter((d: d3.PieArcDatum<ChartValue>) => d.endAngle - d.startAngle > 0.25)
              .append('tspan')
              .attr('x', 0)
              .attr('y', '0.7em')
              .attr('fill-opacity', 0.7)
              .text((d: d3.PieArcDatum<ChartValue>) => d.data.value.toLocaleString('en-US')),
          );
      }
    }
  }
}
