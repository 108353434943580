import {
  Injectable,
} from '@angular/core';
import {
  BehaviorSubject, map,
} from 'rxjs';

/**
 * Loader Service
 */
@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  loaderSubject = new BehaviorSubject<boolean>(false);

  loaderState = this.loaderSubject.asObservable().pipe(
    map((loading) => ({
      loading,
    })),
  );

  /**
   * Method to Show Loader
   */
  show() {
    this.loaderSubject.next(true);
  }

  /**
   * Method to Hide Loader
   */
  hide() {
    this.loaderSubject.next(false);
  }


}
