import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';

/**
 *Favorite component
 */
@Component({
  selector: 'lib-favorite',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './favorite.component.html',
  styleUrls: [
    './favorite.component.scss',
  ],
})
export class FavoriteComponent {
  @Input() inputTitle = '';

  isLiked = false;

  /**
   *toggleFavorite
   */
  toggleLike() {
    this.isLiked = !this.isLiked;
  }
}
