<div class="request-approve">
  <div class="request-approve-inner">
    <div class="ra-img">
      <img src="{{ heroIcon }}" alt="Genera" />
    </div>
    <div class="ra-content">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
    <div class="ra-icon">
      <img src="{{ productIcon }}" alt="Genera" />
    </div>
  </div>
</div>
