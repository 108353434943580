import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSelectModule,
} from '@angular/material/select';
import {
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  ButtonComponent,
} from '../button/button.component';
/**
 *
 */
@Component({
  selector: 'lib-query-builder-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatInputModule,
  ],
  templateUrl: './query-builder-form.component.html',
  styleUrls: [
    './query-builder-form.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryBuilderFormComponent implements OnInit {

  @Input() spinner = false;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() aliases: any;

  checked = false;

  disabled = false;

  color = 'green';

  buttonLabel = 'ADD';

  buttonColor = 'blue';

  btnSize = 'md';

  btnType = 'submit';

  forms!: FormGroup;

  showForm = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailsObject: any;

  iSOpenDetails = false;


  /**
   * Constructor
   * @param fb FormBuilder
   */
  constructor(private fb: FormBuilder) {}

  /**
   * formbuilder
   */
  ngOnInit(): void {
    this.createForm();
    // this.detectChanges();
  }

  /**
   * createForm
   */
  createForm() {
    this.forms = this.fb.group({
      name: [
        '',
        Validators.required,
      ],
      alias: [
        '',
        Validators.required,
      ],
      description: [
        '',
        Validators.required,
      ],
    });
  }

  /**
   *
   */
  detectChanges() {
    this.forms.valueChanges.subscribe(res => {
      if (this.forms.valid) {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      } else {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      }
    });
  }

  /**
   * saveForm
   */
  saveForm() {
    if (!this.forms.valid) {
      const changeData = {
        isModified: true,
        isValid: false,
        value: this.forms.value,
      };
      this.formChange.emit(changeData);
      this.forms.reset();
    } else {
      this.formData.emit({
        data: {
          ...this.forms.value,
        },
      });
      this.iSOpenDetails = true;
      this.detailsObject = this.forms.value;
      this.showForm = false;
      this.markFormGroupTouched(this.forms);
    }
  }

  /**
   *
   * @param formGroup formGroup
   */
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
