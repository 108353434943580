import {
  Component, ElementRef, HostListener, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  MatMenuModule,
} from '@angular/material/menu';
interface UserIcon {
  img: string;
  name: string;
}
interface UserDocs {
  doc: string;
  name: string;
}

/**
 *search-in-content component
 */
@Component({
  selector: 'lib-search-in-content',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
  ],
  templateUrl: './search-in-content.component.html',
  styleUrls: [
    './search-in-content.component.scss',
  ],
})
export class SearchInContentComponent {

  @Input() placeholder = '';

  @Input() matPrefixIcon = '';

  @Input() matSuffixIcon = '';

  @Input() userIcon: UserIcon[] = [
  ];

  @Input() userDocs: UserDocs[] = [
  ];

  @Input() button = '';

  showCard = false;

  /**
   *constructor
   * @param el -el
   */
  constructor(private el: ElementRef) {}

  /**
   * Close the ng-container when clicking outside the search-bar.
   * @param event -event
   */
  @HostListener('document:click', [
    '$event',
  ])
  handleClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.showCard = false;
    }
  }

  /**
   *toggleCardVisibility
   */
  toggleCardVisibility(): void {
    this.showCard = !this.showCard;
  }
}
