<div class="card-contacts">
  <div class="card-padding">
    <label class="lbl-general font-16">All contacts</label>
    <br />
    <label class="lbl-general font-42">10</label>
    <label class="lbl-general font-29">mil</label>
    <br />
    <label class="lbl-italic">Last update ~ December 18, 2018</label>
  </div>
  <div class="border-top">
    <label class="lbl-general font-16">This Month</label>
    <div class="container">
      <div class="section-1">
        <lib-chart-donut
          [data]="data"
          [totalValue]="totalValue"
          [width]="75"
          [textSeccion]="false"
          [textEmpty]="''"
          [colorBackground]="'#FFFFFF'"
          [colorEmpty]="'#F2F2F2'"
        ></lib-chart-donut>
      </div>
      <div class="section-2">
        <label class="lbl-general font-14">New Contacts</label>
        <br />
        <label class="lbl-general font-25">1.000</label>
      </div>
    </div>

    <button class="btn-card">
      <span class="lbl-general font-13">ADD NEW</span>
    </button>
  </div>
</div>
