import {
  Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-people-toggle-button',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './people-toggle-button.component.html',
  styleUrls: [
    './people-toggle-button.component.scss',
  ],
})
export class PeopleToggleButtonComponent {
  isChecked = 'OFF';

  /**
   *
   * @param event -
   */
  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
      this.isChecked = 'ON';
    } else {
      this.isChecked = 'OFF';
    }
  }
}
