import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  RouterModule,
} from '@angular/router';

/**
 * A logo component using SCAM methodology
 */
@Component({
  selector: 'lib-logo',
  templateUrl: './logo.component.html',
  styleUrls: [
    './logo.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() sortName : string | undefined;

  @Input() serviceName : string | undefined;

  @Input() linkURI : string | '/dashboard' | undefined;

  @Input() logoUrl : string | undefined;

  @Input() style : string | undefined;

  @Input() className : string | undefined;
}
