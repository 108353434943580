<div class="page-toolbar">
  <div class="dropdown-button">
    <ng-container *ngIf="toggleType">
      <lib-drive-type [docItems]="typeItems"></lib-drive-type>
    </ng-container>
    <ng-container *ngIf="toggleType2">
      <lib-drive-type
        [docItems]="typeItems2"
        [menuTitle]="menuTitleType2"
      ></lib-drive-type>
    </ng-container>
    <ng-container *ngIf="togglePeople">
      <lib-drive-people
        [docItems]="docItems"
        [folder]="folder"
        [menuTitle]="menuTitle"
      >
      </lib-drive-people>
    </ng-container>
    <ng-container *ngIf="toggleModified">
      <lib-modified-drop-down
        [modifiedItems]="modifiedItems"
        buttonName="Modified"
        dropDownIcon="arrow_drop_down"
        clearButton="Clear all"
        cancelButton="Cancel"
        submitButton="Apply"
      >
      </lib-modified-drop-down>
    </ng-container>
  </div>
  <div class="table-view-toogle-btn">
    <mat-button-toggle-group
      class="toggle-btn-group"
      #group="matButtonToggleGroup"
      [value]="tableView ? 'table' : 'grid'"
    >
      <mat-button-toggle
        class="toggle-btn"
        value="table"
        (click)="switchView('table')"
        aria-label="Text align left"
      >
        <mat-icon *ngIf="group.value === 'table'">done</mat-icon>
        <mat-icon>menu</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        class="toggle-btn"
        value="grid"
        (click)="switchView('grid')"
        aria-label="Text align center"
      >
        <mat-icon *ngIf="group.value === 'grid'">done</mat-icon>
        <mat-icon>grid_view</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button
      *ngIf="false"
      mat-icon-button
      aria-label="Example icon-button with a menu"
      class="info-button"
    >
      <mat-icon class="material-icons-outlined info-icon">info</mat-icon>
    </button>
  </div>
</div>

<div class="section-head">
  <h4 class="section-title">{{ cards_heading }}</h4>
</div>
<ng-container *ngIf="recentCards">
  <lib-recent-cards
    [cardDetails]="cardDetailsData"
    [cardNumber]="tableView === true ? 4 : undefined"
    [iconName]="iconName"
    [menuList]="menuList"
    (openDialogFile)="handleMenuClick($event)"
    (openSharePopup)="SharePopUpData($event)"
    (openFileDetailsPopup)="handelFileDetails($event)"
  >
  </lib-recent-cards>
</ng-container>
<ng-container *ngIf="dynamicCards">
  <div class="dynamic-cards d-flex">
    <lib-card-container-list
      [cardOptions]="cardOptions"
      [sliceCard]="tableView === true ? 4 : undefined"
    >
    </lib-card-container-list>
  </div>
</ng-container>
<div class="table" *ngIf="tableView" (window:resize)="onResize($event)">
  <table mat-table [dataSource]="datasource" matSort>
    <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col }}</th>
      <td mat-cell *matCellDef="let row">
        <!-- Map 'Name' column -->
        <ng-container *ngIf="col === 'Name'">
          <div class="name-container">
            <img
              class="grayScal"
              [src]="row[col].img"
              alt="{{ row[col].name }}"
              *ngIf="row[col].img"
            />
            {{ row[col].name || '----' }}
          </div>
        </ng-container>
        <!-- Map 'State' column -->
        <ng-container *ngIf="col === 'AnalysisName'">
          {{ row[col] }}
        </ng-container>
        <!-- Map 'State' column -->
        <ng-container *ngIf="col === 'Status'">
          {{ row[col] }}
        </ng-container>

        <!-- Map 'Owner' column -->
        <ng-container *ngIf="col === 'Owner'">
          <div class="name-container">
            <img [src]="row[col].img" alt="{{ row[col].name }}" />
            {{ row[col].name || '----' }}
          </div>
        </ng-container>

        <!-- Map 'Last modified' column -->
        <ng-container *ngIf="col === 'LastModified'">
          {{ row[col].date }} - {{ row[col].name }}
        </ng-container>

        <!-- Map 'Last modified' column -->
        <ng-container *ngIf="col === 'LastExecution'">
          {{ row[col] }}
        </ng-container>

        <!-- Map 'State' column -->
        <ng-container *ngIf="col === 'ProcessDetails'">
          <p (click)="handleViewDetailsClicked(row)">
            {{ row[col].name }}
          </p>
        </ng-container>

        <!-- Map 'File size' column -->
        <div class="icon-container-column">
          <ng-container *ngIf="col === 'FileSize'">
            {{ row[col] || '----' }}
          </ng-container>

          <!-- <ng-container  *ngIf="showIcons && col === 'FileSize'">
            <div class="icon-position">
              <div *ngFor="let icon of row.icons">
                <button mat-icon-button>
                  <mat-icon class="action-icon">{{ icon }}</mat-icon>
                </button>
              </div>
            </div>
          </ng-container> -->
        </div>

        <!-- Map 'action' column -->
        <ng-container *ngIf="col === 'action'">
          <!-- <lib-menu
            *ngIf="row[col] && row[col].length > 0"
            [menuItems]="row[col]"
          ></lib-menu> -->
          <lib-menu-icon
            *ngIf="row[col] && row[col].length > 0"
            [iconName]="iconName"
            [menuList]="row[col]"
            (menuListClicked)="handledData(row, $event)"
            (menuListClicked)="handleMenuClick($event)"
          ></lib-menu-icon>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons
    aria-label="Select page of users"
    *ngIf="showPagination"
  ></mat-paginator>
</div>

<div class="row" *ngIf="gridView">
  <div class="folder-heading">
    <div class="section-title">
      <h4>{{ folderHeading }}</h4>
    </div>
    <ng-container *ngIf="menuItemActive === true">
      <div class="name-button">
        <button mat-flat-button [matMenuTriggerFor]="menu1">
          <div class="button-content">
            <span class="my-name-text">{{ selectedMenuItem }}</span>
            <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
          </div>
        </button>
        <mat-menu #menu1="matMenu">
          <button
            mat-menu-item
            *ngFor="let item of buttonNameData"
            (click)="onMenuItemSelect(item)"
          >
            {{ item }}
            <mat-icon *ngIf="selectedMenuItem === item" class="selected-icon"
              >arrow_upward</mat-icon
            >
          </button>
        </mat-menu>
        <div class="menu-items">
          <lib-menu [menuItems]="menuItemsData"> </lib-menu>
        </div>
      </div>
    </ng-container>
  </div>
  <mat-card class="card-folder d-flex" *ngFor="let item of filteredFolders">
    <div class="folder-card-item">
      <div class="file-icon">
        <img src="{{ item.Name?.img }}" alt="image" />
      </div>
      <div class="folder-title">
        {{ item.Name?.name }}
      </div>
      <div class="more-details">
        <lib-menu-icon
          *ngIf="item.action && item.action.length > 0"
          [iconName]="iconName"
          [menuList]="item?.action"
          (menuListClicked)="handleMenuClick($event)"
        ></lib-menu-icon>
      </div>
    </div>
  </mat-card>
</div>

<div class="row" *ngIf="gridView">
  <div class="section-title">
    <h4>{{ fileHeading }}</h4>
  </div>
  <div class="d-flex">
    <mat-card class="card" *ngFor="let item of filteredFiles">
      <div class="card-item">
        <div class="file-icon">
          <img src="{{ item.Name?.img }}" alt="image" />
        </div>
        <div class="file-title">
          {{ item.Name?.name }}
        </div>
        <div class="more-details">
          <lib-menu-icon
            *ngIf="item.action && item.action.length > 0"
            [iconName]="iconName"
            [menuList]="item?.action"
            (menuListClicked)="handleMenuClick($event)"
            (menuListClicked)="handledData(item, $event)"
          ></lib-menu-icon>
        </div>
      </div>
      <mat-card-content class="image-content">
        <img
          src="{{ item?.thumbnailImage }}"
          alt="image"
          (click)="handleMenuClick(item)"
        />
      </mat-card-content>
    </mat-card>
  </div>
</div>
