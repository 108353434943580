<div class="card-campaign">
  <div class="card-campaign-title">
    <h4>Total campaigns created</h4>
  </div>
  <div class="card-campaign-body">
    <div class="value">50</div>
    <lib-chart-line
      [dataPoints]="dataPoints"
      [widthCard]="widthCard"
      [heightCard]="heightCard"
      [strokeLines]="'rgba(255, 255, 255, 0.2)'"
      [percentage]="true"
      [buttons]="false"
      [classEjes]="'-blue'"
    ></lib-chart-line>
  </div>
</div>
