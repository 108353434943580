/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemStateEnum } from './order-item-state-enum';


export interface CreateOrderItemDto { 
    /**
     * Static Banner
     */
    name: string;
    /**
     * Product Variant Id
     */
    productVariantId: string;
    /**
     * Order of the Product Step
     */
    sequence: number;
    /**
     * ISO Date Date when Project is going to be release / live
     */
    plannedStartDate: string;
    /**
     * ISO Date Date when Project is going to be release / live
     */
    plannedEndDate: string;
    /**
     * Send to Product
     */
    sendToProduction: boolean;
    state: OrderItemStateEnum;
}
export namespace CreateOrderItemDto {
}


