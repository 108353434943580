<div class="left-sidebar-wrap main-sidebar">
  <div class="sidebar-collapse-btn" (click)="hideShowMenu()">
    <span>
      <img src="/assets/images/align-center.svg" />
    </span>
  </div>
  <div class="left-nav-head">
    <div class="head-icon">
      <img
        ImageWithPresign
        src="{{ sideNavHeadIcon }}"
        width="100%"
        alt="Nav Icon"
      />
    </div>
    <div class="left-nav-head-content">
      <h4>{{ title }}</h4>
      <p>
        <a href="{{ linkUrl }}"> {{ linkName }} </a>
      </p>
    </div>
  </div>
  <div class="side-nav">
    <ul class="sidebar-menu">
      <ng-container>
        <li *ngFor="let mi of appServices; let i = index">
          <a class="nav-link">
            <i>
              <img src="{{ mi.icon }}" width="100%" alt="icon" />
            </i>
            <span>{{ mi.text }}</span>
          </a>
          <ul class="flex-column d-inline-block submenu">
            <ng-container>
              <li
                [ngClass]="{ active: 'active' }"
                *ngFor="let sub of mi.serviceRow1"
              >
                <a href="javascript:void(0)">{{ sub.mainText }}</a>
                <span href="javascript:void(0)">{{ sub.subText }}</span>
                <ul class="flex-column d-inline-block sub-submenu">
                  <li *ngFor="let ch of sub.serviceRow2">
                    <a href="{{ ch.link }}">{{ ch.text }}</a>
                  </li>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
  <div #dynamicNav></div>
</div>
