<!-- Login block start -->

<div class="login-container">
  <div class="login-body">
    <div class="left-block">
      <lib-login-welcome
        [introTitle]="introTitle"
        [introDescription]="introDescription"
        [quoteFirst]="quoteFirst"
        [quoteTwo]="quoteTwo"
        [quoteThree]="quoteThree"
      >
      </lib-login-welcome>
    </div>
    <div class="login-main">
      <div class="main-content">
        <div class="app-logo">
          <lib-welcome [brandLogo]="brandLogo"></lib-welcome>
        </div>

        <div class="account-section-box company-dialog">
          <div class="back-cta">
            <a id="email-txt" class="link-back" [routerLink]="[linkURI]">
              {{ back }}
            </a>
          </div>
          <div class="company-name">
            Welcome <span>{{ companyName }}</span>
          </div>
          <div class="auto-container">
            <p>{{ infoText }}</p>
          </div>
          <div class="login-brand-icon">
            <img src="{{ logoUrl }}" alt="{{ sortName }}-{{ serviceName }}" />
            <div class="account">
              <h6 class="account-name">{{ accountName }}</h6>
              <span class="account-type">{{ account }}</span>
            </div>
          </div>

          <form class="auth-form" [formGroup]="loginForm">
            <mat-form-field
              class="full-width"
              [hideRequiredMarker]="hideRequiredControl.value"
              [floatLabel]="getFloatLabelValue()"
            >
              <input
                matInput
                placeholder="{{ placeholderEmail }}"
                required
                formControlName="email"
              />
              <mat-error
                *ngIf="loginForm.controls['email'].hasError('required')"
              >
                Please enter a email
              </mat-error>
              <mat-error *ngIf="loginForm.controls['email'].hasError('email')">
                Please enter valid email
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="full-width"
              [hideRequiredMarker]="hideRequiredControl.value"
              [floatLabel]="getFloatLabelValue()"
            >
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                placeholder="{{ password }}"
                formControlName="password"
              />
              <button
                *ngIf="false"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
                type="button"
                [disabled]="spinner || loginForm.disabled"
              >
                <mat-icon>{{
                  hide ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </button>
              <mat-error
                *ngIf="loginForm.controls['password'].hasError('required')"
              >
                Please enter a Password
              </mat-error>
              <mat-error
                *ngIf="loginForm.controls['password'].hasError('pattern')"
                >Password must contain minimum 8 characters, 1 upper case
                letter, and 1 special character
              </mat-error>
              <mat-error
                *ngIf="loginForm.controls['password'].hasError('parentError')"
              >
                {{ loginForm.controls['password'].getError('parentError') }}
              </mat-error>
            </mat-form-field>

            <div class="form-row">
              <mat-checkbox
                color="primary"
                class="checkbox"
                formControlName="remember"
              >
                {{ rememberMe }}
              </mat-checkbox>
              <a
                id="forgot-password"
                class="link-forgot"
                [routerLink]="[forgotPasswordLink]"
              >
                {{ forgotPasswordText }}
              </a>
            </div>
            <lib-button
              [btnLabel]="buttonLabel"
              [btnSize]="btnSize"
              [btnColor]="buttonColor"
              [btnType]="btnType"
              [disabled]="loginForm.invalid"
              [buttonShow]="true"
              (eventData)="onSubmit()"
              [(spinner)]="spinner"
              (spinnerChange)="spinnerChange.emit($event)"
            >
            </lib-button>
          </form>
        </div>
        <div class="terms-text">
          <p>
            By Singing in you accept out
            <a href="javascript:void(0)" target="_blank">Terms</a> &
            <a href="javascript:void(0)" target="_blank">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Login block End -->
