import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  RouterModule,
} from '@angular/router';
export interface MenuList {
  label?: string;
  link?: string;
  icon?: string;
}

/**
 *MenuIconComponent
 */
@Component({
  selector: 'lib-menu-icon',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  templateUrl: './menu-icon.component.html',
  styleUrls: [
    './menu-icon.component.scss',
  ],
})
export class MenuIconComponent {

  @Input() iconName!: string | undefined;

  @Input() menuList!: MenuList[] | undefined;

  @Output() menuListClicked = new EventEmitter<MenuList>();

  /**
   *menuListClick
   * @param item -item
   */
  menuListClick(item: MenuList) {
    this.menuListClicked.emit(item);
  }
}
