import {
  Injectable,
} from '@angular/core';
import {
  MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


export enum BarType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface ISnackBarOptions {
  verticalPosition?: MatSnackBarVerticalPosition;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  duration?: number;
  panelClass?: BarType[];
}

export const INITIAL_ISNACKBAR_OPTIONS: ISnackBarOptions = {
  verticalPosition: 'top',
  horizontalPosition: 'center',
  duration: 5000,
  panelClass: [
    BarType.INFO,
  ],

};
/**
 * Snack Notification Service
 */
@Injectable({
  providedIn: 'root',
})
export class SnackNotificationService {

  /**
   * create an instance of MatSnackBar
   * @param snackBar MatSnackBar Service
   */
  constructor(
    private snackBar: MatSnackBar) {}

  /**
   * Displays the snack bar.
   * @param message message
   * @param actionLabel action Label
   * @param config  optional extra configuration for the type, postion, duration
   */
  public open(message: string, actionLabel: string, config?:ISnackBarOptions): void {
    this.snackBar.open(message, actionLabel , {
      ...INITIAL_ISNACKBAR_OPTIONS, ...config,
    });
  }

  /**
   * Displays the snack bar of type error.
   * @param message message
   * @param actionLabel action Label
   * @param config optional extra configuration for the type, postion, duration
   */
  public error(message: string, actionLabel: string, config?:ISnackBarOptions): void {
    this.open(message, actionLabel , {
      ...INITIAL_ISNACKBAR_OPTIONS, ...config , panelClass : [
        BarType.ERROR,
      ],
    });
  }

  /**
   * Displays the snack bar of type info.
   * @param message message
   * @param actionLabel action Label
   * @param config optional extra configuration for the type, postion, duration
   */
  public info(message: string, actionLabel: string, config?:ISnackBarOptions): void {
    this.open(message, actionLabel , {
      ...INITIAL_ISNACKBAR_OPTIONS, ...config , panelClass : [
        BarType.INFO,
      ],
    });
  }

  /**
   * Displays the snack bar of type success.
   * @param message message
   * @param actionLabel action Label
   * @param config optional extra configuration for the type, postion, duration
   */
  public success(message: string, actionLabel: string, config?:ISnackBarOptions): void {
    this.open(message, actionLabel , {
      ...INITIAL_ISNACKBAR_OPTIONS, ...config , panelClass : [
        BarType.SUCCESS,
      ],
    });
  }


}
