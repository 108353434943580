import {
  ChangeDetectionStrategy, Component, Inject, Input, Output, EventEmitter,
  OnChanges, Optional,
} from '@angular/core';
import {
  CommonModule,
  DOCUMENT,
} from '@angular/common';
import {
  MatSidenavModule,
} from '@angular/material/sidenav';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  DriveButtonDropDownComponent,
} from '../drive-button-drop-down/drive-button-drop-down.component';
import {
  RouterModule,
} from '@angular/router';

export interface singleItem {
  link: string;
  label: string;
  icon: string;
  key: string;
  multilistItem?: multiItems[];
}

export interface multiItems {
  label: string;
  link: string;
  icon: string;
}

export interface CommonNavList {
  navItem: string;
}

export interface CommonObj {
  id: string;
  folderName: string;
  icon: string;
}

export interface DashboardItem {
  label: string;
  link: string;
  icon: string;
}

/**
 *Dashboard component having the sidebar with the services
 */
@Component({
  selector: 'lib-left-navigation',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    DriveButtonDropDownComponent,
    RouterModule,
  ],
  templateUrl: './left-navigation.component.html',
  styleUrls: [
    './left-navigation.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftNavigationComponent implements OnChanges {
  @Input() brandName!: string;

  @Input() brandIcon!: string;

  @Input() listItems!: singleItem[];

  @Input() commonNavLists!: CommonNavList[];

  @Input() commonNavLists1!: CommonNavList[];

  @Input() docItemsMenu!: CommonObj[];

  @Input() folder!: CommonObj[];

  @Input() upload!: CommonObj[];

  @Input() extractFile!: CommonObj[];

  @Input() currentServiceMenu!: string;

  @Output() keyData = new EventEmitter();

  @Output() menubar = new EventEmitter();

  isBtnActive = true;

  selectedIcon = '';

  dataSourse: DashboardItem[] | undefined = [
  ];

  /**
   *
   * @param document document
   */
  constructor(@Optional() @Inject(DOCUMENT) private document: Document) {
  }

  /**
   *
   * @param changes changes
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ngOnChanges(changes: any): void {
    if (changes.currentServiceMenu &&
      (changes.currentServiceMenu.currentValue !== 'genera' && changes.currentServiceMenu.currentValue !== 'track')) {
      const data: string = changes.currentServiceMenu.currentValue;
      this.document.body.classList.add('collapse-sidebar');
      this.selectedIcon = changes.currentServiceMenu.currentValue.toLowerCase();
      this.isBtnActive = false;
      this.updateListItems(data);
    }
  }

  /**
   * To toogle the menu
   */
  hideShowMenu() {
    this.document.body.classList.toggle('collapse-sidebar');
    this.isBtnActive = !this.isBtnActive;
    this.updateListItems();
  }

  /**
   *
   * @param icon icon
   */
  selectIcon(icon: string) {
    this.selectedIcon = icon;
  }

  /**
   *
   * @param item -item of menubar
   */
  handleItemClicked(item: object): void {
    this.menubar.emit(item);
  }

  /**
   *@param serviceKey -serviceKey
   */
  private updateListItems(serviceKey = '') {
    const selectedItem = this.listItems.find((item) => {
      return item.key === serviceKey.toLowerCase();
    });
    this.keyData.emit(selectedItem?.key);

    if (selectedItem) {
      this.dataSourse = selectedItem.multilistItem;
    }
  }
}
