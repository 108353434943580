<div class="segment-container">
  <div class="segment-inner">
    <div class="segment-head">
      <h3>New Segment</h3>
      <p>Create New Segment with filling the required details .</p>
      <a>More help</a>
    </div>
    <div class="segment-form-details">
      <div class="segment-form-head">
        <div class="segment-form-title">
          <h3>Details</h3>
          <div class="detailsMargin" *ngIf="iSOpenDetails">
            <p class="text-name">{{ detailsObject.name }}</p>
            <p class="text-name">{{ detailsObject.alias }}</p>
            <p class="text-decs">{{ detailsObject.description }}</p>
          </div>
          <p>Lorem ipsum dolar sit amet..</p>
        </div>
        <div
          class="segment-form-actions"
          [style.display]="showForm ? 'flex' : 'none'"
        >
          <div class="slide-toogle">
            <mat-label ngClass="labelBeforeSlide">Public Segment</mat-label>
            <mat-slide-toggle [checked]="checked" [disabled]="disabled">
            </mat-slide-toggle>
          </div>
          <div class="slide-toogle">
            <mat-label ngClass="labelBeforeSlide">Published</mat-label>
            <mat-slide-toggle [checked]="checked" [disabled]="disabled">
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>

    <div
      class="segment-form-container"
      [style.display]="showForm ? 'block' : 'none'"
    >
      <div class="segment-form">
        <form [formGroup]="forms" (ngSubmit)="saveForm()">
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <label for="name">Name : </label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Name"
                    formControlName="name"
                    required
                  />
                  <mat-error *ngIf="forms.get('name')?.hasError('required')">
                    Name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label for="alias">Alias : </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="alias"
                    placeholder="Choose One..."
                    required
                  >
                    <mat-option *ngFor="let alias of aliases" [value]="alias">{{
                      alias
                    }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="forms.get('alias')?.hasError('required')">
                    Alias selection is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group desc">
                <label for="description">Description (Optional) : </label>

                <mat-form-field appearance="outline">
                  <textarea
                    matInput
                    placeholder="Enter Description"
                    formControlName="description"
                    id="description"
                  >
                  </textarea>
                  <mat-error
                    *ngIf="forms.get('description')?.hasError('required')"
                  >
                    Description is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form-row">
            <lib-button
              [btnLabel]="buttonLabel"
              [btnSize]="btnSize"
              [btnColor]="buttonColor"
              [btnType]="btnType"
              [buttonShow]="true"
              (eventData)="saveForm()"
              [(spinner)]="spinner"
              (spinnerChange)="spinnerChange.emit($event)"
            >
            </lib-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
