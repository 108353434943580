/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  inject,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  LeftNavigationComponent, SideBarCardComponent,
} from '@genera/ui-components';
import {
  Router,
} from '@angular/router';
import {
  ContextStore,
} from '@genera/ui-data-store';

export interface CommonObj {
  id: string;
  folderName: string;
  icon: string;
}

/**
 * Side Navigation Menu Component
 */
@Component({
  selector: 'app-side-navigation-menu',
  standalone: true,
  imports: [
    CommonModule,
    LeftNavigationComponent,
    SideBarCardComponent,
  ],
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: [
    './side-navigation-menu.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationMenuComponent {

  sideNavHeadIcon = 'https://production-static.wsuite.com/Bancolombia_60x60_1646929505732.jpg';

  title = 'Bancolombia';

  linkName = 'Change brand';

  linkUrl = 'https://my.wsuite.com/608543';

  appServices = [
    {
      icon: 'https://my.wsuite.com/assets/images/service-icons/W-Plan.png',
      text: 'Projects',
      serviceRow1: [
        {
          mainText: 'Projects',
          subText: 'Projects',
          serviceRow2: [
            {
              text: 'All',
              link: '/plan',
            },
            {
              text: 'New',
              link: '/track',
            },
          ],
        },
        {
          mainText: 'Message',
          serviceRow2: [
            {
              text: 'All',
              link: '/message',
            },
          ],
        },
        {
          mainText: 'Content',
          serviceRow2: [
            {
              text: 'All',
              link: '/content',
            },
          ],
        },
      ],
    },
  ];

  brandName = 'Bancolombia';

  brandIcon = 'https://production-static.wsuite.com/Bancolombia_60x60_1646929505732.jpg';

  serviceName = 'Destiny';

  serviceIcon = 'https://production-static.wsuite.com/destiny-favicon_1622617852450.png';

  listItems = [
    {
      'label': 'plan',
      'icon': 'https://my.wsuite.com/assets/images/service-icons/W-Plan.png',
      'link': '/plan',
      'key' : 'plan',
      multilistItem: [
        {
          label: 'Home',
          link: '/plan',
          icon: '../../assets/imaegs/Vector.svg',
        },
        {
          label: 'Starred',
          link: '/plan/starred',
          icon: '../../assets/imaegs/material-symbols_workspaces-outline.svg',
        },
        {
          label: 'New Project',
          link: 'plan/project/create',
          icon: '../../assets/imaegs/Vector.svg',
        },
        {
          label: 'My Projects',
          link: '/plan',
          icon: '../../assets/imaegs/Vector.svg',
        },
        {
          label: 'Share with me',
          link: '/plan/shared-with-me',
          icon: '../../assets/imaegs/mdi_users-outline.svg',
        },
        {
          label: 'Rules',
          link: '/plan/rules',
          icon: '../../assets/imaegs/fluent-mdl2_recent.svg',
        },
        {
          label: 'Trash',
          link: '/plan/trash',
          icon: '../../assets/imaegs/ph_trash-light.svg',
        },
      ],
    },
    {
      'label': 'message',
      'icon': 'https://my.wsuite.com/assets/images/service-icons/W-Message.png',
      'link': '/message/messageDashboard',
      'key' : 'message',
      multilistItem: [
        {
          label: 'Home',
          link: '/message/messageDashboard',
          icon: '../../assets/imaegs/Group 30428.png',
        },
        {
          label: 'Active',
          link: '/message/active',
          icon: '../../assets/imaegs/bell.svg',
        },
        {
          label: 'Stats',
          link: '/message/starts',
          icon: '../../assets/imaegs/material-symbols_workspaces-outline.svg',
        },
        {
          label: 'Activity',
          link: '/message/activity',
          icon: '../../assets/imaegs/Vector.svg',
        },
      ],
    },
    {
      'label': 'insights',
      'icon': 'https://my.wsuite.com/assets/images/service-icons/W-Insights.png',
      'link': '/insights/insightsDashboard',
      'key' : 'insights',
      multilistItem: [
        {
          label: 'Home',
          link: '/insights/insightsDashboard',
          icon: '../../assets/imaegs/Vector.svg',
        },
        {
          label: 'Analysis',
          link: '/insights/insightsDashboard',
          icon: '../../assets/imaegs/material-symbols_workspaces-outline.svg',
        },
        {
          label: 'Reports',
          link: '/insights/reports',
          icon: '../../assets/imaegs/Vector.svg',
        },
      ],
    },
    {
      'label': 'people',
      'icon': 'https://my.wsuite.com/assets/images/service-icons/W-People.png',
      'link': '/people',
      'key' : 'people',
      multilistItem: [
        {
          label: 'Home',
          link: '/people/contacts',
          icon: '../../assets/imaegs/Group 30428.png',
        },
        {
          label: 'Contacts',
          link: '/people/contacts',
          icon: '../../assets/imaegs/contact-form.png',
        },
        {
          label: 'Companies',
          link: '/people/companies',
          icon: '../../assets/imaegs/company-vision.png',
        },
        {
          label: 'Segments',
          link: '/people/segments',
          icon: '../../assets/imaegs/customer-segment.png',
        },
        {
          label: 'Conversations',
          link: '/people/conversations',
          icon: '../../assets/imaegs/material-symbols_workspaces-outline.svg',
        },
        {
          label: 'Calendar',
          link: '/people/calender',
          icon: '../../assets/imaegs/Vector.svg',
        },
        {
          label: 'Rules',
          link: '/people/rules',
          icon: '../../assets/imaegs/mdi_users-outline.svg',
        },
        {
          label: 'Points',
          link: '/people/points',
          icon: '../../assets/imaegs/fluent-mdl2_recent.svg',
        },
        {
          label: 'Stages',
          link: '/people/stages',
          icon: '../../assets/imaegs/stages.png',
        },
      ],
    },
    {
      'label': 'content',
      'icon': 'https://my.wsuite.com/assets/images/service-icons/W-Content.png',
      'link': '/content',
      'key' : 'content',
      multilistItem: [
        {
          'label': 'Home',
          'link': '/content',
          'icon': '../../assets/imaegs/priority-icon.png',
        },
        {
          'label': 'Activity',
          'link': '/content/activity',
          'icon': '../../assets/imaegs/activity-icon.png',
        },
        {
          'label': 'My Drive',
          'link': '/content',
          'icon': '../../assets/imaegs/my-drive-icon.png',
        },
        {
          'label': 'Shared with me',
          'link': '/content/shared-with-me',
          'icon': '../../assets/imaegs/share-with-me-icon.png',
        },
        {
          'label': 'Storage',
          'link': '/content/storage',
          'icon': '../../assets/imaegs/recent-icon.png',
        },
        {
          'label': 'Trash',
          'link': '/content/trash',
          'icon': '../../assets/imaegs/star_FILL0_wght400_GRAD0_opsz24.png',
        },
      ],
    },
  ];

  commonNavLists = [
    {
      navItem: 'Inicio',
    },
    {
      navItem: 'Menú Destiny 11',
    },
    {
      navItem: 'Menú Destiny 22',
    },
    {
      navItem: 'Menú Destiny 33',
    },
    {
      navItem: 'Menú Destiny 44',
    },
    {
      navItem: 'Menú Destiny 55',
    },
  ];

  folder: CommonObj[] = [
    {
      id: '1', folderName: 'Create New Segment', icon: 'lan',
    },
  ];

  upload: any = [
    {
      id: '1', uploadFileName: 'Compaigns', icon: 'upload_file',
    },
    {
      id: '1', uploadFileName: 'Programs', icon: 'notifications',
    },
  ];

  extractFile: any = [
    {
      id: '1', uploadFileName: 'Google doc', icon: 'description',
    },
    {
      id: '1', uploadFileName: 'Google doc', icon: 'description',
    },
  ];

  docItemsMenu: any = [
    {
      id: '1', docName: 'Contact', icon: 'group',
    },
    {
      id: '1', docName: 'Landing Flow', icon: 'schedule',
    },
    {
      id: '1', docName: 'Attraction Flow', icon: 'star',
    },
    {
      id: '1', docName: 'Component', icon: 'description',
    },
    {
      id: '1', docName: 'Companies', icon: 'flag',
    },
    {
      id: '1', docName: 'Calender', icon: 'calendar_today',
    },
    {
      id: '1', docName: 'Points', icon: 'description',
    },
    {
      id: '1', docName: 'Stages', icon: 'open_in_new',
    },
  ];

  //project

  projectFolder: CommonObj[] = [
    {
      id: '1', folderName: 'Create New Project', icon: 'lan',
    },
    {
      id: '1', folderName: 'Create New Rule', icon: 'lan',
    },
  ];

  projectUpload: any = [
  ];

  projectExtractFile: any = [
  ];

  projectDocItemsMenu: any = [
  ];

  //people
  peopleFolder: CommonObj[] = [
    {
      id: '1', folderName: 'Home', icon: 'lan',
    },
    {
      id: '2', folderName: 'Contacts', icon: 'lan',
    },
    {
      id: '3', folderName: 'Companies', icon: 'lan',
    },
    {
      id: '4', folderName: 'Segments', icon: 'lan',
    },
    {
      id: '5', folderName: 'Create New Segment', icon: 'lan',
    },
    {
      id: '5', folderName: 'Create New Rule', icon: 'lan',
    },
  ];

  peopleUpload: any = [
    {
      id: '1', uploadFileName: 'Conversations', icon: 'upload_file',
    },
    {
      id: '1', uploadFileName: 'Calendar', icon: 'notifications',
    },
  ];

  peopleExtractFile: any = [
    {
      id: '1', uploadFileName: 'Rules', icon: 'description',
    },
  ];

  peopleDocItemsMenu: any = [
    {
      id: '1', docName: 'Points', icon: 'group',
    },
    {
      id: '2', docName: 'Stages', icon: 'group',
    },
  ];

  //message
  messageFolder: CommonObj[] = [
    {
      id: '1', folderName: 'Home', icon: 'lan',
    },
    {
      id: '1', folderName: 'Active', icon: 'lan',
    },
  ];

  messageUpload: any = [
    {
      id: '1', uploadFileName: 'Stats', icon: 'upload_file',
    },
    {
      id: '1', uploadFileName: 'Activity', icon: 'notifications',
    },
  ];

  messageDocItemsMenu: any = [
  ];

  messageExtractFile: any = [
  ];

  //content
  contentFolder: CommonObj[] = [
    {
      id: '1', folderName: 'New Content', icon: 'drive_folder_upload',
    },
  ];

  contentUpload: any =  [
    {
      id: '1', uploadFileName: 'File Upload', icon: 'upload_file',
    },
    {
      id: '1', uploadFileName: 'New folder', icon: 'create_new_folder',
    },
    {
      id: '1', uploadFileName: 'Folder Upload', icon: 'create_new_folder',
    },
  ];

  contentDocItemsMenu: any = [
    {
      id: '1', docName: 'Google Doc', icon: 'description',
    },
    {
      id: '1', docName: 'Google Doc', icon: 'description',
    },
    {
      id: '1', docName: 'Google Doc', icon: 'description',
    },
  ];

  contentExtractFile: any = [
  ];


  //insights
  insightsFolder: CommonObj[] = [
    {
      id: '1', folderName: 'Home', icon: 'lan',
    },
    {
      id: '1', folderName: 'Create Analysis', icon: 'lan',
    },
  ];

  insightsUpload: any = [
    {
      id: '1', uploadFileName: 'Create Report', icon: 'upload_file',
    },
  ];

  insightsDocItemsMenu: any = [
  ];

  insightsExtractFile: any = [
  ];

  dataKey: any;

  readonly store = inject(ContextStore);

  /**
   * Page constructor
   * @param router Router
   */
  constructor(
    private router: Router,
  ) {
  }

  /**
   *
   * @param key Service Key
   */
  handleKeyData(key: string): void {
    this.dataKey = key;
  }

  /**
   * Handle item click and open sidebar
   * @param item - clicked item
   */
  handleItemClicked(item: any): void {
    switch (true) {
      case item.folderName === 'Create New Segment':
        this.router.navigateByUrl('/people/create/segment');
        break;
      case item.folderName === 'Create New Rule':
        this.router.navigateByUrl('/people/create/rulesPeople');
        break;
      case item.docName === 'Contact':
        this.router.navigateByUrl('/people/contacts');
        break;
      case item.docName === 'Rules':
        this.router.navigateByUrl('/people/rules');
        break;
      case item.folderName === 'Create New Project':
        this.router.navigateByUrl('/plan/project/create');
        break;
      case item.folderName === 'New Content':
        this.router.navigateByUrl('/content/create/newContent');
        break;
      case item.uploadFileName === 'File Upload':
        this.router.navigateByUrl('/content/create/fileUpload');
        break;
      case item.uploadFileName === 'New folder':
        this.router.navigateByUrl('/content/create/fileUpload');
        break;
      case item.uploadFileName === 'Folder Upload':
        this.router.navigateByUrl('/content/create/fileUpload');
        break;
      default:
        // Handle the case where neither condition is met
        break;
    }
  }
}
