import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
export interface TabLines {
  value: number;
  name: string;
  selected: boolean;
  language: string;
};

/**
 *Language-tabs component
 */
@Component({
  selector: 'lib-language-tabs',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './language-tabs.component.html',
  styleUrls: [
    './language-tabs.component.scss',
  ],
})
export class LanguageTabsComponent {
  @Input() dataTabs!: TabLines[];

  @Input() isClose = false;

  @Output() lang = new EventEmitter();

  currentLanguage = '';

  /**
   *  method to load the data from the tab
   * @param obj Object selected for the tabs
   * @param index Index selected for de tabs
   */
  loadData(obj : TabLines, index: number) {
    for (const data of this.dataTabs) {
      data.selected = false;
    }

    this.dataTabs[index].selected = true;
    this.currentLanguage = this.dataTabs[index].language;
    this.lang.emit(this.dataTabs[index].language);
  }
}
