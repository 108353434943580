/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductVariantStepDefinitionTypeEnum = 'ux' | 'ui' | 'design' | 'html' | 'development';

export const ProductVariantStepDefinitionTypeEnum = {
    Ux: 'ux' as ProductVariantStepDefinitionTypeEnum,
    Ui: 'ui' as ProductVariantStepDefinitionTypeEnum,
    Design: 'design' as ProductVariantStepDefinitionTypeEnum,
    Html: 'html' as ProductVariantStepDefinitionTypeEnum,
    Development: 'development' as ProductVariantStepDefinitionTypeEnum
};

