/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Brand { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Brand identifier
     */
    brandId: string;
    /**
     * The client Identifier the brand belongs to
     */
    clientId: string;
    /**
     * Brand name
     */
    name: string;
    /**
     * Brand logo URL
     */
    logoUrl: string;
}

