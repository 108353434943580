<div *ngIf="isOpenPanel()" class="overlay" (click)="closeNav()"></div>
<div #sideNavDrawer class="second-sidepanel">
  <div class="sidepanel-header">
    <span class="sidepanel-header-closebtn" (click)="closeNav()"
      ><img src="../../assets/images/icon-close.svg" alt="Close"
    /></span>
    <h4 class="sidepanel-header-title" *ngIf="drawerTitle">
      {{ drawerTitle }}
    </h4>
  </div>
  <div class="sidepanel-body">
    <ng-content></ng-content>
  </div>
</div>
