<div class="login-container">
  <div class="login-body">
    <div class="left-block">
      <lib-login-welcome
        [introTitle]="introTitle"
        [introDescription]="introDescription"
        [quoteFirst]="quoteFirst"
        [quoteTwo]="quoteTwo"
        [quoteThree]="quoteThree"
      >
      </lib-login-welcome>
    </div>
    <div class="login-main">
      <div class="main-content">
        <div class="app-logo">
          <lib-welcome [brandLogo]="brandLogo"></lib-welcome>
        </div>

        <div class="account-section-box company-dialog">
          <form class="form-company" [formGroup]="companyForm">
            <div class="comp-img">
              <img src="/assets/images/security-logo.svg" />
            </div>
            <h3>{{ title1 }}</h3>
            <mat-form-field class="full-width">
              <input
                type="text"
                placeholder="{{ companyNamePlaceHolder }}"
                matInput
                formControlName="company"
                [disabled]="spinner"
              />
              <mat-error
                *ngIf="companyForm.controls['company'].hasError('required')"
              >
                Please enter Company Name
              </mat-error>
              <mat-error
                *ngIf="companyForm.controls['company'].hasError('parentError')"
              >
                {{ companyForm.controls['company'].getError('parentError') }}
              </mat-error>
            </mat-form-field>
            <p>
              {{ description }}
            </p>
            <div class="acb-btn">
              <lib-button
                [btnLabel]="buttonLabel"
                [btnSize]="btnSize"
                [btnColor]="buttonColor"
                [btnType]="btnType"
                [disabled]="companyForm.invalid"
                [buttonShow]="true"
                (eventData)="onSubmit()"
                [(spinner)]="spinner"
                (spinnerChange)="spinnerChange.emit($event)"
              >
              </lib-button>
            </div>
          </form>
        </div>
        <div class="terms-text">
          <p>
            By Singing in you accept out
            <a href="javascript:void(0)" target="_blank">Terms</a> &
            <a href="javascript:void(0)" target="_blank">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
