import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 * Component provider card
 */
@Component({
  selector: 'lib-card-providers',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './card-providers.component.html',
  styleUrls: [
    './card-providers.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProvidersComponent {

  @Input() id : string | undefined;

  @Input() icon : string | undefined;

  @Input() name : string | undefined;
}
