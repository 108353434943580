<div class="color-cardLabel">
  <label>Available Color</label>
</div>
<div class="color-card d-flex justify-content-between">
  <div class="color-squares">
    <div
      *ngFor="let color of colors"
      class="color-square"
      [style.background-color]="color"
      (dblclick)="addColor(color)"
    ></div>
  </div>
  <div class="add-color">
    <button (click)="openColorPicker()">
      <i class="material-icons">add</i>
    </button>
  </div>
</div>
