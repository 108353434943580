<div>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="icon-button with a menu"
  >
    <mat-icon>{{ iconName }}</mat-icon>
  </button>
  <!-- <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let item of menuList"
      (click)="menuListClick(item)"
    >
      <mat-icon>{{ item?.icon }}</mat-icon>
      <span>{{ item?.label }}</span>
      <a [routerLink]="item?.link"></a>
    </button>
  </mat-menu> -->
  <mat-menu #menu="matMenu">
    <ul class="icon-menu">
      <li
        *ngFor="let item of menuList"
        mat-menu-item
        (click)="menuListClick(item)"
      >
        <a [routerLink]="">
          <mat-icon class="material-icons-outlined">{{ item?.icon }}</mat-icon>
          <span class="menu-text">{{ item?.label }}</span>
        </a>
      </li>
    </ul>
  </mat-menu>
</div>
