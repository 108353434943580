/**
 * Genera Top Layer API
 * Genera Top Layer API allow clients to read its configurations and enabled services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateTenantDto { 
    /**
     * Tenant/Company name
     */
    name?: string;
    /**
     * Tenant/Company REST API endpoint
     */
    restUrl?: string;
    /**
     * Tenant/Company GraphQL endpoint
     */
    graphQlUrl?: string;
}

