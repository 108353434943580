import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MenuComponent,
} from '../menu/menu.component';
import {
  AssetMenuItem,
} from '../card-type/card-type.component';

/**
 *
 */
@Component({
  selector: 'lib-card-asset-detail',
  standalone: true,
  imports: [
    CommonModule,
    MenuComponent,
  ],
  templateUrl: './card-asset-detail.component.html',
  styleUrls: [
    './card-asset-detail.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardAssetDetailComponent {
  @Input() icon : string | undefined;

  @Input() title : string | undefined;

  @Input() cardId : string | undefined;

  @Input() description : string | undefined;

  @Input() menuItems: AssetMenuItem[] | undefined;

}
