/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProjectStepStateEnum = 'pending' | 'progress' | 'done' | 'cancelled';

export const ProjectStepStateEnum = {
    Pending: 'pending' as ProjectStepStateEnum,
    Progress: 'progress' as ProjectStepStateEnum,
    Done: 'done' as ProjectStepStateEnum,
    Cancelled: 'cancelled' as ProjectStepStateEnum
};

