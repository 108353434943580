import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatButtonModule,
} from '@angular/material/button';

/**
 *
 */
@Component({
  selector: 'lib-drive-component',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
  ],
  templateUrl: './drive-component.component.html',
  styleUrls: [
    './drive-component.component.scss',
  ],
})
export class DriveComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() docItems!: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() folder!: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() upload!: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() extractFile!: any[];

  @Input() dropDownCustomClass!: string;

  @Input() buttonName!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() dataClicked: EventEmitter<any> = new EventEmitter<any>();

  isMenuVisible = false;

  isOpen = false;

  /**
   * onItemClicked
   * @param item - clicked item
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemClicked(item: any): void {
    this.isOpen = item;
    this.itemClicked.emit(item);
  }

  /**
   * onItemClicked
   * @param item - clicked item
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick(item: any): void {
    this.dataClicked.emit(item);
  }
}
