<div class="color-cardLabel">
  <label>{{ inputTitle }}</label>
</div>

<div class="color-card d-flex justify-content-between">
  <div class="color-squares">
    <div class="color-square" *ngFor="let size of selectedSize">
      {{ size }}
    </div>
  </div>

  <div class="add-color">
    <button mat-icon-button [matMenuTriggerFor]="headingMenu">
      <i class="material-icons">add</i>
    </button>
  </div>
  <mat-menu #headingMenu="matMenu">
    <div *ngFor="let item of inputSize">
      <button mat-menu-item (click)="onSelectHeading(item)">
        {{ item }}
      </button>
    </div>
  </mat-menu>
</div>
