import {
  ChangeDetectionStrategy, Component,  EventEmitter,  Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatExpansionModule,
} from '@angular/material/expansion';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatListModule,
} from '@angular/material/list';
import {
  MatSidenavModule,
} from '@angular/material/sidenav';
import {
  SearchBoxComponent,
} from '../search-box/searchbox.component';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  FilterBoxComponent,
} from '../filter-box/filterbox.component';

export interface singleLevelItem {
  label: string;
  link: string;
  icon: string;
  multilistItem?: multiLevelItems[];
}

export interface multiLevelItems {
  label: string;
  link: string;
  icon: string;
  number: number;
}

export interface FIlterValues {
  value: string;
  ViewValue: string;
}

export interface SelectedProjectOrder {
  projectId: string;
  orderId: string;
}

/**
 *
 */
@Component({
  selector: 'lib-asset-navigation',
  standalone: true,
  imports: [
    CommonModule,
    SearchBoxComponent,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    ButtonComponent,
    FilterBoxComponent,
  ],
  templateUrl: './asset-navigation.component.html',
  styleUrls: [
    './asset-navigation.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetNavigationComponent {
  @Input() listItems!: singleLevelItem[];

  @Input() spinner = false;

  @Input() placeHolderFilterCampaign! : string;

  @Input() placeHolderFilterOrder!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() filterCampaignVal!: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() filterOrderVal!: any;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() openPanel = new EventEmitter<boolean>();

  @Output() selectedProject = new EventEmitter<string>();

  @Output() selectedProjectOrder = new EventEmitter<SelectedProjectOrder>();

  buttonLabel = 'REQUEST';

  buttonCoLabel = 'Go';

  buttonCoColor = 'green';

  buttonColor = 'green';

  btnSize = 'sm';

  btnType = 'button';

  isOpenPanel = true;

  searchBoxPlaceholder = 'Search for Project';

  projectId!: string;

  orderId!: string;

  /**
   * Form Submit Function
   */
  onSubmit() {
    this.isOpenPanel = !this.isOpenPanel;
    this.openPanel.emit(true);
  }

  /**
   * Form Close Function
   */
  onClose() {
    this.isOpenPanel = !this.isOpenPanel;
    this.openPanel.emit(false);
  }

  /**
   *
   * @param value string
   * @param type string
   */
  onFilterSelection(value:string, type: string) {
    if (type === 'project') {
      this.projectId = value;
      this.selectedProject.emit(value);
    } else if (type === 'order') {
      this.orderId = value;
    }
  }

  /**
   *
   */
  onRedirection() {
    if (this.projectId && this.orderId) {
      this.selectedProjectOrder.emit({
        projectId : this.projectId,
        orderId: this.orderId,
      });
    }

  }

}
