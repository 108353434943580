import {
  Component, Input,
} from '@angular/core';
import {
  AbstractControl,
} from '@angular/forms';
import {
  GetValidatorErrorMessage,
} from './validator-utils';

/**
 *
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[lib-form-error-message]',
  templateUrl: './form-error-message.component.html',
  styleUrls: [
    './form-error-message.component.scss',
  ],
})
export class FormErrorMessageComponent {
  @Input() control!: AbstractControl;

  // eslint-disable-next-line jsdoc/require-returns
  /**
   *
   */
  get errorMessage() {
    for (const validatorName in this.control?.errors) {
      if (this.control.touched)
        return GetValidatorErrorMessage(validatorName, this.control.errors[validatorName]);
    }
    return null;
  }
}
