import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  Router,
} from '@angular/router';
import {
  ButtonComponent,
} from '@genera/ui-components';
import {
  ContextStore,
} from '@genera/ui-data-store';

/**
 *Dashboard Component
 */
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: [
    './dashboard.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {

  username = 'Genera';

  btnLabel = 'Initiate Onboarding';

  btnColor = 'blue';

  btnSize = 'md';

  contextStore = inject(ContextStore);

  router: Router = inject(Router);

  /**
   * Component initialization
   */
  ngOnInit() {
    if (this.contextStore.user?.()?.preferences?.preferredService) {
      this.router.navigate([
        this.contextStore.user?.()?.preferences?.preferredService,
      ]);
    } else {
      this.contextStore.selectService('genera', '/assets/images/genera-sm-icon.png');
    }
  }

}
