/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientCredentialsGrantDto { 
    /**
     * client credentials grant type identifier
     */
    grantType: string;
    /**
     * The application client id
     */
    clientId: string;
    /**
     * The application client secret
     */
    clientSecret: string;
    /**
     * Space separated Genera scopes for this application token
     */
    scopes: string;
}

