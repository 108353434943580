import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatSidenavModule,
} from '@angular/material/sidenav';
import {
  MatExpansionModule,
} from '@angular/material/expansion';
import {
  MatListModule,
} from '@angular/material/list';
import {
  MatIconModule,
} from '@angular/material/icon';
export interface singleListItem {
  label: string;
  link: string;
  icon: string;
  multilistItem?: multilistItems[];
}

export interface multilistItems {
  label: string;
  link: string;
  icon: string;
  number: number;
}

/**
 * Sidenav component to show the listing in drawer
 */
@Component({
  selector: 'lib-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
  ],
  templateUrl: './sidenav.component.html',
  styleUrls: [
    './sidenav.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {

  @Input() navIcon! : string;

  @Input() navIconPosition! : 'left' | 'right';

  @Input() open! : false;

  @Input() drawerSize! : string;

  @Input() position!: 'start' | 'end' ;

  @Input() listItems!: singleListItem[];
}
