import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import {
  FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  RouterModule,
} from '@angular/router';

export interface ResetPasswords {
  code: string;
  newPassword: string;
  confirmPassword: string;
}

/**
 * A reset password component using SCAM methodology
 */
@Component({
  selector: 'lib-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    './reset-password.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {

  @Input() back : string | undefined;

  @Input() code : string | undefined;

  @Input() ConfirmPassword : string | undefined;

  @Input() description : string | undefined;

  @Input() linkURI : string | undefined;

  @Input() title : string | undefined;

  @Input() NewPassword : string | undefined;

  @Input() submitText : string | undefined;

  @Input() welcomeText! : string;

  @Input() companyName!: string;

  @Input() spinner = false;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() resetPasswords = new EventEmitter<ResetPasswords>();

  resetPassword!: FormGroup;

  buttonLabel = 'CONTINUE';

  buttonColor = 'blue';

  btnSize = 'md';

  btnType = 'submit';

  /**
   *
   * @param _formBuilder _formBuilder
   */
  constructor ( private _formBuilder: FormBuilder) {}

  /**
   * Password validator pattern check
   */
  ngOnInit() {
    this.resetPassword = this._formBuilder.group({
      code: [
        '',
        Validators.required,
      ],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/),
        ],
      ],
    }, {
      validator: confirmedValidator('newPassword', 'confirmPassword'),
    });
  }


  /**
   * Form Submit Function
   */
  onSubmit() {
    this.resetPassword.disable({
      emitEvent: false,
    });
    this.resetPasswords.emit(this.resetPassword.value as ResetPasswords);
  }


}
/**
 *
 * @param controlName controlName
 * @param matchingControlName matchingControlName
 * @returns formGroup
 */
export function confirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors?.['confirmedValidator']) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({
        confirmedValidator: true,
      });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
