/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemStepActivityTypeEnum } from './order-item-step-activity-type-enum';


export interface OrderItemStepActivity { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * OrderItemStepActivity identifier
     */
    orderItemStepActivityId: string;
    /**
     * order Item step Id
     */
    orderItemStepId: string;
    /**
     * Description about the comment
     */
    comment: string;
    activityType: OrderItemStepActivityTypeEnum;
    metaData: object;
}
export namespace OrderItemStepActivity {
}


