import {
  Type,
} from '@angular/core';
import {
  ServiceTypes,
} from './model/services-types';
import {
  DummyComponent,
} from '../dummy/dummy.component';
import {
  FormRenderComponent,
} from '../form-render/form-render.component';
import {
  SummaryStepComponent,
} from '../summary-step/summary-step.component';
import {
  DrawFlawComponent,
} from '../draw-flow/draw-flow.component';
import {
  QueryBuilderComponent,
} from '../query-builder/query-builder.component';
import {
  GrapesjsEditorComponent,
} from '../grapesjs-editor/grapesjs-editor.component';


export type ServiceComponentType =
  DummyComponent |
  FormRenderComponent |
  DrawFlawComponent |
  QueryBuilderComponent |
  SummaryStepComponent |
  GrapesjsEditorComponent;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const servicesComponentFactory:
Record<ServiceTypes, Type<ServiceComponentType>>
  = {
    [ServiceTypes.dummy]: DummyComponent,
    [ServiceTypes.formio]: FormRenderComponent,
    [ServiceTypes.summary]: SummaryStepComponent,
    [ServiceTypes.drawflawComponent]: DrawFlawComponent,
    [ServiceTypes.queryBuilder]: QueryBuilderComponent,
    [ServiceTypes.grapesjsEditor]: GrapesjsEditorComponent,
  };
