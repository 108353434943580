import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';

/**
 *Size component
 */
@Component({
  selector: 'lib-size',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './size.component.html',
  styleUrls: [
    './size.component.scss',
  ],
})
export class SizeComponent {
  @Input() inputTitle = '';

  @Input() inputSize: string[] = [
  ];

  @Output() sizeSelected = new EventEmitter<string>();

  selectedSize: string[] = [
  ];

  selectedHeading: string[] = [
  ];

  /**
   * Add size
   * @param size - Size to add
   */
  addSize(size: string[]): void {
    if (Array.isArray(size)) {
      size.forEach(singleSize => {
        if (singleSize) {
          this.selectedSize.push(singleSize);
          this.sizeSelected.emit(singleSize);
        }
      });
    }
  }

  /**
   * Open size picker
   */
  openSizePicker(): void {
    const sizeInput = document.createElement('input');
    sizeInput.addEventListener('input', (event) => {
      const inputValue = (event.target as HTMLInputElement).value;
      this.selectedSize = inputValue.split(',').map(size => size.trim());
    });
    sizeInput.addEventListener('change', () => {
      this.addSize(this.selectedSize);
    });
    document.body.appendChild(sizeInput);
    const sizeCardRect = document.querySelector('.color-card')?.getBoundingClientRect();
    if (sizeCardRect) {
      sizeInput.style.position = 'absolute';
      sizeInput.style.display = 'none';
      sizeInput.style.top = `${sizeCardRect.bottom}px`;
      sizeInput.style.left = `${sizeCardRect.left}px`;
    }
    sizeInput.click();
    sizeInput.addEventListener('blur', () => {
      document.body.removeChild(sizeInput);
    });
  }

  /**
   *onSelectHeading
   * @param headingLevel -headingLevel
   */
  onSelectHeading(headingLevel: string): void {
    this.addSize([
      headingLevel,
    ]);
  }
}
