<div class="title-dropdown-wrap">
  <button class="title-dropdown-button" [matMenuTriggerFor]="menu1">
    <div class="button-content">
      <span class="button-content-text">{{ buttonName }}</span>
      <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
    </div>
  </button>

  <mat-menu #menu1="matMenu" class="title-dropdown-lists">
    <div mat-menu-item *ngFor="let item of folder" (click)="onClick(item)">
      <mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
      <span>{{ item.folderName }}</span>
    </div>
    <mat-divider></mat-divider>
    <div
      mat-menu-item
      *ngFor="let item of upload"
      (click)="onItemClicked(item)"
    >
      <mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
      <span>{{ item.uploadFileName }}</span>
    </div>
    <mat-divider class="mat-divider2"></mat-divider>
    <div
      mat-menu-item
      *ngFor="let item of docItems"
      (click)="$event.stopPropagation()"
      (click)="onClick(item)"
    >
      <div class="content">
        <mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
        <div class="text">{{ item.docName }}</div>
      </div>
      <div class="action-icon">
        <mat-icon [matMenuTriggerFor]="menu" class="fs-20-grey"
          >play_arrow</mat-icon
        >
      </div>
    </div>
  </mat-menu>

  <mat-menu #menu="matMenu" class="title-dropdown-lists">
    <div mat-menu-item *ngFor="let item of extractFile" (click)="onClick(item)">
      <mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
      <span>{{ item.uploadFileName }}</span>
    </div>
  </mat-menu>
</div>
