import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 * Component asset card
 */
@Component({
  selector: 'lib-card-asset',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './card-asset.component.html',
  styleUrls: [
    './card-asset.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardAssetComponent {
  @Input() icon : string | undefined;

  @Input() name : string | undefined;

  @Input() id : string | undefined;

  @Input() title : string | undefined;

}
