import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-card-multimedia',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './card-multimedia.component.html',
  styleUrls: [
    './card-multimedia.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardMultiMediaComponent {
  @Input() image : string | undefined;

  @Input() icon : string | undefined;

  @Input() title : string | undefined;

  @Input() duration : string | undefined;

  @Input() creator : string | undefined;
}
