/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectStepStateEnum } from './project-step-state-enum';


export interface UpdateProjectStepDto { 
    /**
     * Step Id
     */
    projectStepDefinitionId?: string;
    metaData: object;
    state?: ProjectStepStateEnum;
}
export namespace UpdateProjectStepDto {
}


