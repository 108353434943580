import {
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  NgIf,
  NgFor,
  NgForOf,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatButtonModule,
} from '@angular/material/button';
import type {
  Editor,
} from 'grapesjs';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';

export interface Placeholder {
  name: string;
  icon?: string;
}
type GrapesJSType = 'mjml' | 'webpage' | 'newsletter';
export interface GrapesJSConfig {
  type: GrapesJSType;
  placeholders: Placeholder[];
}

const DefaultConfig: GrapesJSConfig = {
  type: 'mjml',  // Default value
  placeholders: [
  ],
};

const EDITOR_PLACEHOLDER_ACTION = 'placeholders';

/**
 * GrapesJS Editor
 */
@Component({
  selector: 'lib-grapesjs-editor',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgForOf,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  templateUrl: './grapesjs-editor.component.html',
  styleUrls: [
    './grapesjs-editor.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrapesjsEditorComponent implements AfterViewInit, OnDestroy {

  @ViewChild('GeneraGrapesJS') divEditor!: ElementRef;

  @ViewChild('GeneraGrapeJSPlaceholders') placeholdersMenu!: ElementRef;

  @Input() formConfig : GrapesJSConfig = DefaultConfig;

  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  @Input() formPopulateData! : FormPopulateData;

  @Output() customEvent = new EventEmitter();

  protected editor!: Editor;

  private cssPromises: Promise<HTMLStyleElement>[] = [
  ];

  private currentPlaceholder?: Placeholder;

  /**
   * Destroy
   */
  ngOnDestroy() {
    Promise.all(this.cssPromises).then((links) => links.forEach(link => link.remove()));
  }

  /**
   * After view init so ViewChild is ready for usage
   */
  async ngAfterViewInit(): Promise<void> {
    const {
      grapesjs,
    } = await import('grapesjs');
    const module = this.formConfig.type === 'mjml' ?
      await import('grapesjs-mjml') :
      this.formConfig.type === 'newsletter' ?
        await import('grapesjs-preset-newsletter') :
        await import('grapesjs-preset-webpage');
    const commonBlocks = await import('grapesjs-blocks-basic');
    const plugin = module.default;
    this.editor = grapesjs.init({
      container: this.divEditor.nativeElement as HTMLDivElement,
      plugins: [
        commonBlocks.default,
        plugin,
      ],
      pluginsOpts: {
        [plugin as never]: {
          useCustomTheme: false,
        },
      },
    });
    // Add placeholders control on de Rich Text editor
    this.editor?.RichTextEditor?.add(EDITOR_PLACEHOLDER_ACTION, {
      icon: this.placeholdersMenu.nativeElement as HTMLElement,
      event: 'change',
      result: (rte) => {
        if (this.currentPlaceholder) {
          rte.insertHTML(`{{${this.currentPlaceholder.name}}}</a>`);
        }
      },
    });

    // https://codepen.io/artf/pen/MQpZPj
    // TODO (juanescobar) Move these style to the SCSS, the problem is that they were not taking effect so we finally
    // use the same approach from plugin like https://github.com/GrapesJS/preset-newsletter/blob/master/src/index.ts
    const primaryColor = '#f6f8fc';
    const secondaryColor = 'black';
    const thirdColor = '#1E8FE1';
    const quaternaryColor = '#f6f8fc';
    const prefix = 'gjs-';
    let cssString = '';

    [
      [
        'one',
        primaryColor,
      ],
      [
        'two',
        secondaryColor,
      ],
      [
        'three',
        thirdColor,
      ],
      [
        'four',
        quaternaryColor,
      ],
    ].forEach(([
      cnum,
      ccol,
    ]) => {
      cssString += `
        .${prefix}${cnum}-bg {
          background-color: ${ccol};
        }
        .${prefix}${cnum}-color {
          color: ${ccol};
        }
        .${prefix}${cnum}-color-h:hover {
          color: ${ccol};
        }
      `;
    });

    const style = document.createElement('style');
    style.innerText = cssString;
    document.head.appendChild(style);
  }

  /**
   * A Placeholder has been selected on the placeholder menu
   * @param placeholder The placeholder selected
   */
  onPlaceholderClick(placeholder: Placeholder) {
    this.currentPlaceholder = placeholder;
    this.editor?.RichTextEditor.run(EDITOR_PLACEHOLDER_ACTION);
  }

  /**
   * When the menu is closed, let's unset placeholder selected, even when the action is applied this hook
   * will be called
   */
  onPlaceholderMenuClosed() {
    this.currentPlaceholder = undefined;
  }

  /**
   * Custom Submit Method
   */
  public customSubmitFormio():void {
    this.formData.emit({
      data: {
      },
    });
  }
}
