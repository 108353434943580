import {
  Directive, ViewContainerRef,
} from '@angular/core';

/**
 * Directive to host the dyanmic component
 */
@Directive({
  selector: '[libComponentHost]',
  standalone: true,
})
export class ComponentHostDirective {
  /**
   *
   * @param viewContainerRef viewContainerRef Service
   */
  constructor(public readonly viewContainerRef: ViewContainerRef) {}
}
