<button
  *ngIf="buttonShow"
  [class.spinner]="spinner && btnType === 'submit'"
  [disabled]="disabled || (spinner && btnType === 'submit')"
  class="btn btn-{{ btnSize }}"
  [ngClass]="
    disabled || (spinner && btnType === 'submit')
      ? 'btn--disabled'
      : btnStyle
      ? 'btn-' + btnStyle
      : 'btn-' + btnColor
  "
  (click)="_click($event)"
  type="{{ btnType }}"
>
  <span class="btn-icon" *ngIf="btnIcon">
    <mat-icon
      aria-hidden="false"
      aria-label="icon"
      fontIcon="{{ fontIcon }}"
    ></mat-icon>
  </span>
  <span [ngClass]="['btn__label']">
    {{ btnLabel }}
  </span>
  <span class="right-icon" *ngIf="customIcon && btnColor === 'text-with-icon'">
    <img src="{{ customIcon }}" alt="icon" />
  </span>
</button>
