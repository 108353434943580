<div class="center-item">
  <ul
    class="stepper"
    [ngClass]="{
      'stepper-blue': stepperColor === 'blue',
      'stepper-green': stepperColor === 'green',
      'stepper-horizontal': stepperPosition === 'horizontal',
      'stepper-vertical': stepperPosition === 'vertical'
    }"
  >
    <ng-container *ngFor="let step of steps; let last = last">
      <li [class]="getStepStateClass(step)">
        <a
          [routerLink]="step.linkUrl"
          routerLinkActive="router-link-active"
          href="javascript:void(0);"
        >
          <div class="c-wizard"><span></span></div>
          <h3>{{ step.label }}</h3>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
