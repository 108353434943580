<ng-container *ngIf="loaderService.loaderState | async as loaderState">
  <lib-loaderbar *ngIf="loaderState.loading"></lib-loaderbar>
</ng-container>

<div class="app-container">
  <app-header-bar></app-header-bar>
  <div class="app-main fixed-header">
    <div class="app-page-outer left-sidebar scroll">
      <app-side-navigation-menu></app-side-navigation-menu>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer-bar></app-footer-bar>
</div>
