import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MenuIconComponent, MenuList,
} from '../menu-icon/menu-icon.component';
export interface CardDetails {
  Name: ImgData;
  Owner: ImgData;
  LastModified: LastDate;
  FileSize: string;
  iconName?: string;
  action: ActionItem[];
  file?: string;
  thumbnailImage?: string;
  icons?: string[];
  type?: string;
  size?: string;
  creation?: string;
  access?: string;
  shareWith?: string;
  location?: string;
  tags?:ImageTags[];
}
export interface ImgData {
  img?: string;
  name?: string;
}
export interface LastDate {
  date?: string;
  name?: string;
}
export interface ActionItem {
  label?: string;
  link?: string;
  icon?: string;
}
export interface ImageTags {
  tagName?: string;
  tagNumber?: string;
}

/**
 *RecentCardsComponent
 */
@Component({
  selector: 'lib-recent-cards',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MenuIconComponent,
  ],
  templateUrl: './recent-cards.component.html',
  styleUrls: [
    './recent-cards.component.scss',
  ],
})
export class RecentCardsComponent {

  @Input() cardDetails!: CardDetails[] | undefined;

  @Input() cardNumber = this.cardDetails?.length;

  @Input() iconName!: string | undefined;

  @Input() menuList!: MenuList[] | undefined;

  @Output() openDialogFile = new EventEmitter<CardDetails>();

  @Output() openSharePopup = new EventEmitter<CardDetails>();

  @Output() openFileDetailsPopup = new EventEmitter<CardDetails>();

  /**
   *
   * @param item -item
   */
  openDialog(item: CardDetails) {
    // Emit an event to notify the parent component (ContentDashboardComponent)
    this.openDialogFile.emit(item);
  }

  /**
   * @param item -item
   * @param index -i
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleMenuClick(item: ActionItem, index: any) {
    if (item.label === 'Share') {
      this.openSharePopup.emit(index);
    } else if (item.label === 'Details') {
      this.openFileDetailsPopup.emit(index);
    }
  }
}
