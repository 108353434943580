<div class="left-navigation-wrap">
  <div class="left-navigation">
    <div class="nav-btn">
      <button
        type="button"
        (click)="hideShowMenu()"
        [ngClass]="{ active: !isBtnActive }"
      >
        <img src="/assets/images/menu.svg" />
      </button>
    </div>
    <div class="service-menu">
      <ul class="nav-list">
        <li
          *ngFor="let list of listItems"
          [class.active]="selectedIcon === list.label"
          (click)="selectIcon(list.label)"
        >
          <div>
            <a [routerLink]="list.link">
              <span class="service-icon">
                <img src="{{ list.icon }}" alt="{{ list.label }}" />
              </span>
            </a>
            <h4 class="service-name">{{ list.label }}</h4>
          </div>
        </li>
      </ul>
      <div class="left-nav-arrow">
        <mat-icon
          class="material-symbols-outlined"
          (click)="hideShowMenu()"
          [ngClass]="{ active: !isBtnActive }"
        >
          arrow_forward_ios
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="left-side-panel">
    <lib-drive-button-drop-down
      [docItemsMenu]="docItemsMenu"
      [folder]="folder"
      [upload]="upload"
      [extractFile]="extractFile"
      (itemClicked)="handleItemClicked($event)"
    ></lib-drive-button-drop-down>
    <ul class="common-nav">
      <li
        *ngFor="let item of dataSourse"
        [class.selected-icons]="selectedIcon === item.label"
      >
        <a [routerLink]="item.link">
          <img src="{{ item.icon }}" class="nav-icon" alt="icon" />
          <span class="nav-txt">{{ item.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
