import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

/**
 * A progress-bar component using SCAM methodology
 */
@Component({
  selector: 'lib-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: [
    './progressbar.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressbarComponent {

  @Input() percentage!:  number;

  @Input() type = 'determinate';


}
