import {
  Component, Input, ViewChild,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule, MatMenu,
} from '@angular/material/menu';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  FormsModule, FormGroup,
} from '@angular/forms';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  SearchBoxComponent,
} from '../search-box/searchbox.component';

interface DocType {
  id: string; name: string; email: string; img: string;
};
/**
 *
 */
@Component({
  selector: 'lib-drive-people',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    MatFormFieldModule,
    SearchBoxComponent,
  ],
  templateUrl: './drive-people.component.html',
  styleUrls: [
    './drive-people.component.scss',
  ],
})
export class DrivePeopleComponent {

  @Input() menuTitle = 'People';

  @Input() docItems!: DocType[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() folder!: any;

  @Input() placeholder = 'Search for people and groups';

  @ViewChild('menu') menu!: MatMenu;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forgotPasswordForm: FormGroup | any;

  isMenuOpen = true;

  searchTerm = '';

  /**
   *@returns return
   */
  get filteredItems() {
    return this.docItems?.filter((item) =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
    );
  }

  /**
   *
   */
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  /**
   *
   * @param event -
   */
  preventClosure(event: Event) {
    event.stopPropagation();
  }
}
