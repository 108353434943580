import {
  ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  NgxAngularQueryBuilderModule,
  QueryBuilderConfig,
} from 'ngx-angular-query-builder';

import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatSelectModule,
} from '@angular/material/select';
import {
  MatRadioModule,
} from '@angular/material/radio';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Rule {
  field: string;
  operator: string;
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FieldConfig {
  name: string;
  type: string;
  options?: { name: string; value: string }[];
}

/**
 *
 */
@Component({
  selector: 'lib-query-builder',
  standalone: true,
  imports: [
    CommonModule,
    NgxAngularQueryBuilderModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatInputModule,
    ButtonComponent,
  ],
  templateUrl: './query-builder.component.html',
  styleUrls: [
    './query-builder.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryBuilderComponent implements OnInit {

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formConfig!: any;

  @Input() spinner = false;

  @Output() customEvent = new EventEmitter();

  @Input() formPopulateData!: FormPopulateData;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();


  @ViewChild('configFormElement') configFormElement!: ElementRef;

  color = 'green';

  checked = false;

  disabled = false;

  buttonLabel = 'SAVE';

  buttonColor = 'blue';

  btnSize = 'md';

  btnType = 'submit';

  forms!: FormGroup;

  formsDetails = new FormControl();

  aliases: string[] = [
    'Alias 1',
    'Alias 2',
    'Alias 3',
  ];

  public currentConfig: QueryBuilderConfig | undefined;

  public allowRuleset = true;

  public allowCollapse: boolean | undefined;

  public persistValueOnFieldChange = false;

  /**
   * Constructor
   * @param fb FormBuilder
   */
  constructor(private fb: FormBuilder) {}

  /**
   * formbuilder
   */
  ngOnInit(): void {
    this.createForm();
    this.forms = this.fb.group({
      name: [
        this.formPopulateData?.data?.['name'] || '',
      ],
      alias: [
        this.formPopulateData?.data?.['alias'] || '',
      ],
      description: [
        this.formPopulateData?.data?.['description'] || '',
      ],
    });
    //if (this.formPopulateData && Object.keys(this.formPopulateData).length > 0) {
    this.formConfig.query.condition = this.formPopulateData?.data?.['condition'] || '',
    this.formConfig.query.rules = this.formPopulateData?.data?.['rules'] || [
    ];
    //}


    this.detectChanges();
  }

  /**
   * createForm
   */
  createForm() {
    this.forms = this.fb.group({
      name: [
        '',
        Validators.required,
      ],
      alias: [
        '',
        Validators.required,
      ],
      description: [
        '',
        Validators.required,
      ],
    });
  }

  /**
   * Custom Submit Button
   */
  public customSubmitFormio():void {
    this.configFormElement.nativeElement.requestSubmit();
  }

  /**
   *
   */
  detectChanges() {
    this.forms.valueChanges.subscribe(res => {
      if (this.forms.valid) {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      } else {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      }
    });

    this.formsDetails.valueChanges.subscribe((value) => {
      if (this.formsDetails.valid) {
        const changeData = {
          isModified : true,
          isValid: true,
          value : value,
        };
        this.formChange.emit(changeData);
      } else {
        const changeData = {
          isModified : true,
          isValid: true,
          value : value,
        };
        this.formChange.emit(changeData);
      }
    });
  }

  /**
   * saveForm
   */
  saveForm() {
    if (!this.forms.valid) {
      const changeData = {
        isModified: true,
        isValid: false,
        value: this.forms.value,
      };
      this.formChange.emit(changeData);
      this.forms.reset();
    } else {
      this.formData.emit({
        data: {
          ...this.forms.value,
          ...this.formsDetails.value,
        },
      });
      this.markFormGroupTouched(this.forms);
    }
  }

  /**
   *
   * @param formGroup formGroup
   */
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
