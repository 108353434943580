<ng-container *ngFor="let obj of frequencyInfoCards">
  <div class="info-block">
    <div class="campaign-budget">
      <span class="info-block-value">{{ obj.value }}</span>
      <span class="info-block-title">{{ obj.cardTitle }}</span>
    </div>
    <div class="campaign-value" *ngIf="obj.totalBudget">
      <div class="total-block">
        <div class="info-block-title">{{ obj.totalBudget }}</div>
        <strong>{{ obj.totalBudgetValue }}</strong>
      </div>
      <div class="total-block">
        <div class="info-block-title">{{ obj.spentBudget }}</div>
        <strong>{{ obj.spentBudgetValue }}</strong>
      </div>
    </div>
    <img *ngIf="obj.icon" src="{{ obj.icon }}" />
    <span class="view-detail" *ngIf="obj.btnText">{{ obj.btnText }}</span>
  </div>
</ng-container>
