<div class="container">
  <div class="container-title"></div>
  <div class="container-tabs">
    <ng-container *ngFor="let obj of dataTabs; let i = index">
      <button
        (click)="loadData(obj, i)"
        [ngClass]="obj.selected ? 'tab-selected' : 'tab'"
      >
        {{ obj.name }}
      </button>
    </ng-container>
  </div>
  <div class="container-body" *ngIf="isClose">
    <div *ngIf="currentLanguage === 'en'">English Content Goes Here</div>
    <div *ngIf="currentLanguage === 'es'">El contenido en español va aquí</div>
  </div>
</div>
