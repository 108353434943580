/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectStageStateEnum } from './project-stage-state-enum';


export interface UpdateProjectStageDto { 
    /**
     * Stage Id
     */
    projectStageDefinitionId?: string;
    /**
     * The current step the current stage is pointing to
     */
    currentStepId?: string | null;
    state?: ProjectStageStateEnum;
}
export namespace UpdateProjectStageDto {
}


