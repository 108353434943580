<div class="error-box">
  <div class="error-img">
    <img alt="403 error img" src="{{ notFoundImage }}" />
  </div>
  <div class="error-content">
    <div class="error-text-number">
      {{ errorNumber }}
    </div>
    <h3 class="">{{ errorMessage }}</h3>
    <p class="">{{ errorDescription }}</p>
    <div class="btn-box">
      <a class="btn btn-green" href="{{ moveToPageLink }}">
        <span class="button--text">{{ moveToPageName }}</span>
      </a>
      <span class="btn-txt"
        >or ask for
        <a class="link" href="{{ requestAccessLink }}">Request Access</a></span
      >
    </div>
  </div>
</div>
