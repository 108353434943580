/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemStepStateEnum } from './order-item-step-state-enum';


export interface UpdateOrderItemStepDto { 
    /**
     * Product Variant Id
     */
    productVariantStepDefinitionId?: string;
    /**
     * Name of the Order Item Step
     */
    name?: string;
    /**
     * Complexity of the Order Item step
     */
    complexity?: number;
    /**
     * Durartion of the step
     */
    timeSpent?: number;
    /**
     * Specifies if the Step is opertional
     */
    isOperational?: boolean;
    /**
     * The order of the Order Item Step
     */
    sort?: number;
    metaData: object;
    submissionData: object;
    state?: OrderItemStepStateEnum;
}
export namespace UpdateOrderItemStepDto {
}


