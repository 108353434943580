import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  RouterLink,
} from '@angular/router';


export interface ProfileMenuItem {
  label: string;
  link?: string;
  id?: string;
}
/**
 * A profile menu component
 */
@Component({
  selector: 'lib-profilemenu',
  templateUrl: './profilemenu.component.html',
  styleUrls: [
    './profilemenu.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent {
  @Input() userProfileImage! : string;

  @Input() userName! : string;

  @Input() userGroupName! : string;

  @Input() menuItems!: ProfileMenuItem[];

  @Output() menuItemClicked = new EventEmitter<string>();

  displayMenu = false;

  /**
   * Function To Toggle menu
   */
  toggleMenu() {
    this.displayMenu = !this.displayMenu;
  }

}


