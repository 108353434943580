import {
  ValidationErrors,
} from '@angular/forms';

export const GetValidatorErrorMessage =
  (validatorName: string, validatorErrors?: ValidationErrors): string | undefined => {
    const args = Messages.get(validatorName)?.validatorErrorsKey?.map(name => validatorErrors?.[name]);
    return (args) ? stringFormat(Messages.get(validatorName)?.message,...args) : Messages.get(validatorName)?.message;
  };

const Messages = new Map<string, { message : string;validatorErrorsKey? : string[] }>([
  [
    'required',
    {
      message : 'This field is required',
    },
  ],
  [
    'minlength',
    {
      message : 'Password must be at least {8} characters long' ,   validatorErrorsKey :[
        'requiredLength',
      ],
    },
  ],
  [
    'maxlength',
    {
      message : 'Password cannot be more than {8} characters long', validatorErrorsKey :[
        'requiredLength',
      ],
    },
  ],
  [
    'email',
    {
      message : 'Email must be a valid email address',
    },
  ],
]);

/**
 *
 * @param template string template
 * @param args args to replace
 * @returns Unknown value
 */
function stringFormat(template: string | undefined, ...args: string[]) {
  if (template) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined'
        ? args[index]
        : match;
    });
  }
  return undefined;
}
