import {
  Component, Input, OnInit,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  NestedTreeControl,
} from '@angular/cdk/tree';
import {
  MatTreeNestedDataSource, MatTreeModule,
} from '@angular/material/tree';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

/**
 *
 */
@Component({
  selector: 'lib-drive-folder',
  standalone: true,
  imports: [
    CommonModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './drive-folder.component.html',
  styleUrls: [
    './drive-folder.component.scss',
  ],
})
export class DriveFolderTreeComponent implements OnInit {

  @Input() docItems!: FoodNode[];

  treeControl = new NestedTreeControl<FoodNode>(node => node.children);

  dataSource = new MatTreeNestedDataSource<FoodNode>();

  /**
   *
   */
  ngOnInit(): void {
    this.dataSource.data = this.docItems;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  selectedNode: FoodNode | null = null;

  /**
   *
   * @param node node
   */
  onSelect(node: FoodNode): void {
    this.selectedNode = node;
  }


  /**
   *
   * @param node node
   * @returns return
   */
  isNodeSelected(node: FoodNode): boolean {
    return node === this.selectedNode;
  }


  // eslint-disable-next-line @typescript-eslint/naming-convention
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
}
