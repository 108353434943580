import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  ChartDonutComponent,
  ChartValue,
} from '../chart-donut/chart-donut.component';
import {
  FilterBoxComponent,
  FilterItem,
} from '../filter-box/filterbox.component';

/**
 *  card-donut component
 */
@Component({
  selector: 'lib-card-donut',
  standalone: true,
  imports: [
    CommonModule,
    ChartDonutComponent,
    FilterBoxComponent,
  ],
  templateUrl: './card-donut.component.html',
  styleUrls: [
    './card-donut.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDonutComponent {
  @Input() data!: ChartValue[];

  @Input() totalValue!: number;

  @Input() PlaceHolder = 'Select Value';

  @Input() filterItems!: FilterItem[];
}
