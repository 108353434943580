<div
  class="container-steps"
  [ngClass]="{
    'horizontal-step': stepperPosition === 'horizontal',
    'vertical-step': stepperPosition === 'vertical'
  }"
>
  <div class="stepper-container">
    <div class="steps-action">
      <a (click)="pre()" *ngIf="currentIndex > 0" class="link link-prev">
        <span class="material-icons">arrow_circle_left</span>
        <span>Previous</span>
      </a>
    </div>
    <div class="stepper-wizard" *ngIf="stepperPosition === 'horizontal'">
      <lib-stepper
        [stepperColor]="stepperColor"
        [stepperPosition]="stepperPosition"
        [(steps)]="steps"
        (stepsChange)="stepsChange.emit(stepConfig)"
      ></lib-stepper>
    </div>
    <div class="steps-action">
      <button
        type="button"
        (click)="onNext()"
        [class.spinner]="loading"
        (currentStepChange)="currentStepChange.emit($event)"
        [disabled]="!isFormValid"
        *ngIf="currentIndex < stepsCnt"
        class="link link-continue"
      >
        <span>Next </span>
        <span class="material-icons">arrow_circle_right</span>
      </button>
    </div>
  </div>

  <div class="category-header">
    <div class="category-name">
      <h3>{{ stageName }}</h3>
    </div>
    <div class="campaign-details" *ngIf="displayCampaignInfo">
      <lib-campaign-detail
        [logoUrl]="logoUrl"
        [campaignName]="projectName"
        [campaignId]="projectId"
        [campaignDate]="projectReleaseDate"
      ></lib-campaign-detail>
    </div>
  </div>

  <div class="stepper-wizard-verticle">
    <div
      class="stepper-wizard"
      *ngIf="stepperPosition === 'vertical'"
      [ngClass]="{ collapsed: isCollapsed }"
    >
      <div class="toogle-btn" (click)="toggleCollapse()"></div>
      <lib-stepper
        [stepperColor]="stepperColor"
        [stepperPosition]="stepperPosition"
        [(steps)]="steps"
        (stepsChange)="stepsChange.emit(stepConfig)"
      ></lib-stepper>
    </div>

    <div class="formio-form" ng-hide="!noConfigFoundFlag">
      <div class="form-render">
        <div>
          <div class="form-io-container">
            <ng-template libComponentHost />
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="noConfigFoundFlag">
    <div class="no-config-found">
      No Configutation found for this step : &nbsp;<strong>
        {{ currentStep }}
      </strong>
    </div>
  </ng-container>
</div>
