import {
  inject,
  Provider,
} from '@angular/core';
import {
  signalStore,
  patchState,
  withMethods,
  withHooks,
} from '@ngrx/signals';
import {
  withEntities,
  addEntities,
  removeAllEntities,
} from '@ngrx/signals/entities';
import {
  rxMethod,
} from '@ngrx/signals/rxjs-interop';
import {
  debounceTime,
  map,
  pipe,
  switchMap,
} from 'rxjs';
import {
  tapResponse,
} from '@ngrx/operators';
import {
  HttpErrorResponse,
} from '@angular/common/http';
import {
  ClientBrandsFragment as Client,
  GetClientsWithBrandsGQL,
} from '@genera/genera-graphql-angular-sdk';
import {
  ContextStore,
} from '../context/context.store';

export const ClientsAndBrandsStore = signalStore(

  withEntities<Client>(),

  withMethods((store,
               contextStore = inject(ContextStore),
               getClientService: GetClientsWithBrandsGQL = inject(GetClientsWithBrandsGQL),
  ) => ({

    init: rxMethod<void>(
      pipe(
        debounceTime(300),
        switchMap(() =>
          getClientService.watch().valueChanges.pipe(
            map(res => res?.data?.getClients || [
            ]),
            tapResponse({
              next: (clients: Client[]) => {
                patchState(
                  store,
                  addEntities(
                    clients,
                    {
                      idKey: 'clientId',
                    },
                  ),
                );
                const brand = contextStore.brand?.();
                if (!brand?.brandId) {
                  const firstClient = clients[0];
                  const firstBrand = firstClient.brands[0];
                  patchState(
                    contextStore,
                    {
                      client: {
                        createdAt: '',
                        updatedAt: '',
                        ...firstClient,
                      },
                      brand: {
                        createdAt: '',
                        updatedAt: '',
                        ...firstBrand,
                      },
                    },
                  );
                }
              },
              error: (error: HttpErrorResponse) => {
                // eslint-disable-next-line no-console
                console.error(`Error getting clients - ${error?.statusText}`);
              },
            }),
          ),
        ),
      ),
    ),

    /**
     * Clear current state
     */
    clear() {
      patchState(store, removeAllEntities());
    },
  })),

  withHooks({
    /**
     * Init the context store
     * @param store The store object
     * @param store.init The init method
     */
    onInit({
      init,
    }) {
      init();
    },
  }),
);

/**
 * Get Required providers for the store
 * @returns Requiered providers for this store
 */
export function clientsBrandsProviders(): Provider[] {
  return [
    GetClientsWithBrandsGQL,
    ClientsAndBrandsStore,
  ];
};
