import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  ComponentRef,
  DestroyRef,
  EventEmitter,
  Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  ComponentHostDirective,
} from './directive/component-host.directive';
import {
  of, timer, take, finalize,
} from 'rxjs';
import {
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import {
  ServiceTypes,
} from './model/services-types';
import {
  ServiceComponentType, servicesComponentFactory,
} from './services-factory';
import {
  Service,
} from './model/service';
import {
  FormChangesEvent, Position, StepConfig,
} from './model/step-config';
import {
  StepsOrchestratorService,
} from './services/steps-orchestrator.service';
import {
  StepperComponent, StepperColor, StepperPosition, StepState, Steps,
} from '../stepper/stepper.component';
import {
  CampaignDetailComponent,
} from '../campaign-detail/campaign-detail.component';
import {
  RouterModule,
} from '@angular/router';
/**
 * Step orchestrator Component to load the step component dynamically based on the step configuration and current step
 */
@Component({
  selector: 'lib-steps-orchestrator',
  standalone: true,
  imports: [
    CommonModule,
    ComponentHostDirective,
    StepperComponent,
    CampaignDetailComponent,
    RouterModule,
  ],
  providers: [
    StepsOrchestratorService,
  ],
  templateUrl: './steps-orchestrator.component.html',
  styleUrls: [
    './steps-orchestrator.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class StepsOrchestratorComponent implements OnInit, OnChanges {

  @ViewChild(ComponentHostDirective, {
    static: true,
  })
  componentHost!: ComponentHostDirective;

  @Input({
    required: true,
  }) stepConfig! : StepConfig[];

  @Input({
    required: true,
  }) currentStep! : string;

  @Input({
    required: true,
  }) stepperColor : StepperColor = 'green';

  @Input({
    required: true,
  }) stepperPosition : StepperPosition = 'horizontal';

  @Input() displayCampaignInfo  = false;

  @Input() logoUrl! : string;

  @Input() projectName! : string;

  @Input() projectId! : string;

  @Input() projectReleaseDate! : string;

  @Input() stageName! : string;

  @Output() redirectUrl = new EventEmitter<string>();

  @Output() stepNotFound = new EventEmitter<boolean>();

  @Output() stepConfigNotFound = new EventEmitter<boolean>();

  @Output() formSubmittedData = new EventEmitter();

  @Output() currentStepChange = new EventEmitter();

  @Output() stepsChange = new EventEmitter();

  @Output() formChangeEvent = new EventEmitter();

  @Output() customEvent = new EventEmitter();

  @Output() frequencyDistributionSubmittedData = new EventEmitter();

  @Output() clipBoardJson = new EventEmitter();

  @Output() queryBuilder = new EventEmitter();

  @Output() currentStepId = new EventEmitter<string>();

  @Input() spinner = false;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Input() productSpinner = false;

  @Output() productSpinnerChange = new EventEmitter<boolean>();

  @Output() position = new EventEmitter<Position>();

  public currentIndex = 0;

  public stepsCnt = 0;

  slug!: string;

  public noConfigFoundFlag = false;

  public noStepFoundFlag = false;

  public isFormValid = false;

  public loading = false;

  public steps!: Steps[];

  childIndex! : number;

  isCollapsed = false;

  childComponentRef!:ComponentRef<ServiceComponentType>;

  private readonly destroy: DestroyRef = inject(DestroyRef);

  /**
   * Component constructor
   * @param stOrcService StepsOrchestratorService
   * @param changeRef Service to Trigger detection
   */
  constructor ( private stOrcService: StepsOrchestratorService,
                private changeRef: ChangeDetectorRef) {}

  /**
   * Component initialization Call the Load Step method
   */
  ngOnInit(): void {
    if (this.stepConfig?.length > 0 ) {
      this.loadStepperData();
    }

  }

  /**
   * Load the current step data from the stepconfiguration
   */
  loadStepData(): void {
    const [
      currentStepDatum,
    ] = this.stOrcService.getCurrentStep(this.stepConfig, this.currentStep);
    if (currentStepDatum && currentStepDatum.id !== -1) {
      of(currentStepDatum)
        .pipe(takeUntilDestroyed(this.destroy))
        .subscribe((service: Service) => {
          this.loadComponent(service);
          this.noConfigFoundFlag = false;
          this.stepConfigNotFound.emit(false);
        });
    } else {
      const {
        viewContainerRef,
      }: ComponentHostDirective = this.componentHost;
      viewContainerRef.clear();
      this.noConfigFoundFlag = true;
      this.stepConfigNotFound.emit(true);
    }
  }

  /**
   * Previous Button Method
   */
  pre(): void {

    this.currentIndex -= 1;
    this.redirectUrl.emit(`/${this.stepConfig[this.currentIndex].linkUrl}`);
    this.currentStep = this.stepConfig[this.currentIndex].slug;
    this.loadStepperData();

  }

  /**
   * Next Button function
   */
  onNext(): void {

    const customComponents = [
      'FrequencyDistributionComponent',
      'SummaryStepComponent',
    ];

    if (!customComponents.includes(this.childComponentRef.componentType['name'])) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.childComponentRef.instance as any).customSubmitFormio();

      if (this.childComponentRef.componentType['name'] === 'DrawFlawComponent') {
        this.clipBoardJson.emit();
      }
      if (this.childComponentRef.componentType['name'] === 'QueryBuilderComponent') {
        this.queryBuilder.emit();
      }
    } else {
      this.formSubmittedData.emit({
      });
    }
  }

  /**
   * Call when the form submission data is emit for child component
   * @param formData formData
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFormData(formData: any): void {
    this.loading = true;
    const componentName = this.childComponentRef.componentType['name'];
    timer(500).pipe(
      take(1),
      finalize(() => this.loading = false),
    ).subscribe(() => {
      if (componentName === 'FrequencyDistributionComponent') {
        this.frequencyDistributionSubmittedData.emit(formData.data);
        this.isFormValid = true;
      } else if (componentName === 'DrawFlawComponent') {
        this.frequencyDistributionSubmittedData.emit(formData.data);
        this.isFormValid = true;
      } else {
        this.formSubmittedData.emit(formData.data);
      }
    });
  }

  /**
   * Load Dynamically Component Based on the Current Step Configuration
   * @param service Service
   */
  public loadComponent(service: Service): void {
    const {
      viewContainerRef,
    }: ComponentHostDirective = this.componentHost;
    viewContainerRef.clear();
    this.childComponentRef = viewContainerRef.createComponent<ServiceComponentType>(
      servicesComponentFactory[service.id as ServiceTypes],
    );
    this.childIndex = viewContainerRef.indexOf(this.childComponentRef.hostView);
    if (this.stOrcService.isEmpty(service.config)) {
      try {
        this.childComponentRef.setInput('formConfig', service.config);
      } catch (err) {
        // It is possible that we have components without forms
        // eslint-disable-next-line no-console
        console.warn(err);
      }
    }
    this.childComponentRef.setInput('formPopulateData', service.populateData);
    this.childComponentRef.instance.formData.subscribe(val => this.getFormData(val));
    this.childComponentRef.instance.formChange.subscribe(val => this.getFormChange(val));
    this.childComponentRef.instance.customEvent.subscribe(val => this.customEvent.emit(val));

    this.changeRef.markForCheck();
  }


  /**
   * Call when the form  data Change is emit for child component
   * @param formChanges isValid true/false
   */
  public getFormChange(formChanges: FormChangesEvent): void {
    this.changeRef.markForCheck();
    if (formChanges.isValid === true) {
      this.formChangeEvent.emit(formChanges);
      this.isFormValid = true;
    }
    this.changeRef.detectChanges();

  }

  /**
   * Method to detect the changes
   * @param changes changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stepConfig'] && changes['stepConfig'].currentValue) {
      this.stepConfig = changes['stepConfig'].currentValue;
      this.loadStepperData();
    }

    if (changes['currentStep'] && changes['currentStep'].currentValue) {
      this.currentStep = changes['currentStep'].currentValue;
      this.loadStepperData();
    }

    if (changes['spinner']  && changes['spinner'].currentValue) {
      if (this.spinner) {
        this.currentIndex += 1;
        if ((this.stepConfig.length) > this.currentIndex) {
          this.currentStep = this.stepConfig[this.currentIndex].slug;
          this.loadStepperData();
          this.stepsChange.emit(this.currentStep);
        }
      }
    }

    if (changes['productSpinner']  && changes['productSpinner'].currentValue) {
      const componentName = this.childComponentRef.componentType['name'];
      if (componentName === 'FrequencyDistributionComponent') {
        this.childComponentRef.setInput('productSpinner', this.productSpinner);
      }
    }
  }

  /**
   * to collapse product sidebar
   */
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * Load Stepper Data Method load the stepper data
   */
  private loadStepperData() : void {
    this.stepsCnt = this.stepConfig.length;
    this.slug = this.currentStep;
    this.currentIndex = this.stepConfig.findIndex(step => step.slug === this.slug);
    if (this.currentIndex === -1) {
      this.noStepFoundFlag = true;
      this.stepNotFound.emit(true);
      this.redirectUrl.emit('/not-found');
      return;
    } else {
      this.currentStepId.emit(this.stepConfig[this.currentIndex].id);
      const positionData = this.findIndexAndPosition(this.stepConfig,'id',this.stepConfig[this.currentIndex].id);
      this.position.emit(positionData);
      this.loadStepData();
      this.noStepFoundFlag = false;
      this.stepNotFound.emit(false);
    }
    this.steps = this.stepConfig.map((step) => {
      return {
        label: step.label,
        linkUrl: step.linkUrl,
        state: step.state,
      };
    });
    this.steps.map((step, index) => {
      if (index === this.currentIndex) {
        step.state = StepState.Active;
      } else if (index < this.currentIndex) {
        step.state = StepState.Done;
      } else if (index > this.currentIndex) {
        step.state = StepState.Wait;
      }
    });
  }

  /**
   * Method to find the step positon based on the Id
   * @param steps Steps
   * @param key Key
   * @param value Value
   * @returns index, isFirst, isLast
   */
  private findIndexAndPosition(steps: StepConfig[], key: keyof StepConfig, value: string): Position {
    const index = steps.findIndex(item => item[key] === value);
    const isFirst = index === 0;
    const isLast = index === steps.length - 1;
    return {
      index, isFirst, isLast,
    };
  }

}


