<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Example icon-button with a menu"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="action-menu">
  <ul class="user-menu" [ngClass]="menuCustomsClass">
    <li *ngFor="let menu of menuItems">
      <a [routerLink]="menu.link">{{ menu.label }}</a>
    </li>
  </ul>
</mat-menu>
