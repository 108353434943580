<div class="header">
  <div class="logo">
    <lib-logo
      [serviceName]="store.currentService()"
      [linkURI]="dashboard"
      [logoUrl]="store.currentLogo()"
      [style]="primary"
      [className]="brand"
    >
    </lib-logo>
  </div>
  <div class="searchbar">
    <lib-search-in-content
      [placeholder]="placeholder"
      [matPrefixIcon]="matPrefixIcon"
      [matSuffixIcon]="matSuffixIcon"
      [userIcon]="userIcon"
      [userDocs]="userDocs"
    ></lib-search-in-content>
  </div>

  <div class="header-right">
    <button class="">
      <img src="../../../assets/imaegs/icon-help.svg" alt="question" />
    </button>
    <button>
      <img src="../../../assets/imaegs/icon-settings.svg" alt="settings" />
    </button>
    <lib-servicemenu
      [clientDetails]="clients"
      [selectedBrand]="selectedBrand"
      (selection)="selectBrand($event)"
    >
    </lib-servicemenu>

    <lib-profilemenu
      [userProfileImage]="store.user?.()?.picture || userDefaultProfileImage"
      [userName]="store.user?.()?.name || userDefaultName"
      [userGroupName]="userGroupName"
      [menuItems]="menuItems"
      (menuItemClicked)="onMenuClicked($event)"
    >
    </lib-profilemenu>
  </div>
</div>
