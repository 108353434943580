<div
  class="card"
  [class.active]="selectedIcon === title"
  (click)="title ? selectIcon(title) : null"
>
  <div class="card-body">
    <div class="icon-holder">
      <img class="card-image" src="{{ icon }}" alt="{{ title }}" />
    </div>
    <div class="card-content">
      <h4 class="card-title">{{ title }}</h4>
      <p class="card-description">
        {{ description }}
      </p>
    </div>
  </div>
</div>
