import {
  ChangeDetectionStrategy,
  Component,
  inject,
  effect,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  LogoComponent,
  ServiceMenuComponent,
  ProfileMenuComponent,
  ClientDetail,
  SelectedClientAndBrandOptions,
  BrandDetail,
  SearchInContentComponent,
} from '@genera/ui-components';
import {
  RouterModule,
  Router,
} from '@angular/router';
import {
  ClientsAndBrandsStore,
  ContextStore,
  clientsBrandsProviders,
} from '@genera/ui-data-store';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';

const LOGOUT_ID = 'logout';

/**
 * Header Bar Component
 *
 */
@Component({
  selector: 'app-header-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LogoComponent,
    ServiceMenuComponent,
    ProfileMenuComponent,
    SearchInContentComponent,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [
    ...clientsBrandsProviders(),
  ],
  templateUrl: './header-bar.component.html',
  styleUrls: [
    './header-bar.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBarComponent {

  public clients: ClientDetail[] = [
  ];

  public selectedBrand!: BrandDetail;

  dashboard = '/dashboard';

  url = 'https://admin.wsuite.com/assets/images/icons/icon-admin.svg';

  primary = 'primary';

  brand = 'brand-logo';

  brandImage = 'https://production-static.wsuite.com/destiny-favicon_1622617852450.png';

  placeholder = 'What are you looking for?';

  matPrefixIcon = 'search';

  matSuffixIcon = 'tune';

  serviceDetails = [
    {
      imageUrl: '/assets/images/genera-sm-icon.png',
      serviceName: 'Dashboard',
      serviceId: 'dashboard',
    },
    {
      imageUrl: 'https://admin.wsuite.com/assets/images/service-icons/W-PLAN.png',
      serviceName: 'Plan',
      serviceId: 'plan',
    },
    {
      imageUrl: 'https://my.wsuite.com/assets/images/service-icons/W-Message.png',
      serviceName: 'Message',
      serviceId: 'message',
    },
    {
      imageUrl: 'https://my.wsuite.com/assets/images/service-icons/W-People.png',
      serviceName: 'People',
      serviceId: 'people',
    },
    {
      imageUrl: 'https://my.wsuite.com/assets/images/service-icons/W-Content.png',
      serviceName: 'Content',
      serviceId: 'content',
    },
  ];

  userDefaultProfileImage = 'https://s3.amazonaws.com/production-assets.wsuite.com/default_user_images/asset-1.png';

  userDefaultName = 'User';

  userGroupName = 'super admin';

  serviceImage = 'https://my.wsuite.com/assets/images/service-icons/YOU.png';

  menuItems = [
    // { label: 'Report2', link: '/dashboard' },
    {
      label: 'Logout', id: LOGOUT_ID,
    },
  ];

  userIcon = [
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },
    {
      img: '../../../assets/images/Ellipse 2912.svg',
      name:'Lauren Wilson',
    },

  ];

  userDocs = [
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Googlesheet',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Spreadsheets',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Presentations',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Forms',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Photos & Images',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Shortuts',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Archives (zip)',
    },
    {
      doc: '../../../assets/images/image 51.svg',
      name:'Folders',
    },
  ];

  readonly store = inject(ContextStore);

  readonly clientsBrandsStore = inject(ClientsAndBrandsStore);

  /**
   * Constructor
   * @param changeRef Change detector service The change detector
   * @param router The router service
   */
  constructor(
    private changeRef: ChangeDetectorRef,
    private router: Router,
  ) {
    effect(() => {
      const brand = this.store.brand();
      if (brand) {
        this.selectedBrand = {
          brandName: brand.name,
          imageUrl: brand.logoUrl,
          services: this.serviceDetails,
          brandId: brand.brandId,
        };
      }

      this.clients = this.clientsBrandsStore.entities()
        .map((client): ClientDetail => {
          return {
            ...client,
            clientName: client.name,
            imageUrl: client.logoUrl,
            brands: client.brands.map((brand): BrandDetail => {
              return {
                ...brand,
                brandName: brand.name,
                imageUrl: brand.logoUrl,
                services: this.serviceDetails,
              };
            }),
          };
        });

      if (!this.store.user?.()) {
        // this.doLogout();
      }

      this.changeRef.markForCheck();
    });
  }

  /**
   *
   * @param idClick idClick
   */
  onMenuClicked(idClick: string) {
    if (idClick === LOGOUT_ID) {
      this.doLogout();
    }
  }

  /**
   * Change selected brand
   * @param selection the selection data
   */
  selectBrand(selection: SelectedClientAndBrandOptions): void {
    this.store.selectClientBrand(
      {
        name: selection.client.clientName,
        clientId: selection.client.clientId,
        logoUrl: selection.client.imageUrl,
        updatedAt: '',
        createdAt: '',
      },
      {
        name: selection.brand.brandName,
        brandId: selection.brand.brandId,
        logoUrl: selection.brand.imageUrl,
        clientId: selection.client.clientId,
        createdAt: '',
        updatedAt: '',
      },
    );
    if (selection.service?.serviceId) {
      this.router.navigate([
        selection.service.serviceId,
      ]);
    }
  }

  /**
   * Logout method
   */
  private doLogout(): void {
    this.router.navigate([
      'logout',
    ]);
  }
}
