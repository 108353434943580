<div
  class="loader-section-container"
  *ngIf="type === 'intermidateLoader'; else normalTemplate"
>
  <div class="loader-section-inner">
    <div class="loader-sec">
      <lib-welcome
        [brandText]="brandText"
        [brandLogo]="brandLogo"
      ></lib-welcome>
      <div class="loader-area">
        <span class="loader-icon"></span>
        <span class="loader-txt">{{ loaderText }}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #normalTemplate>
  <div class="loader-section-container spinner">
    <div class="">
      <div class="loader-sec">
        <div class="loader-area">
          <span class="loader-icon"></span>
          <span class="loader-txt">{{ loaderText }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
