import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  RouterModule,
} from '@angular/router';

export type StepperColor =  'green' | 'blue';
export type StepperPosition = 'horizontal' | 'vertical';

export interface Steps {
  label: string;
  linkUrl: string;
  state: StepState;
}


export enum StepState {
  Done = 'done',
  Active = 'active',
  Wait = 'wait',
}

/**
 * Stepper component
 */
@Component({
  selector: 'lib-stepper',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './stepper.component.html',
  styleUrls: [
    './stepper.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent implements OnChanges {



  @Input() label!: string;

  @Input() linkUrl!: string;

  @Input()
  stepperColor: StepperColor = 'green';

  @Input()
  stepperPosition: StepperPosition = 'horizontal';

  @Input()
  steps: Steps[] = [
  ];

  @Output() stepsChange = new EventEmitter();

  /**
   *
   * @param step step
   * @param step.label step label
   * @param step.state step state
   * @param isLast isLast
   * @returns state
   */
  getStepStateClass(
    step: { label: string; state: StepState },
    isLast = false,
  ): string {
    if (step.state === StepState.Done) {
      return 'done';
    } else if (step.state === StepState.Active) {
      return 'active';
    } else if (isLast) {
      return 'blank';
    } else {
      return '';
    }
  };

  /**
   *
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['steps'] && changes['steps'].currentValue) {
      this.steps = changes['steps'].currentValue;
    }
  }
}
