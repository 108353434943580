/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuthorizationCodeGrantDto { 
    /**
     * authorization code grant type identifier
     */
    grantType: string;
    /**
     * The application client id, if empty server will use its internal default value
     */
    clientId?: string;
    /**
     * The application client secret, if empty server will use internal default value
     */
    clientSecret?: string;
    /**
     * The application callback URL, if empty server will use internal default value
     */
    redirectUri?: string;
    /**
     * The code to use for authentication flow
     */
    code: string;
}

