/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TokenResponse { 
    /**
     * The JWT Access Token
     */
    access_token: string;
    /**
     * The time from the issue time this token will be valid, in seconds
     */
    expires_in: number;
    /**
     * The type of the issued token
     */
    token_type: string;
    /**
     * The JWT Open ID
     */
    openid_token?: string;
    /**
     * Refresh token for getting a new token 
     */
    refresh_token?: string;
}

