/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductVariantStepDefinitionTypeEnum } from './product-variant-step-definition-type-enum';


export interface CreateProductVariantStepDefinitionDto { 
    /**
     * Product Step name
     */
    name: string;
    /**
     * Description about the Product Step
     */
    description: string;
    /**
     * Icon of the Product Step
     */
    icon: string;
    /**
     * Specifies if the Product Step is enabled/disabled
     */
    enabled: boolean;
    /**
     * Product stages Id the Product steps belongs to
     */
    productVariantId: string;
    /**
     * Specifies if the Step is opertional
     */
    isOperational: boolean;
    /**
     * Specifies if the Product Step is sendToProduction
     */
    sendToProduction: boolean;
    stepType: ProductVariantStepDefinitionTypeEnum;
    /**
     * Complexity of the Product Variant step
     */
    complexity: number;
    /**
     * Durartion of the step
     */
    duration: number;
    /**
     * Order of the Product Step
     */
    order: number;
    metaData: object;
}
export namespace CreateProductVariantStepDefinitionDto {
}


