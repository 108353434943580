<div class="card">
  <div class="card-body">
    <div class="card-content">
      <span class="action-bar"
        ><img src="../../assets/images/icon-toolbar.svg" alt="icon-toolbar"
      /></span>
      <h4 class="card-title">
        <img src="{{ icon }}" alt="icon-analysis" />{{ title }}
      </h4>
      <span class="chips" [ngClass]="getStatusClass()">{{ status }}</span>
      <div class="date-time">{{ dateTime }}</div>
      <div class="editor">{{ description }}</div>
    </div>
  </div>
</div>
