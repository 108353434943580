import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';


/**
 *  Component card-step-progress
 */
@Component({
  selector: 'lib-card-step-progress',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './card-step-progress.component.html',
  styleUrls: [
    './card-step-progress.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardStepProgressComponent {

  @Input() steps: { title: string; description: string; state: string }[] = [
  ];

}
