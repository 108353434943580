<div class="color-cardLabel">
  <label>{{ inputTitle }}</label>
</div>

<div class="color-card">
  <div class="left-side">
    {{ inputDiscount }}
  </div>
  <div class="color-square">
    <div class="button-container">
      <mat-icon *ngIf="selectedHeading">{{ selectedHeading }}</mat-icon>
      <span *ngIf="!selectedHeading">Select</span>
      <button mat-icon-button [matMenuTriggerFor]="headingMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <mat-menu #headingMenu="matMenu">
      <div *ngFor="let item of inputIcon">
        <button mat-menu-item (click)="onSelectHeading(item)">
          <mat-icon>{{ item }}</mat-icon>
        </button>
      </div>
    </mat-menu>
  </div>
</div>
