<div class="container">
  <div class="container-title">
    <div class="title-1">
      <label class="title">Performance</label>
    </div>
    <div class="title-2">
      <button class="image-button"></button>
    </div>
  </div>
  <div class="container-tabs">
    <ng-container *ngFor="let obj of dataTabs; let i = index">
      <button
        (click)="loadData(obj, i)"
        [ngClass]="obj.selected ? 'tab-selected' : 'tab'"
      >
        {{ obj.name }}
      </button>
    </ng-container>
  </div>
  <div class="container-body">
    <lib-chart-line
      [dataPoints]="dataPoints"
      [widthCard]="widthCard"
      [heightCard]="heightCard"
    ></lib-chart-line>
  </div>
</div>
