/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderItemStepActivityTypeEnum = 'comment' | 'attachment' | 'link';

export const OrderItemStepActivityTypeEnum = {
    Comment: 'comment' as OrderItemStepActivityTypeEnum,
    Attachment: 'attachment' as OrderItemStepActivityTypeEnum,
    Link: 'link' as OrderItemStepActivityTypeEnum
};

