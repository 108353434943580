import {
  ChangeDetectionStrategy, Component, ElementRef,
  EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,
} from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import {
  FloatLabelType, MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  CommonModule,
} from '@angular/common';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';
import {
  Subscription,
} from 'rxjs';


/**
 * A simple Dummy component using SCAM methodology
 */
@Component({
  selector: 'lib-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: [
    './dummy.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    provideAnimations(),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyComponent implements OnInit, OnDestroy {

  @ViewChild('dummyFormElement') dummyFormElement!: ElementRef;


  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  @Input() formPopulateData! : FormPopulateData;

  @Output() customEvent = new EventEmitter();

  submitted = false;

  hide = true;

  hideRequiredControl = new FormControl(false);

  floatLabelControl = new FormControl('auto' as FloatLabelType);

  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  dummyForm!: FormGroup;

  hasChange = false;

  private subscription$!: Subscription;

  /**
   * Constructor
   * @param _formBuilder FormBuilder Service
   */
  constructor(public _formBuilder: FormBuilder) {}


  /**
   *Component On Init method
   */
  ngOnInit(): void {
    this.dummyForm = this._formBuilder.group({
      name: [
        this.formPopulateData?.data?.['name'],
        [
          Validators.required,
        ],
      ],
      email: [
        this.formPopulateData?.data?.['email'],
        [
          Validators.required,
          Validators.email,
        ],
      ],
    });
    this.detectChanges();


  }

  /**
   * Form Submit Function
   */
  onSubmit() {
    this.submitted = true;
    if (!this.dummyForm.valid) {
      const changeData = {
        isModified : true,
        isValid: false,
        value : this.dummyForm.value,
      };
      this.formChange.emit(changeData);

    } else {
      this.formData.emit({
        data : this.dummyForm.value,
      });
    }
  }

  /**
   * @returns value
   */
  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  /**
   * Custom Submit Button
   */
  public customSubmitFormio():void {
    this.dummyFormElement.nativeElement.requestSubmit();
  }

  /**
   * Fires on each form control value change
   */
  detectChanges() {
    this.subscription$ = this.dummyForm.valueChanges.subscribe(res => {
      if (this.dummyForm.valid) {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      } else {
        const changeData = {
          isModified : true,
          isValid: false,
          value : res,
        };
        this.formChange.emit(changeData);
      }
    });
  }

  /**
   * On destroy method
   */
  ngOnDestroy() {
    if (this.subscription$)
      this.subscription$.unsubscribe();
  }
}


