import {
  Component, Input, OnInit, ViewChild, Output, EventEmitter, OnChanges, ComponentRef,
  ViewContainerRef,
  ChangeDetectorRef,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import {
  FloatLabelType,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  SearchBoxComponent,
} from '../search-box/searchbox.component';
import {
  DrawJSComponent, NodeEventData,
} from './draw-js/draw-js.component';
import {
  Clipboard,
} from '@angular/cdk/clipboard';
import {
  MatIconModule,
} from '@angular/material/icon';

interface PaletteNodeItem {
  id: string;
  inputValue: string;
  topNodeValue: number;
  bottomNodeValue: number;
  desc: string;
  img: string;
  remark: string;
}

interface DataTab {
  value: number;
  name: string;
  selected: boolean;
}

/**
 *Draw-flaw component
 */
@Component({
  selector: 'lib-draw-flow-component',
  standalone: true,
  templateUrl: './draw-flow.component.html',
  styleUrls: [
    './draw-flow.component.scss',
    './draw-flow-node.component.scss',
    './draw-flow-node2.component.scss',
    './draw-flow-node3.component.scss',
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ButtonComponent,
    ReactiveFormsModule,
    MatInputModule,
    SearchBoxComponent,
    MatIconModule,
  ],
})
export class DrawFlawComponent implements OnInit, OnChanges {
  @Input()
  formConfig!: {
    paletteNodeItems: PaletteNodeItem[];
    dataTabs: DataTab[];
    flowtab : PaletteNodeItem[];
  };

  @Output() customEvent = new EventEmitter();

  @Input() formPopulateData!: FormPopulateData;

  @Input() configForm!: FormGroup;

  @Input() spinner = false;

  @Input() productSpinner = false;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  @ViewChild('drawJs', {
    read: ViewContainerRef,
  })
  drawJs!: ViewContainerRef;

  @ViewChild('configFormElement') configFormElement!: ElementRef;

  public childRef!: ComponentRef<DrawJSComponent>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: any = {
    paletteNodeData: [
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childData: any;

  isProductSelected = false;

  selectedProduct!: string;

  hideRequiredControl = new FormControl(false);

  buttonLabel = 'Save Config';

  clearButtonLabel = 'Clear';

  clipboardBottonLabel = 'Copy to clipboard';

  buttonColor = 'green';

  btnSize = 'sm';

  btnType = 'submit';

  buttonColorSp = 'green-outline';

  clearButtonColorSp = 'blue-outline';

  isCollapsed = false;

  floatLabelControl = new FormControl('auto' as FloatLabelType);

  clipBoard! : string;

  isFlow = false;

  searchTerm = '';

  placeholder = 'Search';

  filteredPaletteNodeItems: PaletteNodeItem[] = [
  ];

  /**
   *constructor
   * @param _formBuilder -formBuilder
   * @param changeRef Service to Trigger detection
   * @param clipboard Service to clipboard
   */
  constructor(public _formBuilder: FormBuilder,
              private changeRef: ChangeDetectorRef,
              private clipboard: Clipboard ) { }

  /**
   *@returns return
   */
  get filteredItems() {
    return this.formConfig.paletteNodeItems?.filter((item) =>
      item.inputValue.toLowerCase().includes(this.searchTerm.toLowerCase()),
    );
  }

  /**
   *@returns return
   */
  get filteredItems2() {
    return this.formConfig.flowtab?.filter((item) =>
      item.inputValue.toLowerCase().includes(this.searchTerm.toLowerCase()),
    );
  }

  /**
   *ngOnInit
   */
  ngOnInit(): void {
    this.configForm = this._formBuilder.group({
      quantity: [
        this.formPopulateData?.data?.['quantity'] || '',
        [
          Validators.required,
          Validators.min(1),
          Validators.max(10),
        ],
      ],
      // email: [
      //   this.formPopulateData?.data?.['email'] || '',
      //   [
      //     Validators.required,
      //     Validators.email,
      //   ],
      // ],
      // text: [
      //   this.formPopulateData?.data?.['text'] || '',
      //   Validators.required,
      // ],
      // description: [
      //   this.formPopulateData?.data?.['text'] || '',
      //   Validators.required,
      // ],
      productVariantId: [
        this.formPopulateData?.data?.['productVariantId'] || '',
        this.hideRequiredControl,
      ],
      name: [
        this.formPopulateData?.data?.['name'] || '',
        this.hideRequiredControl,
      ],
    });
    this.detectChanges();
  }

  /**
   *toggleCollapse
   */
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   *
   */
  async loadDynamically() {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      DrawJSComponent,
    } = await import('./draw-js/draw-js.component');
    this.drawJs.clear();
    this.childRef = this.drawJs.createComponent(DrawJSComponent);
    this.changeRef.markForCheck();
    this.childData = this.childRef.instance;
    this.childData.newItemEvent.subscribe((event: boolean) => {
      this.productSelected(event);
    });
    this.childData.nodeEventData.subscribe((event: NodeEventData) => {
      this.selectedNodeData(event);
    });
  }

  /**
   *
   * @param event -event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callChildDragFunction(event: any) {
    if (this.childRef) {
      this.childRef.instance.drag(event);
    }
  }

  /**
   *
   * @param item -item
   */
  productSelected(item: boolean) {
    this.isProductSelected = item;
  }

  /**
   *
   * @param node NodeEventData
   */
  selectedNodeData(node: NodeEventData) {
    this.selectedProduct = node.name;
    this.configForm.get('productVariantId')?.setValue(node.id);
    this.configForm.get('name')?.setValue(node.name);
  }

  /**
   *
   */
  clearNode() {
    this.childRef.instance.editor.clearModuleSelected();
  }

  /**
   * getFloatLabelValue
   * @returns -return
   */
  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  /**
   * Fires on each form control value change
   */
  detectChanges() {
    this.configForm.valueChanges.subscribe(res => {
      if (this.configForm.valid) {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      } else {
        const changeData = {
          isModified : true,
          isValid: true,
          value : res,
        };
        this.formChange.emit(changeData);
      }
    });
  }

  /**
   *saveConfig
   */
  saveConfig() {
    if (!this.configForm.valid) {
      const changeData = {
        isModified: true,
        isValid: false,
        value: this.configForm.value,
      };
      this.formChange.emit(changeData);
    } else {
      this.formData.emit({
        data: this.configForm.value,
      });
      this.productSelected(false);
    }
  }

  /**
   * Custom Submit Button
   */
  public customSubmitFormio():void {
    //this.configFormElement.nativeElement.requestSubmit();
    this.formData.emit({
      data: {
        'drawflow': this.childRef.instance.editor.drawflow,
        'type': 'flow',
      },
    });
  }

  /**
   *ngOnChanges
   * @param changes -
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productSpinner'] && changes['productSpinner'].currentValue) {
      this.spinner = false;
      this.productSpinner = false;
    }
    this.loadDynamically();
  }

  /**
   * Method to copy the JSON content to the clipboard
   */
  copyToClipboard() {
    this.clipBoard = JSON.stringify(this.childRef.instance.editor.drawflow, null, 4);
    this.clipboard.copy(this.clipBoard);
    this.formPopulateData?.data?.['drawFlowData'] || ' ';
    this.formData.emit({
      data: this.clipBoard,
    });
  }

  /**
   *closePanel
   */
  closePanel() {
    this.isProductSelected = false;
  }

  /**
   *loadData
   * @param obj obj
   * @param index number
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadData(obj: any, index: number) {
    for (const data of this.formConfig.dataTabs) {
      data.selected = false;
    }
    if (obj.name === 'Flow') {
      this.isFlow = true;
    }
    if (obj.name === 'Products') {
      this.isFlow = false;
    }
    this.state.paletteNodeData = this.formConfig.paletteNodeItems[index];
    this.formConfig.dataTabs[index].selected = true;
  }
}
