<div
  class="wrapper"
  [ngClass]="{ collapsed: isCollapsed, 'open-config-form': isProductSelected }"
>
  <div class="draw-section-left">
    <div class="container-products">
      <div class="container-title">
        <h5 class="title">Add products</h5>
        <button class="image-button" (click)="toggleCollapse()"></button>
      </div>

      <div class="container-search">
        <div class="search-box">
          <div class="search">
            <input
              type="text"
              matInput
              [placeholder]="placeholder"
              [(ngModel)]="searchTerm"
            />
            <mat-icon color="secondary">search</mat-icon>
          </div>
        </div>
      </div>

      <div class="container-tabs">
        <div class="tabs-block">
          <ng-container *ngFor="let obj of formConfig.dataTabs; let i = index">
            <button
              (click)="loadData(obj, i)"
              [ngClass]="obj.selected ? 'tab-selected' : 'tab'"
            >
              {{ obj.name }}
            </button>
          </ng-container>
        </div>
        <div class="tabs-info-icon">
          <button class="image-button-warn"></button>
        </div>
      </div>

      <div class="asset-list-block">
        <ng-container *ngIf="isFlow">
          <ng-container *ngFor="let item of filteredItems2">
            <div
              class="drag-drawflow"
              draggable="true"
              (dragstart)="callChildDragFunction($event)"
              [attr.data-id]="item.id"
              [attr.data-node]="item.inputValue"
              [attr.data-left]="item.topNodeValue"
              [attr.data-right]="item.bottomNodeValue"
              [attr.data-desc]="item.desc"
              [attr.data-remark]="item.remark"
            >
              <div class="asset-icon">
                <img src="../../assets/images/{{ item.img }}" alt="logo" />
              </div>
              <div class="asset-info">
                <h4 class="asset-title">
                  {{ item.inputValue }}
                </h4>
                <p class="asset-desc">
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isFlow">
          <ng-container *ngFor="let item of filteredItems">
            <div
              class="drag-drawflow"
              draggable="true"
              (dragstart)="callChildDragFunction($event)"
              [attr.data-id]="item.id"
              [attr.data-node]="item.inputValue"
              [attr.data-left]="item.topNodeValue"
              [attr.data-right]="item.bottomNodeValue"
            >
              <div class="asset-icon">
                <img src="../../assets/images/{{ item.img }}" alt="logo" />
              </div>
              <div class="asset-info">
                <h4 class="asset-title">
                  {{ item.inputValue }}
                </h4>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="drawflow-btn">
        <div *ngIf="false">
          <lib-button
            [btnLabel]="clipboardBottonLabel"
            [btnSize]="btnSize"
            [btnColor]="clearButtonColorSp"
            [buttonShow]="true"
            (click)="copyToClipboard()"
          >
          </lib-button>
        </div>
        <div>
          <lib-button
            [btnLabel]="clearButtonLabel"
            [btnSize]="btnSize"
            [btnColor]="clearButtonColorSp"
            [buttonShow]="true"
            (click)="clearNode()"
          >
          </lib-button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="draw-section"
    [ngClass]="{ styleWidth: isCollapsed, conditionWidth: isProductSelected }"
  >
    <ng-container #drawJs> </ng-container>
  </div>

  <div class="container-conf-drawflow" *ngIf="isProductSelected">
    <div class="conf-title border-bottom">
      <label class="title">{{ selectedProduct }} Config</label>
      <a class="close-panel" (click)="closePanel()">X</a>
    </div>
    <div class="conf-body">
      <p class="description">
        change and set your configuration for your {{ selectedProduct }}
      </p>
      <form
        class="config-form"
        [formGroup]="configForm"
        #configFormElement
        novalidate
      >
        <mat-label class="brd-clr label-12"
          >Name of {{ selectedProduct }}</mat-label
        >
        <br />
        <!-- <mat-form-field
          class="full-width mb-2 fieldClass"
          appearance="outline"
          [hideRequiredMarker]="hideRequiredControl.value"
          [floatLabel]="getFloatLabelValue()"
        >
          <input
            matInput
            type="text"
            placeholder=""
            required
            formControlName="text"
          />
          <mat-error *ngIf="configForm.controls['text'].hasError('required')">
            Please enter name
          </mat-error>
        </mat-form-field>
        <div>
          <mat-label class="brd-clr label-12">Email</mat-label>
          <br />
          <mat-form-field
            class="full-width mb-2 fieldClass"
            appearance="outline"
            [hideRequiredMarker]="hideRequiredControl.value"
            [floatLabel]="getFloatLabelValue()"
          >
            <input
              matInput
              type="email"
              placeholder=""
              required
              formControlName="email"
            />
            <mat-error
              *ngIf="configForm.controls['email'].hasError('required')"
            >
              Please enter an email
            </mat-error>
            <mat-error *ngIf="configForm.controls['email'].hasError('email')">
              Please enter a valid email
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-label class="brd-clr label-12">Description</mat-label>
          <br />
          <mat-form-field
            class="full-width mb-2 fieldClass"
            appearance="outline"
            [hideRequiredMarker]="hideRequiredControl.value"
            [floatLabel]="getFloatLabelValue()"
          >
            <input
              matInput
              placeholder=""
              required
              formControlName="description"
            />
            <mat-error
              *ngIf="configForm.controls['description'].hasError('required')"
            >
              Please enter a description
            </mat-error>
          </mat-form-field>
        </div> -->

        <div>
          <mat-label class="brd-clr label-12">QUANTITY</mat-label>
          <br />
          <mat-form-field
            class="full-wid quantity fieldClass"
            appearance="outline"
            [hideRequiredMarker]="hideRequiredControl.value"
            [floatLabel]="getFloatLabelValue()"
          >
            <input
              matInput
              type="number"
              placeholder=""
              required
              formControlName="quantity"
            />
            <mat-error
              class="quantilyErrorLabel"
              *ngIf="configForm.controls['quantity'].hasError('required')"
            >
              Please enter quantity
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mt-4 d-flex justify-content-end">
          <lib-button
            [btnLabel]="buttonLabel"
            [btnSize]="btnSize"
            [btnColor]="buttonColorSp"
            [btnType]="btnType"
            [disabled]="configForm.invalid"
            [buttonShow]="true"
            (click)="saveConfig()"
            [(spinner)]="spinner"
            (spinnerChange)="spinnerChange.emit($event)"
          >
          </lib-button>
        </div>
      </form>
    </div>
  </div>
</div>
