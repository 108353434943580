/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Client { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Client identifier
     */
    clientId: string;
    /**
     * Client name
     */
    name: string;
    /**
     * Client logo URL
     */
    logoUrl: string;
}

