import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatMenuModule,
} from '@angular/material/menu';

/**
 *Discount component
 */
@Component({
  selector: 'lib-discount',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
  templateUrl: './discount.component.html',
  styleUrls: [
    './discount.component.scss',
  ],
})
export class DiscountComponent {
  @Input() inputTitle = '';

  @Input() inputIcon: string[] = [
  ];

  @Input() inputDiscount = '';

  // @Input() inputIcon = '';

  selectedHeading: string | null = '';

  /**
   *onSelectHeading
   * @param headingLevel -headingLevel
   */
  onSelectHeading(headingLevel: string): void {
    this.selectedHeading = headingLevel;
  }
}
