/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemStepStateEnum } from './order-item-step-state-enum';


export interface OrderItemStep { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Client belongs to
     */
    clientId: string;
    /**
     * Brand belongs to
     */
    brandId: string;
    /**
     * Project identifier
     */
    projectId: string;
    /**
     * Order identifier
     */
    orderId: string;
    /**
     * Order Item identifier
     */
    orderItemId: string;
    /**
     * Order Item Step identifier
     */
    orderItemStepId: string;
    /**
     * Product Variant Id
     */
    productVariantStepDefinitionId: string;
    /**
     * Name of the Order Item Step
     */
    name: string;
    /**
     * Complexity of the Order Item step
     */
    complexity: number;
    /**
     * Durartion of the step
     */
    timeSpent: number;
    /**
     * Specifies if the Step is opertional
     */
    isOperational: boolean;
    /**
     * The order of the Order Item Step
     */
    sort: number;
    metaData: object;
    submissionData: object;
    state: OrderItemStepStateEnum;
}
export namespace OrderItemStep {
}


