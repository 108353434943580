import {
  Injectable,
} from '@angular/core';
import {
  ServiceTypes,
} from '../model/services-types';
import {
  StepConfig,
} from '../model/step-config';
import {
  Service,
} from '../model/service';

/**
 * Step Orchestrator Service
 */
@Injectable({
  providedIn: 'root',
})
export class StepsOrchestratorService {


  /**
   *
   * @param stepConfig Step Configuration Data
   * @param currentStep Current Step
   * @returns Service Service
   */
  public getCurrentStep(stepConfig: StepConfig[], currentStep: string): Service[] {
    return stepConfig.filter(steps => steps.slug == currentStep)
      .map((steps) => {
        return {
          'id':this.getIdByValue(steps.componentName),
          'config': steps.formConfig,
          'populateData': steps.formPopulateData,
        };
      } );
  }

  /**
   *
   * @param obj object
   * @returns boolen true/false
   */
  public isEmpty(obj: object): boolean {
    if (Object.keys(obj).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * This function return id of the given value for the ServiceType
   * @param value value
   * @returns Id Id
   */
  private getIdByValue(value: string): number {
    return  Object.values(ServiceTypes).indexOf(value as unknown as ServiceTypes);
  }



}
