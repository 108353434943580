import {
  ChangeDetectionStrategy, Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule,
} from '@angular/material/core';
import {
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  FormControl, FormGroup, FormsModule, ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MatLuxonDateModule,
} from '@angular/material-luxon-adapter';


const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD MMM. YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 *  Component Datepicker range
 */
@Component({
  selector: 'lib-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatLuxonDateModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE, useValue: 'es',
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS,
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {
        useUtc: true,
      },
    },
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: [
    './date-picker.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent {

  campaignOne = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });
}
