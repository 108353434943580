/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdentityProviderTypeEnum } from './identity-provider-type-enum';
import { IdentityProviderGoogleOptions } from './identity-provider-google-options';
import { IdentityProviderLevelEnum } from './identity-provider-level-enum';


export interface IdentityProvider { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Identity provider Identifier
     */
    identityProviderId: string;
    /**
     * Identity provider name
     */
    name: string;
    providerType: IdentityProviderTypeEnum;
    /**
     * The Identity provider url image
     */
    imageUrl: string;
    /**
     * The internal underlying identifier
     */
    internalId: string;
    providerLevel: IdentityProviderLevelEnum;
    /**
     * Identity provider level identifier, for the Client Identifier or Brand Identifier
     */
    providerLevelId: string;
    /**
     * Identify if this provider is enabled
     */
    enabled: boolean;
    /**
     * JSON formatted data
     */
    metaData: IdentityProviderGoogleOptions;
    /**
     * Start URL for get redirection to the authorization third party application
     */
    startUrl: string;
}
export namespace IdentityProvider {
}


