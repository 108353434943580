<div>
  <div
    class="progress-bar"
    *ngIf="type === 'determinate'; else interminateTemplate"
  >
    <mat-progress-bar
      mode="determinate"
      [value]="percentage"
    ></mat-progress-bar>
    <span class="per-value">{{ percentage }}%</span>
  </div>

  <ng-template #interminateTemplate>
    <div class="progress-bar">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-template>
</div>
