import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  FormsModule,
} from '@angular/forms';
import {
  MatCommonModule,
} from '@angular/material/core';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';

export interface FilterItem {
  value: string;
  viewValue: string;
}

/**
 * A filter-box component
 */
@Component({
  selector: 'lib-filterbox',
  templateUrl: './filterbox.component.html',
  styleUrls: [
    './filterbox.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBoxComponent {
  @Input() filterName!: string;

  @Input() placeHolder!: string;

  @Input() filterItems: FilterItem[] | undefined;

  @Input() filterValue = true;

  @Input() styleSelect = '';

  @Input() styleGeneral = '';

  @Output() filteredValue = new EventEmitter<string>();

  selectedValue!: string;

  selectedCar!: string;

  /**
   *
   * @param selection MatSelectChange
   */
  onSelectionChange(selection: MatSelectChange) {
    this.filteredValue.emit(selection.value);
  }
}
