import {
  Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-welcome-dashbord',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './welcome-dashboard.component.html',
  styleUrls: [
    './welcome-dashboard.component.scss',
  ],
})
export class WelcomeDashbordComponent {}
