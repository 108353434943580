import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  inject,
  Inject,
} from '@angular/core';
import {
  CommonModule,
  DOCUMENT,
} from '@angular/common';
import {
  LoaderComponent,
} from '@genera/ui-components';
import {
  OAuth2Service,
} from '@genera/genera-api-angular-sdk';
import {
  ContextStore,
  RestConfigurationProvider,
} from '@genera/ui-data-store';

/**
 * Logout page component
 */
@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
  ],
  providers: [
    RestConfigurationProvider,
    OAuth2Service,
  ],
  templateUrl: './logout.component.html',
  styleUrls: [
    './logout.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements AfterViewInit {

  readonly store = inject(ContextStore);

  /**
   * Constructor
   * @param oauthService OAuth Service for logout
   * @param document Document service for global navigation
   */
  constructor(
    private oauthService: OAuth2Service,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  /**
   * After view hook
   */
  ngAfterViewInit() {
    this.oauthService.oAuth2ControllerRevoke().subscribe({
      error: () => this.doLogout(),
      next: () => this.doLogout(),
    });
  }

  /**
   * Navigate to SSO frontend
   */
  private doLogout() {
    const ssoUrl = this.store.ssoUrl();
    this.store.clear();
    this.document.location.href = ssoUrl;
  }
}
