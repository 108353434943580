import {
  AfterViewInit,
  Component, ElementRef, EventEmitter, Inject, Input,
  Output, Renderer2, ViewChild, inject, signal,
} from '@angular/core';
import {
  CommonModule, DOCUMENT,
} from '@angular/common';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatSidenavModule,
} from '@angular/material/sidenav';

/**
 * Side bar panel component
 */
@Component({
  selector: 'lib-side-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './side-panel.component.html',
  styleUrls: [
    './side-panel.component.scss',
  ],
})
export class SidePanelComponent implements AfterViewInit {

  @Input() drawerSize = '45%';

  @Input() drawerTitle!: string;

  @Output() closePanel: EventEmitter<void> = new EventEmitter();

  @ViewChild('sideNavDrawer') sideNavDrawer!: ElementRef;

  isOpenPanel = signal(false);

  private renderer = inject(Renderer2);

  /**
   *
   * @param document document
   */
  constructor(@Inject(DOCUMENT) private document: Document) {}


  /**
   * Trriger after view initialization
   */
  ngAfterViewInit() {
    this.openNav();
  }


  /**
   * Open navigation bar panel with perticular size
   */
  openNav() {
    const mySidenavElement = this.sideNavDrawer.nativeElement as HTMLElement;
    this.renderer.setStyle(mySidenavElement, 'width', this.drawerSize);
    this.document.body.classList.add('no-scroll');
    setTimeout(() => {
      mySidenavElement.classList.add('opened');
      this.isOpenPanel.set(true);
    }, 300);
  }

  /**
   * Close navigation bar
   */
  closeNav() {
    const mySidenavElement = this.sideNavDrawer.nativeElement as HTMLElement;
    mySidenavElement.classList.remove('opened');
    this.renderer.setStyle(mySidenavElement, 'width', '0');
    this.document.body.classList.remove('no-scroll');
    // Close animation will take 0.5 second of close panel. So, after will trigger close event
    setTimeout(() => {
      this.isOpenPanel.set(false);
      this.closePanel.emit();
    }, 500);
  }
}
