import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MatIconModule,
} from '@angular/material/icon';

/**
 *
 */
@Component({
  selector: 'lib-contact-card',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
  ],
  templateUrl: './contact-card.component.html',
  styleUrls: [
    './contact-card.component.scss',
  ],
})
export class ContactCardComponent {
  @Input() cardIconColor!: string;

  @Input() cardName!: string;

  @Input() cardNumber!: string;

  @Input() cardIcon!: string;
}
