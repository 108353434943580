<div class="color-cardLabel">
  <label>{{ inputTitle }}</label>
</div>

<div class="color-card">
  <div class="left-side">
    <mat-icon>{{ inputIcon }}</mat-icon
    >{{ inputPrice }}
  </div>
  <div class="color-square">
    <div class="button-container">
      <p>{{ selectedHeading ? selectedHeading : 'Select' }}</p>
      <button mat-icon-button [matMenuTriggerFor]="headingMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <mat-menu #headingMenu="matMenu">
      <div *ngFor="let item of currencyCode">
        <button mat-menu-item (click)="onSelectHeading(item)">
          {{ item }}
        </button>
      </div>
    </mat-menu>
  </div>
</div>
