/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProjectCategories { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Project Category identifier
     */
    projectCategoryId: string;
    /**
     * Project Catgory name
     */
    name: string;
    /**
     * Description about the category
     */
    description: string;
    /**
     * Icon of the category
     */
    icon: string;
    /**
     * Specifies if the Category is enabled/disabled
     */
    enabled: boolean;
    /**
     * Parent Id
     */
    parentId: string;
}

