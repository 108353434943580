import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

export interface FrequencyCard {
  value: string;
  cardTitle: string;
  totalBudget: string;
  totalBudgetValue: string;
  spentBudget: string;
  spentBudgetValue: string;
  btnText: string;
  icon: string;
};

/**
 *
 */
@Component({
  selector: 'lib-frequency-infoblock',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './frequency-infoblock.component.html',
  styleUrls: [
    './frequency-infoblock.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrequencyInfoBlockComponent {
  @Input() frequencyInfoCards!: FrequencyCard[];
}
