import {
  ChangeDetectionStrategy,
  Component,
  inject,
} from '@angular/core';
import {
  RouterModule,
} from '@angular/router';
import {
  CommonModule,
} from '@angular/common';
import {
  HeaderBarComponent,
} from './component/header-bar/header-bar.component';
import {
  FooterBarComponent,
} from './component/footer-bar/footer-bar.component';
import {
  SideNavigationMenuComponent,
} from './component/side-navigation-menu/side-navigation-menu.component';
import {
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import {
  LoaderService,
  LoaderbarComponent,
  LoaderComponent,
} from '@genera/ui-components';

/**
 *This is a Main Application Component
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderBarComponent,
    FooterBarComponent,
    SideNavigationMenuComponent,
    MatSnackBarModule,
    LoaderbarComponent,
    LoaderComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'dashboard-frontend';

  loaderService = inject(LoaderService);
}
