import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *  Request approval component
 */
@Component({
  selector: 'lib-request-approval',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './request-approval.component.html',
  styleUrls: [
    './request-approval.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestApprovalComponent {

  @Input() heroIcon!: string;

  @Input() title!: string;

  @Input() description!: string;

  @Input() productIcon!: string;
}
