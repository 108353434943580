<lib-left-navigation
  [currentServiceMenu]="store.currentService()"
  [brandName]="brandName"
  [brandIcon]="brandIcon"
  [listItems]="listItems"
  [folder]="
    dataKey === 'people'
      ? peopleFolder
      : dataKey === 'message'
      ? messageFolder
      : dataKey === 'content'
      ? contentFolder
      : dataKey === 'plan'
      ? projectFolder
      : dataKey === 'insights'
      ? insightsFolder
      : folder
  "
  [upload]="
    dataKey === 'people'
      ? peopleUpload
      : dataKey === 'message'
      ? messageUpload
      : dataKey === 'content'
      ? contentUpload
      : dataKey === 'plan'
      ? projectUpload
      : dataKey === 'insights'
      ? insightsUpload
      : upload
  "
  [extractFile]="
    dataKey === 'people'
      ? peopleExtractFile
      : dataKey === 'message'
      ? messageExtractFile
      : dataKey === 'content'
      ? contentExtractFile
      : dataKey === 'plan'
      ? projectExtractFile
      : dataKey === 'insights'
      ? insightsExtractFile
      : extractFile
  "
  [docItemsMenu]="
    dataKey === 'people'
      ? peopleDocItemsMenu
      : dataKey === 'message'
      ? messageDocItemsMenu
      : dataKey === 'content'
      ? contentDocItemsMenu
      : dataKey === 'plan'
      ? projectDocItemsMenu
      : dataKey === 'insights'
      ? insightsDocItemsMenu
      : docItemsMenu
  "
  [commonNavLists]="commonNavLists"
  (keyData)="handleKeyData(store.currentService().toLowerCase())"
  (menubar)="handleItemClicked($event)"
>
</lib-left-navigation>
