<div class="card-contact">
  <div class="card-body">
    <div class="card-content">
      <div>
        <h4 class="contact-title">{{ cardName }}</h4>
        <div class="contact-value">{{ cardNumber }}</div>
      </div>
      <div class="icon-box">
        <img src="{{ cardIcon }}" alt="list icon" />
      </div>
    </div>
  </div>
</div>
