import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';

/**
 *
 */
@Component({
  selector: 'lib-drive-type',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './drive-type.component.html',
  styleUrls: [
    './drive-type.component.scss',
  ],
})
export class DriveTypeComponent {

  @Input() menuTitle = 'Type';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() docItems!: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() dataClicked: EventEmitter<any> = new EventEmitter<any>();

  /**
   * onItemClicked
   * @param $event event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemClicked($event: any): void {
    this.itemClicked.emit($event);
  }

}
