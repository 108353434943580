import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  CardProvidersComponent,
} from '../card-providers/card-providers.component';
import {
  CardAssetComponent,
} from '../card-asset/card-asset.component';
import {
  CardMultiMediaComponent,
} from '../card-multimedia/card-multimedia.component';
import {
  PeopleSmallCardComponent,
} from '../people-small-card/people-small-card.component';
import {
  CardQuickAccessComponent,
} from '../card-quick-access/card-quick-access.component';
import {
  CardAssetDetailComponent,
} from '../card-asset-detail/card-asset-detail.component';
import {
  MenuComponent,
} from '../menu/menu.component';

export interface AssetMenuItem {
  label: string;
  link: string ;
}

/**
 * Component card type
 */
@Component({
  selector: 'lib-card-type',
  standalone: true,
  imports: [
    CommonModule,
    CardProvidersComponent,
    CardAssetComponent,
    CardMultiMediaComponent,
    PeopleSmallCardComponent,
    CardQuickAccessComponent,
    CardAssetDetailComponent,
    MenuComponent,
  ],
  templateUrl: './card-type.component.html',
  styleUrls: [
    './card-type.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTypeComponent {

  @Input() type : string | undefined;

  @Input() icon : string | undefined;

  @Input() title : string | undefined;

  @Input() description : string | undefined;

  @Input() name : string | undefined;

  @Input() id : string | undefined;

  @Input() image : string | undefined;

  @Input() duration : string | undefined;

  @Input() creator : string | undefined;

  @Input() status : string | undefined;

  @Input() dateTime : string | undefined;

  @Input() cardId : string | undefined;

  @Input() menuItems2: AssetMenuItem[] | undefined;

  isActive = false;

  /**
   *
   */
  toggleActive(): void {
    this.isActive = !this.isActive;
  }
}
