<div class="container-list">
  <div class="container-title">
    <div class="title-1">
      <label class="lbl-title">Upcoming Emails</label>
    </div>
    <div class="title-2">
      <button class="image-button"></button>
    </div>
  </div>
  <div class="step-progress">
    <ng-container *ngFor="let obj of list; let last = last">
      <div class="step">
        <div class="container-txt-title">
          <span class="txt-title">{{ obj.title }}</span>
        </div>
        <div class="container-txt">
          <span class="txt-description">{{ obj.description }}</span>
          <span class="txt-description2">{{ obj.description2 }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
