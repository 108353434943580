<div class="dashboard" id="dashboard-intro">
  <div class="dashboard-image">
    <img src="../../assets/imaegs/welcome-icon.png" alt="Welcome" />
  </div>
  <h2 class="dashboard-title">
    Welcome to <span>{{ username }},</span>
  </h2>

  <p class="dashboard-description mb-3">
    Thank you for choosing Genera services,<br />
    You have successfully signed up for <b>Genera</b>.
  </p>
  <p class="dashboard-description">
    To Start, please use any of the actions on the left panel.
  </p>
  <div class="btn">
    <lib-button
      [btnColor]="btnColor"
      [btnSize]="btnSize"
      [btnLabel]="btnLabel"
      [buttonShow]="true"
    ></lib-button>
  </div>
</div>
