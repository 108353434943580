import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  RouterModule,
} from '@angular/router';
export interface MenuItem {
  label: string;
  link: string ;
}
/**
 * Menu component
 */
@Component({
  selector: 'lib-menu',
  templateUrl: './menu.component.html',
  styleUrls: [
    './menu.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class MenuComponent {

  @Input() menuItems: MenuItem[] | undefined;

  @Input()  menuCustomsClass!: string;
}
