import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input,
  OnChanges,
  OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  FormioForm,
} from '@formio/angular';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';
import {
  FormioComponent,
} from './formio/formio.component';

/**
 * Form Render Component to load form io component dynamically
 */
@Component({
  selector: 'lib-form-render',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './form-render.component.html',
  styleUrls: [
    './form-render.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRenderComponent implements OnInit, OnChanges {


  @Input() formConfig! : FormioForm;

  @Input() formPopulateData! : FormPopulateData;

  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  @Output() customEvent = new EventEmitter();

  @ViewChild('formComponent', {
    read: ViewContainerRef,
  })
  formComponent!: ViewContainerRef;


  public childRef!: ComponentRef<FormioComponent>;

  /**
   * Component constructor
   * @param changeRef Service to Trigger detection
   */
  constructor(private changeRef: ChangeDetectorRef) { }

  /**
   * OnInit Function
   */
  ngOnInit(): void {
    if (this.formConfig?.components && this.formConfig?.components?.length > 0) {
      this.loadForm();
    }

  }

  /**
   * Load Form Io Render Component
   */
  async loadForm() {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FormioComponent,
    } = await import('./formio/formio.component');
    this.formComponent.clear();
    this.childRef =  this.formComponent.createComponent(FormioComponent);
    this.childRef.setInput('formConfig', this.formConfig);
    this.childRef.setInput('formPopulateData', this.formPopulateData);
    this.childRef.instance.formSubmitted.subscribe((val: FormioForm) => this.formData.emit(val));
    this.childRef.instance.formChangeEvent.subscribe((val: FormioForm) => this.formChange.emit(val));
    this.childRef.instance.customFormEvent.subscribe((val: FormioForm) => this.customEvent.emit(val));
    this.changeRef.markForCheck();
  }

  /**
   *
   * @param changes changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formConfig'] && changes['formConfig'].currentValue) {
      this.formConfig = changes['formConfig'].currentValue;
      this.loadForm();
    }
  }

  /**
   * Call the formio component custom submit Method
   */
  public customSubmitFormio():void {
    this.childRef.instance.onCustommSubmit();

  }

}
