<div class="card">
  <div class="card-body">
    <div class="card-content">
      <div class="action-bar">
        <lib-menu [menuItems]="menuItems"> </lib-menu>
      </div>
      <div class="channel-body">
        <span class="channel-icon">
          <img class="card-image" src="{{ icon }}" alt="{{ title }}" />
        </span>
        <div class="channel-info">
          <h4 class="channel-name">{{ title }}</h4>
          <div class="channel-id">ID: {{ cardId }}</div>
        </div>
      </div>
      <div class="description">{{ description }}</div>
    </div>
  </div>
</div>
