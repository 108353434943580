import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 * Footer component to show the service name and it's version
 */
@Component({
  selector: 'lib-footer',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() serviceName! : string;

  @Input() year! : number;

  @Input() version! : string;
}
