<div class="service-menu">
  <span class="client-name" (click)="toggleMenu()">
    <span class="icon-brand">
      <img
        *ngIf="selectedBrand?.imageUrl; else defaultImage"
        [src]="selectedBrand?.imageUrl"
        alt="Brand Icon"
      />

      <ng-template #defaultImage>
        <img
          src="../../assets/images/default-brand-icon.png"
          alt="Default Icon"
        />
      </ng-template>
    </span>
    {{ selectedBrand?.brandName || '' }}
    <mat-icon
      aria-hidden="false"
      aria-label="arrow drop down"
      fontIcon="arrow_drop_down"
    ></mat-icon>
  </span>

  <div class="client-dropdown" *ngIf="displayMenu">
    <a *ngIf="selectedStep > 1" (click)="setBackStep()" class="back">
      <mat-icon
        aria-hidden="true"
        class="material-icons-outlined"
        aria-label="arrow circle left"
        fontIcon="arrow_circle_left"
      ></mat-icon
      >Back
    </a>
    <div *ngIf="selectedStep === 1" class="step-one">
      <ul class="client-links">
        <li *ngFor="let client of clientDetails">
          <a href="javascript:void(0);" (click)="selectClient(client)">
            <i
              ><img
                [src]="client?.imageUrl"
                [alt]="client?.clientName || ''"
                [title]="client?.clientName || ''"
            /></i>
            {{ client?.clientName || '' }}
          </a>
        </li>
      </ul>
      <div
        class="nav-footer"
        *ngIf="(clientDetails?.length || 0) <= defaultList"
      >
        Clients
      </div>
      <div class="nav-footer" *ngIf="clientDetails.length > defaultList">
        <a href="javascript:void(0);">See all Clients</a>
      </div>
    </div>

    <div *ngIf="selectedStep === 2" class="step-two">
      <ul class="client-links">
        <li *ngFor="let brand of brandDetails">
          <a href="javascript:void(0);" (click)="selectBrand(brand)">
            <i
              ><img
                [src]="brand?.imageUrl || ''"
                [alt]="brand?.brandName || ''"
                [title]="brand?.brandName || ''"
            /></i>
            {{ brand?.brandName || '' }}
          </a>
        </li>
      </ul>
      <div
        class="nav-footer"
        *ngIf="(brandDetails?.length || 0) === defaultList"
      >
        Brands
      </div>
      <div class="nav-footer" *ngIf="brandDetails.length > defaultList">
        <a href="javascript:void(0);">See all brands</a>
      </div>
    </div>

    <div *ngIf="selectedStep === 3" class="step-three">
      <ul class="client-links">
        <li *ngFor="let service of serviceDetails">
          <a href="javascript:void(0);" (click)="selectService(service)">
            <i
              ><img
                [src]="service?.imageUrl"
                [alt]="service?.serviceName || ''"
                [title]="service?.serviceName || ''"
            /></i>
            {{ service?.serviceName || '' }}
          </a>
        </li>
      </ul>
      <div class="nav-footer">Services</div>
    </div>
  </div>
</div>
