/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateConfigDto { 
    /**
     * Client belongs to
     */
    clientId: string;
    /**
     * Brand belongs to
     */
    brandId: string;
    /**
     * Provider belongs to
     */
    providerId: string;
    /**
     * Channel belongs to
     */
    channelId: string;
    metadata: object;
}

