// card-container-list.component.ts
import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  CardAssetDetailComponent,
} from '../card-asset-detail/card-asset-detail.component';
import {
  AssetMenuItem, CardTypeComponent,
} from '../card-type/card-type.component';

export interface CardOption {
  cardId: string;
  title: string;
  menuItems: AssetMenuItem[];
  description?: string;
  icon?: string;
  status?: string;
  type?: string;
}
/**
 * A Card list component using SCAM methodology
 */
@Component({
  selector: 'lib-card-container-list',
  standalone: true,
  templateUrl: './card-container-list.component.html',
  styleUrls: [
    './card-container-list.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CardAssetDetailComponent,
    CardTypeComponent,
  ],
})

export class CardContainerListComponent {

  @Input() cardOptions!: CardOption[] | undefined;

  @Input() sliceCard = this.cardOptions?.length;

  /**
   *
   * @param cardOption card option
   */
}
