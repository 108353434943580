import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-people-small-card',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './people-small-card.component.html',
  styleUrls: [
    './people-small-card.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleSmallCardComponent {
  @Input() icon : string | undefined;

  @Input() title : string | undefined;

  @Input() description : string | undefined;

  selectedIcon = '';

  /**
   *
   * @param icon icon
   */
  selectIcon(icon: string ) {
    this.selectedIcon = icon;
  }
}
