import {
  computed,
  inject,
  InjectionToken,
} from '@angular/core';
import {
  signalStore,
  patchState,
  withComputed,
  withMethods,
  withState,
  withHooks,
} from '@ngrx/signals';
import {
  rxMethod,
} from '@ngrx/signals/rxjs-interop';
import {
  debounceTime,
  map,
  pipe,
  switchMap,
  tap,
  of,
  forkJoin,
} from 'rxjs';
import {
  tapResponse,
} from '@ngrx/operators';
import {
  TenantsService,
  Tenant,
} from '@genera/top-layer-angular-sdk';
import {
  Configuration,
  OAuth2Service,
  User,
  Brand,
  Client,
  BrandsService,
  ClientsService,
} from '@genera/genera-api-angular-sdk';
import {
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Apollo,
} from 'apollo-angular';

const DEFAULT_LOGO = 'https://admin.wsuite.com/assets/images/icons/icon-admin.svg';
export interface ContextStoreData {
  topLayerUrl: string;
  dashboardUrl: string;
  ssoUrl: string;
  tenant: Tenant;
  client: Client;
  brand: Brand;
  user: User | undefined;
  lastError?: HttpErrorResponse;
  status: 'none' | 'in-progress' | 'ready';
  currentService: string;
  currentLogo: string;
}

const INITIAL_STATE: ContextStoreData = {
  tenant: {
    tenantId: '',
    restUrl: '',
    name: '',
    graphQlUrl: '',
    updatedAt: '',
    createdAt: '',
  },
  client: {
    clientId: '',
    createdAt: '',
    name: '',
    updatedAt: '',
    logoUrl: '',
  },
  brand: {
    brandId: '',
    createdAt: '',
    logoUrl: '',
    name: '',
    updatedAt: '',
    clientId: '',
  },
  user: undefined,
  lastError: undefined,
  topLayerUrl: '',
  dashboardUrl: '',
  ssoUrl: '',
  status: 'none',
  currentService: 'genera',
  currentLogo: DEFAULT_LOGO,
};

export const SSO_URL = new InjectionToken<string>('sso.url');

export const DASHBOARD_URL = new InjectionToken<string>('dashboard.url');

export const TOP_LAYER_URL = new InjectionToken<string>('toplayer.url');

export const ContextStore = signalStore(
  {
    providedIn: 'root',
  },

  withState<ContextStoreData>(INITIAL_STATE),

  withComputed(({
    tenant,
    status,
  }) => ({
    graphQlUrl: computed(() => tenant?.()?.graphQlUrl),
    restUrl: computed(() => tenant?.()?.restUrl),
    hasUrls: computed(() => !!(tenant?.()?.graphQlUrl && tenant?.()?.restUrl)),
    initialized: computed(() => status() === 'ready'),
    initInProgress: computed(() => status() === 'in-progress'),
    restConfiguration: computed(() => {
      return new Configuration({
        withCredentials: true,
        basePath: tenant?.()?.restUrl || '',
      });
    }),
  })),

  withMethods((store,
               tenantsService: TenantsService = inject(TenantsService),
               authService: OAuth2Service = inject(OAuth2Service),
               brandService: BrandsService = inject(BrandsService),
               clientService: ClientsService = inject(ClientsService),
               ssoUrl: string = inject(SSO_URL),
               dashboardUrl: string = inject(DASHBOARD_URL),
               topLayerUrl: string = inject(TOP_LAYER_URL),
  ) => ({

    init: rxMethod<void>(
      pipe(
        debounceTime(300),
        tap(() => {
          tenantsService.configuration.basePath = topLayerUrl;
          tenantsService.configuration.withCredentials = true;
          patchState(store, {
            topLayerUrl,
            ssoUrl,
            dashboardUrl,
            status: 'in-progress',
          });
        }),
        switchMap(() =>
          tenantsService.tenantsControllerIntrospect().pipe(
            map(res => res?.data || undefined),
            map(res => {
              if (res?.graphQlUrl) {
                res.graphQlUrl = res.graphQlUrl.endsWith('graphql') ? res.graphQlUrl : `${res.graphQlUrl}/graphql`;
              }
              return res;
            }),
            tapResponse({
              next: (tenant) => {
                patchState(store, {
                  tenant,
                });
              },
              error: (error: HttpErrorResponse) => {
                // eslint-disable-next-line no-console
                console.error(`Error getting tenant - ${error?.statusText}`);
                patchState(store, {
                  lastError: error?.error,
                  status: 'ready',
                });
              },
            }),
            switchMap((tenant) => {
              if (!tenant?.tenantId || !tenant?.restUrl || !tenant?.graphQlUrl) {
                return of(undefined);
              }
              brandService.configuration.basePath = tenant.restUrl;
              brandService.configuration.withCredentials = true;

              clientService.configuration.basePath = tenant.restUrl;
              clientService.configuration.withCredentials = true;

              authService.configuration.basePath = tenant.restUrl;
              authService.configuration.withCredentials = true;
              return authService.oAuth2ControllerUserInfo();
            }),
            map(res => res?.data || undefined),
            tapResponse({
              next: (user) => {
                patchState(store, {
                  user,
                });
              },
              error: (error: HttpErrorResponse) => {
                // eslint-disable-next-line no-console
                console.error(`Error getting userInfo - ${error?.statusText}`);
                patchState(store, {
                  lastError: error?.error,
                  status: 'ready',
                });
              },
            }),
            switchMap((user) => {
              if (user?.preferences?.preferredBrandId && user?.preferences?.preferredClientId) {
                return forkJoin([
                  brandService.brandsControllerFindOne(
                    user.preferences.preferredClientId,
                    user.preferences.preferredBrandId,
                  ),
                  clientService.clientsControllerFindOne(
                    user.preferences.preferredClientId,
                  ),
                ]);
              }
              return of([
                undefined,
                undefined,
              ]);
            }),
            map(([
              responseBrand,
              responseClient,
            ]) => {
              return {
                client: responseClient?.data || undefined,
                brand: responseBrand?.data || undefined,
              };
            }),
            tapResponse({
              next: (clientBrand) => {
                patchState(store, {
                  brand: clientBrand.brand,
                  client: clientBrand.client,
                });
              },
              error: (error: HttpErrorResponse) => {
                // eslint-disable-next-line no-console
                console.error(`Error getting userInfo - ${error?.statusText}`);
                patchState(store, {
                  lastError: error?.error,
                });
              },
              finalize: () => {
                patchState(store, {
                  status: 'ready',
                });
              },
            }),
          ),
        ),
      ),
    ),

    /**
     * Set current service data
     * @param serviceName The service name
     * @param logo The logo URL for the service
     */
    selectService(serviceName: string, logo: string | undefined) {
      patchState(store, {
        currentService: serviceName.toLowerCase(),
        currentLogo: logo || store.currentLogo(),
      });
    },

    /**
     * Set current client and brand
     * @param client The client data
     * @param brand The brand data
     */
    selectClientBrand(client: Client, brand: Brand) {
      patchState(store, {
        client,
        brand,
      });
    },

    /**
     * Clear current state
     */
    clear() {
      patchState(store, INITIAL_STATE);
    },
  })),

  withHooks({
    /**
     * Init the context store
     * @param store The store object
     * @param store.init The init method
     */
    onInit({
      init,
    }) {
      init();
    },
  }),
);

export const GraphQLConfigurationProvider = Apollo;

export const RestConfigurationProvider = {
  provide: Configuration,
  useFactory: () => {
    const store = inject(ContextStore);
    return store.restConfiguration();
  },
};
