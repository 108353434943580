/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,EventEmitter,inject, Inject, Input, Output, signal, ViewChild,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  DialogRef,
  DIALOG_DATA,
} from '@angular/cdk/dialog';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  VideoPlayerComponent,
} from '../video-player/video-player.component';
import {
  NgxDocViewerModule,
} from 'ngx-doc-viewer';
import {
  SidePanelComponent,
} from '../side-panel/side-panel.component';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  COMMA, ENTER,
} from '@angular/cdk/keycodes';
import {
  LiveAnnouncer,
} from '@angular/cdk/a11y';
import {
  MatChipsModule,
} from '@angular/material/chips';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  ChipsWithInputComponent,
} from '../chips-with-input/chips-with-input.component';
import {
  MenuIconComponent, MenuList,
} from '../menu-icon/menu-icon.component';
export interface Fruit {
  name: string;
}

/**
 *FilePopupComponent
 */
@Component({
  selector: 'lib-file-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    VideoPlayerComponent,
    NgxDocViewerModule,
    SidePanelComponent,
    MatDividerModule,
    ButtonComponent,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    ChipsWithInputComponent,
    MenuIconComponent,
  ],
  templateUrl: './file-popup.component.html',
  styleUrls: [
    './file-popup.component.scss',
  ],
})
export class FilePopupComponent {

  @Input() iconName!: string;

  @Input() detailsData: any;

  @Input() chipTagFirst!: any[];

  @Input() chipTagSecond!: any[];

  @Input() chipTagThird!: any[];

  @Input() menuList!: MenuList[];

  @ViewChild(SidePanelComponent) sidePanel!: SidePanelComponent;

  @Output() openSharePopUpInPopup = new EventEmitter<any>();

  isOpenPanel = signal(false);

  btnLabel = 'ADD TAGS';

  iconUrl = 'add';

  fontIcon = 'add';

  btnColor = 'blue-outline';

  btnSize = 'sm';

  sideBarShow = false;

  addOnBlur = true;

  placeHolder = 'Enter tag name';

  CancelButtonLabel = 'CANCEL';

  CancelButtonColor = 'grey';

  buttonLabel = 'SAVE';

  buttonColor = 'green';

  readonly separatorKeysCodes = [
    ENTER,
    COMMA,
  ] as const;

  generalTags: Fruit[] = [
    {
      name: 'League',
    },
    {
      name: 'Fútbol',
    },
    {
      name: 'Champions',
    },
  ];

  announcer = inject(LiveAnnouncer);

  /**
   *constructor
   * @param dialogRef -di
   * @param data -dat
   */
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any,
  ) {
  }

  /**
   *onDetailsClick
   */
  onDetailsClick() {
    this.openSidebarForFilesDetails();
    this.detailsData = this.data.item;
  }

  /**
   *openSidebarForFilesDetails
   */
  openSidebarForFilesDetails(): void {
    if (this.sidePanel) {
      this.sidePanel.openNav();
    }
  }

  /**
   *buttonClick
   */
  buttonClick() {
    this.sideBarShow = true;
  }

  /**
   *onCancelClick
   */
  onCancelClick() {
    this.sidePanel.closeNav();
    this.sideBarShow = false;
  }

  /**
   *
   */
  closePanel() {
    this.sideBarShow = false;
  }

  /**
   *handelMenuListClicked
   * @param item -item
   * @param index -i
   */
  handelMenuListClicked(item: MenuList, index: any) {
    if (item.label === 'Details') {
      this.onDetailsClick();
      this.isOpenPanel = signal(true);
    } else if (item.label === 'Share') {
      this.openSharePopUpInPopup.emit(index);
    }
  }
};
