/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderItemStepStateEnum = 'pending' | 'ready' | 'progress' | 'done' | 'cancelled';

export const OrderItemStepStateEnum = {
    Pending: 'pending' as OrderItemStepStateEnum,
    Ready: 'ready' as OrderItemStepStateEnum,
    Progress: 'progress' as OrderItemStepStateEnum,
    Done: 'done' as OrderItemStepStateEnum,
    Cancelled: 'cancelled' as OrderItemStepStateEnum
};

