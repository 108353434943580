import {
  ChangeDetectionStrategy, Component, Inject, Input,
} from '@angular/core';
import {
  CommonModule, DOCUMENT,
} from '@angular/common';
export interface serviceRow1 {
  mainText: string;
  subText: string;
  serviceRow2?: serviceRow2[];
};
export interface serviceRow2 {
  text: string;
  link: string;
};
export interface appService {
  icon: string;
  text: string;
  serviceRow1?: serviceRow1[];
}

/**
 * Dashboard component having the sidebar with the services
 */
@Component({
  selector: 'lib-dashboardsidenav',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './dashboardsidenav.component.html',
  styleUrls: [
    './dashboardsidenav.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardSidenavComponent {

  @Input()  sideNavHeadIcon! : string;

  @Input()  title! : string;

  @Input()  linkName! : string;

  @Input()  linkUrl! : string;

  @Input() appServices: appService[] | undefined;

  isOpenMenu = true;

  isCollapsed = true;

  /**
   *
   * @param document document
   */
  constructor( @Inject(DOCUMENT) private document: Document) {}



  /**
   * To toogle the menu
   */
  hideShowMenu() {
    if (!this.isOpenMenu) {
      this.isOpenMenu = true;
    } else {
      this.isOpenMenu = false;
    }
    this.isCollapsed = true;
    this.document.body.classList.toggle('collapse-sidebar');
  }


}
