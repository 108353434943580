import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 * Page not found component to be served when path will be mismatched from given routes.
 */
@Component({
  selector: 'lib-page-not-found',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {

  @Input() notFoundImage! : string;

  @Input() errorNumber! : number;

  @Input() errorMessage! : string;

  @Input() errorDescription! : string;

  @Input() moveToPageName! : string;

  @Input() moveToPageLink! : string;

  @Input() requestAccessLink! : string;

}

