import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import {
  FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  RouterModule,
} from '@angular/router';
import {
  WelcomeComponent,
} from '../welcome/welcome.component';

/**
 * A forgot password component with SCAM methodology
 */
@Component({
  selector: 'lib-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonComponent,
    WelcomeComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ForgotPasswordComponent implements OnInit {


  @Input() description : string | undefined;

  @Input() title : string | undefined;

  @Input() placeholderMobile : string | undefined;

  @Input() placeholderEmail : string | undefined;

  @Input() submitText : string | undefined;

  @Input() companyName!: string;

  @Input() spinner = false;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() forgotPassword = new EventEmitter<string>();

  @Input() brandLogo! : string;

  @Input() brandText! : string;

  hide = true;

  forgotPasswordForm!: FormGroup;

  buttonLabel = 'CONTINUE';

  buttonColor = 'blue';

  btnSize = 'md';

  btnType = 'submit';


  /**
   *
   * @param _formBuilder formBuilder
   */
  constructor ( private _formBuilder: FormBuilder) {}

  /**
   * Password validator pattern for formbuilder
   */
  ngOnInit() {
    this.forgotPasswordForm = this._formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /(^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$)/,
          ),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /(^\+?\d{10,14}$)/,
          ),
        ],
      ],
    });
  }

  /**
   * Form Submit Function
   */
  onSubmit() {
    this.forgotPasswordForm.disable({
      emitEvent: false,
    });
    this.forgotPassword.emit(this.forgotPasswordForm.controls['email'].value);
    this.forgotPassword.emit(this.forgotPasswordForm.controls['phone'].value);
  }
}
