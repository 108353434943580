<div class="dynamic-cards">
  <mat-card class="card" *ngFor="let item of cardDetails">
    <div class="card-item">
      <div class="file-icon">
        <img src="{{ item.Name?.img }}" alt="image" />
      </div>
      <div class="file-title">
        {{ item.Name?.name }}
      </div>
      <lib-menu-icon
        *ngIf="item.action && item.action.length > 0"
        [iconName]="iconName"
        [menuList]="menuList"
        (menuListClicked)="handleMenuClick($event, item)"
      ></lib-menu-icon>
    </div>
    <mat-card-content>
      <img
        mat-card-image
        [src]="item?.thumbnailImage"
        alt="image"
        (click)="openDialog(item)"
      />
    </mat-card-content>
    <div class="card-end">
      {{ item.LastModified?.date }}
    </div>
  </mat-card>
</div>
