import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FloatLabelType, MatFormFieldModule,
} from '@angular/material/form-field';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import {
  CommonModule,
} from '@angular/common';
import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  RouterModule,
} from '@angular/router';
import {
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  Subscription,
} from 'rxjs';
import {
  WelcomeComponent,
} from '../welcome/welcome.component';
import {
  LoginWelcomeComponent,
} from '../login-welcome/login-welcome.component';

export interface PasswordCredentials {
  email: string;
  password: string;
  remember: boolean;
}

/**
 * Login component
 */
@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonComponent,
    WelcomeComponent,
    LoginWelcomeComponent,
  ],
  providers: [
    provideAnimations(),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy, OnChanges {
  @Input() logoUrl : string | undefined;

  @Input() sortName : string | undefined;

  @Input() serviceName : string | undefined;

  @Input() back : string | undefined;

  @Input() linkURI : string | undefined;

  @Input() password : string | undefined;

  @Input() code : string | undefined;

  @Input() forgotPasswordText: string | undefined;

  @Input() forgotPasswordLink: string | undefined;

  @Input() submitText : string | undefined;

  @Input() placeholderEmail : string | undefined;

  @Input() rememberMe : string | undefined;

  @Input() parentError!: string;

  @Input() spinner = false;

  @Input() companyName!: string;

  @Input() accountName!: string;

  @Input() account!: string;

  @Output() spinnerChange = new EventEmitter<boolean>();

  @Output() passwordCredentials = new EventEmitter<PasswordCredentials>();

  @Input() brandText! : string;

  @Input() infoText! : string;

  @Input() introTitle!: string;

  @Input() introDescription! : string;

  @Input() quoteFirst!: string;

  @Input() quoteTwo! : string;

  @Input() quoteThree!: string;

  @Input() brandLogo!: string;

  hide = true;

  hideRequiredControl = new FormControl(false);

  floatLabelControl = new FormControl('auto' as FloatLabelType);

  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  loginForm!: FormGroup;

  buttonLabel = 'LOGIN';

  buttonColor = 'blue';

  btnSize = 'md';

  btnType = 'submit';

  private subscription$!: Subscription;

  /**
   * Constructor
   * @param _formBuilder FormBuilder
   */
  constructor(private _formBuilder: FormBuilder) {}

  /**
   * formGroup
   */
  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
        ],
      ],
      password: [
        '',
        [
          Validators.required,
        ],
      ],
      remember: [
        false,
      ],
    });

    this.subscription$ = this.loginForm.valueChanges.subscribe(() => {
      if (this.parentError) {
        this.parentError = '';
        this.loginForm.controls['password'].updateValueAndValidity();
        this.loginForm.controls['email'].updateValueAndValidity();
      }
    });
  }

  /**
   * Component on destroy
   */
  ngOnDestroy() {
    if (this.subscription$)
      this.subscription$.unsubscribe();
  }

  /**
   * Form Submit Function
   */
  onSubmit() {
    this.loginForm.disable({
      emitEvent: false,
    });
    this.passwordCredentials.emit(this.loginForm.value as PasswordCredentials);
  }

  /**
   * @returns value
   */
  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  /**
   * Detect Changer for setting an error from parent component
   * @param changes The Angular SimpleChanges object
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parentError'] && !changes['parentError'].firstChange && !this.spinner) {
      this.loginForm.enable({
        emitEvent: false,
      });
      if (!changes['parentError'].currentValue) {
        this.loginForm.controls['password'].updateValueAndValidity();
        this.loginForm.controls['email'].updateValueAndValidity();
      } else {
        this.loginForm.controls['password'].setErrors({
          parentError: changes['parentError'].currentValue,
        });
        this.loginForm.controls['password'].markAsTouched();
        this.loginForm.controls['email'].setErrors({
          parentError: changes['parentError'].currentValue,
        });
        this.loginForm.controls['email'].markAsTouched();
      }
    }

    if (changes['spinner'] && !changes['spinner'].firstChange && !changes['spinner'].currentValue) {
      this.loginForm.enable({
        emitEvent: false,
      });
      if (this.parentError) {
        this.loginForm.controls['password'].setErrors({
          parentError: this.parentError,
        });
        this.loginForm.controls['password'].markAsTouched();
        this.loginForm.controls['email'].setErrors({
          parentError: this.parentError,
        });
        this.loginForm.controls['email'].markAsTouched();
      }
    }
  }
}

