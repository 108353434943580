/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  DriveComponent,
} from '../drive-component/drive-component.component';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatButtonModule,
} from '@angular/material/button';

/**
 *
 */
@Component({
  selector: 'lib-drive-button-drop-down',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    DriveComponent,
    MatDividerModule,
    MatMenuModule,
    MatButtonModule,
  ],
  templateUrl: './drive-button-drop-down.component.html',
  styleUrls: [
    './drive-button-drop-down.component.scss',
  ],
})
export class DriveButtonDropDownComponent {
  @ViewChild('myDrive', {
    static: true,
    read: ViewContainerRef,
  })
  myDrive!: ViewContainerRef;

  @Input() docItems!: string[];

  @Input() docItemsMenu!: any[];

  @Input() folder!: any[];

  @Input() upload!: any[];

  @Input() extractFile!: any[];

  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  isSelected = false;

  /**
   *
   * @param item -item
   */
  onItemClicked(item: any): void {
    this.itemClicked.emit(item);
  }

  /**
   *
   */
  toggleComponent() {
    this.isSelected = !this.isSelected;
  }
}
