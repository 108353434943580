<div class="card">
  <div class="card-body">
    <span class="channel-icon">
      <img class="card-image" src="{{ icon }}" alt="{{ name }}" />
    </span>
  </div>
  <div class="card-footer">
    <span>{{ name }}</span>
  </div>
</div>
