/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPreferences } from './user-preferences';
import { UserAddress } from './user-address';


export interface User { 
    /**
     * User Identifier
     */
    sub: string;
    /**
     * User\'s name
     */
    name: string;
    /**
     * User\'s Given Name
     */
    given_name: string;
    /**
     * User\'s Family Name
     */
    family_name: string;
    /**
     * User\'s Middle Name
     */
    middle_name: string;
    /**
     * User\'s nickname
     */
    nickname: string;
    /**
     * User\'s username, required for refresh token grant flow
     */
    username: string;
    /**
     * User\'s Preferred username
     */
    preferred_username: string;
    /**
     * User\'s profile
     */
    profile: string;
    /**
     * User\'s picture
     */
    picture: string;
    /**
     * User\'s website
     */
    website: string;
    /**
     * User\'s email
     */
    email: string;
    /**
     * Specify if user\'s email is verified
     */
    email_verified: boolean;
    /**
     * User\'s gender
     */
    gender: string;
    /**
     * User\'s birthday
     */
    birthday: string;
    /**
     * User\'s Zone Info
     */
    zoneinfo: string;
    /**
     * User\'s locale info
     */
    locale: string;
    /**
     * User\'s Phone number
     */
    phone_number: string;
    /**
     * Specify if user\'s phone number is verified
     */
    phone_number_verified: boolean;
    address: UserAddress;
    /**
     * User\'s last updated at date
     */
    updated_at: string;
    /**
     * User\'s Level access
     */
    tenantAccessLevel: string;
    /**
     * Identifier for the tenantAccessLevel, in other words, tenantId or clientId or brandId
     */
    tenantAccessLevelId: string;
    /**
     * Specified if the user is root
     */
    isRoot: boolean;
    preferences: UserPreferences;
}

