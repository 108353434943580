<mat-form-field class="class-datepicker-form-field">
  <mat-date-range-input
    class="class-daterange"
    [formGroup]="campaignOne"
    [rangePicker]="campaignOnePicker"
  >
    <input matStartDate placeholder="Start date" formControlName="start" />
    <input matEndDate placeholder="End date" formControlName="end" />
  </mat-date-range-input>
  <mat-datepicker-toggle
    class="class-datepicker"
    matIconSuffix
    [for]="campaignOnePicker"
  ></mat-datepicker-toggle>
  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
</mat-form-field>
