import {
  ENVIRONMENT,
} from '../../environments/environment';
import {
  ContextStore,
  GraphQLConfigurationProvider,
  DASHBOARD_URL,
  SSO_URL,
  TOP_LAYER_URL,
} from '@genera/ui-data-store';
import {
  APOLLO_OPTIONS,
} from 'apollo-angular';
import {
  HttpLink,
} from 'apollo-angular/http';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import {
  inject,
  APP_INITIALIZER,
} from '@angular/core';
import {
  skipWhile,
  lastValueFrom,
  interval,
  take,
} from 'rxjs';

// This provider is needed to get proper injection at Guard, it also requires the same provider
// to be declared at bootstrap (app.config.ts), There should be a way to avoid declaring it in
// both places
export const RouteProviders = [
  // APP_INITIALIZER is one of the most important providers, as it ensures the root store is initialized for the
  // entire application
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      const rootStore = inject(ContextStore);
      const promiseWaitCondition = lastValueFrom(
        interval(200)
          .pipe(
            skipWhile(() => {
              return !rootStore.initialized();
            }),
            take(1),
          ),
      );

      return () => promiseWaitCondition;
    },
    multi: true,
  },
  {
    provide: SSO_URL,
    useValue: ENVIRONMENT.ssoURL,
  },
  {
    provide: TOP_LAYER_URL,
    useValue: ENVIRONMENT.topLayerBaseURL,
  },
  {
    provide: DASHBOARD_URL,
    useValue: ENVIRONMENT.dashboardURL,
  },
  // Apollo Global Configuration
  {
    provide: APOLLO_OPTIONS,
    useFactory: (
      httpLink: HttpLink,
    ): ApolloClientOptions<unknown> => {
      const rootStore = inject(ContextStore);
      return {
        link: ApolloLink.from([
          httpLink.create({
            uri: rootStore.tenant?.()?.graphQlUrl,
          }),
        ]),
        cache: new InMemoryCache(),
      };
    },
    deps: [
      HttpLink,
    ],
  },
  GraphQLConfigurationProvider,
  // The REST Global configuration can not be set here as it will generate circular DI, as ContextStore depends on the
  // REST API for User introspection, So Rest configuration is setup on lib.routes of each micro frontend
];
