/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderItemStateEnum = 'confirmed' | 'planned' | 'progress' | 'done' | 'cancelled';

export const OrderItemStateEnum = {
    Confirmed: 'confirmed' as OrderItemStateEnum,
    Planned: 'planned' as OrderItemStateEnum,
    Progress: 'progress' as OrderItemStateEnum,
    Done: 'done' as OrderItemStateEnum,
    Cancelled: 'cancelled' as OrderItemStateEnum
};

