import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-empty-page',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './empty-page.component.html',
  styleUrls: [
    './empty-page.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyPageComponent {
  @Input() heroIcon!: string | undefined;

  @Input() title!: string | undefined;

  @Input() description!: string | undefined;
}
