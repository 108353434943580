<div class="container-donut">
  <div class="container-title">
    <div class="title-1">
      <label class="lbl-title">Behavior</label>
    </div>
    <div class="title-2">
      <button class="image-button"></button>
    </div>
  </div>
  <lib-filterbox
    [filterValue]="false"
    [styleSelect]="'class-card-donut'"
    [styleGeneral]="'class-general'"
    [filterName]="''"
    [placeHolder]="PlaceHolder"
    [filterItems]="filterItems"
  ></lib-filterbox>
  <div class="card-donut-lbl">
    <lib-chart-donut
      [data]="data"
      [totalValue]="totalValue"
      [width]="130"
      [textSeccion]="false"
      [textEmpty]="''"
      [colorBackground]="'#FFFFFF'"
      [colorEmpty]="'#E8E8E8'"
    ></lib-chart-donut>
    <div class="class-lbl">
      <label class="lbl-porc">5.47%</label>
      <label class="lbl-identify">Identified</label>
    </div>
  </div>
  <div class="container container-princ">
    <div class="section-1">
      <div class="circle-princ"></div>
    </div>
    <div class="section-2">
      <label class="lbl-txt-values">Identified:</label>
    </div>
    <div class="section-3">
      <label class="lbl-values">7x | .47%</label>
    </div>
  </div>

  <div class="container">
    <div class="section-1">
      <div class="circle"></div>
    </div>
    <div class="section-2">
      <label class="lbl-txt-values">Anonymous:</label>
    </div>
    <div class="section-3">
      <label class="lbl-values">121x | 4.53%</label>
    </div>
  </div>
</div>
