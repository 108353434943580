import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  RouterModule,
} from '@angular/router';

export type DetailsType = 'Campaign' | 'Asset';

/**
 * campaign detail component
 */
@Component({
  selector: 'lib-campaign-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './campaign-detail.component.html',
  styleUrls: [
    './campaign-detail.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignDetailComponent {

  @Input() logoUrl!: string;

  @Input() campaignName!: string;

  @Input() campaignId!: string;

  @Input() campaignDate!: string;

  @Input() downloadUrl!: string;

  @Input() downloadIcon!: string;

}
