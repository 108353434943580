<div class="search-bar">
  <mat-form-field
    appearance="outline"
    class="search-hover splwidth"
    [ngClass]="{ 'confitional-Width': showCard }"
  >
    <button mat-icon-button matPrefix>
      <mat-icon color="secondary">{{ matPrefixIcon }}</mat-icon>
    </button>
    <input
      type="text"
      matInput
      [placeholder]="placeholder"
      (click)="toggleCardVisibility()"
    />

    <ng-container *ngIf="showCard">
      <div class="custom-card">
        <mat-divider></mat-divider>
        <div class="search-bar-option-row">
          <div class="img-container" *ngFor="let option of userIcon">
            <div class="icon-container">
              <img [src]="option.img" alt="txt-copia" />
            </div>
            <div class="name-size">
              <p class="text-span">{{ option.name }}</p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="search-bar-option-row">
          <div class="docs-container" *ngFor="let option of userDocs">
            <div class="doc-container doc-border">
              <img
                [src]="option.doc"
                alt="txt-copia"
                style="height: 17.5px; width: 12.5px"
              />
            </div>
            <div class="doc-size">
              <p class="doc-span">{{ option.name }}</p>
            </div>
          </div>
        </div>
        <mat-divider class="divide"></mat-divider>
        <div class="button-class">
          <a mat-flat-button href="" target="_blank">{{ button }}</a>
        </div>
      </div>
    </ng-container>
    <button mat-icon-button matSuffix>
      <mat-icon color="primary">{{ matSuffixIcon }}</mat-icon>
    </button>
  </mat-form-field>
</div>
