import {
  Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatRadioModule,
} from '@angular/material/radio';
import {
  MatMenuModule,
} from '@angular/material/menu';
import {
  MatSelectModule,
} from '@angular/material/select';
import {
  NgxAngularQueryBuilderModule,
  QueryBuilderConfig,
} from 'ngx-angular-query-builder';
import {
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  ButtonComponent,
} from '../button/button.component';

interface FormConfig {
  query: {
    condition: 'and' | 'or';
    rules: QueryRule[];
  };
  config: {
    fields: {
      [fieldName: string]: FieldConfig;
    };
  };
}

interface QueryRule {
  field: string;
  operator: '=' | '<' | '<=' | '>' | '>=';
  value: string | number;
}

interface FieldConfig {
  name: string;
  type: 'number' | 'category'; // Add more types if needed
  options?: CategoryOption[];
}

interface CategoryOption {
  name: string;
  value: string;
}

/**
 *
 */
@Component({
  selector: 'lib-query-builder-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatRadioModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxAngularQueryBuilderModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    ButtonComponent,
  ],
  templateUrl: './query-builder-filter.component.html',
  styleUrls: [
    './query-builder-filter.component.scss',
  ],
})
export class QueryBuilderFilterComponent implements OnInit {

  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  @Input() formConfig!: FormConfig;

  @Input() formPopulateData!: FormPopulateData;

  formsDetails = new FormControl();

  buttonLabel = 'Process';

  btnSize = 'sm';

  buttonColor = 'blue';

  public currentConfig: QueryBuilderConfig | undefined;

  public allowRuleset = true;

  public allowCollapse: boolean | undefined;

  public persistValueOnFieldChange = false;

  /**
   *
   * @param cdr -cdr
   */
  constructor(private cdr: ChangeDetectorRef) { }

  /**
   *
   */
  ngOnInit(): void {
    const conditionValue = this.formPopulateData?.data?.['condition'] as unknown as string;
    this.formConfig.query.condition = conditionValue === 'and' || conditionValue === 'or' ? conditionValue : 'and';
    this.formConfig.query.rules = (this.formPopulateData?.data?.['rules'] as QueryRule[]) || [
    ];
    this.detectChanges();
    this.detectChange();
  }


  /**
   *
   */
  detectChanges() {
    this.formsDetails.valueChanges.subscribe((value) => {
      if (this.formsDetails.valid) {
        const changeData = {
          isModified : true,
          isValid: true,
          value : value,
        };
        this.formChange.emit(changeData);
      } else {
        const changeData = {
          isModified : true,
          isValid: true,
          value : value,
        };
        this.formChange.emit(changeData);
      }
    });
  }

  /**
   *
   */
  private detectChange() {
    this.cdr.detectChanges();
  }
}
