import {
  CanActivateFn,
  Router,
} from '@angular/router';
import {
  inject,
} from '@angular/core';
import {
  skipWhile,
  of,
  switchMap,
  interval,
  take,
} from 'rxjs';
import {
  ContextStore,
} from '../../state/context/context.store';

export const IsAuthenticatedGuard: CanActivateFn = (route, state) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const context: any = inject(ContextStore);
  const router: Router = inject(Router);

  return interval(200)
    .pipe(
      skipWhile(() => {
        return !context.initialized();
      }),
      take(1),
      switchMap(() => {
        const isAuthenticated = !!(context.user?.() && context.tenant?.());
        if (isAuthenticated) {
          return of(isAuthenticated);
        }
        const redirectUri = context.dashboardUrl() + state.url;
        if (context.ssoUrl()?.startsWith('http')) {
          const ssoFullUrl = new URL(context.ssoUrl());
          ssoFullUrl.searchParams.append('redirect_uri', redirectUri);
          document.location.href = ssoFullUrl.toString();
          return of(false);
        } else {
          return router.navigate([
            context.ssoUrl(),
          ], {
            queryParams: {
              redirect_uri: state.url,
            },
          });
        }
      }),
    );
};
