<div class="card fs-6 text">
  <div class="card-header fw-bolder">General Configuration</div>
  <div class="card-body text-dark">
    <ng-container *ngFor="let obj of list; let last = last">
      <p class="card-text fw-bolder text">
        {{ obj.title }}:<span class="fw-light">{{ obj.description }}</span>
      </p>
    </ng-container>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-sm-6">
        <div class="step">
          <div class="container-txt-title fw-bolder .fs-5">
            <span class="txt-title">Test Result</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container-txt text-muted text-end">
          <span class="txt-description">
            {{
              testResult === 'TRUE' ? 'Send and received' : 'no success'
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
