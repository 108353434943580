<div class="auth-container">
  <div class="auth-inner">
    <div class="company-name">
      Welcome <span>{{ companyName }}</span>
    </div>
    <mat-card class="card card-login">
      <form class="auth-form" [formGroup]="forgotPasswordForm">
        <h4>{{ title }}</h4>
        <p class="mb-4">{{ description }}</p>
        <mat-form-field class="full-width">
          <input
            matInput
            type="text"
            [placeholder]="placeholderMobile || ''"
            formControlName="phone"
          />
          <mat-error
            *ngIf="forgotPasswordForm.controls['phone'].hasError('required')"
          >
            Please enter your mobile number
          </mat-error>
          <mat-error
            *ngIf="forgotPasswordForm.controls['phone'].hasError('pattern')"
          >
            Please enter a valid mobile number
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width mb-3">
          <input
            matInput
            type="text"
            [placeholder]="placeholderEmail || ''"
            formControlName="email"
          />
          <mat-error
            *ngIf="forgotPasswordForm.controls['email'].hasError('required')"
          >
            Please enter your email
          </mat-error>
          <mat-error
            *ngIf="forgotPasswordForm.controls['email'].hasError('pattern')"
          >
            Please enter a valid email
          </mat-error>
        </mat-form-field>
        <lib-button
          [btnLabel]="buttonLabel"
          [btnSize]="btnSize"
          [btnColor]="buttonColor"
          [btnType]="btnType"
          [disabled]="forgotPasswordForm.invalid"
          [buttonShow]="true"
          (eventData)="onSubmit()"
          [(spinner)]="spinner"
          (spinnerChange)="spinnerChange.emit($event)"
        >
        </lib-button>
      </form>
    </mat-card>
  </div>
</div>
