import {
  Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *Quantity component
 */
@Component({
  selector: 'lib-quantity',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './quantity.component.html',
  styleUrls: [
    './quantity.component.scss',
  ],
})
export class QuantityComponent {
  @Input() inputTitle = '';

  @Input() inputQuantity: number | null = null;

  /**
   *increment quantity
   */
  incrementQuantity() {
    if (this.inputQuantity !== null) {
      this.inputQuantity++;
    }
  }
}
