<div
  class="card card-big"
  *ngIf="type === 'cardBig'"
  [ngClass]="{ 'card-active': isActive }"
  (click)="toggleActive()"
>
  <div class="card-body">
    <span class="icon-check"
      ><img src="../../assets/images/icon-check-white.svg" alt="icon-check"
    /></span>
    <img class="card-image" src="{{ icon }}" alt="{{ title }}" />
  </div>
  <div class="card-footer">
    <h4 class="card-title">{{ title }}</h4>
    <p class="card-description">
      {{ description }}
    </p>
  </div>
</div>

<div
  class="card card-small"
  *ngIf="type === 'cardSmall'"
  [ngClass]="{ 'card-active': isActive }"
  (click)="toggleActive()"
>
  <div class="card-body">
    <span class="icon-check"
      ><img src="../../assets/images/icon-check-white.svg" alt="icon-check"
    /></span>
    <h4 class="card-title">{{ title }}</h4>
    <p class="card-description">
      {{ description }}
    </p>
  </div>
</div>

<ng-container *ngIf="type === 'assetCard'">
  <lib-card-asset
    icon="{{ icon }}"
    name="{{ name }}"
    id="{{ id }}"
    title="{{ title }}"
  ></lib-card-asset>
</ng-container>

<ng-container *ngIf="type === 'providersCard'">
  <lib-card-providers
    icon="{{ icon }}"
    title="{{ title }}"
  ></lib-card-providers>
</ng-container>

<ng-container *ngIf="type === 'multimediaCard'">
  <lib-card-multimedia
    image="{{ image }}"
    icon="{{ icon }}"
    title="{{ title }}"
    duration="{{ duration }}"
    creator="{{ creator }}"
  ></lib-card-multimedia>
</ng-container>

<ng-container *ngIf="type === 'peoplesmallCard'">
  <lib-people-small-card
    icon="{{ icon }}"
    title="{{ title }}"
    description="{{ description }}"
  ></lib-people-small-card>
</ng-container>

<ng-container *ngIf="type === 'quickaccessCard'">
  <lib-card-quick-access
    icon="{{ icon }}"
    status="{{ status }}"
    title="{{ title }}"
    description="{{ description }}"
  ></lib-card-quick-access>
</ng-container>

<ng-container *ngIf="type === 'assetDetailCard'">
  <lib-card-asset-detail
    [menuItems]="menuItems2"
    icon="{{ icon }}"
    title="{{ title }}"
    cardId="{{ cardId }}"
    description="{{ description }}"
  ></lib-card-asset-detail>
</ng-container>
