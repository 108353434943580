import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *  Component card list
 */
@Component({
  selector: 'lib-card-list',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './card-list.component.html',
  styleUrls: [
    './card-list.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListComponent {
  @Input() list: { title: string; description: string; description2: string }[] = [
  ];
}
