<div class="welcome-block">
  <div class="welcome-img">
    <img src="../../assets/images/welcome-image.svg" alt="Welcome" />
  </div>
  <div class="welcome-content">
    <h3>Welcome Andres Hernández</h3>
    <p>
      Empower your marketing strategy with PEOPLE's insightful dashboards,
      providing real-time behavioral data for precise targeting and impactful
      campaigns.
    </p>
  </div>
</div>
