import {
  NgModule,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  FormErrorMessageComponent,
} from './form-error-message.component';

/**
 *
 */
@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FormErrorMessageComponent,
  ],
  exports: [
    FormErrorMessageComponent,
  ],
})
export class FormErrorMessageComponentModule {}
