/**
 * Genera Top Layer API
 * Genera Top Layer API allow clients to read its configurations and enabled services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RefreshTokenGrantDto { 
    /**
     * refresh token grant type identifier
     */
    grantType: string;
    /**
     * The application client id, if empty server will use its internal default value
     */
    clientId?: string;
    /**
     * The application client secret, if empty server will use internal default value
     */
    clientSecret?: string;
    /**
     * The refresh token for getting a new pair of refresh token access token
     */
    refreshToken: string;
    /**
     * The username for the given user, it is system username and it is not always the email, so a userInfo call must be done
     */
    username: string;
}

