<mat-card class="analysis-card">
  <mat-card-header class="analysis-card-header">
    <mat-grid-list cols="2" rowHeight="40px" *ngIf="selectedRowInfo">
      <mat-grid-tile class="left-column-content">
        <mat-card-title class="analysis-card-title">{{
          generalTitle
        }}</mat-card-title>
      </mat-grid-tile>
      <mat-grid-tile class="right-column-content">
        <div
          class="docs-api-modifier-method-marker"
          [style.backgroundColor]="'blue'"
        >
          {{ selectedRowInfo.Status }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="left-column-content">
        <ng-container
          *ngIf="
            moreInfoActiveId === 0 && moreInfoActive;
            else noDataFoundTemplate0
          "
        >
          <div class="container-txt">
            <span class="fw-light txt-description">Creation date:</span>
            <span class="txt-description2">{{
              selectedRowInfo.CreationDate
            }}</span>
          </div>
          <div class="container-txt">
            <span class="fw-light txt-description">Creation by:</span>
            <span class="txt-description2">{{
              selectedRowInfo.Owner?.name
            }}</span>
          </div>
          <div class="container-txt">
            <span class="fw-light txt-description">Description:</span>
            <span class="txt-description2">{{
              selectedRowInfo.Description
            }}</span>
          </div>
          <u class="more-info" (click)="moreInfoClicked(0)"> - Less info</u>
        </ng-container>
        <ng-template #noDataFoundTemplate0>
          <u class="more-info" (click)="moreInfoClicked(0)"> + More info</u>
        </ng-template>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="2" rowHeight="80px">
      <mat-grid-tile class="fw-bold left-column-content"
        ><p class="title-analysis">List of analysis runs</p></mat-grid-tile
      >
      <mat-grid-tile class="right-column-content">
        <p class="fw-light text">
          Result:<span class="fw-bold title-analysis">3 Executions</span>
        </p></mat-grid-tile
      >
      <mat-grid-tile class="left-column-content"
        ><lib-date-picker> </lib-date-picker
      ></mat-grid-tile>
      <mat-grid-tile class="right-column-content">
        <form [formGroup]="forms">
          <div class="form-group">
            <label for="category">Filter by</label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="categories"
                placeholder="All Categories"
                (selectionChange)="changeCategory($event)"
                required
              >
                <mat-option
                  *ngFor="let category of categories"
                  [value]="category"
                  >{{ category.name }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="forms.get('categories')?.hasError('required')">
                Category selection is required
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
  <mat-card-footer>
    <div *ngFor="let content of filteredContent; let i = index">
      <mat-grid-list cols="2" rowHeight="35px">
        <!--first row-->
        <mat-grid-tile class="left-column-content">
          <p class="fw-bolder title-analysis">{{ content?.title }}</p>
        </mat-grid-tile>
        <mat-grid-tile class="right-column-content">
          <!--small button-->
          <div
            class="docs-api-modifier-method-marker"
            [style.backgroundColor]="content?.smallButton?.color"
          >
            {{ content?.smallButton?.text }}
          </div>
          <!--big button-->
          <div
            class="docs-api-modifier-method-marker"
            [style.backgroundColor]="content?.bigButton?.color"
          >
            {{ content?.bigButton?.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="3" rowHeight="80px">
        <!--second row-->
        <mat-grid-tile class="left-column-content">
          <div class="container-txt">
            <span class="fw-light txt-description">Date runner:</span>
            <span class="txt-description2">{{ content?.dateRunner }}</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="container-txt">
            <span class="fw-light txt-description">Executed by:</span>
            <span class="txt-description2">{{ content?.executedBy }}</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="right-column-content">
          <div class="container-txt">
            <span class="fw-light txt-description">Execution time:</span>
            <span class="txt-description2">{{ content?.executionTime }}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <u class="more-info"> + More info</u>
      <mat-divider></mat-divider>
    </div>
  </mat-card-footer>
</mat-card>
