/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateProjectDto { 
    /**
     * Project name
     */
    name?: string;
    /**
     * Description about the Project
     */
    description?: string | null;
    /**
     * project Stage Id in order to keep track of the current project stage
     */
    currentStageId?: string;
    /**
     * Specifies if the Project is enabled/disabled
     */
    enabled?: boolean;
    /**
     * ISO Date Date when Project is going to be release / live
     */
    releaseDate?: string;
    /**
     * Status of Project
     */
    status?: string;
    /**
     * Current Project Stage Name
     */
    currentStageName?: string;
}

