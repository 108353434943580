<div>
  <div class="newButton" [matMenuTriggerFor]="menu2">
    <mat-icon class="fs-20-grey" (click)="toggleComponent()">add</mat-icon>
    <div class="text">New</div>
  </div>
  <mat-menu class="style1" #menu2="matMenu">
    <div
      class="dropDownContent"
      *ngFor="let item of folder"
      (click)="onItemClicked(item)"
    >
      <div class="margin-icon">
        <mat-icon class="material-icons-outlined dropdown-icon"
          >{{ item.icon }}
        </mat-icon>
      </div>
      <div class="toggle-text">{{ item.folderName }}</div>
    </div>
    <mat-divider></mat-divider>
    <div
      class="dropDownContent"
      *ngFor="let item of upload"
      (click)="onItemClicked(item)"
    >
      <div class="margin-icon">
        <mat-icon class="material-icons-outlined dropdown-icon"
          >{{ item.icon }}
        </mat-icon>
      </div>
      <div class="toggle-text">{{ item.uploadFileName }}</div>
    </div>
    <mat-divider></mat-divider>
    <div
      class="dropDownContent"
      *ngFor="let item of docItemsMenu"
      (click)="onItemClicked(item)"
    >
      <div class="content">
        <div class="icon margin-icon">
          <mat-icon class="doc-icon dropdown-icon">{{ item.icon }}</mat-icon>
        </div>
        <div class="toggle-text">{{ item.docName }}</div>
      </div>
      <div class="action-icon">
        <mat-icon [matMenuTriggerFor]="menu3" class="fs-20-grey2"
          >play_arrow</mat-icon
        >
      </div>
    </div>
  </mat-menu>
  <mat-menu class="style1" #menu3="matMenu">
    <div class="menuDrive">
      <div class="dropDownContent" *ngFor="let item of extractFile">
        <div class="icon margin-icon">
          <mat-icon class="doc-icon">{{ item.icon }}</mat-icon>
        </div>
        <div class="toggle-text">{{ item.uploadFileName }}</div>
      </div>
    </div>
  </mat-menu>
</div>
