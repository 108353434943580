/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IdentityProviderTypeEnum = 'google' | 'facebook' | 'apple' | 'openid' | 'saml' | 'internal';

export const IdentityProviderTypeEnum = {
    Google: 'google' as IdentityProviderTypeEnum,
    Facebook: 'facebook' as IdentityProviderTypeEnum,
    Apple: 'apple' as IdentityProviderTypeEnum,
    Openid: 'openid' as IdentityProviderTypeEnum,
    Saml: 'saml' as IdentityProviderTypeEnum,
    Internal: 'internal' as IdentityProviderTypeEnum
};

