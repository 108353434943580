<div class="qb-container">
  <div class="qb-inner">
    <query-builder
      [formControl]="formsDetails"
      [(ngModel)]="formConfig.query"
      [config]="formConfig.config"
    >
      <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
        <button type="button" mat-button (click)="removeRule(rule)">
          <mat-icon>close</mat-icon>
        </button>
      </ng-container>
      <ng-container *querySwitchGroup="let ruleset">
        <div class="radio-add-group">
          <div class="addiconMain">
            <div
              class="addiconGroup"
              *queryButtonGroup="
                let ruleset;
                let addRule = addRule;
                let addRuleSet = addRuleSet;
                let removeRuleSet = removeRuleSet
              "
            >
              <button mat-button [matMenuTriggerFor]="menuRule">
                <div class="add-button">
                  <mat-icon>add</mat-icon
                  ><span class="text-filter">ADD FILTERS</span>
                </div>
              </button>
              <mat-menu #menuRule="matMenu">
                <button mat-menu-item (click)="addRule()">+ Rule</button>
                <button mat-menu-item (click)="addRuleSet()">+ Ruleset</button>
                <button mat-menu-item (click)="removeRuleSet()">
                  - Ruleset
                </button>
              </mat-menu>
            </div>
          </div>
          <mat-radio-group *ngIf="ruleset" [(ngModel)]="ruleset.condition">
            <mat-radio-button value="and">And</mat-radio-button>
            <mat-radio-button value="or">Or</mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>
      <ng-container
        *queryField="let rule; let fields = fields; let onChange = onChange"
      >
        <mat-form-field>
          <mat-select
            [(ngModel)]="rule.field"
            (ngModelChange)="onChange($event, rule)"
          >
            <mat-option *ngFor="let field of fields" [value]="field.value">{{
              field.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *queryOperator="let rule; let operators = operators">
        <mat-form-field>
          <mat-select [(ngModel)]="rule.operator">
            <mat-option *ngFor="let value of operators" [value]="value">{{
              value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *queryInput="let rule; type: 'boolean'">
        <mat-checkbox [(ngModel)]="rule.value"></mat-checkbox>
      </ng-container>
      <ng-container
        *queryInput="
          let rule;
          let field = field;
          let options = options;
          type: 'category'
        "
      >
        <mat-form-field>
          <mat-select [(ngModel)]="rule.value" [placeholder]="field.name">
            <mat-option *ngFor="let opt of options" [value]="opt.value">
              {{ opt.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      ...
    </query-builder>
  </div>
</div>
<div class="margin-button">
  <lib-button
    [btnLabel]="buttonLabel"
    [btnIcon]="false"
    [btnSize]="btnSize"
    [btnColor]="buttonColor"
    [buttonShow]="true"
  >
  </lib-button>
</div>
