<div class="asset-nav">
  <div class="search-box" *ngIf="false">
    <lib-searchbox [placeholder]="searchBoxPlaceholder"></lib-searchbox>
  </div>
  <mat-accordion>
    <mat-expansion-panel
      class="expansion-panel"
      *ngFor="let list of listItems; let i = index"
      [disabled]="!list.multilistItem"
    >
      <mat-expansion-panel-header class="panel-header-body">
        <a [attr.href]="!list.multilistItem ? list.link : null">
          <i class="asset-icon"
            ><img src="{{ list.icon }}" alt="list icon"
          /></i>
          <span>{{ list.label }}</span>
          <span *ngIf="list.link" class="icon-check"
            ><img
              src="https://plan.wsuite.com/assets/images/icons/check.svg"
              alt="check icon"
          /></span>
        </a>
      </mat-expansion-panel-header>
      <ul class="sub-nav">
        <li *ngFor="let ch of list.multilistItem">
          <a href="{{ ch.link }}" class="active-menu">
            <i class="asset-icon"><img src="{{ ch.icon }}" alt="icon" /></i>
            <span class="notranslate">{{ ch.label }}</span>
            <span class="number">{{ ch.number }}</span>
          </a>
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="request-btn">
    <lib-button
      [btnLabel]="buttonLabel"
      [btnSize]="btnSize"
      [btnColor]="buttonColor"
      [btnType]="btnType"
      [buttonShow]="true"
      (eventData)="onSubmit()"
      [(spinner)]="spinner"
      (spinnerChange)="spinnerChange.emit($event)"
    >
    </lib-button>
  </div>
  <div class="right-sidebar" [ngClass]="isOpenPanel ? 'closed' : 'open'">
    <div class="campaign-order-panel">
      <div class="close-icon">
        <a (click)="onClose()"
          ><img src="../../assets/images/icon-close.svg" alt="close"
        /></a>
      </div>
      <div class="co-head">
        <h3>REQUEST TO CHANGE THE PROJECT AND ORDER</h3>
        <p>You can select the project and order from here and press go</p>
      </div>
      <div class="co-selection">
        <div class="co-filter">
          <lib-filterbox
            [placeHolder]="placeHolderFilterCampaign"
            [filterItems]="filterCampaignVal"
            (filteredValue)="onFilterSelection($event, 'project')"
          ></lib-filterbox>
        </div>

        <div class="co-filter">
          <lib-filterbox
            [placeHolder]="placeHolderFilterOrder"
            [filterItems]="filterOrderVal"
            (filteredValue)="onFilterSelection($event, 'order')"
          ></lib-filterbox>
        </div>
      </div>
      <div class="co-btn">
        <lib-button
          [btnLabel]="buttonCoLabel"
          [btnSize]="btnSize"
          [btnColor]="buttonCoColor"
          [btnType]="btnType"
          [buttonShow]="true"
          [(spinner)]="spinner"
          (click)="onRedirection()"
          (spinnerChange)="spinnerChange.emit($event)"
        >
        </lib-button>
      </div>
    </div>
  </div>
</div>
