export * from './brands.service';
import { BrandsService } from './brands.service';
export * from './channels.service';
import { ChannelsService } from './channels.service';
export * from './clients.service';
import { ClientsService } from './clients.service';
export * from './config.service';
import { ConfigService } from './config.service';
export * from './identity-providers.service';
import { IdentityProvidersService } from './identity-providers.service';
export * from './o-auth2.service';
import { OAuth2Service } from './o-auth2.service';
export * from './order-item-step-activities.service';
import { OrderItemStepActivitiesService } from './order-item-step-activities.service';
export * from './product-categories.service';
import { ProductCategoriesService } from './product-categories.service';
export * from './product-variant-steps-definitions.service';
import { ProductVariantStepsDefinitionsService } from './product-variant-steps-definitions.service';
export * from './product-variants.service';
import { ProductVariantsService } from './product-variants.service';
export * from './products.service';
import { ProductsService } from './products.service';
export * from './project-categories.service';
import { ProjectCategoriesService } from './project-categories.service';
export * from './project-stages-definitions.service';
import { ProjectStagesDefinitionsService } from './project-stages-definitions.service';
export * from './project-steps-definitions.service';
import { ProjectStepsDefinitionsService } from './project-steps-definitions.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './providers.service';
import { ProvidersService } from './providers.service';
export const APIS = [BrandsService, ChannelsService, ClientsService, ConfigService, IdentityProvidersService, OAuth2Service, OrderItemStepActivitiesService, ProductCategoriesService, ProductVariantStepsDefinitionsService, ProductVariantsService, ProductsService, ProjectCategoriesService, ProjectStagesDefinitionsService, ProjectStepsDefinitionsService, ProjectsService, ProvidersService];
