/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateProjectStageDefinitionDto { 
    /**
     * Project Stage name
     */
    name: string;
    /**
     * Description about the Project stages
     */
    description: string;
    /**
     * Icon of the stage
     */
    icon: string;
    /**
     * Specifies if the Project Stages is enabled/disabled
     */
    enabled: boolean;
    /**
     * Project Categories Id the Project Stages belongs to
     */
    projectCategoryId: string;
    /**
     * Order of the Project Stage
     */
    order: number;
}

