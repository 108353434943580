import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  WelcomeComponent,
} from '../welcome/welcome.component';
import {
  RouterModule,
} from '@angular/router';
import {
  LoginWelcomeComponent,
} from '../login-welcome/login-welcome.component';

export interface LoginOption {
  imageUrl: string;
  name: string;
  optionLink: string;
  linkBackground?: string;
}

/**
 * A login flow component using SCAM methodology
 */
@Component({
  selector: 'lib-login-flow',
  templateUrl: './login-flow.component.html',
  styleUrls: [
    './login-flow.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    WelcomeComponent,
    RouterModule,
    LoginWelcomeComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LoginFlowComponent  {
  @Input() imageUrl: string | undefined;

  @Input() name: string | undefined;

  @Input() loginOptions!: LoginOption[] | undefined;

  @Output() clicked = new EventEmitter<LoginOption>();

  @Input() companyName!: string;

  @Input() infoText! : string;

  @Input() back : string | undefined;

  @Input() linkURI : string | undefined;

  @Input() introTitle!: string;

  @Input() introDescription! : string;

  @Input() quoteFirst!: string;

  @Input() quoteTwo! : string;

  @Input() quoteThree!: string;

  @Input() brandLogo!: string;
}
