<div class="dialog-header">
  <span class="title-text" mat-dialog-title>{{ data?.item?.Name?.name }}</span>
  <div class="icons">
    <lib-menu-icon
      [iconName]="data?.iconName"
      [menuList]="data?.menuList"
      (menuListClicked)="handelMenuListClicked($event, data?.item)"
    ></lib-menu-icon>
    <mat-icon class="actionIcon" (click)="dialogRef.close()">close</mat-icon>
  </div>
</div>

<div mat-dialog-content *ngIf="data?.item?.type === 'image'">
  <img [src]="data?.item?.thumbnailImage" alt="data.item.Name?.name" />
</div>

<div
  mat-dialog-content
  *ngIf="data?.item?.type === 'video'"
  class="video-player-class"
>
  <lib-video-player [videoLink]="data?.item?.file"></lib-video-player>
</div>

<div mat-dialog-content *ngIf="data?.item?.type === 'file'">
  <ngx-doc-viewer
    [url]="data?.item?.file"
    viewer="google"
    style="width: 126vh; height: 93vh"
  ></ngx-doc-viewer>
</div>

<div *ngIf="isOpenPanel()">
  <lib-side-panel drawerTitle="DETAILS" (closePanel)="closePanel()">
    <div *ngIf="!sideBarShow">
      <span class="heading-titles">General Information</span>
      <div class="file-details">
        <div>
          <div class="file-data">
            {{ data?.item?.Name?.name }}<br />
            {{ data?.item?.type }} - {{ data.item.FileSize }}<br />
            Size: {{ data?.item?.size }}<br />
            Creation: {{ data?.item?.creation }}<br />
            Last Modified: {{ data?.item?.LastModified?.date }}<br />
            Access: {{ data?.item?.access }}
          </div>
        </div>
        <div>
          <img src="../../assets/images/txt copia.svg" alt="image" />
        </div>
      </div>
      <br />
      <mat-divider></mat-divider>
      <div class="file-details">
        <span class="heading-titles">Share Use</span>
        <span class="link-color"><u>Share +</u></span>
      </div>
      <br />
      <div class="file-data">
        {{ data?.item?.shareWith }}
      </div>
      <br />
      <mat-divider></mat-divider>
      <br />
      <span class="heading-titles">Geographic Information</span>
      <br />
      <div class="geographic-class">
        <p class="file-data">
          Location: {{ data?.item?.location }}<br />
          Creation: {{ data?.item?.creation }}<br />
        </p>
      </div>
      <br />
      <mat-divider></mat-divider>
      <br />
      <span class="tags-titles">TAGS</span>
      <br />
      <div class="tags-details" *ngFor="let item of data?.item?.tags">
        <div class="tags-numbers">{{ item?.tagNumber }}</div>
        <div class="tags-names">{{ item?.tagName }}</div>
        <div class="link-color"><u>View Detail</u></div>
      </div>
      <div class="tag-button-class">
        <lib-button
          [btnLabel]="btnLabel"
          [btnIcon]="true"
          [iconUrl]="iconUrl"
          [fontIcon]="fontIcon"
          [btnColor]="btnColor"
          [btnSize]="btnSize"
          [buttonShow]="true"
          (click)="buttonClick()"
        >
        </lib-button>
      </div>
    </div>

    <div *ngIf="sideBarShow">
      <div>
        <div class="chip-title">General Tags</div>
        <div class="chip-component">
          <lib-chips-with-input
            [tags]="data?.chipTagFirst"
            [placeHolder]="placeHolder"
          >
          </lib-chips-with-input>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div>
        <div class="chip-title">Demographic tags</div>
        <div class="chip-component">
          <lib-chips-with-input
            [tags]="data?.chipTagSecond"
            [placeHolder]="placeHolder"
          >
          </lib-chips-with-input>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div>
        <div class="chip-title">Geographic tags</div>
        <div class="chip-component">
          <lib-chips-with-input
            [tags]="data?.chipTagThird"
            [placeHolder]="placeHolder"
          >
          </lib-chips-with-input>
        </div>
      </div>
      <div class="button-position">
        <lib-button
          [btnLabel]="CancelButtonLabel"
          [btnIcon]="false"
          [btnSize]="btnSize"
          [btnColor]="CancelButtonColor"
          [buttonShow]="true"
          (click)="onCancelClick()"
        >
        </lib-button>
        <lib-button
          [btnLabel]="buttonLabel"
          [btnIcon]="false"
          [btnSize]="btnSize"
          [btnColor]="buttonColor"
          [buttonShow]="true"
        >
        </lib-button>
      </div>
    </div>
  </lib-side-panel>
</div>
