import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import {
  FormBuilder, FormControl,
} from '@angular/forms';
import {
  FormPopulateData,
} from '../steps-orchestrator/model/step-config';
import {
  FloatLabelType,
} from '@angular/material/form-field';
import {
  Subscription,
} from 'rxjs';
import {
  CommonModule,
} from '@angular/common';

/**
 *
 */
@Component({
  selector: 'lib-summary-step',
  standalone: true,
  templateUrl: './summary-step.component.html',
  styleUrls: [
    './summary-step.component.scss',
  ],
  imports: [
    CommonModule,
  ],
})
export class SummaryStepComponent implements OnDestroy, OnInit {
  @Input() list: { title: string; description: string }[] = [
  ];


  @Output() formData = new EventEmitter();

  @Output() formChange = new EventEmitter();

  @Input() formPopulateData! : FormPopulateData;

  @Output() customEvent = new EventEmitter();

  submitted = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  testResult!: any;

  hide = true;

  hideRequiredControl = new FormControl(false);

  floatLabelControl = new FormControl('auto' as FloatLabelType);

  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  hasChange = false;

  private subscription$!: Subscription;


  /**
   * Constructor
   * @param _formBuilder FormBuilder Service
   */
  constructor(public _formBuilder: FormBuilder,
  ) {

  }

  /**
   *Component On Init method
   */
  ngOnInit(): void {
    this.detectChanges();
    const {
      test, ...others
    } = this.formPopulateData.data;
    this.testResult = test;
    this.list = [
    ];

    for (const key in others) {
      if (Object.prototype.hasOwnProperty.call(others, key)) {
        const element = others[key];
        this.list.push({
          title: key,
          description: element.toString(),
        });
      }
    }
  }


  /**
   * Form Submit Function
   */
  onSubmit() {
    this.submitted = true;
  }

  /**
   * @returns value
   */
  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  /**
   * Custom Submit Button
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public customSubmitFormio():void {
  }

  /**
   * Fires on each form control value change
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  detectChanges() {
  }

  /**
   * On destroy method
   */
  ngOnDestroy() {
    if (this.subscription$)
      this.subscription$.unsubscribe();
  }
}
