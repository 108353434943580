/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Metadata } from './metadata';


export interface Provider { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Provider identifier
     */
    providerId: string;
    /**
     * Provider name
     */
    name: string;
    /**
     * Description about the provider
     */
    description: string;
    /**
     * Specifies if the Category is enabled/disabled
     */
    enabled: boolean;
    /**
     * The channel Identifier the provider belongs to
     */
    channelId: string;
    /**
     * Icon of the provider
     */
    icon: string;
    metadata: Metadata;
}

