<div class="auth-container reset-password">
  <div class="auth-inner">
    <div class="company-name">
      Welcome <span>{{ companyName }}</span>
    </div>
    <mat-card class="card card-login">
      <a id="email-txt" class="link-back d-none" [routerLink]="linkURI">
        {{ back }}
      </a>

      <form class="auth-form" [formGroup]="resetPassword">
        <h4>{{ title }}</h4>
        <p class="mb-4">{{ description }}</p>
        <mat-form-field class="full-width">
          <input matInput placeholder="{{ code }}" formControlName="code" />
          <mat-error
            *ngIf="resetPassword.controls['code'].hasError('required')"
          >
            Please enter a valid Code
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            type="password"
            matInput
            placeholder="{{ NewPassword }}"
            formControlName="newPassword"
          />
          <mat-error
            *ngIf="resetPassword.controls['newPassword'].hasError('required')"
          >
            Please enter a Password
          </mat-error>
          <mat-error
            *ngIf="resetPassword.controls['newPassword'].hasError('pattern')"
          >
            Have 8 characters, 1 upper case, 1 special character
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            type="password"
            matInput
            placeholder="{{ ConfirmPassword }}"
            formControlName="confirmPassword"
          />
          <mat-error
            *ngIf="
              resetPassword.controls['confirmPassword'].hasError('required')
            "
          >
            Please enter Confirm Password
          </mat-error>
          <mat-error
            *ngIf="
              resetPassword.controls['confirmPassword'].hasError('pattern')
            "
          >
            Have 8 characters, 1 upper case, 1 special character
          </mat-error>
          <mat-error
            *ngIf="
              resetPassword.controls['confirmPassword'].hasError(
                'confirmedValidator'
              )
            "
          >
            Password and Confirm Password must be match
          </mat-error>
        </mat-form-field>
        <lib-button
          [btnLabel]="buttonLabel"
          [btnSize]="btnSize"
          [btnColor]="buttonColor"
          [btnType]="btnType"
          [disabled]="resetPassword.invalid"
          [buttonShow]="true"
          (eventData)="onSubmit()"
          [(spinner)]="spinner"
          (spinnerChange)="spinnerChange.emit($event)"
        >
        </lib-button>
      </form>
    </mat-card>
  </div>
</div>
