import {
  ApplicationConfig,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import {
  provideHttpClient,
} from '@angular/common/http';
import {
  AppRoutes,
} from './app.routes';
import {
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  RouteProviders,
} from './constants/router-providers.const';

export const AppConfig: ApplicationConfig = {
  providers: [
    ...RouteProviders,
    provideRouter(
      AppRoutes,
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
    ),
    provideAnimations(),
    provideHttpClient(),
  ],
};
