import {
  Component,
  ChangeDetectionStrategy,
  AfterViewChecked,
  OnChanges,
  Input,
  ViewChildren,
  ElementRef,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import * as d3 from 'd3';
import {
  customAlphabet,
} from 'nanoid';
import {
  lowercase,
} from 'nanoid-dictionary';
const DEFAULT_ID_LENGTH = 12;
const NANO_ID = customAlphabet(lowercase, DEFAULT_ID_LENGTH);

export interface ChartPoint {
  value: number;
  date: Date;
};

export interface ChartPoints {
  name: string;
  selected: boolean;
  color: string;
  colorFill: string;
  lineThickness: number;
  chartPointList: ChartPoint[];
}

/**
 *  chart-line component
 */
@Component({
  selector: 'lib-chart-line',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './chart-line.component.html',
  styleUrls: [
    './chart-line.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLineComponent implements AfterViewChecked, OnChanges {

  @Input() dataPoints!: ChartPoints[];

  @Input() widthCard = 600;

  @Input() heightCard = 200;

  @Input() strokeLines = 'rgba(52, 52, 66, 0.07000000029802322)';

  @Input() classEjes = '';

  @Input() percentage = false;

  @Input() buttons = true;

  @ViewChildren('axisYElements', {
    read: ElementRef,
  })
  axisYElements!: QueryList<ElementRef>;

  margin = {
    top: 20, right: 20, bottom: 30, left: 50,
  };

  width = this.widthCard - this.margin.left - this.margin.right;

  height = this.heightCard - this.margin.top - this.margin.bottom;

  x!: d3.ScaleTime<number, number, never>;

  y!: d3.ScaleLinear<number, number, never>;

  svg!: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

  readonly id = `${NANO_ID(8)}`;

  /**
   *  Initial method
   */
  public ngAfterViewChecked(): void {
    this.buildSvg();
    if (this.dataPoints) {
      this.loadData(this.dataPoints[0], false);
    }
  }

  /**
   * On Changes hook
   * @param changes Changes object
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.svg) {
      return;
    }

    if (changes['widthCard']?.currentValue || changes['heightCard']?.currentValue) {
      this.width = this.widthCard - this.margin.left - this.margin.right;
      this.height = this.heightCard - this.margin.top - this.margin.bottom;
    }

    if (changes['dataPoints']?.currentValue || changes['strokeLines']?.currentValue) {
      if (this.dataPoints) {
        this.loadData(this.dataPoints[0], false);
      }
    }
  }

  /**
   *  method to load data of the gray lines
   */
  loadDataGray() {
    const yTicks = d3.select(`#${this.id}`).select('g').select('.axis-y').selectAll('.tick');

    const valoresTransform: string[] = [
    ];

    yTicks.each(function () {
      const transform = d3.select(this).attr('transform');
      valoresTransform.push(transform);
    });

    const numberOfTicks = valoresTransform.length - 1;
    if (numberOfTicks <= 0) {
      return;
    }

    const firstTickY = valoresTransform[0].replace('translate(0,', '').replace(')', '');
    const secondTickY = valoresTransform[1].replace('translate(0,', '').replace(')', '');
    const distanceBetweenPoints = Math.abs(Number(secondTickY) - Number(firstTickY));

    this.drawLinesGray(numberOfTicks, distanceBetweenPoints);
  }

  /**
   *  Load data from lines
   * @param dataPoints array of points to load the lines
   * @param clic boolean to validate if it is a click
   */
  loadData(dataPoints: ChartPoints, clic: boolean) {
    if (!this.svg) {
      return;
    }

    this.svg.selectAll('*').remove();
    if (clic) {
      dataPoints.selected = !dataPoints.selected;
    }
    const dataSel = this.getSelectedCharts();
    this.addXandYAxis(dataSel);
    if (this.dataPoints) {
      for (const obj of this.dataPoints) {
        if (obj.selected) {
          this.drawLineAndPath(obj.chartPointList, obj.color, obj.colorFill, obj.lineThickness);
        }
      }
    }
    this.loadDataGray();
  }

  /**
   *
   * @returns selected chars points
   */
  private getSelectedCharts() {
    const dataSel: ChartPoint[] = [
    ];

    if (this.dataPoints) {
      for (const obj of this.dataPoints) {
        if (obj.selected) {
          dataSel.push(...obj.chartPointList);
        }
      }
    }
    return dataSel;
  }

  /**
   *  Build component svg
   */
  private buildSvg() {
    this.svg = d3.select(`#${this.id}`)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  /**
   *  Add the x and y axes
   * @param data array of points to load the line
   */
  private addXandYAxis(data: ChartPoint[]) {
    if (data?.length) {
      const [
        minX,
        maxX,
      ] = d3.extent(data, (d) => d.date);

      if (minX && maxX) {
        this.x = d3.scaleTime()
          .domain([
            minX,
            maxX,
          ])
          .range([
            0,
            this.width,
          ]);
      }

      const [
        minY,
        maxY,
      ] = d3.extent(data, (d) => d.value);

      if ((minY !== undefined && minY !== null) && maxY) {
        this.y = d3.scaleLinear()
          .domain([
            minY,
            ((this.percentage && maxY < 1) ? 1 : maxY),
          ])
          .range([
            this.height,
            0,
          ]);
      }

      this.svg.append('g')
        .attr('class', 'axis-x' + this.classEjes)
        .attr('transform', 'translate(0,' + this.height + ')')
        .call(d3.axisBottom(this.x));

      if (this.percentage) {
        const yAxisTickFormat = d3.format('.0%');

        this.svg.append('g')
          .attr('class', 'axis-y' + this.classEjes)
          .call(d3.axisLeft(this.y).ticks(2).tickFormat(yAxisTickFormat));
      } else {

        this.svg.append('g')
          .attr('class', 'axis-y' + this.classEjes)
          .call(d3.axisLeft(this.y));
      }
    }
  }

  /**
   *  Draw lines
   * @param data array of points to load the line
   * @param color color the line
   * @param colorFill color fill line
   * @param lineThickness line thickness
   */
  private drawLineAndPath(data: ChartPoint[], color: string, colorFill: string, lineThickness: number) {
    const line = d3.line<ChartPoint>()
      .x((d) => this.x(d.date))
      .y((d) => this.y(d.value))
      .curve(d3.curveMonotoneX);

    this.svg.append('path')
      .datum(data)
      .attr('class', 'line')
      .attr('d', line)
      .style('stroke', color)
      .style('stroke-width', lineThickness);

    this.svg.selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d: ChartPoint) => this.x(new Date(d.date)))
      .attr('cy', (d: ChartPoint) => this.y(d.value))
      .attr('r', 5)
      .attr('stroke', color)
      .attr('fill', colorFill)
      .attr('stroke-width', lineThickness);
  }

  /**
   *  Draw dashed lines
   * @param numOfLines total number of lines
   * @param lineSpacing space between the lines
   */
  private drawLinesGray(numOfLines: number, lineSpacing: number) {
    this.svg.append('line')
      .attr('class', 'x-axis-line')
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', this.height)
      .attr('y2', this.height)
      .style('stroke', this.strokeLines);

    const coordInit = 0.5;
    for (let i = 0; i <= numOfLines; i++) {
      const yCoord = (i * lineSpacing) + coordInit;
      this.svg.append('line')
        .attr('class', 'x-parallel-line')
        .attr('x1', 0)
        .attr('x2', this.width)
        .attr('y1', yCoord)
        .attr('y2', yCoord)
        .style('stroke', this.strokeLines)
        .style('stroke-dasharray', '3, 3');
    }
  }
}
