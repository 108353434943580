import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  ChartLineComponent, ChartPoints,
} from '../chart-line/chart-line.component';
export interface TabLine {
  value: number;
  name: string;
  selected: boolean;
};

/**
 *  card-line-tab component
 */
@Component({
  selector: 'lib-card-line-tab',
  standalone: true,
  imports: [
    CommonModule,
    ChartLineComponent,
  ],
  templateUrl: './card-line-tab.component.html',
  styleUrls: [
    './card-line-tab.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLineTabComponent {
  @Input() dataPoints!: ChartPoints[];

  @Input() dataTabs!: TabLine[];

  @Input() widthCard!: number;

  @Input() heightCard!: number;

  /**
   *  method to load the data from the tab
   * @param obj Object selected for the tabs
   * @param index Index selected for de tabs
   */
  loadData(obj : TabLine, index: number) {
    for (const data of this.dataTabs) {
      data.selected = false;
    }

    this.dataTabs[index].selected = true;
  }
}
