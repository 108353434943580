import {
  CommonModule,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MatGridListModule,
} from '@angular/material/grid-list';
import {
  MatChipsModule,
} from '@angular/material/chips';
import {
  ButtonComponent,
} from '../button/button.component';
import {
  DatePickerComponent,
} from '../date-picker/date-picker.component';
import {
  MatDividerModule,
} from '@angular/material/divider';
import {
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MenuItem,
} from '../menu/menu.component';

const ALL_CATEGORIES = 1;

export interface PeriodicElement {
  AnalysisName?: string;
  Description?: string;
  LastExecution?: string;
  CreationDate?: string;
  Status?: string;
  Owner?: Owner;
  ProcessDetails?: ProcessDetail;
  action?: MenuItem[];
  icons?: string[];
  type?: string;
}

export interface Owner {
  img?: string;
  name?: string;
}

export interface ProcessDetail {
  id: string;
  name?: string;
}
export interface CardContentList {
  title?: string;
  smallButton?: AnalysisButton;
  bigButton?: AnalysisButton;
  dateRunner?: string;
  executedBy?: string;
  executionTime?: string;
  category?: Category;
}

export interface AnalysisButton{
  text:string;
  color:string;
}

export interface Category{
  id:number;
  name:string;
}

/**
 * The CardAnalysisDetailComponent class is used for initializing and managing the details of a card analysis
 */
@Component({
  standalone: true,
  selector: 'lib-card-analysis-detail',
  templateUrl: './card-analysis-detail.component.html',
  styleUrls: [
    './card-analysis-detail.component.scss',
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatGridListModule,
    ButtonComponent,
    DatePickerComponent,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    MatDividerModule,
    MatChipsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CardAnalysisDetailComponent implements OnInit {

  @Input() categories!: Category[];

  @Input() generalTitle!: string;

  @Input() selectedRowInfo!: PeriodicElement;

  @Input() cardContentList!: CardContentList[];

  moreInfoActive = false;

  moreInfoActiveId!: number;

  forms!: FormGroup;

  filteredContent!: CardContentList[];

  /**
   * Constructor
   * @param fb FormBuilder
   */
  constructor(private fb: FormBuilder) {}

  /**
   * formbuilder
   */
  ngOnInit(): void {
    this.createForm();
    this.filteredContent = [
      ...this.cardContentList,
    ];
    //console.log(this.categories);
  }

  /**
   * createForm
   */
  createForm() {
    this.forms = this.fb.group({
      categories: [
        '',
        Validators.required,
      ],
    });
  }


  /**
   * The function toggles the active state of a section based on a boolean flag.
   * @param section - The section parameter is a number that represents the section of the page
   * that the user clicked on to request more information.
   */
  moreInfoClicked(section: number) {
    if (this.moreInfoActive == true) {
      this.moreInfoActiveId = section;
      this.moreInfoActive = false;
    } else {
      this.moreInfoActiveId = section;
      this.moreInfoActive = true;
    }
  }

  /**
   * changeCategory
   * @param selection selection
   */
  changeCategory(selection: MatSelectChange) {
    const categoryId = selection.value.id;
    if (categoryId === ALL_CATEGORIES) {
      this.filteredContent = [
        ...this.cardContentList,
      ];
    } else  {
      this.filteredContent = this.cardContentList.filter(
        (cardContentList) => categoryId === cardContentList.category?.id);
    }
  }
}
