import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  join__FieldSet: { input: any; output: any };
  link__Import: { input: any; output: any };
};

export type Brand = {
  __typename?: 'Brand';
  /** Brand identifier */
  brandId: Scalars['ID']['output'];
  /** Client the brand belongs to */
  client: Client;
  /** The client Identifier the brand belongs to */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Brand logo URL */
  logoUrl: Scalars['String']['output'];
  /** Brand name */
  name: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Channel = {
  __typename?: 'Channel';
  /** Channel identifier */
  channelId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the channel */
  description: Scalars['String']['output'];
  /** Specifies if the channel is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the channel */
  icon: Scalars['String']['output'];
  /** Channel name */
  name: Scalars['String']['output'];
  /** Providers that belong to a Channel */
  providers: Array<Provider>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Client = {
  __typename?: 'Client';
  /** Brands belong to this Client */
  brands: Array<Brand>;
  /** Client identifier */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Client logo URL */
  logoUrl: Scalars['String']['output'];
  /** Client name */
  name: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Config = {
  __typename?: 'Config';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Channel belongs to */
  channelId: Scalars['String']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** Configuration identifier */
  configId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Configuration belongs to */
  metadata: Scalars['JSONObject']['output'];
  /** Provider belongs to */
  providerId: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type CreateBrandInput = {
  /** The client Identifier the brand belongs to */
  clientId: Scalars['ID']['input'];
  /** Brand logo URL */
  logoUrl: Scalars['String']['input'];
  /** Brand name */
  name: Scalars['String']['input'];
};

export type CreateClientInput = {
  /** Client logo URL */
  logoUrl: Scalars['String']['input'];
  /** Client name */
  name: Scalars['String']['input'];
};

export type CreateConfigInput = {
  /** Brand belongs to */
  brandId: Scalars['ID']['input'];
  /** Channel belongs to */
  channelId: Scalars['String']['input'];
  /** Client belongs to */
  clientId: Scalars['ID']['input'];
  /** Configuration belongs to */
  metadata: Scalars['JSONObject']['input'];
  /** Provider belongs to */
  providerId: Scalars['String']['input'];
};

export type CreateIdentityProviderInput = {
  /** Identify if this provider is enabled */
  enabled: Scalars['Boolean']['input'];
  /** The Identity provider url image */
  imageUrl: Scalars['String']['input'];
  /** JSON formatted data */
  metaData: Scalars['JSONObject']['input'];
  /** Identity provider name */
  name: Scalars['String']['input'];
  /** Identity provider level */
  providerLevel: Scalars['String']['input'];
  /** Identity provider level identifier, for the Client Identifier or Brand Identifier */
  providerLevelId: Scalars['String']['input'];
  /** Identity provider type */
  providerType: Scalars['String']['input'];
};

export type CreateOrderInput = {
  /** State of the Order */
  state: Scalars['String']['input'];
};

export type CreateOrderItemInput = {
  /** Static Banner */
  name: Scalars['String']['input'];
  /** ISO Date Date when Project is going to be release / live */
  plannedEndDate: Scalars['String']['input'];
  /** ISO Date Date when Project is going to be release / live */
  plannedStartDate: Scalars['String']['input'];
  /** Product Variant Id */
  productVariantId: Scalars['String']['input'];
  /** Send to Product */
  sendToProduction: Scalars['Boolean']['input'];
  /** Order of the Product Step */
  sequence: Scalars['Float']['input'];
  /** State of the Order */
  state: Scalars['String']['input'];
};

export type CreateOrderItemStepInput = {
  /** Complexity of the Order Item step */
  complexity: Scalars['Float']['input'];
  /** Specifies if the Step is opertional */
  isOperational: Scalars['Boolean']['input'];
  /** JSON formatted field with all the configuration for the step form */
  metaData: Scalars['JSONObject']['input'];
  /** Name of the Order Item Step */
  name: Scalars['String']['input'];
  /** Product Variant Id */
  productVariantStepDefinitionId: Scalars['String']['input'];
  /** The order of the Order Item Step */
  sort: Scalars['Float']['input'];
  /** State of the Order Item Step */
  state: Scalars['String']['input'];
  /** JSON formatted submission data for the step form */
  submissionData: Scalars['JSONObject']['input'];
  /** Durartion of the step */
  timeSpent: Scalars['Float']['input'];
};

export type CreateProductInput = {
  /** Description about the Product stages */
  description: Scalars['String']['input'];
  /** Specifies if the Product is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Icon of the stage */
  icon: Scalars['String']['input'];
  /** Product name */
  name: Scalars['String']['input'];
  /** Product Categories Id the Product belongs to */
  productCategoryId: Scalars['String']['input'];
};

export type CreateProductVariantInput = {
  /** Description about the Product Variants */
  description: Scalars['String']['input'];
  /** Specifies if the Product Variants is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Icon of the Product Variant */
  icon: Scalars['String']['input'];
  /** Specifies if the Product Variants Steps is edit able or not */
  isEditStepsAble: Scalars['Boolean']['input'];
  /** Product Variant name */
  name: Scalars['String']['input'];
  /** Product Id the Product Variants belongs to */
  productId: Scalars['String']['input'];
  productVariantType: Scalars['String']['input'];
};

export type CreateProductVariantStepDefinitionInput = {
  /** Complexity of the Product Variant step */
  complexity: Scalars['Float']['input'];
  /** Description about the Product Step */
  description: Scalars['String']['input'];
  /** Durartion of the step */
  duration: Scalars['Float']['input'];
  /** Specifies if the Product Step is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Icon of the Product Step */
  icon: Scalars['String']['input'];
  /** Specifies if the Step is opertional */
  isOperational: Scalars['Boolean']['input'];
  /** JSON formatted field with all the configuration for the step form */
  metaData: Scalars['JSONObject']['input'];
  /** Product Step name */
  name: Scalars['String']['input'];
  /** Order of the Product Step */
  order: Scalars['Float']['input'];
  /** Product stages Id the Product steps belongs to */
  productVariantId: Scalars['String']['input'];
  /** Specifies if the Product Step is sendToProduction */
  sendToProduction: Scalars['Boolean']['input'];
  stepType: Scalars['String']['input'];
};

export type CreateProjectCategoriesInput = {
  /** Description about the category */
  description: Scalars['String']['input'];
  /** Specifies if the Category is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Icon of the category */
  icon: Scalars['String']['input'];
  /** Project Catgory name */
  name: Scalars['String']['input'];
  /** Parent Id */
  parentId: Scalars['String']['input'];
};

export type CreateProjectInput = {
  /** project Stage Id in order to keep track of the current project stage */
  currentStageId: Scalars['String']['input'];
  /** Current Project Stage Name */
  currentStageName: Scalars['String']['input'];
  /** Description about the Project */
  description: Scalars['String']['input'];
  /** Specifies if the Project is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Project name */
  name: Scalars['String']['input'];
  /** ISO Date Date when Project is going to be release / live */
  releaseDate: Scalars['String']['input'];
  /** Status of Project */
  status: Scalars['String']['input'];
};

export type CreateProjectStageDefinitionInput = {
  /** Description about the Project stages */
  description: Scalars['String']['input'];
  /** Specifies if the Project Stages is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Icon of the stage */
  icon: Scalars['String']['input'];
  /** Project Stage name */
  name: Scalars['String']['input'];
  /** Order of the Project Stage */
  order: Scalars['Float']['input'];
  /** Project Categories Id the Project Stages belongs to */
  projectCategoryId: Scalars['String']['input'];
};

export type CreateProjectStageInput = {
  /** The current step the current stage is pointing to */
  currentStepId: Scalars['String']['input'];
  /** Stage Id */
  projectStageDefinitionId: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CreateProjectStepDefinitionInput = {
  /** Name of the component */
  component: Scalars['String']['input'];
  /** Description about the Project Step */
  description: Scalars['String']['input'];
  /** Specifies if the Project Step is enabled/disabled */
  enabled: Scalars['Boolean']['input'];
  /** Icon of the Project Step */
  icon: Scalars['String']['input'];
  /** JSON formatted field with all the configuration for the step form */
  metaData: Scalars['JSONObject']['input'];
  /** Project Step name */
  name: Scalars['String']['input'];
  /** Order of the Project Step */
  order: Scalars['Float']['input'];
  /** Project stages Id the Project steps belongs to */
  projectStageDefinitionId: Scalars['String']['input'];
};

export type CreateProjectStepInput = {
  /** JSON formatted data */
  metaData: Scalars['JSONObject']['input'];
  /** Step Id */
  projectStepDefinitionId: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Identify if this provider is enabled */
  enabled: Scalars['Boolean']['output'];
  /** Identity provider Identifier */
  identityProviderId: Scalars['ID']['output'];
  /** The Identity provider url image */
  imageUrl: Scalars['String']['output'];
  /** The internal underlying identifier */
  internalId: Scalars['String']['output'];
  /** JSON formatted data */
  metaData: Scalars['JSONObject']['output'];
  /** Identity provider name */
  name: Scalars['String']['output'];
  /** Identity provider level */
  providerLevel: Scalars['String']['output'];
  /** Identity provider level identifier, for the Client Identifier or Brand Identifier */
  providerLevelId: Scalars['String']['output'];
  /** Identity provider type */
  providerType: Scalars['String']['output'];
  /** Start URL for get redirection to the authorization third party application */
  startUrl: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Metadata = {
  __typename?: 'Metadata';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Dynamic steps labels */
  stepsLabel: Array<Scalars['String']['output']>;
  /** Dynamic steps lists by step */
  stepsList: Array<Scalars['JSONObject']['output']>;
  /** Dynamic stage name by step */
  stepsName: Array<Scalars['String']['output']>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a Brand */
  createBrand: Brand;
  /** Create a Client */
  createClient: Client;
  /** Create a Configuration */
  createConfig: Config;
  /** Create an Identity Provider */
  createIdentityProvider: IdentityProvider;
  /** Create a Order */
  createOrder: Order;
  /** Create a OrderItem */
  createOrderItem: OrderItem;
  /** Create a OrderItemStep */
  createOrderItemStep: OrderItemStep;
  /** Create a Product */
  createProduct: Product;
  /** Create a ProductVariant */
  createProductVariant: ProductVariant;
  /** Create a Product Variant Step Definition */
  createProductVariantStepDefinition: ProductVariantStepDefinition;
  /** Create a Project */
  createProject: Project;
  /** Create a ProjectCategory */
  createProjectCategory: ProjectCategories;
  /** Create a ProjectStage */
  createProjectStage: ProjectStage;
  /** Create a Project Stages Defination */
  createProjectStageDefinition: ProjectStageDefinition;
  /** Create a ProjectStep */
  createProjectStep: ProjectStep;
  /** Create a Project Steps Definitions */
  createProjectStepDefinition: ProjectStepDefinition;
  /** Remove a Brand by Id */
  removeBrand: Brand;
  /** Remove a Client by Id */
  removeClient: Client;
  /** Remove a Identity Provider by Id */
  removeIdentityProvider: IdentityProvider;
  /** Remove a Order by Id */
  removeOrder: Order;
  /** Remove a OrderItem by Id */
  removeOrderItem: OrderItem;
  /** Remove a OrderItemStep by Id */
  removeOrderItemStep: OrderItemStep;
  /** Remove a Product by Id */
  removeProduct: Product;
  /** Remove a ProductVariant by Id */
  removeProductVariant: ProductVariant;
  /** Remove a Product Variant Step Definition by Id */
  removeProductVariantStepsDefinition: ProductVariantStepDefinition;
  /** Remove a Project by Id */
  removeProject: Project;
  /** Remove a ProjectCategory by Id */
  removeProjectCategory: ProjectCategories;
  /** Remove a ProjectStep by Id */
  removeProjectStage: ProjectStep;
  /** Remove a Project Steps Definitions by Id */
  removeProjectStepDefinition: ProjectStepDefinition;
  /** Remove a Project Stages Defination by Id */
  removeStage: ProjectStageDefinition;
  /** Update a Brand by ID */
  updateBrand: Brand;
  /** Update a Client by ID */
  updateClient: Client;
  /** Update a Identity Provider by ID */
  updateIdentityProvider: IdentityProvider;
  /** Update a OrderItem by ID */
  updateOrder: Order;
  /** Update a OrderItem by ID */
  updateOrderItem: OrderItem;
  /** Update a OrderItemStep by ID */
  updateOrderItemStep: OrderItemStep;
  /** Update a Product by ID */
  updateProduct: Product;
  /** Update a ProductVariant by ID */
  updateProductVariant: ProductVariant;
  /** Update a Product Variant Step Definition by ID */
  updateProductVariantStepDefinition: ProductVariantStepDefinition;
  /** Update a Project by ID */
  updateProject: Project;
  /** Update a ProjectCategory by ID */
  updateProjectCategory: ProjectCategories;
  /** Update a ProjectStage by ID */
  updateProjectStage: ProjectStage;
  /** Update a Project Stages Defination by ID */
  updateProjectStageDefinition: ProjectStageDefinition;
  /** Update a ProjectStep by ID */
  updateProjectStep: ProjectStep;
  /** Update a Project Steps Definitions by ID */
  updateProjectStepDefinition: ProjectStepDefinition;
};

export type MutationCreateBrandArgs = {
  createBrandInput: CreateBrandInput;
};

export type MutationCreateClientArgs = {
  createClientInput: CreateClientInput;
};

export type MutationCreateConfigArgs = {
  CreateConfigInput: CreateConfigInput;
};

export type MutationCreateIdentityProviderArgs = {
  createIdentityProviderInput: CreateIdentityProviderInput;
};

export type MutationCreateOrderArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  createOrderInput: CreateOrderInput;
  projectId: Scalars['String']['input'];
};

export type MutationCreateOrderItemArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  createOrderItemInput: CreateOrderItemInput;
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type MutationCreateOrderItemStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  createOrderItemStepInput: CreateOrderItemStepInput;
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};

export type MutationCreateProductVariantArgs = {
  createProductVariantInput: CreateProductVariantInput;
};

export type MutationCreateProductVariantStepDefinitionArgs = {
  createProductVariantStepDefinitionInput: CreateProductVariantStepDefinitionInput;
};

export type MutationCreateProjectArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  createProjectInput: CreateProjectInput;
};

export type MutationCreateProjectCategoryArgs = {
  createCategoryInput: CreateProjectCategoriesInput;
};

export type MutationCreateProjectStageArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  createProjectStageInput: CreateProjectStageInput;
  projectId: Scalars['String']['input'];
};

export type MutationCreateProjectStageDefinitionArgs = {
  createProjectStageDefinitionInput: CreateProjectStageDefinitionInput;
};

export type MutationCreateProjectStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  createProjectStepInput: CreateProjectStepInput;
  projectId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};

export type MutationCreateProjectStepDefinitionArgs = {
  createProjectStepDefinitionInput: CreateProjectStepDefinitionInput;
};

export type MutationRemoveBrandArgs = {
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemoveClientArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveIdentityProviderArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveOrderArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type MutationRemoveOrderItemArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type MutationRemoveOrderItemStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type MutationRemoveProductArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveProductVariantArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveProductVariantStepsDefinitionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveProjectArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemoveProjectCategoryArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveProjectStageArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};

export type MutationRemoveProjectStepDefinitionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveStageArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdateBrandArgs = {
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  updateBrandInput: UpdateBrandInput;
};

export type MutationUpdateClientArgs = {
  updateClientInput: UpdateClientInput;
};

export type MutationUpdateIdentityProviderArgs = {
  updateIdentityProviderInput: UpdateIdentityProviderInput;
};

export type MutationUpdateOrderArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  updateOrderInput: UpdateOrderInput;
};

export type MutationUpdateOrderItemArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  updateOrderItemInput: UpdateOrderItemInput;
};

export type MutationUpdateOrderItemStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  updateOrderItemStepInput: UpdateOrderItemStepInput;
};

export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};

export type MutationUpdateProductVariantArgs = {
  updateProductVariantInput: UpdateProductVariantInput;
};

export type MutationUpdateProductVariantStepDefinitionArgs = {
  updateProductVariantStepsDefinitionInput: UpdateProductVariantStepDefinitionInput;
};

export type MutationUpdateProjectArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  updateProjectInput: UpdateProjectInput;
};

export type MutationUpdateProjectCategoryArgs = {
  updateCategoryInput: UpdateProjectCategoriesInput;
};

export type MutationUpdateProjectStageArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  updateProjectStageInput: UpdateProjectStageInput;
};

export type MutationUpdateProjectStageDefinitionArgs = {
  updateProjectStageDefinitionInput: UpdateProjectStageDefinitionInput;
};

export type MutationUpdateProjectStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
  updateProjectStepInput: UpdateProjectStepInput;
};

export type MutationUpdateProjectStepDefinitionArgs = {
  updateProjectStepDefinitionInput: UpdateProjectStepDefinitionInput;
};

export type Order = {
  __typename?: 'Order';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Order items for the current order */
  items: Array<OrderItem>;
  /** Order identifier */
  orderId: Scalars['ID']['output'];
  /** Project the resource belongs to */
  project: Project;
  /** Project identifier */
  projectId: Scalars['ID']['output'];
  /** State of the Order */
  state: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Static Banner */
  name: Scalars['String']['output'];
  /** Order the resource belongs to */
  order: Order;
  /** Order identifier */
  orderId: Scalars['ID']['output'];
  /** Order Item identifier */
  orderItemId: Scalars['ID']['output'];
  /** ISO Date Date when Project is going to be release / live */
  plannedEndDate: Scalars['String']['output'];
  /** ISO Date Date when Project is going to be release / live */
  plannedStartDate: Scalars['String']['output'];
  /** Reference of Project Variant */
  productVariant: ProductVariant;
  /** Product Variant Id */
  productVariantId: Scalars['String']['output'];
  /** Project the resource belongs to */
  project: Project;
  /** Project identifier */
  projectId: Scalars['ID']['output'];
  /** Send to Product */
  sendToProduction: Scalars['Boolean']['output'];
  /** Order of the Product Step */
  sequence: Scalars['Float']['output'];
  /** State of the Order */
  state: Scalars['String']['output'];
  /** The steps for the given order item */
  steps: Array<OrderItemStep>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type OrderItemStep = {
  __typename?: 'OrderItemStep';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** Complexity of the Order Item step */
  complexity: Scalars['Float']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Specifies if the Step is opertional */
  isOperational: Scalars['Boolean']['output'];
  /** Order item the resource belongs to */
  item: OrderItem;
  /** JSON formatted field with all the configuration for the step form */
  metaData: Scalars['JSONObject']['output'];
  /** Name of the Order Item Step */
  name: Scalars['String']['output'];
  /** Order the resource belongs to */
  order: Order;
  /** Order identifier */
  orderId: Scalars['ID']['output'];
  /** Order Item identifier */
  orderItemId: Scalars['ID']['output'];
  /** Order Item Step identifier */
  orderItemStepId: Scalars['String']['output'];
  /** Product Variant Id */
  productVariantStepDefinitionId: Scalars['String']['output'];
  /** Project the resource belongs to */
  project: Project;
  /** Project identifier */
  projectId: Scalars['ID']['output'];
  /** The order of the Order Item Step */
  sort: Scalars['Float']['output'];
  /** State of the Order Item Step */
  state: Scalars['String']['output'];
  /** JSON formatted submission data for the step form */
  submissionData: Scalars['JSONObject']['output'];
  /** Durartion of the step */
  timeSpent: Scalars['Float']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the Product stages */
  description: Scalars['String']['output'];
  /** Specifies if the Product is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the stage */
  icon: Scalars['String']['output'];
  /** Product name */
  name: Scalars['String']['output'];
  /** Product Categories the Product belongs to */
  productCategory: Array<ProductCategories>;
  /** Product Categories Id the Product belongs to */
  productCategoryId: Scalars['String']['output'];
  /** Product identifier */
  productId: Scalars['ID']['output'];
  /** Product Variants belongs to Product */
  productVariants: Array<ProductVariant>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProductCategories = {
  __typename?: 'ProductCategories';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the category */
  description: Scalars['String']['output'];
  /** Specifies if the Category is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the category */
  icon: Scalars['String']['output'];
  /** Product Catgory name */
  name: Scalars['String']['output'];
  /** Product Category identifier */
  productCategoryId: Scalars['ID']['output'];
  /** Products belongs to Product Category */
  products: Array<Product>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the Product Variants */
  description: Scalars['String']['output'];
  /** Specifies if the Product Variants is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the Product Variant */
  icon: Scalars['String']['output'];
  /** Specifies if the Product Variants Steps is edit able or not */
  isEditStepsAble: Scalars['Boolean']['output'];
  /** Product Variant name */
  name: Scalars['String']['output'];
  /** Product the Product Variants belongs to */
  product: Product;
  /** Product Id the Product Variants belongs to */
  productId: Scalars['String']['output'];
  /** Product Variant identifier */
  productVariantId: Scalars['ID']['output'];
  /** Product Variant Steps Definitions belongs to Product Variants */
  productVariantStepsDefinitions: Array<ProductVariantStepDefinition>;
  productVariantType: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProductVariantStepDefinition = {
  __typename?: 'ProductVariantStepDefinition';
  /** Complexity of the Product Variant step */
  complexity: Scalars['Float']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the Product Step */
  description: Scalars['String']['output'];
  /** Durartion of the step */
  duration: Scalars['Float']['output'];
  /** Specifies if the Product Step is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the Product Step */
  icon: Scalars['String']['output'];
  /** Specifies if the Step is opertional */
  isOperational: Scalars['Boolean']['output'];
  /** JSON formatted field with all the configuration for the step form */
  metaData: Scalars['JSONObject']['output'];
  /** Product Step name */
  name: Scalars['String']['output'];
  /** Order of the Product Step */
  order: Scalars['Float']['output'];
  /** Product stages Id the Product steps belongs to */
  productVariant: ProductVariant;
  /** Product stages Id the Product steps belongs to */
  productVariantId: Scalars['String']['output'];
  /** Product Vairant Step identifier */
  productVariantStepDefinitionId: Scalars['ID']['output'];
  /** Specifies if the Product Step is sendToProduction */
  sendToProduction: Scalars['Boolean']['output'];
  stepType: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** The current project Stage */
  currentStage: ProjectStage;
  /** project Stage Id in order to keep track of the current project stage */
  currentStageId: Scalars['String']['output'];
  /** Current Project Stage Name */
  currentStageName: Scalars['String']['output'];
  /** Description about the Project */
  description: Scalars['String']['output'];
  /** Specifies if the Project is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Project name */
  name: Scalars['String']['output'];
  /** Orders of the project */
  orders: Array<Order>;
  /** Project identifier */
  projectId: Scalars['ID']['output'];
  /** ISO Date Date when Project is going to be release / live */
  releaseDate: Scalars['String']['output'];
  /** Project stages */
  stages: Array<ProjectStage>;
  /** Status of Project */
  status: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProjectCategories = {
  __typename?: 'ProjectCategories';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the category */
  description: Scalars['String']['output'];
  /** Specifies if the Category is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the category */
  icon: Scalars['String']['output'];
  /** Project Catgory name */
  name: Scalars['String']['output'];
  /** Parent Id */
  parentId: Scalars['String']['output'];
  /** Project Category identifier */
  projectCategoryId: Scalars['ID']['output'];
  /** Project stages Definitions belongs to Project Category */
  projectStagesDefinitions: Array<ProjectStageDefinition>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProjectStage = {
  __typename?: 'ProjectStage';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** The current step the stage is at */
  currentStep: ProjectStep;
  /** The current step the current stage is pointing to */
  currentStepId: Scalars['String']['output'];
  /** Project the resource belongs to */
  project: Project;
  /** Project identifier */
  projectId: Scalars['ID']['output'];
  /** List of the Project Stage Definitions */
  projectStageDefinition: ProjectStageDefinition;
  /** Stage Id */
  projectStageDefinitionId: Scalars['String']['output'];
  /** Project Stage identifier */
  projectStageId: Scalars['ID']['output'];
  state: Scalars['String']['output'];
  /** Project stage's steps */
  steps: Array<ProjectStep>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProjectStageDefinition = {
  __typename?: 'ProjectStageDefinition';
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the Project stages */
  description: Scalars['String']['output'];
  /** Specifies if the Project Stages is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the stage */
  icon: Scalars['String']['output'];
  /** Project Stage name */
  name: Scalars['String']['output'];
  /** Order of the Project Stage */
  order: Scalars['Float']['output'];
  /** Project Categories the Project Stages belongs to */
  projectCategory: ProjectCategories;
  /** Project Categories Id the Project Stages belongs to */
  projectCategoryId: Scalars['String']['output'];
  /** Project Stage Definition identifier */
  projectStageDefinitionId: Scalars['ID']['output'];
  /** Project Step Definitions belongs to Project Stage Definition */
  projectStepsDefinitions: Array<ProjectStepDefinition>;
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProjectStep = {
  __typename?: 'ProjectStep';
  /** Brand belongs to */
  brand: Brand;
  /** Brand belongs to */
  brandId: Scalars['ID']['output'];
  /** Client belongs to */
  client: Client;
  /** Client belongs to */
  clientId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** JSON formatted data */
  metaData: Scalars['JSONObject']['output'];
  /** Project the resource belongs to */
  project: Project;
  /** Project identifier */
  projectId: Scalars['ID']['output'];
  /** Project stage the resource belongs to */
  projectStage: ProjectStage;
  /** Project Stage identifier */
  projectStageId: Scalars['ID']['output'];
  /** Step Definition */
  projectStepDefinition: ProjectStepDefinition;
  /** Step Id */
  projectStepDefinitionId: Scalars['String']['output'];
  /** Project Step identifier */
  projectStepId: Scalars['String']['output'];
  /** The parent stage for the step */
  stage: ProjectStage;
  state: Scalars['String']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type ProjectStepDefinition = {
  __typename?: 'ProjectStepDefinition';
  /** Name of the component */
  component: Scalars['String']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the Project Step */
  description: Scalars['String']['output'];
  /** Specifies if the Project Step is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the Project Step */
  icon: Scalars['String']['output'];
  /** JSON formatted field with all the configuration for the step form */
  metaData: Scalars['JSONObject']['output'];
  /** Project Step name */
  name: Scalars['String']['output'];
  /** Order of the Project Step */
  order: Scalars['Float']['output'];
  /** Project stages Id the Project steps belongs to */
  projectStageDefinition: ProjectStageDefinition;
  /** Project stages Id the Project steps belongs to */
  projectStageDefinitionId: Scalars['String']['output'];
  /** Project Step Definition identifier */
  projectStepDefinitionId: Scalars['ID']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Provider = {
  __typename?: 'Provider';
  /** The channel Identifier the provider belongs to */
  channelId: Scalars['ID']['output'];
  /** ISO Date when the entry was created */
  createdAt: Scalars['String']['output'];
  /** Description about the provider */
  description: Scalars['String']['output'];
  /** Specifies if the Category is enabled/disabled */
  enabled: Scalars['Boolean']['output'];
  /** Icon of the provider */
  icon: Scalars['String']['output'];
  /** Specifies if the Category is enabled/disabled */
  metadata: Metadata;
  /** Provider name */
  name: Scalars['String']['output'];
  /** Provider identifier */
  providerId: Scalars['ID']['output'];
  /** ISO Date when the entry was last updated */
  updatedAt: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Get a Brand by Id */
  getBrand: Brand;
  /** Get All Brands */
  getBrands: Array<Brand>;
  /** Get All Channels */
  getChannels: Array<Channel>;
  /** Get a Client by Id */
  getClient: Client;
  /** Get All Clients */
  getClients: Array<Client>;
  /** Get configuration by Product Id and Channel Id */
  getConfigurationByFilters: Config;
  /** Get configuration by Product Id and Channel Id */
  getConfigurationByProviderAndChannelId: Metadata;
  /** Get an Identity provider by Id */
  getIdentityProvider: IdentityProvider;
  /** Get All Identity Providers */
  getIdentityProviders: Array<IdentityProvider>;
  /** Get a Order by Id */
  getOrder: Order;
  /** Get a OrderItem by Id */
  getOrderItem: OrderItem;
  /** Get a OrderItemStep by Id */
  getOrderItemStep: OrderItemStep;
  /** Get All OrderItemSteps */
  getOrderItemSteps: Array<OrderItemStep>;
  /** Get All OrderItems */
  getOrderItems: Array<OrderItem>;
  /** Get All Orders */
  getOrders: Array<Order>;
  /** Get a Product by Id */
  getProduct: Product;
  /** Get All Product Variant Steps Definitions */
  getProductStepsDefinitions: Array<ProductVariantStepDefinition>;
  /** Get a ProductVariant by Id */
  getProductVariant: ProductVariant;
  /** Get All Product Variant Steps Definitions by ProductVariant Id */
  getProductVariantStepDefinitionsByProductVariant: Array<ProductVariantStepDefinition>;
  /** Get a Product Variant Step Definition by Id */
  getProductVariantStepsDefinition: ProductVariantStepDefinition;
  /** Get All ProductVariants */
  getProductVariants: Array<ProductVariant>;
  /** Get All Product Variants by Product Id */
  getProductVariantsByProduct: Array<ProductVariant>;
  /** Get All Products */
  getProducts: Array<Product>;
  /** Get a Project by Id */
  getProject: Project;
  /** Get All ProjectCategories */
  getProjectCategories: Array<ProjectCategories>;
  /** Get a ProjectCategory by Id */
  getProjectCategory: ProjectCategories;
  /** Get a ProjectStage by Id */
  getProjectStage: ProjectStage;
  /** Get a Project Stages Defination by Id */
  getProjectStageDefinition: ProjectStageDefinition;
  /** Get All ProjectStages */
  getProjectStages: Array<ProjectStage>;
  /** Get All Project Stages by Project Id */
  getProjectStagesByProject: Array<ProjectStage>;
  /** Get All Project Stages Definations */
  getProjectStagesDefinitions: Array<ProjectStageDefinition>;
  /** Get a ProjectStep by Id */
  getProjectStep: ProjectStep;
  /** Get a Project Steps Definitions by Id */
  getProjectStepDefinition: ProjectStepDefinition;
  /** Get All ProjectSteps */
  getProjectSteps: Array<ProjectStep>;
  /** Get All Project Steps Definitionss */
  getProjectStepsDefinitions: Array<ProjectStepDefinition>;
  /** Get All Stpes by Stage Id */
  getProjectStepsDefinitionsByProjectStageDefinition: Array<ProjectStepDefinition>;
  /** Get All Projects */
  getProjects: Array<Project>;
  /** Get providers by channel id */
  getProvidersByChannelId: Array<Provider>;
  /** Get All Stages by ProjectCategory Id */
  getStagesByProjectCategory: Array<ProjectStageDefinition>;
};

export type QueryGetBrandArgs = {
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type QueryGetBrandsArgs = {
  clientId: Scalars['String']['input'];
};

export type QueryGetClientArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetConfigurationByFiltersArgs = {
  brandId: Scalars['String']['input'];
  channelId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
};

export type QueryGetConfigurationByProviderAndChannelIdArgs = {
  channelId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type QueryGetIdentityProviderArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetOrderArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetOrderItemArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetOrderItemStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetOrderItemStepsArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetOrderItemsArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetOrdersArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetProductArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProductVariantArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProductVariantStepDefinitionsByProductVariantArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProductVariantStepsDefinitionArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProductVariantsByProductArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProjectArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type QueryGetProjectCategoryArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProjectStageArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetProjectStageDefinitionArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProjectStagesArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type QueryGetProjectStagesByProjectArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProjectStepArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};

export type QueryGetProjectStepDefinitionArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProjectStepsArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};

export type QueryGetProjectStepsDefinitionsByProjectStageDefinitionArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetProjectsArgs = {
  brandId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
};

export type QueryGetProvidersByChannelIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetStagesByProjectCategoryArgs = {
  id: Scalars['String']['input'];
};

export type UpdateBrandInput = {
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Brand logo URL */
  logoUrl: Scalars['String']['input'];
  /** Brand name */
  name: Scalars['String']['input'];
};

export type UpdateClientInput = {
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Client logo URL */
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Client name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIdentityProviderInput = {
  /** The Identity provider url image */
  imageUrl: Scalars['String']['input'];
  /** Identity provider name */
  name: Scalars['String']['input'];
};

export type UpdateOrderInput = {
  /** State of the Order */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderItemInput = {
  /** Static Banner */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ISO Date Date when Project is going to be release / live */
  plannedEndDate?: InputMaybe<Scalars['String']['input']>;
  /** ISO Date Date when Project is going to be release / live */
  plannedStartDate?: InputMaybe<Scalars['String']['input']>;
  /** Product Variant Id */
  productVariantId?: InputMaybe<Scalars['String']['input']>;
  /** Send to Product */
  sendToProduction?: InputMaybe<Scalars['Boolean']['input']>;
  /** Order of the Product Step */
  sequence?: InputMaybe<Scalars['Float']['input']>;
  /** State of the Order */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderItemStepInput = {
  /** Complexity of the Order Item step */
  complexity?: InputMaybe<Scalars['Float']['input']>;
  /** Specifies if the Step is opertional */
  isOperational?: InputMaybe<Scalars['Boolean']['input']>;
  /** JSON formatted field with all the configuration for the step form */
  metaData?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Name of the Order Item Step */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Product Variant Id */
  productVariantStepDefinitionId?: InputMaybe<Scalars['String']['input']>;
  /** The order of the Order Item Step */
  sort?: InputMaybe<Scalars['Float']['input']>;
  /** State of the Order Item Step */
  state?: InputMaybe<Scalars['String']['input']>;
  /** JSON formatted submission data for the step form */
  submissionData?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Durartion of the step */
  timeSpent?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateProductInput = {
  /** Description about the Product stages */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Product is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Icon of the stage */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Product Categories Id the Product belongs to */
  productCategoryId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductVariantInput = {
  /** Description about the Product Variants */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Product Variants is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Icon of the Product Variant */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Specifies if the Product Variants Steps is edit able or not */
  isEditStepsAble?: InputMaybe<Scalars['Boolean']['input']>;
  /** Product Variant name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Product Id the Product Variants belongs to */
  productId?: InputMaybe<Scalars['String']['input']>;
  productVariantType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductVariantStepDefinitionInput = {
  /** Complexity of the Product Variant step */
  complexity?: InputMaybe<Scalars['Float']['input']>;
  /** Description about the Product Step */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Durartion of the step */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Specifies if the Product Step is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Icon of the Product Step */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Specifies if the Step is opertional */
  isOperational?: InputMaybe<Scalars['Boolean']['input']>;
  /** JSON formatted field with all the configuration for the step form */
  metaData?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Product Step name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Order of the Product Step */
  order?: InputMaybe<Scalars['Float']['input']>;
  /** Product stages Id the Product steps belongs to */
  productVariantId?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Product Step is sendToProduction */
  sendToProduction?: InputMaybe<Scalars['Boolean']['input']>;
  stepType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectCategoriesInput = {
  /** Description about the category */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Category is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Icon of the category */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Project Catgory name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Parent Id */
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInput = {
  /** project Stage Id in order to keep track of the current project stage */
  currentStageId?: InputMaybe<Scalars['String']['input']>;
  /** Current Project Stage Name */
  currentStageName?: InputMaybe<Scalars['String']['input']>;
  /** Description about the Project */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Project is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Project name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ISO Date Date when Project is going to be release / live */
  releaseDate?: InputMaybe<Scalars['String']['input']>;
  /** Status of Project */
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectStageDefinitionInput = {
  /** Description about the Project stages */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Project Stages is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Icon of the stage */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** Project Stage name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Order of the Project Stage */
  order?: InputMaybe<Scalars['Float']['input']>;
  /** Project Categories Id the Project Stages belongs to */
  projectCategoryId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectStageInput = {
  /** The current step the current stage is pointing to */
  currentStepId?: InputMaybe<Scalars['String']['input']>;
  /** Stage Id */
  projectStageDefinitionId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectStepDefinitionInput = {
  /** Name of the component */
  component?: InputMaybe<Scalars['String']['input']>;
  /** Description about the Project Step */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if the Project Step is enabled/disabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Icon of the Project Step */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Resource identifier */
  id: Scalars['ID']['input'];
  /** JSON formatted field with all the configuration for the step form */
  metaData?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Project Step name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Order of the Project Step */
  order?: InputMaybe<Scalars['Float']['input']>;
  /** Project stages Id the Project steps belongs to */
  projectStageDefinitionId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectStepInput = {
  /** JSON formatted data */
  metaData?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Step Id */
  projectStepDefinitionId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  /** Country name component */
  country: Scalars['String']['output'];
  /** Full mailing address, formatted for display or use on a mailing label. This field MAY contain multiple lines, separated by newlines. Newlines can be represented either as a carriage return/line feed pair ("\r\n") or as a single line feed character ("\n") */
  formatted: Scalars['String']['output'];
  /** City or locality component */
  locality: Scalars['String']['output'];
  /** Zip code or postal code component */
  postal_code: Scalars['String']['output'];
  /** State, province, prefecture, or region component */
  region: Scalars['String']['output'];
  /**
   * Full street address component, which MAY include house number, street name, Post Office Box, and multi-line extended street address information. This field MAY contain multiple lines, separated by newlines. Newlines can be represented either as a carriage return/line feed pair ("
   * ") or as a single line feed character ("
   * ")
   */
  street_address: Scalars['String']['output'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  /** User Preferred Brand Id */
  preferredBrandId: Scalars['String']['output'];
  /** User Preferred Client Id */
  preferredClientId: Scalars['String']['output'];
  /** User Preferred Service Name */
  preferredService: Scalars['String']['output'];
};

export enum Join__Graph {
  Admin = 'ADMIN',
  Auth = 'AUTH',
  Message = 'MESSAGE',
  Plan = 'PLAN',
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
}

export type GetProjectCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectCategoriesQuery = {
  __typename?: 'Query';
  getProjectCategories: Array<{
    __typename?: 'ProjectCategories';
    projectCategoryId: string;
    name: string;
    description: string;
    enabled: boolean;
    icon: string;
    parentId: string;
    projectStagesDefinitions: Array<{
      __typename?: 'ProjectStageDefinition';
      projectStageDefinitionId: string;
      projectCategoryId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
      order: number;
      projectStepsDefinitions: Array<{
        __typename?: 'ProjectStepDefinition';
        projectStepDefinitionId: string;
        projectStageDefinitionId: string;
        name: string;
        description: string;
        component: string;
        order: number;
        metaData: any;
      }>;
    }>;
  }>;
};

export type ProjectCategoriesFieldsFragment = {
  __typename?: 'ProjectCategories';
  projectCategoryId: string;
  name: string;
  description: string;
  enabled: boolean;
  icon: string;
  parentId: string;
  projectStagesDefinitions: Array<{
    __typename?: 'ProjectStageDefinition';
    projectStageDefinitionId: string;
    projectCategoryId: string;
    name: string;
    description: string;
    icon: string;
    enabled: boolean;
    order: number;
    projectStepsDefinitions: Array<{
      __typename?: 'ProjectStepDefinition';
      projectStepDefinitionId: string;
      projectStageDefinitionId: string;
      name: string;
      description: string;
      component: string;
      order: number;
      metaData: any;
    }>;
  }>;
};

export type ProjectStageDefinitionFieldsFragment = {
  __typename?: 'ProjectStageDefinition';
  projectStageDefinitionId: string;
  projectCategoryId: string;
  name: string;
  description: string;
  icon: string;
  enabled: boolean;
  order: number;
  projectStepsDefinitions: Array<{
    __typename?: 'ProjectStepDefinition';
    projectStepDefinitionId: string;
    projectStageDefinitionId: string;
    name: string;
    description: string;
    component: string;
    order: number;
    metaData: any;
  }>;
};

export type GetProductVariantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductVariantsQuery = {
  __typename?: 'Query';
  getProductVariants: Array<{
    __typename?: 'ProductVariant';
    productVariantId: string;
    name: string;
    description: string;
    icon: string;
    enabled: boolean;
    productId: string;
    isEditStepsAble: boolean;
    productVariantType: string;
    product: {
      __typename?: 'Product';
      productId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
    };
    productVariantStepsDefinitions: Array<{
      __typename?: 'ProductVariantStepDefinition';
      productVariantStepDefinitionId: string;
      productVariantId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
      isOperational: boolean;
      sendToProduction: boolean;
      stepType: string;
      complexity: number;
      duration: number;
      order: number;
      metaData: any;
    }>;
  }>;
};

export type ProjectStepDefinitionFieldsFragment = {
  __typename?: 'ProjectStepDefinition';
  projectStepDefinitionId: string;
  projectStageDefinitionId: string;
  name: string;
  description: string;
  component: string;
  order: number;
  metaData: any;
};

export type ProductVariantFieldsFragment = {
  __typename?: 'ProductVariant';
  productVariantId: string;
  name: string;
  description: string;
  icon: string;
  enabled: boolean;
  productId: string;
  isEditStepsAble: boolean;
  productVariantType: string;
  product: {
    __typename?: 'Product';
    productId: string;
    name: string;
    description: string;
    icon: string;
    enabled: boolean;
  };
  productVariantStepsDefinitions: Array<{
    __typename?: 'ProductVariantStepDefinition';
    productVariantStepDefinitionId: string;
    productVariantId: string;
    name: string;
    description: string;
    icon: string;
    enabled: boolean;
    isOperational: boolean;
    sendToProduction: boolean;
    stepType: string;
    complexity: number;
    duration: number;
    order: number;
    metaData: any;
  }>;
};

export type ProductFieldsFragment = {
  __typename?: 'Product';
  productId: string;
  name: string;
  description: string;
  icon: string;
  enabled: boolean;
};

export type ProductVariantStepDefinitionFieldsFragment = {
  __typename?: 'ProductVariantStepDefinition';
  productVariantStepDefinitionId: string;
  productVariantId: string;
  name: string;
  description: string;
  icon: string;
  enabled: boolean;
  isOperational: boolean;
  sendToProduction: boolean;
  stepType: string;
  complexity: number;
  duration: number;
  order: number;
  metaData: any;
};

export type BrandFieldsFragment = {
  __typename?: 'Brand';
  clientId: string;
  brandId: string;
  name: string;
  logoUrl: string;
};

export type ClientFieldsFragment = {
  __typename?: 'Client';
  clientId: string;
  name: string;
  logoUrl: string;
};

export type ClientBrandsFragment = {
  __typename?: 'Client';
  clientId: string;
  name: string;
  logoUrl: string;
  brands: Array<{
    __typename?: 'Brand';
    clientId: string;
    brandId: string;
    name: string;
    logoUrl: string;
  }>;
};

export type GetClientsWithBrandsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClientsWithBrandsQuery = {
  __typename?: 'Query';
  getClients: Array<{
    __typename?: 'Client';
    clientId: string;
    name: string;
    logoUrl: string;
    brands: Array<{
      __typename?: 'Brand';
      clientId: string;
      brandId: string;
      name: string;
      logoUrl: string;
    }>;
  }>;
};

export type GetIdentityProvidersEnabledQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetIdentityProvidersEnabledQuery = {
  __typename?: 'Query';
  getIdentityProviders: Array<{
    __typename?: 'IdentityProvider';
    identityProviderId: string;
    name: string;
    imageUrl: string;
    startUrl: string;
    enabled: boolean;
  }>;
};

export type IdentityProviderFieldsFragment = {
  __typename?: 'IdentityProvider';
  identityProviderId: string;
  name: string;
  imageUrl: string;
  startUrl: string;
  enabled: boolean;
};

export type ChannelFieldsFragment = {
  __typename?: 'Channel';
  updatedAt: string;
  createdAt: string;
  channelId: string;
  name: string;
  description: string;
  icon: string;
};

export type GetChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetChannelsQuery = {
  __typename?: 'Query';
  getChannels: Array<{
    __typename?: 'Channel';
    updatedAt: string;
    createdAt: string;
    channelId: string;
    name: string;
    description: string;
    icon: string;
  }>;
};

export type GetProvidersByChannelIdQueryVariables = Exact<{
  channelId: Scalars['String']['input'];
}>;

export type GetProvidersByChannelIdQuery = {
  __typename?: 'Query';
  getProvidersByChannelId: Array<{
    __typename?: 'Provider';
    channelId: string;
    updatedAt: string;
    createdAt: string;
    providerId: string;
    name: string;
    description: string;
    enabled: boolean;
    icon: string;
  }>;
};

export type GetConfigurationByProviderAndChannelIdQueryVariables = Exact<{
  providerId: Scalars['String']['input'];
  channelId: Scalars['String']['input'];
}>;

export type GetConfigurationByProviderAndChannelIdQuery = {
  __typename?: 'Query';
  getConfigurationByProviderAndChannelId: {
    __typename?: 'Metadata';
    stepsLabel: Array<string>;
    stepsList: Array<any>;
    stepsName: Array<string>;
    updatedAt: string;
    createdAt: string;
  };
};

export type MetadataFieldsFragment = {
  __typename?: 'Metadata';
  stepsLabel: Array<string>;
  stepsList: Array<any>;
  stepsName: Array<string>;
  updatedAt: string;
  createdAt: string;
};

export type ProviderFieldsFragment = {
  __typename?: 'Provider';
  channelId: string;
  updatedAt: string;
  createdAt: string;
  providerId: string;
  name: string;
  description: string;
  enabled: boolean;
  icon: string;
};

export type ConfigFieldsFragment = {
  __typename?: 'Config';
  configId: string;
  providerId: string;
  channelId: string;
  createdAt: string;
  updatedAt: string;
  metadata: any;
};

export type CreateConfigMutationVariables = Exact<{
  createConfigInput: CreateConfigInput;
}>;

export type CreateConfigMutation = {
  __typename?: 'Mutation';
  createConfig: {
    __typename?: 'Config';
    configId: string;
    providerId: string;
    channelId: string;
    createdAt: string;
    updatedAt: string;
    metadata: any;
  };
};

export type GetConfigurationByFiltersQueryVariables = Exact<{
  channelId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
}>;

export type GetConfigurationByFiltersQuery = {
  __typename?: 'Query';
  getConfigurationByFilters: {
    __typename?: 'Config';
    configId: string;
    providerId: string;
    channelId: string;
    createdAt: string;
    updatedAt: string;
    metadata: any;
  };
};

export type GetOrderByIdQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
}>;

export type GetOrderByIdQuery = {
  __typename?: 'Query';
  getOrder: {
    __typename?: 'Order';
    clientId: string;
    brandId: string;
    projectId: string;
    orderId: string;
    state: string;
    project: {
      __typename?: 'Project';
      clientId: string;
      brandId: string;
      projectId: string;
      name: string;
      description: string;
      currentStageId: string;
      enabled: boolean;
      releaseDate: string;
      status: string;
      currentStageName: string;
      createdAt: string;
      updatedAt: string;
    };
    items: Array<{
      __typename?: 'OrderItem';
      clientId: string;
      brandId: string;
      projectId: string;
      orderId: string;
      orderItemId: string;
      name: string;
      productVariantId: string;
      sequence: number;
      plannedStartDate: string;
      plannedEndDate: string;
      sendToProduction: boolean;
      state: string;
      productVariant: {
        __typename?: 'ProductVariant';
        productVariantId: string;
        name: string;
        description: string;
        icon: string;
        enabled: boolean;
        productId: string;
        isEditStepsAble: boolean;
        productVariantType: string;
        product: {
          __typename?: 'Product';
          productId: string;
          name: string;
          description: string;
          icon: string;
          enabled: boolean;
        };
        productVariantStepsDefinitions: Array<{
          __typename?: 'ProductVariantStepDefinition';
          productVariantStepDefinitionId: string;
          productVariantId: string;
          name: string;
          description: string;
          icon: string;
          enabled: boolean;
          isOperational: boolean;
          sendToProduction: boolean;
          stepType: string;
          complexity: number;
          duration: number;
          order: number;
          metaData: any;
        }>;
      };
      steps: Array<{
        __typename?: 'OrderItemStep';
        clientId: string;
        brandId: string;
        projectId: string;
        orderId: string;
        orderItemId: string;
        orderItemStepId: string;
        productVariantStepDefinitionId: string;
        name: string;
        complexity: number;
        timeSpent: number;
        isOperational: boolean;
        sort: number;
        metaData: any;
        submissionData: any;
        state: string;
      }>;
    }>;
  };
};

export type GetOrdersQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  getOrders: Array<{
    __typename?: 'Order';
    clientId: string;
    brandId: string;
    projectId: string;
    orderId: string;
    state: string;
    project: {
      __typename?: 'Project';
      clientId: string;
      brandId: string;
      projectId: string;
      name: string;
      description: string;
      currentStageId: string;
      enabled: boolean;
      releaseDate: string;
      status: string;
      currentStageName: string;
      createdAt: string;
      updatedAt: string;
    };
    items: Array<{
      __typename?: 'OrderItem';
      clientId: string;
      brandId: string;
      projectId: string;
      orderId: string;
      orderItemId: string;
      name: string;
      productVariantId: string;
      sequence: number;
      plannedStartDate: string;
      plannedEndDate: string;
      sendToProduction: boolean;
      state: string;
      productVariant: {
        __typename?: 'ProductVariant';
        productVariantId: string;
        name: string;
        description: string;
        icon: string;
        enabled: boolean;
        productId: string;
        isEditStepsAble: boolean;
        productVariantType: string;
        product: {
          __typename?: 'Product';
          productId: string;
          name: string;
          description: string;
          icon: string;
          enabled: boolean;
        };
        productVariantStepsDefinitions: Array<{
          __typename?: 'ProductVariantStepDefinition';
          productVariantStepDefinitionId: string;
          productVariantId: string;
          name: string;
          description: string;
          icon: string;
          enabled: boolean;
          isOperational: boolean;
          sendToProduction: boolean;
          stepType: string;
          complexity: number;
          duration: number;
          order: number;
          metaData: any;
        }>;
      };
      steps: Array<{
        __typename?: 'OrderItemStep';
        clientId: string;
        brandId: string;
        projectId: string;
        orderId: string;
        orderItemId: string;
        orderItemStepId: string;
        productVariantStepDefinitionId: string;
        name: string;
        complexity: number;
        timeSpent: number;
        isOperational: boolean;
        sort: number;
        metaData: any;
        submissionData: any;
        state: string;
      }>;
    }>;
  }>;
};

export type GetProjectByIdQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
}>;

export type GetProjectByIdQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    clientId: string;
    brandId: string;
    projectId: string;
    name: string;
    description: string;
    currentStageId: string;
    enabled: boolean;
    releaseDate: string;
    status: string;
    currentStageName: string;
    createdAt: string;
    updatedAt: string;
    currentStage: {
      __typename?: 'ProjectStage';
      clientId: string;
      brandId: string;
      projectId: string;
      projectStageId: string;
      currentStepId: string;
      state: string;
      currentStep: {
        __typename?: 'ProjectStep';
        clientId: string;
        brandId: string;
        projectId: string;
        projectStageId: string;
        projectStepId: string;
        projectStepDefinitionId: string;
        metaData: any;
        state: string;
      };
    };
    orders: Array<{
      __typename?: 'Order';
      clientId: string;
      brandId: string;
      projectId: string;
      orderId: string;
      state: string;
    }>;
  };
};

export type GetProjectsQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  getProjects: Array<{
    __typename?: 'Project';
    clientId: string;
    brandId: string;
    projectId: string;
    name: string;
    description: string;
    currentStageId: string;
    enabled: boolean;
    releaseDate: string;
    status: string;
    currentStageName: string;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type GetOrderItemByIdQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  orderItemId: Scalars['String']['input'];
}>;

export type GetOrderItemByIdQuery = {
  __typename?: 'Query';
  getOrderItem: {
    __typename?: 'OrderItem';
    clientId: string;
    brandId: string;
    projectId: string;
    orderId: string;
    orderItemId: string;
    name: string;
    productVariantId: string;
    sequence: number;
    plannedStartDate: string;
    plannedEndDate: string;
    sendToProduction: boolean;
    state: string;
    productVariant: {
      __typename?: 'ProductVariant';
      productVariantId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
      productId: string;
      isEditStepsAble: boolean;
      productVariantType: string;
      product: {
        __typename?: 'Product';
        productId: string;
        name: string;
        description: string;
        icon: string;
        enabled: boolean;
      };
      productVariantStepsDefinitions: Array<{
        __typename?: 'ProductVariantStepDefinition';
        productVariantStepDefinitionId: string;
        productVariantId: string;
        name: string;
        description: string;
        icon: string;
        enabled: boolean;
        isOperational: boolean;
        sendToProduction: boolean;
        stepType: string;
        complexity: number;
        duration: number;
        order: number;
        metaData: any;
      }>;
    };
    steps: Array<{
      __typename?: 'OrderItemStep';
      clientId: string;
      brandId: string;
      projectId: string;
      orderId: string;
      orderItemId: string;
      orderItemStepId: string;
      productVariantStepDefinitionId: string;
      name: string;
      complexity: number;
      timeSpent: number;
      isOperational: boolean;
      sort: number;
      metaData: any;
      submissionData: any;
      state: string;
    }>;
  };
};

export type CreateProjectMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  createProjectInput: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: {
    __typename?: 'Project';
    clientId: string;
    brandId: string;
    projectId: string;
    name: string;
    description: string;
    currentStageId: string;
    enabled: boolean;
    releaseDate: string;
    status: string;
    currentStageName: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type GetProjectStageQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  projectStageId: Scalars['String']['input'];
}>;

export type GetProjectStageQuery = {
  __typename?: 'Query';
  getProjectStage: {
    __typename?: 'ProjectStage';
    clientId: string;
    brandId: string;
    projectId: string;
    projectStageId: string;
    currentStepId: string;
    state: string;
    project: {
      __typename?: 'Project';
      clientId: string;
      brandId: string;
      projectId: string;
      name: string;
      description: string;
      currentStageId: string;
      enabled: boolean;
      releaseDate: string;
      status: string;
      currentStageName: string;
      createdAt: string;
      updatedAt: string;
    };
    projectStageDefinition: {
      __typename?: 'ProjectStageDefinition';
      projectStageDefinitionId: string;
      projectCategoryId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
      order: number;
      projectStepsDefinitions: Array<{
        __typename?: 'ProjectStepDefinition';
        projectStepDefinitionId: string;
        projectStageDefinitionId: string;
        name: string;
        description: string;
        component: string;
        order: number;
        metaData: any;
      }>;
    };
    currentStep: {
      __typename?: 'ProjectStep';
      clientId: string;
      brandId: string;
      projectId: string;
      projectStageId: string;
      projectStepId: string;
      projectStepDefinitionId: string;
      metaData: any;
      state: string;
    };
    steps: Array<{
      __typename?: 'ProjectStep';
      clientId: string;
      brandId: string;
      projectId: string;
      projectStageId: string;
      projectStepId: string;
      projectStepDefinitionId: string;
      metaData: any;
      state: string;
    }>;
  };
};

export type ProjectFieldsFragment = {
  __typename?: 'Project';
  clientId: string;
  brandId: string;
  projectId: string;
  name: string;
  description: string;
  currentStageId: string;
  enabled: boolean;
  releaseDate: string;
  status: string;
  currentStageName: string;
  createdAt: string;
  updatedAt: string;
};

export type SingleProjectFieldsFragment = {
  __typename?: 'Project';
  clientId: string;
  brandId: string;
  projectId: string;
  name: string;
  description: string;
  currentStageId: string;
  enabled: boolean;
  releaseDate: string;
  status: string;
  currentStageName: string;
  createdAt: string;
  updatedAt: string;
  currentStage: {
    __typename?: 'ProjectStage';
    clientId: string;
    brandId: string;
    projectId: string;
    projectStageId: string;
    currentStepId: string;
    state: string;
    currentStep: {
      __typename?: 'ProjectStep';
      clientId: string;
      brandId: string;
      projectId: string;
      projectStageId: string;
      projectStepId: string;
      projectStepDefinitionId: string;
      metaData: any;
      state: string;
    };
  };
  orders: Array<{
    __typename?: 'Order';
    clientId: string;
    brandId: string;
    projectId: string;
    orderId: string;
    state: string;
  }>;
};

export type SingleProjectStageFieldsFragment = {
  __typename?: 'ProjectStage';
  clientId: string;
  brandId: string;
  projectId: string;
  projectStageId: string;
  currentStepId: string;
  state: string;
  currentStep: {
    __typename?: 'ProjectStep';
    clientId: string;
    brandId: string;
    projectId: string;
    projectStageId: string;
    projectStepId: string;
    projectStepDefinitionId: string;
    metaData: any;
    state: string;
  };
};

export type ProjectStageFieldsFragment = {
  __typename?: 'ProjectStage';
  clientId: string;
  brandId: string;
  projectId: string;
  projectStageId: string;
  currentStepId: string;
  state: string;
  project: {
    __typename?: 'Project';
    clientId: string;
    brandId: string;
    projectId: string;
    name: string;
    description: string;
    currentStageId: string;
    enabled: boolean;
    releaseDate: string;
    status: string;
    currentStageName: string;
    createdAt: string;
    updatedAt: string;
  };
  projectStageDefinition: {
    __typename?: 'ProjectStageDefinition';
    projectStageDefinitionId: string;
    projectCategoryId: string;
    name: string;
    description: string;
    icon: string;
    enabled: boolean;
    order: number;
    projectStepsDefinitions: Array<{
      __typename?: 'ProjectStepDefinition';
      projectStepDefinitionId: string;
      projectStageDefinitionId: string;
      name: string;
      description: string;
      component: string;
      order: number;
      metaData: any;
    }>;
  };
  currentStep: {
    __typename?: 'ProjectStep';
    clientId: string;
    brandId: string;
    projectId: string;
    projectStageId: string;
    projectStepId: string;
    projectStepDefinitionId: string;
    metaData: any;
    state: string;
  };
  steps: Array<{
    __typename?: 'ProjectStep';
    clientId: string;
    brandId: string;
    projectId: string;
    projectStageId: string;
    projectStepId: string;
    projectStepDefinitionId: string;
    metaData: any;
    state: string;
  }>;
};

export type SingleOrderFieldsFragment = {
  __typename?: 'Order';
  clientId: string;
  brandId: string;
  projectId: string;
  orderId: string;
  state: string;
};

export type OrderFieldsFragment = {
  __typename?: 'Order';
  clientId: string;
  brandId: string;
  projectId: string;
  orderId: string;
  state: string;
  project: {
    __typename?: 'Project';
    clientId: string;
    brandId: string;
    projectId: string;
    name: string;
    description: string;
    currentStageId: string;
    enabled: boolean;
    releaseDate: string;
    status: string;
    currentStageName: string;
    createdAt: string;
    updatedAt: string;
  };
  items: Array<{
    __typename?: 'OrderItem';
    clientId: string;
    brandId: string;
    projectId: string;
    orderId: string;
    orderItemId: string;
    name: string;
    productVariantId: string;
    sequence: number;
    plannedStartDate: string;
    plannedEndDate: string;
    sendToProduction: boolean;
    state: string;
    productVariant: {
      __typename?: 'ProductVariant';
      productVariantId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
      productId: string;
      isEditStepsAble: boolean;
      productVariantType: string;
      product: {
        __typename?: 'Product';
        productId: string;
        name: string;
        description: string;
        icon: string;
        enabled: boolean;
      };
      productVariantStepsDefinitions: Array<{
        __typename?: 'ProductVariantStepDefinition';
        productVariantStepDefinitionId: string;
        productVariantId: string;
        name: string;
        description: string;
        icon: string;
        enabled: boolean;
        isOperational: boolean;
        sendToProduction: boolean;
        stepType: string;
        complexity: number;
        duration: number;
        order: number;
        metaData: any;
      }>;
    };
    steps: Array<{
      __typename?: 'OrderItemStep';
      clientId: string;
      brandId: string;
      projectId: string;
      orderId: string;
      orderItemId: string;
      orderItemStepId: string;
      productVariantStepDefinitionId: string;
      name: string;
      complexity: number;
      timeSpent: number;
      isOperational: boolean;
      sort: number;
      metaData: any;
      submissionData: any;
      state: string;
    }>;
  }>;
};

export type ProjectStepFieldsFragment = {
  __typename?: 'ProjectStep';
  clientId: string;
  brandId: string;
  projectId: string;
  projectStageId: string;
  projectStepId: string;
  projectStepDefinitionId: string;
  metaData: any;
  state: string;
};

export type OrderItemFieldsFragment = {
  __typename?: 'OrderItem';
  clientId: string;
  brandId: string;
  projectId: string;
  orderId: string;
  orderItemId: string;
  name: string;
  productVariantId: string;
  sequence: number;
  plannedStartDate: string;
  plannedEndDate: string;
  sendToProduction: boolean;
  state: string;
  productVariant: {
    __typename?: 'ProductVariant';
    productVariantId: string;
    name: string;
    description: string;
    icon: string;
    enabled: boolean;
    productId: string;
    isEditStepsAble: boolean;
    productVariantType: string;
    product: {
      __typename?: 'Product';
      productId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
    };
    productVariantStepsDefinitions: Array<{
      __typename?: 'ProductVariantStepDefinition';
      productVariantStepDefinitionId: string;
      productVariantId: string;
      name: string;
      description: string;
      icon: string;
      enabled: boolean;
      isOperational: boolean;
      sendToProduction: boolean;
      stepType: string;
      complexity: number;
      duration: number;
      order: number;
      metaData: any;
    }>;
  };
  steps: Array<{
    __typename?: 'OrderItemStep';
    clientId: string;
    brandId: string;
    projectId: string;
    orderId: string;
    orderItemId: string;
    orderItemStepId: string;
    productVariantStepDefinitionId: string;
    name: string;
    complexity: number;
    timeSpent: number;
    isOperational: boolean;
    sort: number;
    metaData: any;
    submissionData: any;
    state: string;
  }>;
};

export type OrderItemStepFieldsFragment = {
  __typename?: 'OrderItemStep';
  clientId: string;
  brandId: string;
  projectId: string;
  orderId: string;
  orderItemId: string;
  orderItemStepId: string;
  productVariantStepDefinitionId: string;
  name: string;
  complexity: number;
  timeSpent: number;
  isOperational: boolean;
  sort: number;
  metaData: any;
  submissionData: any;
  state: string;
};

export type PlanProjectStepDefinitionFragment = {
  __typename?: 'ProjectStepDefinition';
  projectStepDefinitionId: string;
  projectStageDefinitionId: string;
  name: string;
  description: string;
  component: string;
  order: number;
  metaData: any;
};

export const ProjectStepDefinitionFieldsFragmentDoc = gql`
  fragment ProjectStepDefinitionFields on ProjectStepDefinition {
    projectStepDefinitionId
    projectStageDefinitionId
    name
    description
    component
    order
    metaData
  }
`;
export const ProjectStageDefinitionFieldsFragmentDoc = gql`
  fragment ProjectStageDefinitionFields on ProjectStageDefinition {
    projectStageDefinitionId
    projectCategoryId
    name
    description
    icon
    enabled
    order
    projectStepsDefinitions {
      ...ProjectStepDefinitionFields
    }
  }
  ${ProjectStepDefinitionFieldsFragmentDoc}
`;
export const ProjectCategoriesFieldsFragmentDoc = gql`
  fragment ProjectCategoriesFields on ProjectCategories {
    projectCategoryId
    name
    description
    enabled
    icon
    parentId
    projectStagesDefinitions {
      ...ProjectStageDefinitionFields
    }
  }
  ${ProjectStageDefinitionFieldsFragmentDoc}
`;
export const ClientFieldsFragmentDoc = gql`
  fragment ClientFields on Client {
    clientId
    name
    logoUrl
  }
`;
export const BrandFieldsFragmentDoc = gql`
  fragment BrandFields on Brand {
    clientId
    brandId
    name
    logoUrl
  }
`;
export const ClientBrandsFragmentDoc = gql`
  fragment ClientBrands on Client {
    ...ClientFields
    brands {
      ...BrandFields
    }
  }
  ${ClientFieldsFragmentDoc}
  ${BrandFieldsFragmentDoc}
`;
export const IdentityProviderFieldsFragmentDoc = gql`
  fragment IdentityProviderFields on IdentityProvider {
    identityProviderId
    name
    imageUrl
    startUrl
    enabled
  }
`;
export const ChannelFieldsFragmentDoc = gql`
  fragment ChannelFields on Channel {
    updatedAt
    createdAt
    channelId
    name
    description
    icon
  }
`;
export const MetadataFieldsFragmentDoc = gql`
  fragment MetadataFields on Metadata {
    stepsLabel
    stepsList
    stepsName
    updatedAt
    createdAt
  }
`;
export const ProviderFieldsFragmentDoc = gql`
  fragment ProviderFields on Provider {
    channelId
    updatedAt
    createdAt
    providerId
    name
    description
    enabled
    icon
  }
`;
export const ConfigFieldsFragmentDoc = gql`
  fragment ConfigFields on Config {
    configId
    providerId
    channelId
    createdAt
    updatedAt
    metadata
  }
`;
export const ProjectStepFieldsFragmentDoc = gql`
  fragment ProjectStepFields on ProjectStep {
    clientId
    brandId
    projectId
    projectStageId
    projectStepId
    projectStepDefinitionId
    metaData
    state
  }
`;
export const SingleProjectStageFieldsFragmentDoc = gql`
  fragment SingleProjectStageFields on ProjectStage {
    clientId
    brandId
    projectId
    projectStageId
    currentStepId
    state
    currentStep {
      ...ProjectStepFields
    }
  }
  ${ProjectStepFieldsFragmentDoc}
`;
export const SingleOrderFieldsFragmentDoc = gql`
  fragment SingleOrderFields on Order {
    clientId
    brandId
    projectId
    orderId
    state
  }
`;
export const SingleProjectFieldsFragmentDoc = gql`
  fragment SingleProjectFields on Project {
    clientId
    brandId
    projectId
    name
    description
    currentStageId
    enabled
    releaseDate
    status
    currentStageName
    createdAt
    updatedAt
    currentStage {
      ...SingleProjectStageFields
    }
    orders {
      ...SingleOrderFields
    }
  }
  ${SingleProjectStageFieldsFragmentDoc}
  ${SingleOrderFieldsFragmentDoc}
`;
export const ProjectFieldsFragmentDoc = gql`
  fragment ProjectFields on Project {
    clientId
    brandId
    projectId
    name
    description
    currentStageId
    enabled
    releaseDate
    status
    currentStageName
    createdAt
    updatedAt
  }
`;
export const PlanProjectStepDefinitionFragmentDoc = gql`
  fragment PlanProjectStepDefinition on ProjectStepDefinition {
    projectStepDefinitionId
    projectStageDefinitionId
    name
    description
    component
    order
    metaData
  }
`;
export const ProjectStageFieldsFragmentDoc = gql`
  fragment ProjectStageFields on ProjectStage {
    clientId
    brandId
    projectId
    projectStageId
    currentStepId
    state
    project {
      ...ProjectFields
    }
    projectStageDefinition {
      projectStageDefinitionId
      projectCategoryId
      name
      description
      icon
      enabled
      order
      projectStepsDefinitions {
        ...PlanProjectStepDefinition
      }
    }
    currentStep {
      ...ProjectStepFields
    }
    steps {
      ...ProjectStepFields
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${PlanProjectStepDefinitionFragmentDoc}
  ${ProjectStepFieldsFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on Product {
    productId
    name
    description
    icon
    enabled
  }
`;
export const ProductVariantStepDefinitionFieldsFragmentDoc = gql`
  fragment ProductVariantStepDefinitionFields on ProductVariantStepDefinition {
    productVariantStepDefinitionId
    productVariantId
    name
    description
    icon
    enabled
    isOperational
    sendToProduction
    stepType
    complexity
    duration
    order
    metaData
  }
`;
export const ProductVariantFieldsFragmentDoc = gql`
  fragment ProductVariantFields on ProductVariant {
    productVariantId
    name
    description
    icon
    enabled
    productId
    product {
      ...ProductFields
    }
    isEditStepsAble
    productVariantType
    productVariantStepsDefinitions {
      ...ProductVariantStepDefinitionFields
    }
  }
  ${ProductFieldsFragmentDoc}
  ${ProductVariantStepDefinitionFieldsFragmentDoc}
`;
export const OrderItemStepFieldsFragmentDoc = gql`
  fragment OrderItemStepFields on OrderItemStep {
    clientId
    brandId
    projectId
    orderId
    orderItemId
    orderItemStepId
    productVariantStepDefinitionId
    name
    complexity
    timeSpent
    isOperational
    sort
    metaData
    submissionData
    state
  }
`;
export const OrderItemFieldsFragmentDoc = gql`
  fragment OrderItemFields on OrderItem {
    clientId
    brandId
    projectId
    orderId
    orderItemId
    name
    productVariantId
    sequence
    plannedStartDate
    plannedEndDate
    sendToProduction
    state
    productVariant {
      ...ProductVariantFields
    }
    steps {
      ...OrderItemStepFields
    }
  }
  ${ProductVariantFieldsFragmentDoc}
  ${OrderItemStepFieldsFragmentDoc}
`;
export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on Order {
    clientId
    brandId
    projectId
    orderId
    state
    project {
      ...ProjectFields
    }
    items {
      ...OrderItemFields
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${OrderItemFieldsFragmentDoc}
`;
export const GetProjectCategoriesDocument = gql`
  query getProjectCategories {
    getProjectCategories {
      ...ProjectCategoriesFields
    }
  }
  ${ProjectCategoriesFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetProjectCategoriesGQL extends Apollo.Query<
  GetProjectCategoriesQuery,
  GetProjectCategoriesQueryVariables
> {
  override document = GetProjectCategoriesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetProductVariantsDocument = gql`
  query getProductVariants {
    getProductVariants {
      ...ProductVariantFields
    }
  }
  ${ProductVariantFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetProductVariantsGQL extends Apollo.Query<
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables
> {
  override document = GetProductVariantsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClientsWithBrandsDocument = gql`
  query getClientsWithBrands {
    getClients {
      ...ClientBrands
    }
  }
  ${ClientBrandsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetClientsWithBrandsGQL extends Apollo.Query<
  GetClientsWithBrandsQuery,
  GetClientsWithBrandsQueryVariables
> {
  override document = GetClientsWithBrandsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetIdentityProvidersEnabledDocument = gql`
  query getIdentityProvidersEnabled {
    getIdentityProviders {
      ...IdentityProviderFields
    }
  }
  ${IdentityProviderFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetIdentityProvidersEnabledGQL extends Apollo.Query<
  GetIdentityProvidersEnabledQuery,
  GetIdentityProvidersEnabledQueryVariables
> {
  override document = GetIdentityProvidersEnabledDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetChannelsDocument = gql`
  query getChannels {
    getChannels {
      ...ChannelFields
    }
  }
  ${ChannelFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetChannelsGQL extends Apollo.Query<
  GetChannelsQuery,
  GetChannelsQueryVariables
> {
  override document = GetChannelsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetProvidersByChannelIdDocument = gql`
  query getProvidersByChannelId($channelId: String!) {
    getProvidersByChannelId(id: $channelId) {
      ...ProviderFields
    }
  }
  ${ProviderFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetProvidersByChannelIdGQL extends Apollo.Query<
  GetProvidersByChannelIdQuery,
  GetProvidersByChannelIdQueryVariables
> {
  override document = GetProvidersByChannelIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetConfigurationByProviderAndChannelIdDocument = gql`
  query getConfigurationByProviderAndChannelId(
    $providerId: String!
    $channelId: String!
  ) {
    getConfigurationByProviderAndChannelId(
      providerId: $providerId
      channelId: $channelId
    ) {
      ...MetadataFields
    }
  }
  ${MetadataFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetConfigurationByProviderAndChannelIdGQL extends Apollo.Query<
  GetConfigurationByProviderAndChannelIdQuery,
  GetConfigurationByProviderAndChannelIdQueryVariables
> {
  override document = GetConfigurationByProviderAndChannelIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateConfigDocument = gql`
  mutation createConfig($createConfigInput: CreateConfigInput!) {
    createConfig(CreateConfigInput: $createConfigInput) {
      ...ConfigFields
    }
  }
  ${ConfigFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateConfigGQL extends Apollo.Mutation<
  CreateConfigMutation,
  CreateConfigMutationVariables
> {
  override document = CreateConfigDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetConfigurationByFiltersDocument = gql`
  query getConfigurationByFilters(
    $channelId: String!
    $clientId: String!
    $brandId: String!
  ) {
    getConfigurationByFilters(
      channelId: $channelId
      clientId: $clientId
      brandId: $brandId
    ) {
      ...ConfigFields
    }
  }
  ${ConfigFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetConfigurationByFiltersGQL extends Apollo.Query<
  GetConfigurationByFiltersQuery,
  GetConfigurationByFiltersQueryVariables
> {
  override document = GetConfigurationByFiltersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetOrderByIdDocument = gql`
  query getOrderById(
    $clientId: String!
    $brandId: String!
    $projectId: String!
    $orderId: String!
  ) {
    getOrder(
      clientId: $clientId
      brandId: $brandId
      projectId: $projectId
      id: $orderId
    ) {
      ...OrderFields
    }
  }
  ${OrderFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetOrderByIdGQL extends Apollo.Query<
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables
> {
  override document = GetOrderByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetOrdersDocument = gql`
  query getOrders($clientId: String!, $brandId: String!, $projectId: String!) {
    getOrders(clientId: $clientId, brandId: $brandId, projectId: $projectId) {
      ...OrderFields
    }
  }
  ${OrderFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetOrdersGQL extends Apollo.Query<
  GetOrdersQuery,
  GetOrdersQueryVariables
> {
  override document = GetOrdersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetProjectByIdDocument = gql`
  query getProjectById(
    $clientId: String!
    $brandId: String!
    $projectId: String!
  ) {
    getProject(clientId: $clientId, brandId: $brandId, id: $projectId) {
      ...SingleProjectFields
    }
  }
  ${SingleProjectFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetProjectByIdGQL extends Apollo.Query<
  GetProjectByIdQuery,
  GetProjectByIdQueryVariables
> {
  override document = GetProjectByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetProjectsDocument = gql`
  query getProjects($clientId: String!, $brandId: String!) {
    getProjects(clientId: $clientId, brandId: $brandId) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetProjectsGQL extends Apollo.Query<
  GetProjectsQuery,
  GetProjectsQueryVariables
> {
  override document = GetProjectsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetOrderItemByIdDocument = gql`
  query getOrderItemById(
    $clientId: String!
    $brandId: String!
    $projectId: String!
    $orderId: String!
    $orderItemId: String!
  ) {
    getOrderItem(
      clientId: $clientId
      brandId: $brandId
      projectId: $projectId
      orderId: $orderId
      id: $orderItemId
    ) {
      ...OrderItemFields
    }
  }
  ${OrderItemFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetOrderItemByIdGQL extends Apollo.Query<
  GetOrderItemByIdQuery,
  GetOrderItemByIdQueryVariables
> {
  override document = GetOrderItemByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateProjectDocument = gql`
  mutation createProject(
    $clientId: String!
    $brandId: String!
    $createProjectInput: CreateProjectInput!
  ) {
    createProject(
      clientId: $clientId
      brandId: $brandId
      createProjectInput: $createProjectInput
    ) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateProjectGQL extends Apollo.Mutation<
  CreateProjectMutation,
  CreateProjectMutationVariables
> {
  override document = CreateProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetProjectStageDocument = gql`
  query getProjectStage(
    $clientId: String!
    $brandId: String!
    $projectId: String!
    $projectStageId: String!
  ) {
    getProjectStage(
      clientId: $clientId
      brandId: $brandId
      projectId: $projectId
      id: $projectStageId
    ) {
      ...ProjectStageFields
    }
  }
  ${ProjectStageFieldsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetProjectStageGQL extends Apollo.Query<
  GetProjectStageQuery,
  GetProjectStageQueryVariables
> {
  override document = GetProjectStageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
