import {
  Component, inject, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatChipEditedEvent, MatChipInputEvent, MatChipsModule,
} from '@angular/material/chips';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  LiveAnnouncer,
} from '@angular/cdk/a11y';
import {
  COMMA, ENTER,
} from '@angular/cdk/keycodes';
export interface Tags {
  name: string;
}

/**
 *ChipsWithInputComponent
 */
@Component({
  selector: 'lib-chips-with-input',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
  ],
  templateUrl: './chips-with-input.component.html',
  styleUrls: [
    './chips-with-input.component.scss',
  ],
})
export class ChipsWithInputComponent {
  @Input() tags: Tags[] = [
  ];

  @Input() placeHolder = 'Enter tag name';

  addOnBlur = true;

  readonly separatorKeysCodes = [
    ENTER,
    COMMA,
  ] as const;


  announcer = inject(LiveAnnouncer);

  /**
   *Add
   * @param event -event
   */
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tags.push({
        name: value,
      });
    }

    // Clear the input value
    event.chipInput?.clear();
  }

  /**
   *Remove
   * @param fruit -fruit
   */
  remove(fruit: Tags): void {
    const index = this.tags.indexOf(fruit);

    if (index >= 0) {
      this.tags.splice(index, 1);

      this.announcer.announce(`Removed ${fruit}`);
    }
  }

  /**
   *edit
   * @param fruit -fruit
   * @param event -event
   */
  edit(fruit: Tags, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(fruit);
      return;
    }

    // Edit existing fruit
    const index = this.tags.indexOf(fruit);
    if (index >= 0) {
      this.tags[index].name = value;
    }
  }
}
