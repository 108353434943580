<div class="dummy-container">
  <form
    class="dummy-form"
    [formGroup]="dummyForm"
    (ngSubmit)="onSubmit()"
    #dummyFormElement
    novalidate
  >
    <mat-form-field
      class="full-width"
      appearance="outline"
      [hideRequiredMarker]="hideRequiredControl.value"
      [floatLabel]="getFloatLabelValue()"
    >
      <mat-label>Name</mat-label>
      <input
        matInput
        placeholder="Enter Your Name"
        required
        formControlName="name"
      />
      <mat-error *ngIf="dummyForm.controls['name'].hasError('required')">
        Please enter a name
      </mat-error>
    </mat-form-field>
    <mat-form-field
      class="full-width"
      appearance="outline"
      [hideRequiredMarker]="hideRequiredControl.value"
      [floatLabel]="getFloatLabelValue()"
    >
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder="Enter Your Email"
        required
        formControlName="email"
      />
      <mat-error *ngIf="dummyForm.controls['email'].hasError('required')">
        Please enter a email
      </mat-error>
      <mat-error *ngIf="dummyForm.controls['email'].hasError('email')">
        Please enter valid email
      </mat-error>
    </mat-form-field>
  </form>
</div>
