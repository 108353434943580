/**
 * Genera REST API
 * Genera REST API Definiton for all the services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Metadata { 
    /**
     * ISO Date when the entry was last updated
     */
    updatedAt: string;
    /**
     * ISO Date when the entry was created
     */
    createdAt: string;
    /**
     * Dynamic steps labels
     */
    stepsLabel: Array<string>;
    stepsList: object;
    /**
     * Dynamic stage name by step
     */
    stepsName: Array<string>;
}

