import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';

/**
 *This component comes beside all login components.
 */
@Component({
  selector: 'lib-login-welcome',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './login-welcome.component.html',
  styleUrls: [
    './login-welcome.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginWelcomeComponent {

  @Input() introTitle! : string;

  @Input() introDescription! : string;

  @Input() quoteFirst! : string;

  @Input() quoteTwo! : string;

  @Input() quoteThree! : string;
}
