<div class="card">
  <div class="card-body">
    <span class="channel-icon">
      <img class="card-image" src="{{ icon }}" alt="{{ name }}" />
    </span>
    <div class="channel-info">
      <h4 class="channel-name">{{ name }}</h4>
      <div class="channel-id">ID: {{ id }}</div>
    </div>
  </div>
  <div class="card-footer">
    <span>{{ title }}</span>
  </div>
</div>
