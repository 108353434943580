import {
  ChangeDetectionStrategy, Component,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

/**
 * Loader Bar Component
 */
@Component({
  selector: 'lib-loaderbar',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule,

  ],
  templateUrl: './loaderbar.component.html',
  styleUrls: [
    './loaderbar.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderbarComponent {
}
