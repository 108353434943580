import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  ChartDonutComponent,
} from '../chart-donut/chart-donut.component';

/**
 *  card-donut-blue component
 */
@Component({
  selector: 'lib-card-donut-blue',
  standalone: true,
  imports: [
    CommonModule,
    ChartDonutComponent,
  ],
  templateUrl: './card-donut-blue.component.html',
  styleUrls: [
    './card-donut-blue.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDonutBlueComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;

  @Input() totalValue!: number;
}
